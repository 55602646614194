/**
 * Ecol内部收发消息
 */
export enum EnumProjectMsgInternal {
  /** 立项文件新建 */
  ProjectProposalDocNew = '1001',
  /** 立项文件 添加 */
  ProjectProposalDocAdd = '1002',
  /** 立项文件 移除 */
  ProjectProposalDocRemove = '1003',
  /** 立项文件 删除 */
  ProjectProposalDocDelete = '1004',

  /** 参考对象(项目) 新建 */
  ProjectRefNew = '1101',
  /** 参考对象(项目) 添加 */
  ProjectRefAdd = '1102',
  /** 参考对象(项目) 移除 */
  ProjectRefRemove = '1103',
  /** 参考对象(项目) 删除 */
  ProjectRefDelete = '1104',

  /** 参考对象(计划/任务) 新建 */
  PlanRefNew = '2001',
  /** 参考对象(计划/任务) 添加 */
  PlanRefAdd = '2002',
  /** 参考对象(计划/任务) 移除 */
  PlanRefRemove = '2003',
  /** 参考对象(计划/任务) 删除 */
  PlanRefDelete = '2004',

  /** 交付物 新建 */
  PlanDeliverableNew = '2101',
  /** 交付物 添加 */
  PlanDeliverableAdd = '2102',
  /** 交付物 移除 */
  PlanDeliverableRemove = '2103',
  /** 交付物 删除 */
  PlanDeliverableDelete = '2104',
  /** 加入到现有流程*/
  PlanDeliverableAddToExistFlow = '2105',
  /** 按批次加入流程*/
  PlanDeliverableBatchAddToFlow = '2106',
  /** 加入到现有批次任务*/
  PlanDeliverableAddToBatchTask = '2107',
  /** 查看流程*/
  PlanDeliverableViewFlow = '2108',

  /** 立项文件 数据刷新 */
  ProjectProposalDataUpdated = '1000',
  /** 参考对象(项目) 数据刷新 */
  ProjectRefDataUpdated = '1100',
  /** 参考对象(计划/任务) 数据刷新 */
  PlanRefDataUpdated = '2000',
  /** 交付物 数据刷新 */
  PlanDeliverableDataUpdated = '2100',
  /** 打开对象 */
  OpenObj = 'openObj'
}

/**
 * 菜单项
 */
export enum EnumProjectToolStripItemKeys {
  /** 立项文件 新建 */
  TOOL_STRIP_ITEM_PROPOSAL_DOC_NEW = 'toolStripItemProposalDocNew',
  /** 立项文件 添加 */
  TOOL_STRIP_ITEM_PROPOSAL_DOC_ADD = 'toolStripItemProposalDocAdd',
  /** 立项文件 移除 */
  TOOL_STRIP_ITEM_PROPOSAL_DOC_REMOVE = 'toolStripItemProposalDocRemove',
  /** 立项文件 删除 */
  TOOL_STRIP_ITEM_PROPOSAL_DOC_DELETE = 'toolStripItemProposalDocDelete',

  /** 参考对象(项目) 新建 */
  TOOL_STRIP_ITEM_PROJECT_REF_OBJ_NEW = 'toolStripItemProjectRefNew',
  /** 参考对象(项目) 添加 */
  TOOL_STRIP_ITEM_PROJECT_REF_OBJ_ADD = 'toolStripItemProjectRefAdd',
  /** 参考对象(项目) 移除 */
  TOOL_STRIP_ITEM_PROJECT_REF_OBJ_REMOVE = 'toolStripItemProjectRefRemove',
  /** 参考对象(项目) 删除 */
  TOOL_STRIP_ITEM_PROJECT_REF_OBJ_DELETE = 'toolStripItemProjectRefDelete',

  /** 参考对象(计划/任务) 新建 */
  TOOL_STRIP_ITEM_PLAN_REF_OBJ_NEW = 'toolStripItemPlanRefNew',
  /** 参考对象(计划/任务) 添加 */
  TOOL_STRIP_ITEM_PLAN_REF_OBJ_ADD = 'toolStripItemPlanRefAdd',
  /** 参考对象(计划/任务) 移除 */
  TOOL_STRIP_ITEM_PLAN_REF_OBJ_REMOVE = 'toolStripItemPlanRefRemove',
  /** 参考对象(计划/任务) 删除 */
  TOOL_STRIP_ITEM_PLAN_REF_OBJ_DELETE = 'toolStripItemPlanRefDelete',

  /** 交付物 新建 */
  TOOL_STRIP_ITEM_PLAN_DELIVERABLE_NEW = 'toolStripItemDeliverableNew',
  /** 交付物 添加 */
  TOOL_STRIP_ITEM_PLAN_DELIVERABLE_ADD = 'toolStripItemDeliverableAdd',
  /** 交付物 移除 */
  TOOL_STRIP_ITEM_PLAN_DELIVERABLE_REMOVE = 'toolStripItemDeliverableRemove',
  /** 交付物 删除 */
  TOOL_STRIP_ITEM_PLAN_DELIVERABLE_DELETE = 'toolStripItemDeliverableDelete',

  /** 操作 */
  TOOL_STRIP_GROUP_OPERATION_DELIVERABLE = 'operationDeliverable',
  /** 查看流程 */
  TOOL_STRIP_ITEM_VIEW_FLOW_OBJ_DELIVERABLE = 'toolStripItemDeliverableViewFlowObj',
  /** 加入到现有流程 */
  TOOL_STRIP_ITEM_ADD_TO_EXIST_FLOW_DELIVERABLE = 'toolStripItemDeliverableAddToExistFlow',
  /** 按批次加入流程 */
  TOOL_STRIP_ITEM_BATCH_ADD_TO_FLOW_DELIVERABLE = 'toolStripItemDeliverableBatchAddToFlow',
  /** 加入到现有批次任务 */
  TOOL_STRIP_ITEM_ADD_TO_BATCH_TASK_DELIVERABLE = 'toolStripItemDeliverableAddToBatchTask',
  /** 交付物 刷新 */
  TOOL_STRIP_ITEM_REFRESH_DELIVERABLE = 'toolStripItemDeliverableRefresh'
}

/** 项目管理消息类型 */
export enum EnumPMSMessageType {
  OpenUrl = 'OpenUrl',
  /** 打开对象详情*/
  PMS_OPEN_OBJECT = 'PMS_OPEN_OBJECT',
  /** 加入到现有流程*/
  AddToExistFlow = 'AddToExistFlow',
  /** 按批次加入流程*/
  BatchAddToFlow = 'BatchAddToFlow',
  /** 加入到现有批次任务*/
  AddToBatchTask = 'AddToBatchTask',
  /** 查看流程*/
  ViewFlow = 'ViewFlow',
  /** 结构管理 */
  TOOL_STRIP_ITEM_STRUC_MANAGE = 'toolStripItemStrucManage',

  /** 计划参考对象 新建*/
  PMS_CREATE_PLAN_REF = 'PMS_CREATE_PLAN_REF',
  /** 计划参考对象 添加*/
  PMS_ADD_PLAN_REF = 'PMS_ADD_PLAN_REF',

  /** 项目立项文档 新建*/
  PMS_CREATE_DOC = 'PMS_CREATE_DOC',
  /** 项目立项文档 添加*/
  PMS_ADD_DOC = 'PMS_ADD_DOC',

  /** 项目参考对象 新建*/
  PMS_CREATE_PROJECT_REF = 'PMS_CREATE_PROJECT_REF',
  /** 项目参考对象 添加*/
  PMS_ADD_PROJECT_REF = 'PMS_ADD_PROJECT_REF',

  /** 任务参考对象 添加*/
  PMS_ADD_TASK_REF = 'PMS_ADD_TASK_REF',

  /** 任务交付物 新建*/
  PMS_CREATE_TASK_DELIVERABLE = 'PMS_CREATE_TASK_DELIVERABLE',
  /** 任务交付物 添加*/
  PMS_ADD_TASK_DELIVERABLE = 'PMS_ADD_TASK_DELIVERABLE',
  //管理评审检查项证明文档 新建
  PMS_CREATE_MANA_REVIEW_DOC = 'PMS_CREATE_MANA_REVIEW_DOC',
  //管理评审检查项证明文档 添加
  PMS_ADD_MANA_REVIEW_DOC = 'PMS_ADD_MANA_REVIEW_DOC'
}
