import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fbb305f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%","width":"100%","padding":"10px 0px","box-sizing":"border-box"} }
const _hoisted_2 = { style: {"height":"15%","padding-top":"15px"} }
const _hoisted_3 = { style: {"width":"100%","display":"flex"} }
const _hoisted_4 = { style: {"height":"85%"} }
const _hoisted_5 = { style: {"display":"flex","width":"100%","height":"100%"} }
const _hoisted_6 = { style: {"width":"45%"} }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { style: {"width":"10%","height":"100%","display":"flex","align-items":"center","justify-content":"center"} }
const _hoisted_9 = { style: {"width":"45%","height":"100%"} }
const _hoisted_10 = { style: {"width":"100%","height":"10%","padding-bottom":"8px"} }
const _hoisted_11 = { style: {"height":"90%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_col = _resolveComponent("k-col")!
  const _component_k_row = _resolveComponent("k-row")!
  const _component_k_form = _resolveComponent("k-form")!
  const _component_KCollectRelatedObject = _resolveComponent("KCollectRelatedObject")!
  const _component_k_tab_pane = _resolveComponent("k-tab-pane")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_input_search = _resolveComponent("k-input-search")!
  const _component_k_tabs = _resolveComponent("k-tabs")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_k_form, {
            ref: _ctx.$vm.refForm,
            model: _ctx.$vm.formData,
            rules: _ctx.rules,
            "label-col": { span: 4 },
            "wrapper-col": { span: 18 },
            class: "form-style"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_row, { span: "24" }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_col, { span: "24" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_k_form_item, {
                        label: "流程模板",
                        name: "templateId"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_k_input, {
                              class: "input-box",
                              value: _ctx.$vm.workflowTemplateName,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.workflowTemplateName) = $event)),
                              disabled: true
                            }, null, 8, ["value"]),
                            _createVNode(_component_k_button, {
                              class: "k-reference-editor-selector",
                              onClick: _ctx.$vm.openWorkflowTemplateSelector
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_k_icon, { type: "select-arrow" })
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "rules"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createVNode(_component_k_tabs, {
            activeKey: _ctx.$vm.activeKey,
            "onUpdate:activeKey": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.activeKey) = $event))
          }, {
            default: _withCtx(() => [
              (_ctx.$vm.showRelatedObject)
                ? (_openBlock(), _createBlock(_component_k_tab_pane, {
                    key: "2",
                    tab: "相关对象",
                    forceRender: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_KCollectRelatedObject, {
                        ref: _ctx.$vm.refCollectRelatedObject,
                        "obj-params": _ctx.objParams,
                        templateKey: _ctx.$vm.formData.templateKey
                      }, null, 8, ["obj-params", "templateKey"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_k_tab_pane, {
                key: "3",
                tab: "执行人"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_k_data_grid, {
                        ref: _ctx.$vm.refExecutorDataGrid,
                        rowKey: "id",
                        isMultipleSelection: false,
                        selectFirstRow: true,
                        loadData: _ctx.$vm.loadDataExecutor,
                        rowModelType: _ctx.rowModelType,
                        onRowClick: _cache[1] || (_cache[1] = (event)=>_ctx.$vm.refreshUserGrid())
                      }, {
                        columns: _withCtx(() => [
                          _createVNode(_component_k_data_grid_text_box_column, {
                            id: "name",
                            name: "name",
                            dataPropertyName: "name",
                            headerText: "流程活动",
                            rowSpanMode: _ctx.rowSpanMode,
                            align: "center",
                            width: "180"
                          }, null, 8, ["rowSpanMode"]),
                          _createVNode(_component_k_data_grid_template_column, {
                            id: "executorName",
                            name: "executorName",
                            dataPropertyName: "executorName",
                            headerText: "执行人名称",
                            align: "center",
                            width: "189"
                          }, {
                            default: _withCtx(({ cellValue}) => [
                              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_k_data_grid_template_column, {
                            id: "action",
                            name: "action",
                            fixed: "right",
                            align: "left",
                            headerText: "操作",
                            width: "80"
                          }, {
                            default: _withCtx(({row}) => [
                              _createElementVNode("a", {
                                class: "row-operation-item",
                                onClick: (e) => _ctx.$vm.delete(row.id)
                              }, "移除", 8, _hoisted_7)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["loadData", "rowModelType"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_k_button, {
                        type: "primary",
                        onClick: _ctx.$vm.addExecutor
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_k_icon, { type: "backward" })
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_k_input_search, {
                          value: _ctx.$vm.userCondition,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.userCondition) = $event)),
                          placeholder: "请输入姓名或邮箱",
                          onSearch: _cache[3] || (_cache[3] = () => _ctx.$vm.searchUser())
                        }, null, 8, ["value"])
                      ]),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_k_data_grid, {
                          ref: _ctx.$vm.refUserDataGrid,
                          rowKey: "id",
                          isMultipleSelection: true,
                          loadData: _ctx.$vm.loadDataUser,
                          rowModelType: _ctx.rowModelType
                        }, {
                          columns: _withCtx(() => [
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "name",
                              name: "name",
                              dataPropertyName: "name",
                              headerText: "姓名",
                              align: "center",
                              width: "190"
                            }, {
                              default: _withCtx(({ cellValue}) => [
                                _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "email",
                              name: "email",
                              dataPropertyName: "email",
                              headerText: "邮箱",
                              align: "center",
                              width: "211"
                            })
                          ]),
                          _: 1
                        }, 8, ["loadData", "rowModelType"])
                      ])
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["activeKey"]), [
            [_vShow, _ctx.$vm.formData.templateId != '']
          ])
        ])
      ])
    ]),
    _: 1
  }))
}