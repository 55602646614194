import {
  Api,
  CommonClientSrv,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectClassGridViewModel,
  KObjectPanelPropType,
  ObjBusinessParam,
  ObjectToolStripItem,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumItemClickedCancelType,
  KNotification,
  ToolStripItemClickedEventArgs,
  VNodeProps,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { ref, resolveComponent, watch } from 'vue'
import { PartClientSrv, PartStructureClientSrv, SortChildPartItem } from '../../../client-srv'
import { EnumWorkState } from '../../../controls/part-edit-panel/interface'
import { IQuantityUnit, KPartStructEmitsType, KPartStructPropType } from './interface'

/** KPartStruct */
export default class KPartStructViewModel extends BaseViewModel<KPartStructEmitsType, KPartStructPropType> {
  /**
   * 工具栏定义
   */
  toolItem = ref<Array<ObjectToolStripItem>>(ToolStripHelper.getPartStructureOperationToolStripItems())
  /**
   * 网格对象
   */
  refObjGrid = ref<KObjectClassGridViewModel>()

  objParams = ref<ObjBusinessParam>()

  /**
   * 零部件工作状态
   */
  partWorkingState = ref<string>('')

  quantityUnitOptions = ref<Array<IQuantityUnit>>()

  constructor(options: ViewModelOptions<KPartStructPropType>) {
    super(options)
    this.objParams.value = options.props.objParam

    watch(
      () => options.props.objParam,
      (newVal, oldVal) => {
        // 获取当前网格是否处于编辑状态
        const isEditing = this.refObjGrid.value?.getIsEditing()
        if (isEditing && newVal && newVal.masterId == oldVal?.masterId) {
          this.objParams.value = this.props.objParam
          this.initToolStripItem(true)
          this.refObjGrid.value?.beginEdit()
          return
        }
        // 树节点切换刷新网格初始化工具栏
        this.objParams.value = this.props.objParam
        this.initToolStripItem(false)
        // this.refObjGrid.value?.refresh()
        this.refObjGrid.value?.endEdit(true)
      },
      {
        immediate: true
      }
    ),
      watch(
        () => this.objParams.value?.id,
        (newVal, oldVal) => {
          if (newVal && newVal != oldVal) {
            this.refObjGrid.value?.refresh()
          }
        },
        {
          immediate: true
        }
      ),
      watch(
        () => options.props.attachParams.QueryRule,
        () => {
          this.refObjGrid.value?.refresh()
        },
        {
          immediate: true
        }
      )
  }

  viewDidMount() {
    this.initToolStripItem(false)
    this.getQuantityUnit()
  }
  /**
   * 初始化工具栏
   */
  initToolStripItem(isEditing: boolean) {
    // 判断当前对象是否检出
    Api.post('part', 'Part', 'get', { data: [this.objParams.value!.id] }).then(result => {
      if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
        const workingState = result.data.workingState
        this.partWorkingState.value = workingState
        if (workingState == EnumWorkState.CHECKED_IN) {
          this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART, true)
          this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART, false)
          this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART, false)
        } else {
          this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART, false)
          this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART, true)
          this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART, true)
        }
        // 修改工具栏状态
        this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PART, !isEditing)
        this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, isEditing)
      } else {
        KNotification.warn({
          message: '系统提示',
          description: result.message
        })
        return
      }
    })
  }
  /**
   * 获取单位候选值
   */
  async getQuantityUnit() {
    const result = (await Api.post('part', 'BomLink', 'getQuantityUnit', { data: [] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      this.quantityUnitOptions.value = result.data
    }
  }

  async loadData() {
    return this.listBomLinkBySource()
  }

  /**
   * 查询零部件结构列表根据源对象
   *
   * @returns
   */
  async listBomLinkBySource() {
    const ruleParam = this.props.attachParams.QueryRule
    const requestData = {
      id: this.objParams.value!.id,
      clazz: 'BOMLink',
      className: 'BOMLink'
    }
    const queryRule: Record<string, any> = { type: 'LATEST' }
    if (ruleParam?.viewName == '最新版本') {
      queryRule.type = 'LATEST_WITH_WORKING_COPY'
    } else if (ruleParam?.viewName == '最新发布版本') {
      queryRule.type = 'LATEST_RELEASED'
    } else if (ruleParam?.viewName == '时间有效性') {
      queryRule.type = 'DATED_EFFECTIVITY'
      queryRule.datedValue = ruleParam?.datedValue
    } else if (ruleParam?.baselineObj) {
      queryRule.type = 'BASELINE'
      queryRule.baseline = { clazz: ruleParam?.baselineObj.modelCode, id: ruleParam?.baselineObj.id }
    }
    const result = (await Api.post('part', 'BomLink', 'listBomLinkBySource', { data: [requestData, queryRule] })) as any

    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      if (result.data.length == 0) {
        this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT, false)
      } else {
        this.refObjGrid.value?.setToolStripItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT, true)
      }
      const resultData = result.data.map((data: any) => {
        return {
          ...data,
          number: data.target.number,
          name: data.targetPart.name,
          unitName: data.unitEnum,
          version: data.targetPart.version,
          iteration: data.targetPart.iteration,
          workingState: data.targetPart.workingState,
          checkOutTime: data.targetPart.checkOutTime
        }
      })
      return resultData
      // return {
      //   rows: resultData,
      //   total: resultData.length
      // }
    } else {
      return []
      // return {
      //   rows: [],
      //   total: 0
      // }
    }
  }

  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART:
        this.checkout()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART:
        this.checkin()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART:
        this.undoCheckout()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PART:
        this.edit()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.save()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_OPEN_OBJ:
        //打开
        this.openObj()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        //刷新
        this.refresh()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SORT:
        //调整零部件顺序
        this.changePartPosition()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      default:
        break
    }
  }
  /**
   * 编辑
   */
  async edit() {
    /**
     * 如果对象未检出则先检出对象
     */
    if (this.partWorkingState.value == EnumWorkState.CHECKED_IN) {
      // 获取对象id
      const result = await Api.post('part', 'Part', 'checkout', { data: [this.objParams.value!.id] })
      if (result.code == EnumRequestCode.SUCCESS) {
        KNotification.success({
          title: '系统提示',
          content: '检出成功'
        })

        // 检出后的副本对象
        this.objParams.value!.id = result.data.id
        // this.emit('dataUpdated')
        this.emit('retrieve', { command: 'partNodeObjectUpdate', data: { id: result.data.id } })
      } else {
        KNotification.error({
          title: '操作失败',
          content: result.message || '检出失败',
          details: result.detail
        })
        return
      }
    }
    // 网格开启编辑
    this.refObjGrid.value?.beginEdit()
    // this.refObjGrid.value?.refresh()
    this.initToolStripItem(true)
  }
  /**
   * 保存
   */
  async save() {
    this.refObjGrid.value?.endEdit(true)
    await CommonClientSrv.sleep(200)
    const changedRows = this.refObjGrid.value?.getChangedRows()

    const modifiedRows = changedRows?.modifiedRows
    if (modifiedRows && modifiedRows.length > 0) {
      const updateDataList = modifiedRows?.map(element => {
        const updateData = {
          id: element.id,
          quantity: element.quantity,
          occurrence: element.occurrence
        } as Record<string, any>
        if (element.unitName) {
          const unit = this.quantityUnitOptions.value?.filter(unit => unit.unitSymbol == element.unitName)[0]
          updateData.unit = {
            id: unit!.id,
            clazz: 'MeasuringUnit'
          }
        }

        return updateData
      })
      //更新数据
      const result = await Api.post('part', 'BomLink', 'batchUpdate', { data: [updateDataList] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        // this.emit('dataUpdated')
      } else {
        KNotification.warn({
          message: '修改失败',
          description: result.message
        })
        this.refObjGrid.value?.beginEdit()
        return
      }
    }
    this.refObjGrid.value?.endEdit(true)
    this.refObjGrid.value?.refresh()
    this.initToolStripItem(false)
  }
  /**
   * 检出
   */
  async checkout() {
    PartClientSrv.checkoutPart(this.objParams.value!.id).then((res: any) => {
      if (res) {
        this.objParams.value!.id = res.id
        this.emit('retrieve', { command: 'partNodeObjectUpdate', data: { id: res.id } })
        this.refObjGrid.value?.endEdit(true)
        // this.refObjGrid.value?.refresh()
        this.initToolStripItem(false)
      }
    })
  }
  /**
   * 取消检出
   */
  async undoCheckout() {
    PartClientSrv.undoCheckoutPart(this.objParams.value!.id).then((res: any) => {
      if (res) {
        this.objParams.value!.id = res.id
        this.emit('retrieve', { command: 'partNodeObjectUpdate', data: { id: res.id } })
        this.refObjGrid.value?.endEdit(true)
        // this.refObjGrid.value?.refresh()
        this.initToolStripItem(false)
      }
    })
  }
  /**
   * 检入
   */
  async checkin() {
    PartClientSrv.checkInPart(this.objParams.value!.id).then((res: any) => {
      if (res) {
        this.objParams.value!.id = res.id
        this.emit('retrieve', { command: 'partNodeObjectUpdate', data: { id: res.id } })
        this.refObjGrid.value?.endEdit(true)
        this.refObjGrid.value?.refresh()
        this.initToolStripItem(false)
      }
    })
  }
  /**
   * 刷新
   */
  refresh() {
    this.refObjGrid.value?.refresh()
  }
  /**
   * 打开对象
   */
  openObj() {
    const selectedRow = this.refObjGrid.value?.getSelectedRow()
    if (!selectedRow) {
      KNotification.warning({
        title: '系统提示',
        content: '请选择一个对象打开'
      })
      return
    }
    const param = { id: selectedRow.targetPart.id, modelCode: 'Part', modelGroup: 'part' }
    const name = selectedRow.name
    const number = selectedRow.number
    const partViewEnum = selectedRow.partViewEnum === 'DESIGN' ? '设计' : '制造'
    const version = selectedRow.version + '.' + selectedRow.iteration
    const workingState =
      selectedRow.workingState === 'INWORK' ? '工作中' : 'CHECKED_IN' ? '已检入' : 'CHECKED_OUT' ? '已检出' : '未知'

    /** 标签页标识 */
    const tabKey = `${param.id}#${param.modelCode}`
    /** 标题 */
    const title = `${number}` + ':' + `${name}` + ':' + `${partViewEnum}` + ':' + `${version}` + ':' + `${workingState}`
    /** 面板参数 */
    const panelProps: VNodeProps<KObjectPanelPropType> = { objParam: param }

    // 打开页面
    CommonClientSrv.openPage(title, () => resolveComponent('KObjectPanel'), panelProps, tabKey)
    //ObjectClientSrv.openObj(param)
  }

  /**
   * 调整零部件顺序
   */
  async changePartPosition() {
    const rows = this.refObjGrid.value?.getRows()
    const parts = rows!.map(a => {
      return { id: a.id, name: a.targetPart.name, orderId: a.sequenceNumber } as SortChildPartItem
    })
    /** 排序结果 */
    const result = await PartStructureClientSrv.sortChildParts({ objParam: this.objParams.value!, childParts: parts })

    if (result) {
      await this.refresh()
    }
  }
}
