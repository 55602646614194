import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  SelectOption,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KFileComparisonPropOptions = {
  ...BaseViewPropOptions,
  operateType: VuePropTypes.createType<EnumOperateType>().def(),
  operateOptions: VuePropTypes.createType<Array<SelectOption>>().def([
    { label: '更新', value: 'Update' },
    { label: '忽略', value: 'Ignore' },
    { label: '不更新', value: 'UnUpdate' }
  ]),
  /** 对象参数 */
  comparisonResult: VuePropTypes.array<Record<string, any>>().def([])
}

/** 参数类型 **/
export type KFileComparisonPropType = ViewPropsTypeExtract<typeof KFileComparisonPropOptions>

/** 事件 */
export const KFileComparisonEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KFileComparisonEmitsType = ViewEmitsTypeExtract<typeof KFileComparisonEventEmits>

/**
 * 文件对比结果
 */
export interface IFileComparisonResult {
  // id
  id: string
  //名称
  name: string
  //编码
  number: string
  //是否存在差异
  diff: Boolean
  //父id
  parentId: string
  //当前文件
  currentFile: IFileInfo
  //系统文件
  systemFile: IFileInfo
}

/**
 * 文件信息
 */
export interface IFileInfo {
  path: string
  name: string
  md5: string
  size: string
}

/** 操作类型 */
export enum EnumOperateType {
  /** 更新 */
  Update = 'Update',
  /** 忽略 */
  Ignore = 'Ignore',
  /** 不更新 */
  UnUpdate = 'UnUpdate'
}
