import {
  BaseViewModel,
  EnumDialogResult,
  KDialog,
  KDialogClosingEvent,
  SimpleViewModel,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { Api, EnumRequestCode, ObjBusinessResult } from '../../client-srv'
import KFolderSelectorForm, { KFolderSelectorFormViewModel } from './form/k-folder-selector-form'
import { KFolderSelectorEmitsType, KFolderSelectorPropType } from './interface'
import { Ref } from 'vue'
import { isObject } from 'lodash'

/** KFolderSelector */
export default class KFolderSelectorViewModel extends SimpleViewModel<KFolderSelectorEmitsType, KFolderSelectorPropType> {
  /**
   * 文件夹路径
   */
  folderPath = ref<string>('')
  /**
   * 文件夹Id
   */
  folderId = ref<string>('')

  /**记录旧值 */
  originalValue = ref<string>()
  constructor(options: ViewModelOptions<KFolderSelectorPropType>) {
    super(options)
    watch(
      () => options.props.value,
      (newValue, oldValue) => {
        if (isObject(newValue)) {
          this.setFolderId((newValue as Record<string, any>).id)
        } else {
          this.setFolderId(newValue)
        }
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  viewDidMount() {}
  /**
   * 获取文件夹路径
   */
  async getFolderPath() {
    if (!this.folderId.value) {
      return
    }
    const param = {
      data: [this.folderId.value]
    }
    const result = await Api.post('folder', 'Folder', 'getFolderPath', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.folderPath.value = result.data
    }
  }
  /**
   * 设置值
   * @param value 业务对象数据
   * @param setChanged 设置是否更改
   */
  public setValue(value: ObjBusinessResult | undefined, setChanged?: boolean) {
    if (value) {
      setTimeout(() => {
        if (setChanged) {
          this.originalValue.value = value!.id
        }
        if (this.folderId.value != value.id!) {
          this.folderId.value = value.id!
          this.getFolderPath()
        }
      }, 50)
    }
  }
  /**
   * 设置文件夹Id
   * @param value 文件夹Id
   */
  setFolderId(value: string) {
    setTimeout(() => {
      this.folderId.value = value
      this.getFolderPath()
    }, 50)
  }
  /**
   * 获取属性
   */
  public getValue() {
    if (!this.props.allData) {
      return {
        id: this.folderId.value,
        clazz: 'Folder'
      }
    } else {
      return {
        id: this.folderId.value,
        clazz: 'Folder',
        fullPath: this.folderPath.value
      }
    }
  }
  /**
   * 打开文件夹选择弹框
   */
  openFolderSelector() {
    if (this.props.isDesigner) return
    KDialog.show({
      title: '选择文件夹',
      size: { width: 640, height: 520 },
      props: { folderId: this.folderId.value, showWorkspaceType: this.props.showWorkspaceType },
      content: KFolderSelectorForm,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
        /** 当前组件 */
        const formViewModel = event.viewModel as KFolderSelectorFormViewModel

        /** 获取表单值 */
        const folderId = formViewModel.getFolderId()
        this.folderId.value = folderId
        /** 判断folderId是否为空，若为空则将文件夹路径清空 */
        if (folderId == '' || folderId == null) {
          this.folderPath.value = ''
        } else {
          this.getFolderPath()
        }
        this.emit('update:value', folderId)
      }
    })
  }

  /**
   * 清空
   */
  cleanForce() {
    this.folderId.value = ''
    this.folderPath.value = ''
  }

  isModified(): boolean {
    return this.originalValue.value != this.folderId.value
  }

  getModifiedValue() {
    return this.getValue()
  }
}
