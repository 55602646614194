import { connect, withInstall } from '@kmsoft/upf-core'
import KRequireSearchFromView from './KRequireSearchFrom.vue'
import KRequireSearchFromViewModel from './KRequireSearchFromViewModel'

const KRequireSearchFrom = connect(KRequireSearchFromView, KRequireSearchFromViewModel)

export default withInstall(KRequireSearchFrom)
export { KRequireSearchFrom, KRequireSearchFromView, KRequireSearchFromViewModel }

// 模板生成文件
// export * from './{{folderName}}'
