import { BaseViewModel, ViewModelOptions, AppContext, request, KModal } from '@kmsoft/upf-core'
import { KOfficeDocumentEditorEmitsType, KOfficeDocumentEditorPropType, config } from './interface'
import { ref } from 'vue'
import { uuid } from '@kmsoft/upf-core/src/toolkits/utils/uuid'

/** KOfficeDocumentEditor */
export default class KOfficeDocumentEditorViewModel extends BaseViewModel<
  KOfficeDocumentEditorEmitsType,
  KOfficeDocumentEditorPropType
> {
  config = ref<Record<string, any>>({})
  retryNum = ref<number>(0)
  keyId = ref<string>()
  constructor(options: ViewModelOptions<KOfficeDocumentEditorPropType>) {
    super(options)
  }

  viewDidMount() {
    this.keyId.value = this.props.modelName + '-' + this.props.attributeName + '-' + this.props.id + '-' + uuid()
    this.setConfig()
  }

  setConfig() {
    const url = this.getUrl()
    const callbackUrl = this.getCallbackUrl()
    const documentType = this.handleDocType(this.props.fileType)
    const config = {
      documentType,
      document: {
        title: this.props.title,
        url,

        // 当前用户对于当前文档的操作权限
        permissions: {
          print: false,
          download: true //用户是否可以下载
        },
        fileType: this.props.fileType, //文件类型
        //onlyoffice用key做文件缓存索引，推荐每次都随机生成一下，不然总是读取缓存
        key: this.keyId.value
      },
      editorConfig: {
        // 编辑器常规配置
        customization: {
          // 自动保存可以关闭，常规ctrl+s更好用
          autosave: false,
          compactToolbar: true,
          forcesave: true,
          toolbarNoTabs: true,
          help: false,
          compactHeader: true,
          hideRightMenu: true,
          logo: {
            //自定义logo配置
            image: '',
            imageDark: '',
            url: '',
            visible: false
          }
        },
        enableCommentRights: this.props.enableCommentRights,
        mode: this.props.mode, //view为只能浏览  edit为编辑
        //这个回调及其的重要
        callbackUrl,
        // 菜单显示语言
        lang: 'zh-CN',
        // 当前操作用户信息
        user: {
          name: AppContext.current.getIdentity()?.name,
          id: AppContext.current.getIdentity()?.id
        }
      },
      events: {
        onDocumentStateChange: (event: any) =>
          setTimeout(() => {
            this.onDocumentSave(event, {
              key: this.keyId.value
            }) // 修改文档时调用的函数
          }, 500)
      }
    }
    this.config.value = config
  }

  getUrl() {
    const host = window.location.host
    const pathName = window.location.pathname
    if (this.props.url) {
      return this.props.url
    }
    return `http://${host}${pathName}kmsaasFileApi/ilePreview/${this.props.modelName}/${this.props.id}`
    // return `http://plm.kmsoft.com.cn/kmsaasFileApi/ilePreview/${this.props.modelName}/${this.props.id}`
  }

  getCallbackUrl() {
    const host = window.location.host
    const pathName = window.location.pathname
    if (this.props.callbackUrl) {
      return this.props.callbackUrl
    }
    return `http://${host}${pathName}kmsaasFileApi/onlyoffice/callback`
    // return `http://plm.kmsoft.com.cn/kmsaasFileApi/onlyoffice/callback`
  }

  handleDocType(fileType: string) {
    let docType = ''
    const fileTypesDoc = [
      'doc',
      'docm',
      'docx',
      'dot',
      'dotm',
      'dotx',
      'epub',
      'fodt',
      'htm',
      'html',
      'mht',
      'odt',
      'ott',
      'pdf',
      'rtf',
      'txt',
      'djvu',
      'xps'
    ]
    const fileTypesCsv = ['csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx']
    const fileTypesPPt = ['fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx']
    if (fileTypesDoc.includes(fileType)) {
      docType = 'text'
    }
    if (fileTypesCsv.includes(fileType)) {
      docType = 'spreadsheet'
    }
    if (fileTypesPPt.includes(fileType)) {
      docType = 'presentation'
    }
    return docType
  }

  async onDocumentSave(event: any, param: any) {
    if (!event.data) {
      this.getSavedFileId(param)
    }
  }

  async getSavedFileId(param: any) {
    try {
      const newFileId = ((await request.get('/kmsaasFileApi/onlyoffice/getSavedFile', {
        key: param.key
      })) as unknown) as string
      if (newFileId == 'NOT_FOUND' && this.retryNum.value < 5) {
        setTimeout(() => {
          this.retryNum.value++
          this.getSavedFileId({
            key: this.keyId.value
          })
        }, 500)
        return
      } else if (newFileId == 'NOT_FOUND' || newFileId == 'SAVE_ERROR') {
        throw new Error('error')
      }
      const id = newFileId?.replace('#', '')
      this.props.onSave(id)
    } catch (error) {
      KModal.delete({
        title: '系统提示',
        content: '未保存成功，请问是否重新保存',
        onOk: async () => {
          this.getSavedFileId({
            key: this.keyId.value
          })
        }
      })
    }
  }
}
