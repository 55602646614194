import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  SimpleViewPropOptions,
  SimpleViewEventEmits
} from '@kmsoft/upf-core'

/** 参数 **/
export const KActivityPlanPreDetailGridPropOptions = {
  ...SimpleViewPropOptions,
  dataSource: VuePropTypes.array().def([]),
  preChangeActivities: VuePropTypes.array().def([])
}

/** 参数类型 **/
export type KActivityPlanPreDetailGridPropType = ViewPropsTypeExtract<typeof KActivityPlanPreDetailGridPropOptions>

/** 事件 */
export const KActivityPlanPreDetailGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KActivityPlanPreDetailGridEmitsType = ViewEmitsTypeExtract<typeof KActivityPlanPreDetailGridEventEmits>
