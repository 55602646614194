import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_bottom_bar = _resolveComponent("k-bottom-bar")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowModelType: _ctx.rowModelType,
        class: "material-grid",
        rowKey: "id",
        name: "MaterialGrid",
        pagination: _ctx.pagination,
        ref: _ctx.$vm.refMaterialGrid,
        loadData: _ctx.$vm.loadData
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "factory",
            name: "factory",
            dataPropertyName: "factory",
            headerText: "工厂",
            align: "left"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "quantity",
            name: "quantity",
            dataPropertyName: "quantity",
            headerText: "数量",
            align: "left"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "unit",
            name: "unit",
            dataPropertyName: "unit",
            headerText: "单位",
            align: "left"
          })
        ]),
        bottomBar: _withCtx(() => [
          _createVNode(_component_k_bottom_bar, {
            enableExcelExport: "",
            onRefresh: _cache[0] || (_cache[0] = () => _ctx.$vm.refresh()),
            onExcelSelectExport: _cache[1] || (_cache[1] = () => _ctx.$vm.exportSelectAsExcel()),
            onExcelExport: _cache[2] || (_cache[2] = () => _ctx.$vm.exportAsExcel())
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "pagination", "loadData"])
    ]),
    _: 1
  }))
}