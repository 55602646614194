
import {
  KDataGridRowSelectedEvent,
  defineView,
  EnumDataGridRowModelType,
  EnumDataGridAutoSizeColumnsMode
} from '@kmsoft/upf-core'
import { clsCodeMap } from '@kmsoft/ebf-common'
import { KBaselineComparePropOptions, KBaselineCompareEventEmits } from './interface'
import KBaselineCompareViewModel from './KBaselineCompareViewModel'

export default defineView({
  name: 'KBaselineCompare',
  props: KBaselineComparePropOptions,
  emits: KBaselineCompareEventEmits,
  viewModel: KBaselineCompareViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { clsCodeMap, EnumDataGridRowModelType, autoSizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL }
  }
})
