import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowKey: "id",
        name: "docEchoGrid",
        ref: "refDataGrid",
        autoIndex: false,
        disabled: false,
        dataSource: _ctx.dataSource,
        isMultipleSelection: false,
        getCellEditable: (params)=>_ctx.$vm.getCellEditable(params)
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "index",
            headerText: "序号",
            readonly: "true",
            align: "left",
            width: "50"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            headerText: "类型",
            dataPropertyName: "rdmExtensionType",
            readonly: "true",
            align: "center",
            width: "100"
          }, {
            default: _withCtx(({ cellValue, row}) => [
              _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue) + _ctx.$vm.getView(row)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_template_column, {
            dataPropertyName: "number",
            headerText: "编码",
            readonly: _ctx.operateType == _ctx.EnumOperateType.Revise || _ctx.operateType == _ctx.EnumOperateType.Priview,
            align: "center",
            cellTemplateSelector: (params)=>_ctx.$vm.cellTemplateSelector(params),
            cellEditingTemplateSelector: (params)=>_ctx.$vm.cellEditingTemplateSelector(params)
          }, null, 8, ["readonly", "cellTemplateSelector", "cellEditingTemplateSelector"]),
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "name",
            headerText: "名称",
            readonly: _ctx.operateType == _ctx.EnumOperateType.Revise || _ctx.operateType == _ctx.EnumOperateType.Priview,
            align: "center"
          }, null, 8, ["readonly"]),
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "version",
            headerText: "版本",
            readonly: "true",
            align: "center",
            width: "50"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            dataPropertyName: "folder",
            headerText: "文件夹",
            hide: _ctx.operateType == _ctx.EnumOperateType.Revise,
            align: "center",
            cellTemplateSelector: (params)=>_ctx.$vm.folderCellTemplateSelector(params),
            cellEditingTemplateSelector: (params)=>_ctx.$vm.folderCellEditingTemplateSelector(params)
          }, null, 8, ["hide", "cellTemplateSelector", "cellEditingTemplateSelector"]),
          _createVNode(_component_k_data_grid_template_column, {
            headerText: "操作",
            align: "center",
            dataPropertyName: "operate",
            readonly: _ctx.operateType == _ctx.EnumOperateType.Revise || _ctx.operateType == _ctx.EnumOperateType.SaveAs,
            cellTemplateSelector: (params)=>_ctx.$vm.operationCellTemplateSelector(params),
            cellEditingTemplateSelector: (params)=>_ctx.$vm.operationCellEditingTemplateSelector(params),
            width: "100"
          }, null, 8, ["readonly", "cellTemplateSelector", "cellEditingTemplateSelector"])
        ]),
        _: 1
      }, 8, ["dataSource", "getCellEditable"])
    ]),
    _: 1
  }))
}