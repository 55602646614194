
import { defineView } from '@kmsoft/upf-core'
import { DocumentEditor } from '@onlyoffice/document-editor-vue'
import { KOfficeDocumentEditorPropOptions, KOfficeDocumentEditorEventEmits } from './interface'
import KOfficeDocumentEditorViewModel from './KOfficeDocumentEditorViewModel'

export default defineView({
  name: 'KOfficeDocumentEditor',
  props: KOfficeDocumentEditorPropOptions,
  emits: KOfficeDocumentEditorEventEmits,
  components: { DocumentEditor },
  viewModel: KOfficeDocumentEditorViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
