import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createVNode(_component_k_tool_strip, { name: "objClsToolStrip" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        ref: "refDataGrid",
        rowKey: "linkId",
        autoIndex: true,
        isMultipleSelection: false,
        isLazyLoadTreeData: false,
        treeData: true,
        loadData: _ctx.$vm.loadData,
        rowModelType: 'serverSide',
        disabled: false,
        getCellEditable: (params)=>_ctx.$vm.getCellEditable(params)
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "filename",
            name: "filename",
            dataPropertyName: "filename",
            headerText: "文件名称",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "filePath",
            name: "filePath",
            dataPropertyName: "filePath",
            headerText: "文件路径",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "systemFileHash",
            name: "systemFileHash",
            dataPropertyName: "systemFileHash",
            headerText: "系统文件md5",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "localFileHash",
            name: "localFileHash",
            dataPropertyName: "localFileHash",
            headerText: "本地文件md5",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "isMatch",
            name: "isMatch",
            dataPropertyName: "isMatch",
            headerText: "是否一致",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            headerText: "操作",
            align: "center",
            dataPropertyName: "operate",
            readonly: _ctx.operateType == _ctx.EnumOperateType.UnUpdate,
            cellTemplateSelector: (params)=>_ctx.$vm.operationCellTemplateSelector(params),
            cellEditingTemplateSelector: (params)=>_ctx.$vm.operationCellEditingTemplateSelector(params),
            width: "100"
          }, null, 8, ["readonly", "cellTemplateSelector", "cellEditingTemplateSelector"])
        ]),
        _: 1
      }, 8, ["loadData", "getCellEditable"])
    ]),
    _: 1
  }))
}