import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KCollectRelatedPartPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.record()
    .setRequired()
    .def(),
  /** 流程模板key */
  templateKey: VuePropTypes.string()
    .setRequired()
    .def(),
  /** 默认选中行 */
  defaultSelectRows: VuePropTypes.array<string>().def([])
}

/** 参数类型 **/
export type KCollectRelatedPartPropType = ViewPropsTypeExtract<typeof KCollectRelatedPartPropOptions>

/** 事件 */
export const KCollectRelatedPartEventEmits = {
  ...BaseViewEventEmits,
  cancel: () => true,
  confirm: (params: Record<string, any>) => true
}

/** 事件类型 **/
export type KCollectRelatedPartEmitsType = ViewEmitsTypeExtract<typeof KCollectRelatedPartEventEmits>
