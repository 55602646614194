import * as UpfCore from '@kmsoft/upf-core'

import { FileAttributes } from '../../io/FileAttributes'
import { CommonDialog } from './CommonDialog'
import { DialogResult } from './DialogResult'

import KFileDialog from './file-dialog'
import KFileDialogViewModel from './file-dialog/KFileDialogViewModel'

/**提示用户选择文件夹。 此类不能被继承。*/
export class FolderBrowserDialog extends CommonDialog {
  protected get CreateParams(): Record<string, any> {
    return { ...super.CreateParams, resultType: FileAttributes.Directory }
  }

  /**要显示的说明。 默认值为空字符串("")。*/
  public Description: string = ''

  /**获取或设置一个值，该值指示是否将 {@link Description} 属性的值用作 Vista 样式对话框的标题。 此属性对旧样式对话框不起任何作用。*/
  public UseDescriptionForTitle: boolean

  /**获取或设置用户选定的路径。*/
  public SelectedPath: string

  public state: UpfCore.EnumDialogState

  public Reset(): void {}

  public async ShowDialog(): Promise<DialogResult> {
    return new Promise(resolve => {
      UpfCore.KDialog.show({
        title: this.UseDescriptionForTitle ? this.Description : '选择文件夹',
        size: UpfCore.EnumDialogSize.Fat,
        state: this.state || UpfCore.EnumDialogState.Normal,
        okText: '打开',
        content: KFileDialog,
        props: this.CreateParams,
        onClosing: (event: UpfCore.KDialogClosingEvent) => {
          if (event.dialogResult !== UpfCore.EnumDialogResult.Confirm) {
            resolve(DialogResult.Cancel)
            return
          }

          const result = (event.viewModel as KFileDialogViewModel).collect()
          event.cancel = result.cancel
          if (!event.cancel) {
            this.SelectedPath = result.fileName
            resolve(DialogResult.OK)
          }
        }
      })
    })
  }
}
