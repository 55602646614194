import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KCadRelationDocPropOptions = {
  parts: VuePropTypes.createType<Array<any>>().def([]),
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KCadRelationDocPropType = ViewPropsTypeExtract<typeof KCadRelationDocPropOptions>

/** 事件 */
export const KCadRelationDocEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCadRelationDocEmitsType = ViewEmitsTypeExtract<typeof KCadRelationDocEventEmits>

/**
 * 工作状态
 * @description 工作状态
 */
export enum EnumWorkState {
  /**检入 */
  CHECKED_IN = 'CHECKED_IN',
  /** 检出 */
  CHECKED_OUT = 'CHECKED_OUT',
  /** 工作副本 */
  INWORK = 'INWORK'
}
