import { BaseViewModel, ViewModelOptions, KNotification, EnumAttributeType, KSchema } from '@kmsoft/upf-core'
import { LayoutClientSrv, EnumLayoutSchemaType, Api, EnumRequestCode, ClassMetaClientSrv } from '@kmsoft/ebf-common'
import {
  KClassUiConfigEmitsType,
  KClassUiConfigPropType,
  IKClassDefinition,
  EnumNodeType,
  EnumPropertyType,
  IKMetaPropertyDefinition,
  classTreeType,
  EnumClsDataType,
  editTypeMap,
  __getControlInfo,
  EnumSpecialFiled,
  dataTypeConfig,
  customControls
} from './interface'
import { EnumClsEditorType, EnumControl, allDataTypeConfig, controlOptions } from './type'
import { KLayoutDesignerViewModel, KDataElementTree } from '@kmsoft/ebf-view-engine'
import _, { uniqueId } from 'lodash'
import { ref } from 'vue'
import { ClassTransition } from './transition'

/** KClassUiConfig */
export default class KClassUiConfigViewModel extends BaseViewModel<KClassUiConfigEmitsType, KClassUiConfigPropType> {
  /**
   * 主对象 Code
   * @description 主对象用当前属性标识，其他用对象类 Code 标识
   */
  MAIN_CLASS_CODE = '_'

  refDesigner = ref<KLayoutDesignerViewModel>()
  /**页面标识 */
  pageName = ref(this.props.layoutName)
  /**页面标题 */
  pageTitle = ref(this.props.layoutCode)
  /**布局方案 */
  schema = ref({} as KSchema)
  /**数据元素 */
  metaDataSource = ref({} as KDataElementTree)
  /**数据类型对应的控件集合 */
  controlCollection = dataTypeConfig
  designerMetaControl = customControls
  constructor(options: ViewModelOptions<KClassUiConfigPropType>) {
    super(options)
  }

  viewDidMount() {
    this.getSchema()
    this.getData()
  }

  /**重置 */
  resetSchema() {
    const schema = this.schema.value
    this.refDesigner.value?.resetSchema(schema as KSchema)
  }

  /**导出schema */
  exportSchema() {
    this.refDesigner.value?.export()
  }
  /**预览 */
  preview() {
    this.refDesigner.value?.preview()
  }

  /**获取schema */
  async getSchema() {
    const params = {
      data: [
        {
          code: this.props.layoutCode,
          clsCode: this.props.belongClsCode
        }
      ]
    }
    const result: any = await Api.post('official', 'ClsLayoutDefService', 'getClsLayoutSchema', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const schema = JSON.parse(result.data == '' ? '{}' : result.data)
      if (schema) {
        this.schema.value = (schema as unknown) as KSchema
      }
    } else {
      KNotification.warn({
        message: '系统提示',
        description: result.message
      })
    }
  }

  /** 保存 */
  async saveSchema() {
    const res = this.refDesigner.value?.getSchema()
    const schema = JSON.stringify(res)
    const params = {
      data: [
        {
          code: this.props.layoutCode,
          clsCode: this.props.belongClsCode,
          schema
        }
      ]
    }
    const result: any = await Api.post('official', 'ClsLayoutDefService', 'updateClsLayoutSchema', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '保存成功'
      })
    } else {
      KNotification.warn({
        message: '系统提示',
        description: result.message
      })
    }
  }
  /** 获取对象类信息 */
  async getData() {
    //零部件特殊处理,将时间有效性和part合并
    const classInfo = await this.getClassInfo()
    const transitionClass = await ClassTransition((_.cloneDeep(classInfo) as unknown) as IKClassDefinition)
    if (classInfo) {
      const metaData = await this.convert2MetaDataSource((transitionClass as unknown) as IKClassDefinition)
      this.metaDataSource.value = metaData
      console.log('metaDataSource', metaData)
    } else {
      KNotification.warn({
        message: '提示',
        description: '对象类信息查询失败'
      })
    }
  }

  /**
   * 转换元数据
   * @param classDefinition 对象类信息
   */
  convert2MetaDataSource = async (classDefinition: IKClassDefinition) => {
    const metaClassDefinitions = {} as KDataElementTree
    metaClassDefinitions.id = classDefinition.classCode
    metaClassDefinitions.name = classDefinition.classCode
    metaClassDefinitions.title = classDefinition.className
    metaClassDefinitions.children = []
    //构建基础属性
    const treeClass = this.__buildTreeClass(classDefinition.classCode, 'base')
    treeClass.children = await this.__buildBaseProperty(classDefinition, 'BASE')
    metaClassDefinitions.children.push(treeClass)
    //构建扩展属性
    const extendTreeClass = this.__buildTreeClass(classDefinition.classCode, 'extend')
    extendTreeClass.children = await this.__buildBaseProperty(classDefinition, 'EXTEND')
    metaClassDefinitions.children.push(extendTreeClass)
    // //附属表格
    // if (classDefinition.attachedTable && classDefinition.attachedTable.length > 0) {
    //   const treeClass = this.__buildTreeClass(classDefinition.classCode, 'attachedTables')
    //   treeClass.children = await this.__buildTableProperty(
    //     classDefinition.attachedTable!,
    //     classDefinition.classCode,
    //     EnumPropertyType.ATTACHED_TABLES
    //   )
    //   metaClassDefinitions.children.push(treeClass)
    // }
    // //关联对象附属表格
    // if (classDefinition.relClassAttachTables && classDefinition.relClassAttachTables.length > 0) {
    //   const treeClass = this.__buildTreeClass(classDefinition.classCode, 'relClassAttachTables')
    //   treeClass.children = await this.__buildTableProperty(
    //     classDefinition.relClassAttachTables!,
    //     classDefinition.classCode,
    //     EnumPropertyType.ATTACHED_TABLES_REF
    //   )
    //   metaClassDefinitions.children.push(treeClass)
    // }
    // //关联对象属性
    // if (classDefinition.relationClassProps && classDefinition.relationClassProps.length > 0) {
    //   const treeClass = this.__buildTreeClass(classDefinition.classCode, 'relationClassProps')
    //   treeClass.children = await Promise.all(
    //     classDefinition.relationClassProps.map(async x => {
    //       const res = {} as any
    //       const id = `${x.classCode}${uniqueId()}`
    //       res.id = id
    //       res.name = x.className
    //       res.nodeType = EnumNodeType.CLASSIFICATION
    //       res.title = x.className
    //       res.parentId = treeClass.id
    //       res.children = await this.__buildBaseProperty(x)
    //       return res
    //     })
    //   )
    //   metaClassDefinitions.children.push(treeClass)
    // }
    // //关系列表
    // if (classDefinition.relatedList && classDefinition.relatedList.length > 0) {
    //   const treeClass = this.__buildTreeClass(classDefinition.classCode, 'relatedList')
    //   treeClass.children = await this.__buildTableProperty(
    //     classDefinition.relatedList!,
    //     classDefinition.classCode,
    //     EnumPropertyType.RELATION_LIST
    //   )
    //   metaClassDefinitions.children.push(treeClass)
    // }
    return metaClassDefinitions
  }

  /**
   * 构建基础属性
   * @param classDefinition 对象类信息
   * @param base 是否为基础属性
   */
  __buildBaseProperty = async (classDefinition: IKClassDefinition, type: string): Promise<any[]> => {
    const baseProperty = [] as Array<any>
    // 先过滤掉不需要显示的属性 0->否，1->是
    let properties = [] as IKMetaPropertyDefinition[]
    switch (type) {
      case 'BASE':
        properties = classDefinition.properties.filter(x => (!x.propSource || x.propSource == 'BASE') && x.visible == 1)
        break
      case 'EXTEND':
        properties = classDefinition.properties.filter(x => x.propSource && x.propSource != 'BASE' && x.visible == 1)
        break
      default:
        properties = classDefinition.properties.filter(x => x.visible == 1)
        break
    }
    for (let i = 0; i < properties.length; i++) {
      const element = properties[i]
      let res = {} as any
      //引用属性且引用属性编辑器
      if (
        (element.dataType === EnumClsDataType.OBJ || element.dataType === EnumClsDataType.CLASSIFY) &&
        (element.editType === EnumClsEditorType.OBJ_SELECTOR || element.editType === EnumClsEditorType.DIALOG_OBJ_SELECTOR)
      ) {
        const children = await this.__buildReferenceProperty(element, classDefinition)
        res.id = element.id
        res.name = element.column
        res.nodeType = EnumNodeType.CLASSIFICATION
        res.title = element.displayName
        res.parentId = classDefinition.classCode
        res.children = children
      } else {
        res = {
          id: element.id,
          parentId: classDefinition.classCode,
          nodeType: EnumNodeType.PROPERTY,
          name: element.column,
          title: element.displayName,
          attrs: this.__buildPropertyAttribute(classDefinition, element, type),
          events: editTypeMap.get(element.editType) ? __getControlInfo(editTypeMap.get(element.editType)!).events : [],
          control: editTypeMap.get(element.editType) || EnumControl.INPUT,
          dataType: element.dataType.toString()
        } as any
      }
      baseProperty.push(res)
    }
    return baseProperty
  }

  /**
   * 构建数据元素分类
   * @param parentId 父id
   * @param key 唯一key
   */
  __buildTreeClass = (parentId: string, key: string) => {
    const res = {} as any
    const id = key
    res.id = id
    res.name = classTreeType.get(key)!
    res.nodeType = EnumNodeType.CLASSIFICATION
    res.title = classTreeType.get(key)!
    res.parentId = parentId
    res.children = [] as Array<any>
    return res
  }
  // /**
  //  * 构建表格
  //  * @param classDefinitions 对象类信息集合
  //  * @param parentId 父id
  //  */
  // __buildTableProperty = async (classDefinitions: Array<IKClassDefinition>, parentId: string, type: EnumPropertyType) => {
  //   const result = [] as Array<any>
  //   for (let i = 0; i < classDefinitions.length; i++) {
  //     const element = classDefinitions[i]
  //     const options = await this.__buildBaseProperty(element, type)
  //     const item = {
  //       id: element.classCode,
  //       parentId: parentId,
  //       nodeType: EnumNodeType.PROPERTY,
  //       name: element.classCode,
  //       title: element.className,
  //       attrs: [
  //         ..._.cloneDeep(this.__buildTableControlAttribute(element, type)),
  //         {
  //           name: 'columns',
  //           title: '列配置',
  //           control: EnumControl.COLUMNS_DESIGNER,
  //           options: options,
  //           defaultValue: [],
  //           attributeType: EnumAttributeType.PROPS
  //         }
  //       ] as any,
  //       events: __getControlInfo(EnumControl.GRID).events,
  //       control: EnumControl.GRID,
  //       dataType: EnumClsDataType.GRID
  //     }
  //     result.push(item)
  //   }
  //   return _.cloneDeep(result)
  // }
  /**
   * 构建对象类属性的各属性的配置项
   * @param classDefinition 对象类信息
   * @param propertyDefinition 对象类属性信息
   */
  __buildPropertyAttribute = (classDefinition: IKClassDefinition, propertyDefinition: IKMetaPropertyDefinition, type: string) => {
    //若没有编辑类型则推断编辑类型
    const editType =
      propertyDefinition.editType || ((allDataTypeConfig as any)[propertyDefinition.dataType].defaultEditorType as any)

    const control = editTypeMap.get(editType) || EnumControl.INPUT

    const PropertyConfig = __getControlInfo(control).attrs
    const attrs = [] as Array<any>
    /**将对象类属性值赋值给属性的默认值
     * 1.循环对象类的属性，name、title、dataSource、filed、control需特殊处理
     * 2.根据对象类属性编辑方式获取控件的属性配置
     */
    for (let i = 0; i < PropertyConfig.length; i++) {
      const element = PropertyConfig[i] as any
      switch (element.name) {
        case EnumSpecialFiled.NAME:
          element.defaultValue = this.MAIN_CLASS_CODE + '#' + propertyDefinition.column

          break
        case EnumSpecialFiled.TITLE:
          element.defaultValue =
            propertyDefinition.displayName === '' ? propertyDefinition.propName : propertyDefinition.displayName
          break
        case EnumSpecialFiled.CONTROL:
          {
            element.defaultValue = control
            element.options = controlOptions
          }
          break
        case EnumSpecialFiled.FIELD:
          element.defaultValue =
            type === 'EXTEND' ? 'extendedAttributes' + '#' + propertyDefinition.column : propertyDefinition.column
          break
        // case EnumSpecialFiled.DATASOURCE:
        //   {
        //     element.defaultValue = '' //type === 'BASE' ? '' : type === 'EXTEND' ? 'extendedAttributes' : propertyDefinition.column
        //   }
        //   break
        case EnumSpecialFiled.DISABLED:
          {
            element.defaultValue = propertyDefinition.readonly
          }
          break
        case EnumSpecialFiled.REQUIRED:
          {
            element.defaultValue = !propertyDefinition.nullable
          }
          break
        default:
          {
            const value = (propertyDefinition as Record<string, any>)[element.name]
            element.defaultValue = value !== undefined ? value : element.defaultValue
          }
          break
      }
      attrs.push(element)
    }

    // 1.解析编辑方式的编辑参数
    const editArgs = propertyDefinition.editArgs ? JSON.parse(propertyDefinition.editArgs) : {}
    // 2.循环为控件的属性赋默认值
    for (let i = 0; i < attrs.length; i++) {
      const element = attrs[i]
      if (element.name in editArgs) {
        element.defaultValue = editArgs[element.name]
      }
    }
    return _.cloneDeep(attrs)
  }
  /**
   * 构建表格类控件
   * @param classDefinition 对象类信息
   */
  __buildTableControlAttribute = (classDefinition: IKClassDefinition, type: EnumPropertyType) => {
    //对象类基础属性和布局基础属性
    const PropertyConfig = _.cloneDeep(__getControlInfo(EnumControl.GRID).attrs)
    /**将对象类属性值赋值给属性的默认值
     * 1.循环对象类的属性，name、title、dataSource、filed需特殊处理
     * 2.根据对象类属性编辑方式获取控件的属性配置
     */
    const attrs = [] as Array<any>
    for (let i = 0; i < PropertyConfig.length; i++) {
      const element = PropertyConfig[i] as any
      switch (element.name) {
        case EnumSpecialFiled.NAME:
          element.defaultValue = classDefinition.classCode
          break
        case EnumSpecialFiled.TITLE:
          element.defaultValue = classDefinition.className
          break
        case EnumSpecialFiled.CONTROL:
          element.defaultValue = EnumControl.GRID
          break
        case EnumSpecialFiled.FIELD:
          element.defaultValue = classDefinition.classCode
          break
        case EnumSpecialFiled.DATASOURCE:
          element.defaultValue = type
          break
        default:
          break
      }
      attrs.push(element)
    }
    return _.cloneDeep(attrs)
  }
  /**
   * 构建引用属性配置
   * @param metaClassDefinitions 对象类属性信息
   * @param classDefinition 对象类信息
   */
  __buildReferenceProperty = async (metaClassDefinitions: IKMetaPropertyDefinition, classDefinition: IKClassDefinition) => {
    const json = metaClassDefinitions.propertyArgs || '{}'
    const propertyArgs = JSON.parse(json) as any
    const classCode = propertyArgs.refClsCode
    const editArgsJson = metaClassDefinitions.editArgs || '{}'
    const editArgs = JSON.parse(editArgsJson) as any
    const params = {
      data: [[classCode]]
    }
    const referenceClassInfo: any = await Api.post('official', 'ClsLayoutPropService', 'listPropByClsCode', params)
    // const referenceClassInfo: any = await LayoutClientSrv.getSchemeById('mock', 'Mock', EnumLayoutSchemaType.FormNew)
    const referenceClass = {} as IKClassDefinition
    const propertyList = [] as Array<IKMetaPropertyDefinition>
    if (referenceClassInfo && referenceClassInfo.code == EnumRequestCode.SUCCESS) {
      // referenceClass = (referenceClassInfo.data! as unknown) as IKClassDefinition
      referenceClass['properties'] = (referenceClassInfo.data![classCode] as unknown) as Array<IKMetaPropertyDefinition>
      // 1.获取引用属性的属性集
      const referenceProperties = referenceClass.properties
      // 2.过滤出被引用的属性列
      if (editArgs && Object.keys(editArgs).length > 0) {
        referenceClass['classCode'] = editArgs.refClsCode
        const props = editArgs.refObjCodes || []
        for (let i = 0; i < props.length; i++) {
          const element = (referenceProperties.find(x => x.propCode === props[i]) as unknown) as IKMetaPropertyDefinition
          if (element) {
            //element.column = (metaClassDefinitions.column + '$' + element.column).trim()
            propertyList.push(element)
          }
        }
        // 3.将属性转换成设计器需要的格式
        const newReferenceClass = _.cloneDeep(referenceClass)
        newReferenceClass.properties = propertyList
        let res = (await this.__buildBaseProperty(newReferenceClass, 'reference')) as Array<any>
        // 4.引用属性的字段加上前缀
        res = res.map((x: any) => {
          const field = (x as any).attrs.find((x: any) => x.name === 'field')
          field!.defaultValue = metaClassDefinitions.column + '.' + x.name
          // const dataSource = (x as any).attrs.find((x: any) => x.name === 'dataSource')
          // dataSource!.defaultValue = metaClassDefinitions.column
          return x
        })
        return res
      }
      return [] as Array<any>
    } else {
      return [] as Array<any>
    }
  }
  /**获取对象类信息 */
  async getClassInfo() {
    const classCode = this.props.belongClsCode
    //零部件特殊处理
    const params = [classCode]
    const result: any = await Api.post('official', 'ClsLayoutPropService', 'listPropByClsCode', { data: [params] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data.length == 0) {
        KNotification.warn({
          message: '提示',
          description: '未查到对象类信息'
        })
        return undefined
      }
      const properties = result.data
      const newVal = result.data[classCode!] as Array<IKMetaPropertyDefinition>
      const className = result.data[classCode!].length > 0 ? result.data[classCode!][0].className : ''
      const classInfo = {
        attachedTable: [],
        classCode: classCode,
        className: className,
        properties: newVal
      }
      return classInfo
    }
    return undefined
  }
}
