import { ComponentVNodeProps, VuePropTypes } from '@kmsoft/upf-core'
import * as Types from './types'
import { EnumKmVueFileType, EnumMenuType } from './enums'

/** 通用浏览器 */
export interface IK3DVueBrowser {
  setNodesHighlight(path: string | Array<string>, highlighted: boolean): void
  setNodesVisible(path: string | Array<string>, hided: boolean): void
  setNodesTransparency(path: string, transparency: number): void
  enableMark(enable: boolean): void
  clearComments(): void
  addComments(items: Array<Types.CommentItem>): void
  reset(): void
  setPMIHided(hided: boolean): void
  snapShot(): void
  explode(): void
  setMenusVisble(visible: boolean, menuIds: Array<EnumMenuType>): void
  hideOfficeFileSnapshot(hided: boolean): void
}

/** 组件属性定义 */
const props = {
  /** 文件Id */
  fileUrl: VuePropTypes.string()
    .setRequired()
    .def(),
  /** 文件Id */
  fileType: VuePropTypes.createType<EnumKmVueFileType>()
    .setRequired()
    .def(),
  /** 结构数据 */
  structData: VuePropTypes.createType<Array<Types.StructData>>().def([]),
  showRemark: VuePropTypes.bool().def(false),
  showTree: VuePropTypes.bool().def(false),
  environment: VuePropTypes.string().def(),
  /** 当前页面 */
  container: VuePropTypes.string().def()
}

/** 组件事件定义 */
const emits = {
  saveComment: (event: Types.SaveCommentsParams) => true,
  loaded: (event: Types.LoadedParams) => true,
  setNodesHighlighted: (event: Types.SetNodesHighlightedParams) => true,
  setNodesVisible: (event: Types.SetNodesHideParams) => true,
  doubleClickNode: (event: Types.DoubleClickNodeParams) => true
}

/** 组件方法名称定义 */
const methods: string[] = [
  'setNodesHighlight',
  'setNodesVisible',
  'setNodesTransparency',
  'enableMark',
  'clearComments',
  'addComments',
  'reset',
  'setPMIHided',
  'snapShot',
  'explode',
  'setMenusVisble',
  'hideOfficeFileSnapshot'
]

/** 导出所有继承相关信息 */
export const KVueBrowserInherits = { props, emits, methods }

export type KVueBrowserProps = ComponentVNodeProps<typeof KVueBrowserInherits>
