import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KRequirementChangeBeforePropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<any>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KRequirementChangeBeforePropType = ViewPropsTypeExtract<typeof KRequirementChangeBeforePropOptions>

/** 事件 */
export const KRequirementChangeBeforeEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KRequirementChangeBeforeEmitsType = ViewEmitsTypeExtract<typeof KRequirementChangeBeforeEventEmits>
