import { App, Plugin } from 'vue'

import KAddPlanDialog from './add-plan-dialog'

export { KAddPlanDialog }

const components: Record<string, Plugin> = {}

const install = function(app: App) {
  Object.keys(components).forEach(key => {
    app.use(components[key])
  })
}

export default {
  install: install
}
