import {
  Agent,
  Api,
  CommonClientSrv,
  ConfigClientSrv,
  DownloadFileParams,
  EnumClassTemplate,
  EnumFileCategory,
  EnumRequestCode,
  FileClientSrv,
  KOfficeDocumentEditor,
  ObjBusinessParam,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { FileUtil } from '@kmsoft/ebf-common/src/client-agent'
import { EnumDocDependencyType, EnumDocType } from '@kmsoft/ebf-common/src/types/enums'
import { KModelBrowser } from '@kmsoft/km-vue'
import { EnumKmVueFileType } from '@kmsoft/km-vue/src/components'
import {
  AppContext,
  EnumDialogResult,
  EnumDialogSize,
  EnumDialogState,
  IKTreeNode,
  KDataGridRowData,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  request
} from '@kmsoft/upf-core'
import { createVNode } from 'vue'
import { AgentDdb } from '..'
import { ChangeManageClientSrv } from '../../../ebf-change-manage/src/client-srv'
import { WorkflowClientSrv } from '../../../ebf-workflow/src'
import { Doc } from '../beans/Doc'
import { DocProperty, PropertyCheck } from '../client-agent'
import { KDocStructureManage, KSelectDoc, KSelectDocViewModel } from '../controls'
import { KDocEchoListPanel, KDocEchoListPanelViewModel } from '../controls/doc-echo-list-panel'
import { DocEchoGridDataInner, EnumOperateOptions, EnumOperateType } from '../controls/doc-echo-list-panel/interface'
import { EnumWorkState } from '../controls/doc-edit-panel/interface'
import kDocAnnotationListPanel, { KDocAnnotationListPanelViewModel } from '../controls/k-doc-annotation-list-panel'
import { mapAsync } from './Array'
import { DocTool } from './DocTool'
import { EnumDocToolType, EnumOperatorType, EnumPartNodeChildExpandMode, EnumPartViewModeConstDef } from './enums'

export class DocClientSrv {
  constructor() {}

  /**选择文档树 */
  static async selectDocs(
    doc: Doc,
    type: EnumOperatorType,
    title?: string,
    state?: EnumDialogState
  ): Promise<
    | {
        downLoadList: Doc[]
        operatorList: Doc[]
        unOperatorList: Doc[]
        options: Record<string, any>
      }
    | undefined
  > {
    return new Promise((resolve, reject) =>
      KDialog.showAsync({
        title: title || '文档列表',
        size: EnumDialogSize.Large,
        movable: true,
        maximizeBox: false,
        minimizeBox: false,
        floatFooter: true,
        state: state,
        content: KSelectDoc,
        props: { type: type, doc: doc },
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
            resolve(undefined)
            return
          }
          const model = event.viewModel as KSelectDocViewModel
          const options = model.getOptions()
          if ((options.isShowPath && options.selectedFilePath == '') || options.selectedFilePath == undefined) {
            KDialog.info({
              content: '请选择路径'
            })
            event.cancel = true
            return
          }

          const result = await model.getDocList()

          if (result) {
            resolve({
              downLoadList: result?.downLoadList,
              operatorList: result?.operatorList,
              unOperatorList: result?.unOperatorList,
              options: options
            })
          } else {
            resolve(undefined)
          }
        }
      })
    )
  }

  /** 代理下载 */
  public static async downloadDocsByAgent(docs: Doc[], path: string) {
    // 代理下载
    return await FileClientSrv.downloadFilesByAgentPost(
      docs.map(_ => ({
        id: _.fileId!,
        fileName: _.fileName!,
        modelCode: _.modelCode,
        attributeName: 'primary'
      })),
      path
    )
  }

  /**
   * 浏览文档对象
   * @param docParam 对象参数
   * @returns
   */
  static async browseDoc(docParam: ObjBusinessParam) {
    const resDoc = await DocClientSrv.getDoc(docParam.id)
    // 先找主文件
    if (resDoc.primary && resDoc.primary[0].name) {
      const ext = resDoc.primary[0].name
        .split('.')
        .pop()
        ?.toLowerCase()
      if (['docx', 'xlsx', 'pdf'].includes(ext)) {
        CommonClientSrv.openPage(
          `主文件预览: ${resDoc.number}`,
          KOfficeDocumentEditor,
          {
            modelName: 'Document',
            title: resDoc.primary[0].name,
            id: resDoc.primary[0].id,
            fileType: ext,
            mode: 'view'
          },
          resDoc.id
        )
        return
      }
    }
    /** 标签页标识 */
    const tabKey = `${docParam.id}#${docParam.modelCode}#browse`
    const result = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', {
      data: [docParam.id, EnumDocDependencyType.BROWSE]
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data && result.data?.primary) {
        const primary = result.data?.primary[0]
        const fileName = primary.name
        const ext = fileName
          .split('.')
          .pop()
          ?.toLowerCase()
        switch (ext) {
          case 'docx':
          case 'xlxs':
          case 'pdf':
            {
              CommonClientSrv.openPage(
                resDoc.number,
                KOfficeDocumentEditor,
                {
                  modelName: 'Document',
                  title: primary.name,
                  id: primary.id,
                  fileType: ext,
                  mode: 'view'
                },
                tabKey
              )
            }
            break
          case 'scs':
          case 'slddrw':
            {
              CommonClientSrv.openPage(
                `浏览文件预览: ${resDoc.number}`,
                KModelBrowser,
                {
                  objParam: docParam
                },
                tabKey
              )
            }
            break
        }
      } else {
        if (!resDoc.primary) {
          KNotification.info({
            message: '没有主文件或浏览文件'
          })
          return
        }
        // 下载文件
        DocClientSrv.primaryDownload(resDoc.primary[0].id, resDoc.primary[0].name, resDoc.rdmExtensionType)
      }
    } else {
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
    }
  }

  /**
   * 下载文档对象
   * @param objParam 对象参数
   * @param callBack
   * @returns
   */
  static async downloadDoc(
    doc: Doc & {
      category?: EnumFileCategory
    },
    callBack?: (result: any) => void
  ): Promise<void> {
    /** 如果指定了类型,则使用指定的类型，如果传入了fileId 则浏览附属文件 */
    const fileCategory = doc.category || (doc.fileId ? EnumFileCategory.DocAttach : EnumFileCategory.DocMain)
    let tool: string | undefined
    // eslint-disable-next-line prefer-const
    tool = 'DocToolCommon'
    /** 获取浏览组件 */
    return DocTool.getDocTool(EnumDocToolType.Downloader, tool)?.execute(doc)
  }

  // 主文件普通下载
  static async primaryDownload(fileId: string, fileName: string, modelCode: string) {
    const params = {
      fileIds: fileId,
      modelName: modelCode,
      attributeName: 'primary'
    }
    const downloadResult = (await request.post('/kmsaasFileApi/download', params, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })) as any
    if (!downloadResult) {
      KNotification.error({
        title: '文件下载失败',
        content: '文件不存在'
      })
      return
    }
    if (!downloadResult || downloadResult.status != EnumRequestCode.SUCCESS) {
      KNotification.error({
        title: '文件下载失败',
        content: downloadResult.message || '下载失败'
      })
      return
    }
    const link = document.createElement('a')
    link.href = URL.createObjectURL(downloadResult.data)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // 普通下载
  static async download(doc: Doc) {
    await FileClientSrv.downloadFile({
      id: doc.fileId!,
      location: doc.location,
      startIndex: 0,
      endIndex: 1,
      fileName: doc.fileName!
    })
  }

  /** 下载 */
  public static async downloads(docs: Doc[]) {
    for (const doc of docs) await this.download(doc)
  }

  // 检出文档
  static async checkOutDoc(doc: any) {
    // 判断是否已被他人检出
    if (doc.workingState === EnumWorkState.CHECKED_OUT) {
      KNotification.error({
        title: '检出失败',
        content: `文档[${doc.code}:${doc.id}]已被他人检出，无法检出`
      })
      return undefined
    }

    // 判断生命周期状态是否为工作中
    if (doc.workingState === EnumWorkState.CHECKED_IN && doc.lifecycleStateCode !== 'InWork') {
      KNotification.error({
        title: '检出失败',
        content: `文档[${doc.code}:${doc.id}]生命周期状态不处于工作中，无法检出`
      })
      return undefined
    }
    const docs = await this.selectDocs(doc, EnumOperatorType.Checkout)
    if (!docs) return undefined
    // AssertUtils.isTrue(!documentView.getWorkingState().equals(WorkingState.CHECKED_OUT), String.format("文档[%s:%s]已被他人检出，无法检出", documentView.getMaster().getNumber(), id));
    //     // 1.判断是否最新版本
    //     AssertUtils.isTrue(documentView.getLatest(), String.format("文档[%s:%s]非最新版本", documentView.getMaster().getNumber(), id));
    //     // 2.判断生命周期状态是否为工作中
    //     AssertUtils.isTrue(documentView.getLifecycleState() != null && LifecycleStateEnum.WORKING.getCode().equals(documentView.getLifecycleState().getInternalName())
    //             , String.format("文档[%s:%s]生命周期状态不处于工作中，无法检出", documentView.getMaster().getNumber(), id));
    //     //3.判断是否检出
    //     AssertUtils.isTrue(!documentView.getWorkingCopy(), String.format("文档[%s:%s]已检出，无法检出", documentView.getMaster().getNumber(), id));
    // 检查文档状态
    for (const doc of docs.operatorList) {
      // 判断是否已被他人检出
      if (doc.workingState === EnumWorkState.CHECKED_OUT) {
        KNotification.error({
          title: '检出失败',
          content: `文档[${doc.code}:${doc.id}]已被他人检出，无法检出`
        })
        return undefined
      }

      // 判断生命周期状态是否为工作中
      if (doc.lifecycleStateCode !== 'InWork') {
        KNotification.error({
          title: '检出失败',
          content: `文档[${doc.code}:${doc.id}]生命周期状态不处于工作中，无法检出`
        })
        return undefined
      }
    }
    const confirmRes = KDialog.info({ content: '       ', title: '正在下载文件，请稍后...', showOk: false })
    // 下载操作
    await this.downloadDocsByAgent(docs.downLoadList, docs.options.selectedFilePath)
    confirmRes.destroy()
    // 已检出 更新检出路径
    const updateDocList = docs.downLoadList
      .filter(_ => _.checkout)
      .map(item => {
        return { id: item.id, checkoutPath: (docs.options.selectedFilePath + '\\' + item.fileName).toUpperCase() }
      })
    if (updateDocList.length > 0) {
      const updateResult = await Api.post('doc', 'Document', 'batchUpdate', { data: [updateDocList] })
      if (!updateResult || updateResult.code != EnumRequestCode.SUCCESS) {
        KNotification.error({
          title: '保存检出路径失败',
          content: updateResult.message
        })
        return undefined
      }
    }
    // 未检出 检出并更新检出路径
    if (docs.operatorList.length > 0) {
      const operatorIds = docs.operatorList.map(_ => _.id)
      const result = await Api.post('doc', 'Document', 'batchCheckout', {
        data: [operatorIds, { checkoutPath: docs.options.selectedFilePath.toUpperCase() }]
      })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        KNotification.success('检出成功！')
        // 将doc根节点放在首位
        if (result && result.data) {
          const docIndex = result.data.findIndex((item: any) => item.number == doc.number)
          if (docIndex > 0) {
            const docItem = result.data.splice(docIndex, 1)[0]
            result.data.unshift(docItem)
          }
        }
        return result
      } else {
        KNotification.error({
          title: '检出失败',
          content: result.message
        })
      }
    }
    return undefined
  }

  // 检出文档
  static async checkOutDocWithoutFile(doc: Doc) {
    const result = await Api.post('doc', 'Document', 'batchCheckout', {
      data: [[doc.id], { checkoutPath: '' }]
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success('检出成功！')
      return result
    }
    KNotification.error({
      title: '检出失败',
      content: result.message
    })
    return undefined
  }

  // 取消检出文档
  static async UndoCheckoutDoc(doc: Doc) {
    let ids = [doc.id]
    if (doc.checkoutPath && (await Agent.AgentManager.initialize())) {
      const docs = await this.selectDocs(doc, EnumOperatorType.UnCheckout)
      if (!docs) return undefined
      ids = docs.operatorList.map(a => a.id)
    }
    const result = await Api.post('doc', 'Document', 'batchUndoCheckout', { data: [ids] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success('取消检出成功！')
      return result
    }
    KNotification.error({
      title: '取消检出失败',
      content: result.message
    })
    return undefined
  }

  // 检入文档
  static async checkInDoc(doc: Doc, state?: EnumDialogState, f?: Function) {
    const docs = await this.selectDocs(doc, EnumOperatorType.Checkin, undefined, state)
    if (!docs) return undefined
    let argIntegrationConfig = {} as any
    argIntegrationConfig = await DocClientSrv.getIntegrationConfig(doc.documentType)
    const baseFileUrl = ConfigClientSrv.getFileApiBaseUrl
    const filePathList = docs.downLoadList.filter(item => item.checkoutPath)
    if (f) {
      f()
    }
    // 勾选提取文件属性
    if (docs.options.isCheckedFileProperty) {
      if (!argIntegrationConfig || !argIntegrationConfig.data) {
        KDialog.info({
          title: '提示',
          content: '获取ddb配置文件失败！'
        })
        return
      }
      let dialog = KDialog.info({ content: '正在提取文档属性，请稍后...', title: '提示', showOk: false })
      const fileProperty = await AgentDdb.DdbClientSrv.GetFileProperty(
        [doc.checkoutPath!],
        [],
        docs.options.isCascade,
        docs.operatorList,
        docs.unOperatorList,
        argIntegrationConfig.data,
        AppContext.current.getIdentity()?.token!,
        {
          downloadUrl: '',
          checkMd5Url: '',
          copyUrl: '',
          uploadUrl: baseFileUrl + '/upload'
        },
        {
          location: '1',
          chunkSize: '0'
        }
      )
      dialog.destroy()
      if (docs.options.isCreatePart) {
        for (const docProperty of fileProperty.docList) {
          docProperty.part = {
            number: docProperty.code,
            name: docProperty.name
          }
        }
      }
      const checkResult = await Api.post('doc', 'Document', 'checkDocument', {
        data: [
          {
            createPart: docs.options.isCreatePart,
            docList: fileProperty.docList,
            docStructList: fileProperty.docStructList
          }
        ]
      })
      if (!checkResult || checkResult.code != EnumRequestCode.SUCCESS || !checkResult.data) {
        KNotification.warn({
          message: '校验失败',
          description: checkResult.message || '获取预览文档失败'
        })
        return undefined
      }
      const previewDocList = checkResult.data
      const docList = await this.getPreviewDocList(previewDocList, doc.folder!, docs.options.isCreatePart)
      if (!docList || docList.length == 0) {
        return undefined
      }
      dialog = KDialog.info({ content: '正在检入文档，请稍后...', title: '提示', showOk: false })
      fileProperty.docList = docList
      // 批量上传
      const filePaths: string[] = []
      for (const docProperty of fileProperty.docList) {
        filePaths.push(docProperty.checkoutPath)
      }

      // 检入文档时使用
      const newFileInfo = await this.handleFileUploadWithLockCheck(filePaths)
      if (!newFileInfo) {
        dialog?.destroy()
        return undefined
      }
      for (let i = 0; i < fileProperty.docList.length; i++) {
        fileProperty.docList[i].primary = [{ id: newFileInfo?.data[i] }]
      }
      const result = await Api.post('doc', 'Document', 'batchCheckinWithProp', {
        data: [
          {
            createPart: docs.options.isCreatePart,
            docList: fileProperty.docList,
            docStructList: fileProperty.docStructList
          }
        ]
      })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        if (docs.options.isDeleteLocalFile) {
          const deleteList = filePathList.map(item => {
            return Agent.File.Delete(item.checkoutPath!)
          })
          await Promise.all(deleteList)
        }
        KNotification.success('检入成功！')
        dialog.destroy()
        return result
      }
      dialog.destroy()
      KNotification.error({
        title: '检入失败',
        content: result.message
      })
    } else {
      // 查找附属文件
      let docList = docs.operatorList as any
      if (argIntegrationConfig && argIntegrationConfig.data) {
        docList = await AgentDdb.DdbClientSrv.FindAttachFileBySameNameRule(
          docs.operatorList,
          argIntegrationConfig?.data,
          AppContext.current.getIdentity()?.token!,
          {
            downloadUrl: '',
            checkMd5Url: '',
            copyUrl: '',
            uploadUrl: baseFileUrl + '/upload'
          }
        )
      }
      const dialog = KDialog.info({ content: '正在检入文档，请稍后...', title: '提示', showOk: false })
      // 批量上传
      const filePaths: string[] = []
      for (const docProperty of docList) {
        filePaths.push(docProperty.checkoutPath)
      }
      // 检入文档时使用
      const newFileInfo = await this.handleFileUploadWithLockCheck(filePaths)
      if (!newFileInfo) {
        dialog?.destroy()
        return undefined
      }
      for (let i = 0; i < docList.length; i++) {
        docList[i].primary = [{ id: newFileInfo?.data[i] }]
      }
      // 设置根节点
      // 根据master.id设置根节点
      for (const item of docList) {
        if (item.masterId === doc.master.id) {
          item.isRoot = true
        }
      }
      const result = await Api.post('doc', 'Document', 'batchCheckin', { data: [docList] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        if (docs.options.isDeleteLocalFile) {
          const deleteList = filePathList.map(item => {
            return Agent.File.Delete(item.checkoutPath!)
          })
          await Promise.all(deleteList)
        }
        dialog.destroy()
        KNotification.success('检入成功！')
        return result
      }
      dialog.destroy()
      KNotification.error({
        title: '检入失败',
        content: result.message
      })
    }
    return undefined
  }

  // 检入文档(CADENCE原理图、CADENCE_PCB)
  static async checkInCadenceDoc(docData: any, updatePart = false) {
    const docProperty = {
      ...docData,
      code: docData.number,
      name: docData.master.name,
      nodeId: 0,
      isRoot: true,
      masterId: docData.master.id
    } as DocProperty
    const checkResult = await Api.post('doc', 'Document', 'checkDocument', {
      data: [
        {
          createPart: updatePart,
          docList: [docProperty]
        }
      ]
    })
    if (!checkResult || checkResult.code != EnumRequestCode.SUCCESS || !checkResult.data) {
      KNotification.warn({
        message: '校验失败',
        description: checkResult.message || '获取预览文档失败'
      })
      return undefined
    }
    const previewDocList = checkResult.data
    const docList = await this.getPreviewDocList(previewDocList, docProperty.folder!, updatePart)
    if (!docList || docList.length == 0) {
      return undefined
    }
    if (updatePart && docList[0].part) {
      const checkoutPath = docProperty.checkoutPath as string
      const jsonPath = checkoutPath.substring(0, checkoutPath.lastIndexOf('.')) + '.json'
      if (await Agent.File.Exists(jsonPath)) {
        // 获取本地json文件
        const jsonText = (await Agent.File.ReadAllText(jsonPath)) as any
        // 解析bom清单
        // const cadenceBomJson = JSON.parse(jsonText)
        if (docData.documentType == EnumDocType.CADENCE) {
          docList[0].part.cadenceBomDTO = jsonText
        }
        if (docData.documentType == EnumDocType.CADENCE_PCB) {
          const parts = jsonText.components.map((component: any) => {
            return {
              'Part Number': component.type,
              'Part Reference': component.name
            }
          })
          docList[0].part.cadenceBomDTO = { parts: parts }
        }
      }
    }
    const showDialog = KDialog.info({ content: '正在检入，请稍后...', title: '提示', showOk: false })
    // 上传
    const files = []
    if (docProperty && docProperty.checkoutPath) {
      files.push(docProperty.checkoutPath)
    }
    // 上传主文件
    const newFileInfo = await this.handleFileUploadWithLockCheck(files)
    if (!newFileInfo) {
      showDialog.destroy()
      return undefined
    }
    docList[0].primary = [{ id: newFileInfo?.data[0] }]
    const result = await Api.post('doc', 'Document', 'batchCheckinWithProp', { data: [{ docList, createPart: updatePart }] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success('检入成功！')
      showDialog.destroy()
      return result
    }
    KNotification.error({
      title: '检入失败',
      content: result.message
    })
    showDialog.destroy()
    return undefined
  }

  // 检入文档 (不检入文件)
  static async checkInDocWithoutFile(docs: Doc[]) {
    const result = await Api.post('doc', 'Document', 'batchCheckin', { data: [docs] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success('检入成功！')
      return result
    }
    KNotification.error({
      title: '检入失败',
      content: result.message
    })
    return undefined
  }

  static async getPreviewDocList(docList: DocProperty[], folder: any, isCreatePart: boolean): Promise<DocProperty[]> {
    const docEchoList = [] as DocEchoGridDataInner[]
    let index = 0
    if (folder) {
      const folderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id] })
      if (folderPathResult && folderPathResult.code == EnumRequestCode.SUCCESS) {
        folder.fullPath = folderPathResult.data
      }
    }
    for (const docProperty of docList) {
      const docEchoGridDataInner = {
        id: 'doc' + ++index,
        index: index.toString(),
        rdmExtensionType: 'Document',
        number: docProperty.code,
        name: docProperty.name,
        folder: folder,
        version: 'A',
        iteration: '1',
        operate: docProperty.operate,
        operateOption: docProperty.operateOption,
        docProperty: docProperty
      } as DocEchoGridDataInner
      docEchoList.push(docEchoGridDataInner)
    }
    index = 0
    for (const docProperty of docList) {
      if (docProperty.part) {
        const docEchoGridDataInner = {
          id: 'part' + ++index,
          index: index.toString(),
          rdmExtensionType: 'Part',
          number: docProperty.part.number,
          name: docProperty.part.name,
          folder: folder,
          version: 'A',
          iteration: '1',
          operate: docProperty.part.operate,
          operateOption: docProperty.part.operateOption,
          nodeId: docProperty.nodeId
        } as DocEchoGridDataInner
        docEchoList.push(docEchoGridDataInner)
      }
    }
    return new Promise((reslove, reject) => {
      KDialog.show({
        title: '预览',
        size: { width: 900, height: 600 },
        props: {
          dataSource: docEchoList,
          operateType: EnumOperateType.CheckIn,
          operateOptions: EnumOperateOptions.CHECKIN
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KDocEchoListPanel,
        onClosing: async (event: KDialogClosingEvent) => {
          const docEchoListPanelViewModel = event.viewModel as KDocEchoListPanelViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) {
            reslove([])
            return
          }
          if (event.dialogResult == EnumDialogResult.Close) {
            reslove([])
            return
          }
          const operateDocList = [] as DocProperty[]
          if (!docEchoListPanelViewModel.validate()) {
            KNotification.warn({
              message: '系统提示',
              description: '请补全数据'
            })
            event.cancel = true
            return
          }

          const operateData = docEchoListPanelViewModel.getOperateData() as DocEchoGridDataInner[]
          const operateDocData = operateData.filter(_ => _.rdmExtensionType == 'Document')
          const operatePartData = operateData.filter(_ => _.rdmExtensionType == 'Part')
          for (const operateDoc of operateDocData) {
            const docProperty = operateDoc.docProperty
            docProperty.code = operateDoc.number
            docProperty.name = operateDoc.name
            docProperty.folder = operateDoc.folder
            docProperty.operate = operateDoc.operate
            const operatePart = operatePartData.find(_ => _.nodeId == docProperty.nodeId)
            if (operatePart) {
              docProperty.part = {
                ...docProperty.part,
                number: operatePart.number,
                name: operatePart.name,
                folder: operatePart.folder,
                operate: operatePart.operate
              }
            } else {
              docProperty.part = undefined
            }
            operateDocList.push(docProperty)
          }
          const checkResult = await Api.post('doc', 'Document', 'checkDocument', {
            data: [{ createPart: isCreatePart, docList: operateDocList }]
          })
          if (!checkResult || checkResult.code != EnumRequestCode.SUCCESS || !checkResult.data) {
            KNotification.warn({
              message: '校验失败',
              description: checkResult.message || '校验文档失败'
            })
            event.cancel = true
            return
          }
          reslove(operateDocList)
        }
      })
    })
  }

  static async downloadToUrl(doc: Doc): Promise<string | undefined> {
    // 下载整个文件
    const result = await this.downloadToBlob(doc)
    if (result) return window.URL.createObjectURL(result)

    return undefined
  }

  /** 下载 */
  public static async downloadToUrls(docs: Doc[]): Promise<(string | undefined)[]> {
    return mapAsync(docs, this.downloadToUrl)
  }

  static async downloadToBlob(doc: Doc): Promise<Blob | undefined> {
    /*     if (doc.fileId && doc.location) {
      const result = await FileClientSrv.getFile({
        id: doc.fileId,
        location: doc.location,
        startIndex: 0,
        endIndex: 1,
        fileName: ''
      })
      if (result.success) {
        return result.content
      }
    }*/

    const req = { modelName: doc.modelCode, fileIds: doc.fileId || '' }

    const result = (await request.post('/kmsaasFileApi/download', req, { responseType: 'blob' })) as any

    if (result && result.result == 'FAIL') {
      return undefined
    }
    return result
  }

  /** 下载 */
  public static async downloadToBlobs(docs: Doc[]): Promise<(Blob | undefined)[]> {
    return mapAsync(docs, this.downloadToBlob)
  }

  formatFileSize(size: number | string): string {
    if (typeof size === 'string') {
      return size
    }
    const unit = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    if (!size) {
      return ''
    }
    const index = Math.floor(Math.log2(size) / 10)
    const scale = (size / Math.pow(2, index * 10)).toFixed(2)
    return `${scale}${unit[index]}`
  }

  formatFileType(filetype: string | number) {
    let filetypeInt
    if (typeof filetype === 'string') {
      filetypeInt = Number.parseInt(filetype)
    } else {
      filetypeInt = filetype
    }
    switch (filetypeInt) {
      case -1:
        return '主文件'
      case 1:
        return '浏览文件'
      case 2:
        return '打印文件'
      case 3:
        return '批注文件'
      case 4:
        return '工程图文件'
      case 5:
        return '签字文件'
      case 6:
        return '自定义文件'
      case 7:
        return '工程图浏览文件'
      case 8:
        return '模型图文件'
      case 9:
        return 'Web浏览文件'
      case 10:
        return 'EPLAN包文件'
      default:
        return filetype.toString()
    }
  }

  formatFileState(state: string | number) {
    if (typeof state === 'string') {
      return state
    } else {
      return state === 0 ? '未同步' : '已同步'
    }
  }

  /**
   * 获取展开模式
   * @param viewName
   * @param isBaseLine
   * @returns
   */
  static getPartNodeChildExpandMode(viewName?: string, isBaseLine: boolean = false): EnumPartNodeChildExpandMode {
    if (isBaseLine) {
      return EnumPartNodeChildExpandMode.LatestVersion
    }

    switch (viewName) {
      case EnumPartViewModeConstDef.StructTabView:
        return EnumPartNodeChildExpandMode.Default
      case EnumPartViewModeConstDef.LatestView:
        return EnumPartNodeChildExpandMode.LatestVersion
      case EnumPartViewModeConstDef.LatestPublishedView:
        return EnumPartNodeChildExpandMode.LatestPublishVersion
      default:
        return EnumPartNodeChildExpandMode.OtherRules
    }
  }

  static getPartViewModeViewName(mode: EnumPartNodeChildExpandMode) {
    switch (mode) {
      case EnumPartNodeChildExpandMode.Default:
        return EnumPartViewModeConstDef.StructTabView
      case EnumPartNodeChildExpandMode.LatestVersion:
        return EnumPartViewModeConstDef.LatestView
      case EnumPartNodeChildExpandMode.LatestPublishVersion:
        return EnumPartViewModeConstDef.LatestPublishedView
      default:
        return EnumPartViewModeConstDef.StructTabView
    }
  }

  //获取文档配置
  static async getIntegrationConfig(documentType: string): Promise<any> {
    try {
      const result = await Api.post('sys', 'ConfigSysQueryService', 'listGridConfigByCode', {
        data: ['"SYS_DOC_PROPERTY_CHECK"']
      })
      if (!(await Agent.AgentManager.initialize())) {
        KDialog.info({
          title: '提示',
          content: '代理未连接，无法下载ddb配置文件！'
        })
        return Promise.reject(undefined)
      }
      // 获取DDB配置文件
      const ddbConfigResult = await Api.post('sys', 'ConfigSysQueryService', 'listGridConfigByCode', {
        data: ['"SYS_DOC_DDB"']
      })
      const ddbConfigData = ddbConfigResult.data as any[]
      if (!ddbConfigData || ddbConfigData.length == 0) {
        KDialog.info({
          title: '提示',
          content: '获取ddb配置文件失败！'
        })
        return Promise.reject(undefined)
      }
      const ddbConfigList = ddbConfigData.filter(item => item.code == documentType)
      if (ddbConfigList.length == 0) {
        return Promise.resolve(undefined)
      }
      const ddbConfig = ddbConfigList[0]
      const ddbFilePath = 'C:\\KMSOFT\\temp\\Documents\\KMSoft\\SAASPLM\\DdbTemp\\Config'
      await FileClientSrv.downloadFilesByAgentPost(
        [
          {
            id: ddbConfig.fileId,
            fileName: ddbConfig.fileName,
            modelCode: 'Document'
          }
        ],
        ddbFilePath
      )
      // 获取附属文档匹配规则
      const sameNameRuleResult = await Api.post('sys', 'ConfigSysQueryService', 'listGridConfigByCode', {
        data: ['"SYS_DOC_SAME_NAME_RULE"']
      })
      const docClsConfig = {} as any
      const sameNameRuleData = sameNameRuleResult.data as any[]
      if (sameNameRuleData && sameNameRuleData.length > 0) {
        const sameNameRuleList = sameNameRuleData.filter(item => item.code == documentType)
        if (sameNameRuleList.length > 0) {
          docClsConfig.sameNameRuleList = sameNameRuleList
        }
      }
      return Promise.resolve({
        data: {
          //其它通用配置  Key-Value(单值)的配置
          commonConfig: {},
          //文档类型配置
          docClsConfig: docClsConfig,
          //应用程序配置
          softConfig: { code: ddbConfig.softCode },
          //Ddb配置文件
          ddbConfigFile: 'C:\\KMSOFT\\temp\\Documents\\KMSoft\\SAASPLM\\DdbTemp\\Config\\' + ddbConfig.fileName,
          // 检入 属性对应配置
          checkinPropertyMap: this.getPropertyMap(ddbConfig.softCode, result.data, 'checkin'),
          // 检出 属性对应配置
          checkoutPropertyMap: this.getPropertyMap(ddbConfig.softCode, result.data, 'checkout'),
          //协同集成接口
          interopInterfaceList: [{ messageName: '', messageId: '', assembleName: '', methodName: '', argList: [] }]
        }
      })
    } catch (error) {
      return Promise.reject(error)
    }
  }

  //获取文档配置
  static async getAttachmentFileMatchConfig(documentType: string) {
    // 获取附属文档匹配规则
    const sameNameRuleResult = await Api.post('sys', 'ConfigSysQueryService', 'listGridConfigByCode', {
      data: ['"SYS_DOC_SAME_NAME_RULE"']
    })
    const docClsConfig = {} as any
    const sameNameRuleData = sameNameRuleResult.data as any[]
    if (sameNameRuleData && sameNameRuleData.length > 0) {
      const sameNameRuleList = sameNameRuleData.filter(item => item.code == documentType)
      if (sameNameRuleList.length > 0) {
        docClsConfig.sameNameRuleList = sameNameRuleList
      }
    }
    return {
      data: {
        //其它通用配置  Key-Value(单值)的配置
        commonConfig: {},
        //文档类型配置
        docClsConfig: docClsConfig,
        //应用程序配置
        softConfig: { code: documentType },
        //Ddb配置文件
        ddbConfigFile: '',
        // 检入 属性对应配置
        checkinPropertyMap: {},
        // 检出 属性对应配置
        checkoutPropertyMap: {},
        //协同集成接口
        interopInterfaceList: [{ messageName: '', messageId: '', assembleName: '', methodName: '', argList: [] }]
      }
    }
  }

  /**
   * 根据集成类型和检入/检出类型获取属性集
   */
  static getPropertyMap(softType: string, data: any[], checkType: string): Record<string, PropertyCheck[]> {
    const propertyMap: Record<string, PropertyCheck[]> = {}

    data
      .filter(item => item.softCode === softType && item.checkType === checkType)
      .forEach(item => {
        if (!propertyMap[item.propertyType]) {
          propertyMap[item.propertyType] = []
        }
        propertyMap[item.propertyType].push({
          objPropertyName: item.objPropertyName,
          filePropertyName: item.filePropertyName
        })
      })

    return propertyMap
  }

  /**
   * 结构管理
   */
  static async structManage(docId: string) {
    // 获取对象
    const docData = (await this.getDoc(docId)) as any
    const title = '结构管理:' + ObjectClientSrv.getObjBusinessDesc(docData)
    CommonClientSrv.openPage(
      title,
      KDocStructureManage,
      { objParam: { modelCode: docData.rdmExtensionType, modelGroup: 'doc', id: docData.id } },
      docId
    )
  }

  /**
   * 获取文档
   */
  static async getDoc(id: string) {
    const result = (await Api.post('doc', 'Document', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取文档失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 检出文档
   */
  static async docCheckOut(docId: string) {
    // 获取对象
    const docData = (await this.getDoc(docId)) as any
    //判断状态
    const workingState = docData?.workingState
    if (workingState != 'CHECKED_IN') {
      KNotification.info({
        message: '文档已检出'
      })
      return new Promise((resolve, reject) => {
        reject(void 0)
      })
    }
    if (docData.lifecycleStateCode != 'InWork') {
      KNotification.info({
        message: '文档生命周期状态不处于工作中，无法检出'
      })
      return new Promise((resolve, reject) => {
        reject(void 0)
      })
    }

    const doc = Object.assign(new Doc(), docData)
    if (docData.primary && (await Agent.AgentManager.initialize())) {
      doc.checkoutPath = `C:\\KMSOFT\\temp\\Documents`
      const result = await DocClientSrv.checkOutDoc(doc)
      // 后台成功之后，重置当前面板对象的ID参数
      return new Promise((resolve, reject) => {
        resolve(result)
      })
    } else {
      const result = await DocClientSrv.checkOutDocWithoutFile(doc)
      return new Promise((resolve, reject) => {
        resolve(result)
      })
    }
  }

  /**
   * 撤销检出文档
   */
  static async docUnCheckOut(docId: string) {
    // 获取对象
    const docData = (await this.getDoc(docId)) as any
    //判断状态
    const workingState = docData?.workingState
    if (workingState == 'CHECKED_IN') {
      KDialog.info({
        title: '提示',
        content: '文档未检出'
      })
      return new Promise((resolve, reject) => {
        reject(void 0)
      })
    }
    const doc = Object.assign(new Doc(), docData)
    const result = await DocClientSrv.UndoCheckoutDoc(doc)
    // 后台成功之后，重置当前面板对象的ID参数
    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  /**
   * 检入文档
   */
  static async docCheckIn(docId: string, state?: EnumDialogState, f?: Function) {
    // 获取对象
    const docData = (await this.getDoc(docId)) as any
    //判断状态
    const workingState = docData?.workingState
    if (workingState == 'CHECKED_IN') {
      KDialog.info({
        title: '提示',
        content: '文档已检入'
      })
      return new Promise((resolve, reject) => {
        reject(void 0)
      })
    }
    const doc = Object.assign(new Doc(), docData)
    doc.masterId = docData.master.id
    if (doc.checkoutPath) {
      if (docData.primary) {
        doc.fileName = docData.primary[0].name
      }
      if (await Agent.AgentManager.initialize()) {
        if (doc.documentType == EnumDocType.CADENCE || doc.documentType == EnumDocType.CADENCE_PCB) {
          const result = await DocClientSrv.checkInCadenceDoc(docData)
          return new Promise((resolve, reject) => {
            resolve(result)
          })
        }
        const result = await DocClientSrv.checkInDoc(doc, state, f)
        // 后台成功之后，重置当前面板对象的ID参数
        return new Promise((resolve, reject) => {
          resolve(result)
        })
      }
    }
    const result = await DocClientSrv.checkInDocWithoutFile([doc])
    return new Promise((resolve, reject) => {
      resolve(result)
    })
  }

  /**
   * 检入文档
   */
  static async cadenceDocCheckIn(docId: string) {
    // 获取对象
    const docData = (await this.getDoc(docId)) as any
    //判断状态
    const workingState = docData?.workingState
    if (workingState == 'CHECKED_IN') {
      KDialog.info({
        title: '提示',
        content: '文档已检入'
      })
      return new Promise((resolve, reject) => {
        reject(void 0)
      })
    }
  }

  /**
   *下载文档
   */
  static async docDownload(docId: string, isOpen: boolean = true) {
    // 获取对象
    const docData = (await this.getDoc(docId)) as any
    // 判断是否有主文件
    if (!docData.primary || docData.primary.length == 0) {
      KNotification.info({ message: '没有主文件' })
      return
    }
    if (!docData) return
    const isConnected = await Agent.AgentManager.initialize()
    if (!isConnected) {
      DocClientSrv.primaryDownload(docData.primary[0].id, docData.primary[0].name, docData.rdmExtensionType)
      return
    }
    const doc = Object.assign(new Doc(), docData)
    doc.checkoutPath = `C:\\KMSOFT\\temp\\Documents`
    const docs = await DocClientSrv.selectDocs(doc, EnumOperatorType.Download)
    if (!docs) return
    // 下载操作
    // 检查同名文件
    const unDownLoadList = [] as Doc[]
    for (const doc of docs.downLoadList) {
      if (doc.fileId && doc.fileName) {
        const fullPath = await Agent.Path.Combine(docs.options.selectedFilePath, doc.fileName)
        const isExists = await Agent.File.Exists(fullPath)
        if (isExists) {
          const shouldOverwrite = await new Promise<boolean>((resolve, reject) => {
            KDialog.confirm({
              title: `文件[${doc.fileName}]已存在，是否覆盖?`,
              okText: '覆盖',
              cancelText: '跳过',
              onOk: () => resolve(true),
              onCancel: () => resolve(false)
            })
          })
          if (!shouldOverwrite) {
            unDownLoadList.push(doc)
          }
        }
      }
    }
    if (unDownLoadList.length > 0) {
      docs.downLoadList = docs.downLoadList.filter(item => !unDownLoadList.includes(item))
    }
    if (docs.downLoadList.length > 0) {
      const showDialog = KDialog.info({ content: '文件下载中，请稍后...', title: '提示', showOk: false })
      await DocClientSrv.downloadDocsByAgent(docs.downLoadList, docs.options.selectedFilePath).finally(() => showDialog.destroy())
      if (isOpen) {
        // 打开目录
        // 打开文件夹并选中
        const process = await Agent.Process.create()
        const startinfo = await process.StartInfo()
        //await startinfo.FileName(fullFilePath)
        await startinfo.Arguments('/select,' + docs.options.selectedFilePath + '\\' + docData.primary[0]?.name)
        await startinfo.UseShellExecute(true)
        await startinfo.FileName('explorer')
        await process.Start()
      }
    }
    return docs.options.selectedFilePath + '\\' + docData.primary[0]?.name
  }

  static async docDownloadBatch(docIds: string[]) {
    const result = await Api.post('doc', 'Document', 'getDownloadDocList', { data: [docIds] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      let docDownloadList = result.data as any[]
      const filenameCounts = new Map()
      if (docDownloadList.length == 0) {
        KNotification.info({ message: '未找到主文件' })
        return
      }
      // 检查文件名重复
      for (const item of docDownloadList) {
        const count = filenameCounts.get(item.fileName) || 0
        filenameCounts.set(item.fileName, count + 1)
      }
      const duplicates = [...filenameCounts.entries()].filter(([_, count]) => count > 1).map(([name]) => name)
      if (duplicates.length > 0) {
        KDialog.warning({
          content: '存在重复文件名' + duplicates
        })
        return
      }
      const isConnected = await Agent.AgentManager.initialize()
      if (!isConnected) {
        // 普通下载
        for (const docDownload of docDownloadList) {
          DocClientSrv.primaryDownload(docDownload.fileId, docDownload.fileName, 'Document')
        }
        return
      }

      const dialog = new Agent.FolderBrowserDialog()
      if ((await dialog.ShowDialog()) === Agent.DialogResult.OK) {
        // alert(dialog.FileName)
        const selectedPath = dialog.SelectedPath
        // 检查同名文件
        const unDownLoadList = [] as any[]
        for (const doc of docDownloadList) {
          if (doc.fileId && doc.fileName) {
            const fullPath = await Agent.Path.Combine(selectedPath, doc.fileName)
            const isExists = await Agent.File.Exists(fullPath)
            if (isExists) {
              const shouldOverwrite = await new Promise<boolean>((resolve, reject) => {
                KDialog.confirm({
                  title: `文件[${doc.fileName}]已存在，是否覆盖?`,
                  okText: '覆盖',
                  cancelText: '跳过',
                  onOk: () => resolve(true),
                  onCancel: () => resolve(false)
                })
              })
              if (!shouldOverwrite) {
                unDownLoadList.push(doc)
              }
            }
          }
        }
        if (unDownLoadList.length > 0) {
          docDownloadList = docDownloadList.filter(item => !unDownLoadList.includes(item))
        }
        if (docDownloadList.length > 0) {
          const showDialog = KDialog.info({ content: '正在下载文件，请稍后...', title: '提示', showOk: false })
          await FileClientSrv.downloadFilesByAgentPost(
            docDownloadList.map(_ => ({
              id: _.fileId,
              fileName: _.fileName,
              modelCode: 'Document'
            })),
            selectedPath
          )
          showDialog.destroy()
          KNotification.success('下载成功')
        }
      }
    } else {
      KNotification.error({
        title: '下载失败',
        content: result.message || '下载失败'
      })
    }
  }

  /**
   * 文档另存
   */
  static async docSaveAs(docId: string, folderId: string): Promise<any> {
    const result = await Api.post('doc', 'Document', 'previewDoc', { data: [{ id: docId, type: 2 }] })
    const folder = {
      id: folderId
    } as any
    // 获取路径
    const folderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id] })
    if (folderPathResult && folderPathResult.code == EnumRequestCode.SUCCESS) {
      folder.fullPath = folderPathResult.data
    }
    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      const data = result.data.data
      const docEchoList = data.map((item: any, index: number) => {
        return {
          ...item,
          index: index + 1,
          folder: folder,
          operate: 'Saveas'
        }
      })
      return new Promise((resolve, reject) => {
        KDialog.show({
          title: '另存',
          size: { width: 900, height: 600 },
          props: {
            dataSource: docEchoList,
            operateType: EnumOperateType.SaveAs,
            operateOptions: EnumOperateOptions.SAVEAS
          },
          showApply: false,
          maximizeBox: false,
          minimizeBox: false,
          content: KDocEchoListPanel,
          onClosing: async (event: KDialogClosingEvent) => {
            const docEchoListPanelViewModel = event.viewModel as KDocEchoListPanelViewModel
            if (event.dialogResult == EnumDialogResult.Cancel) return
            if (event.dialogResult == EnumDialogResult.Close) return
            if (!docEchoListPanelViewModel.validate()) {
              KNotification.warn({
                message: '系统提示',
                description: '请补全数据'
              })
              event.cancel = true
              return
            }
            const operateList = docEchoListPanelViewModel.getOperateData() as any
            const result = await Api.post('doc', 'Document', 'saveAs', { data: [{ data: operateList }] })
            if (result && result.code == EnumRequestCode.SUCCESS) {
              KNotification.success({
                title: '系统提示',
                content: '另存成功'
              })
              // 后台成功之后，重置��前面板对象的ID参��
              resolve(result)
            } else {
              KNotification.error({
                title: '操作失败',
                content: result.message || '另存失败',
                details: result.detail
              })
              event.cancel = true
              reject(0)
            }
          }
        })
      })
    } else {
      KNotification.error({
        title: '另存失败',
        content: result.message || '另存失败'
      })
    }
  }

  /**
   * 文档修订
   */
  static async docRevise(docId: string, folderId: string): Promise<any> {
    // 获取对象
    const result = await Api.post('doc', 'Document', 'previewDoc', { data: [{ id: docId, type: 1 }] })
    const folder = {
      id: folderId
    } as any
    // 获取路径
    const folderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id] })
    if (folderPathResult && folderPathResult.code == EnumRequestCode.SUCCESS) {
      folder.fullPath = folderPathResult.data
    }
    if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
      const data = result.data.data
      const docEchoList = data.map((item: any, index: number) => {
        return {
          ...item,
          index: index + 1,
          folder: folder,
          operate: 'Revise'
        }
      })
      return new Promise((resolve, reject) => {
        KDialog.show({
          title: '修订',
          size: { width: 700, height: 600 },
          props: {
            dataSource: docEchoList,
            operateType: EnumOperateType.Revise,
            operateOptions: EnumOperateOptions.REVISE
          },
          showApply: false,
          maximizeBox: false,
          minimizeBox: false,
          content: KDocEchoListPanel,
          onClosing: async (event: KDialogClosingEvent) => {
            const docEchoListPanelViewModel = event.viewModel as KDocEchoListPanelViewModel
            if (event.dialogResult == EnumDialogResult.Cancel) return
            if (event.dialogResult == EnumDialogResult.Close) return
            if (!docEchoListPanelViewModel.validate()) {
              KNotification.warn({
                message: '系统提示',
                description: '请补全数据'
              })
              event.cancel = true
              return
            }
            const operateList = docEchoListPanelViewModel.getOperateData() as any
            const result = await Api.post('doc', 'Document', 'revise', { data: [{ data: operateList }] })
            if (result && result.code == EnumRequestCode.SUCCESS) {
              KNotification.success({
                title: '系统提示',
                content: '修订成功'
              })
              // 后台成功之后，重置当前面板对象的ID参数
              resolve(result)
            } else {
              KNotification.error({
                title: '操作失败',
                content: result.message || '修订失败',
                details: result.detail
              })
              event.cancel = true
              reject(0)
            }
          }
        })
      })
    } else {
      KNotification.error({
        title: '修订失败',
        content: result.message || '修订失败'
      })
    }
  }

  /**
   * 替换主文件（从本地）
   * @param docId 文档id
   */
  static doReplaceFromLocalFile(docId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // 动态创建文件输入框
      const fileInput = document.createElement('input')
      fileInput.type = 'file'

      // 获取对象
      this.getDoc(docId)
        .then(docData => {
          if (docData.primary) {
            const fileName = docData.primary[0].name
            // 提取文件后缀
            const fileExtension = fileName.split('.').pop()
            fileInput.accept = `.${fileExtension}`
          }
          fileInput.multiple = false

          // 监听文件选择事件
          fileInput.addEventListener('change', () => {
            const files = fileInput.files
            if (files && files.length > 0) {
              DocClientSrv.uploadPrimaryFile(files[0])
                .then(fileId => {
                  if (!fileId) {
                    KDialog.error('上传文件失败')
                    reject('上传文件失败')
                    return
                  }
                  Api.post('doc', 'Document', 'modifyPrimary', { data: [{ id: docId, fileId: fileId }] })
                    .then(result => {
                      if (result && result.code === EnumRequestCode.SUCCESS) {
                        KNotification.success('替换成功')
                        resolve(result)
                      } else {
                        KNotification.error({
                          title: '操作失败',
                          content: result.message || '替换失败',
                          details: result.detail
                        })
                        reject(result.message || '替换失败')
                      }
                    })
                    .catch(reject)
                })
                .catch(reject)
            } else {
              reject('未选择文件')
            }
          })

          // 触发文件选择对话框
          fileInput.click()
        })
        .catch(reject)
    })
  }

  static async doRemark(docId: string, documentType: string) {
    const doc = await DocClientSrv.getDoc(docId)
    // 获取流程
    const result = await Api.post('official', 'ProcessContentService', 'getProcessByWorkObj', {
      data: [{ workObjId: doc.branch.id, workObjClsCode: EnumClassTemplate.DOC }]
    })
    if (!result || !result.data || result.data.length == 0 || result.data.tasks.length == 0) {
      KNotification.warn({ message: '系统提示', description: '对象不在流程中，不能进行批注' })
      return
    }
    if (documentType == EnumDocType.SW) {
      DocClientSrv.doBrowse(docId, '批注：' + doc.master.number, undefined, true)
      return
    }
    const taskData = result.data.tasks[0]
    const workflowId = taskData.businessId
    const taskId = taskData.taskId
    // 获取批注文档
    let remarkDoc = undefined
    if (doc.documentType == EnumDocType.EXCEL) {
      remarkDoc = doc
    } else {
      const getPdfDoc = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', {
        data: [docId, EnumDocDependencyType.BROWSE]
      })
      if (getPdfDoc && getPdfDoc.code == EnumRequestCode.SUCCESS) {
        if (!getPdfDoc.data) {
          KNotification.info({ message: '未找到批注文件' })
        }
        remarkDoc = getPdfDoc.data
      } else {
        KNotification.error({
          title: '操作失败',
          content: getPdfDoc.message || '获取批注文件失败',
          details: getPdfDoc.detail
        })
        return
      }
    }
    if (remarkDoc) {
      // 调用onlyOffice打开文件
      const fileData = remarkDoc.primary[0].name.split('.')
      CommonClientSrv.openPage(
        remarkDoc.name,
        KOfficeDocumentEditor,
        {
          modelName: 'Document',
          title: remarkDoc.primary[0].name,
          id: remarkDoc.primary[0].id,
          fileType: fileData[1],
          mode: 'edit',
          onSave: async (fileId: string) => {
            await handleUploadAnnotation(fileId)
          }
        },
        remarkDoc.id
      )
      // 提取上传批注的逻辑为单独函数
      const handleUploadAnnotation = async (fileId: string) => {
        // 保存批注记录
        const result = await Api.post('doc', 'Document', 'createAnnotation', {
          data: [
            {
              documentId: docId,
              rdmExtensionType: doc.rdmExtensionType,
              fileId: fileId,
              taskId: taskId,
              workflowId: workflowId
            }
          ]
        })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('上传批注文件完成')
        } else {
          KNotification.error({
            title: '上传批注失败',
            content: result.message || '上传批注失败',
            details: result.detail
          })
          return
        }
      }
    }
  }

  static async doViewRemark(docId: string, validate: boolean = true) {
    // 获取流程
    const doc = await DocClientSrv.getDoc(docId)
    const result = await Api.post('official', 'ProcessContentService', 'getProcessByWorkObj', {
      data: [{ workObjId: doc.branch.id, workObjClsCode: EnumClassTemplate.DOC }]
    })
    if (!result || !result.data || result.data.length == 0) {
      KNotification.warn({ message: '系统提示', description: '未查询到批注信息' })
      return
    }
    const taskData = result.data.tasks[0]
    const reqParam = {
      data: [
        {
          workflowId: taskData.businessId,
          documentId: docId,
          taskId: taskData.taskId
        }
      ]
    }
    const docAnnotations = await Api.post('doc', 'Document', 'listDocAnnotation', reqParam)
    if (docAnnotations && docAnnotations.code == EnumRequestCode.SUCCESS) {
      if (!docAnnotations.data || docAnnotations.data.length == 0) {
        KDialog.warning({ title: '提示', content: `当前文档在[${taskData.processName}-${taskData.taskName}]时，没有批注信息` })
        return
      }
      DocClientSrv.viewRemark(docAnnotations.data)
    } else {
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
    }
  }

  static async doViewRemarkHistory(docId: string, callback?: Function) {
    const doc = await DocClientSrv.getDoc(docId)
    // 获取流程
    const result = await Api.post('official', 'ProcessContentService', 'getProcessByWorkObj', {
      data: [{ workObjId: doc.branch.id, workObjClsCode: EnumClassTemplate.DOC }]
    })
    if (!result || !result.data || result.data.length == 0) {
      // 不在流程中
      const reqParam = {
        data: [
          {
            documentId: docId
          }
        ]
      }
      const docAnnotations = await Api.post('doc', 'Document', 'listDocHistoryAnnotation', reqParam)
      if (docAnnotations && docAnnotations.code == EnumRequestCode.SUCCESS) {
        if (!docAnnotations.data || docAnnotations.data.length == 0) {
          if (callback) {
            await new Promise(resolve => {
              KDialog.info({
                content: '未查询到批注信息',
                onOk: () => {
                  callback
                  return resolve(0)
                }
              })
            })
            return
          } else {
            await new Promise(() => {
              KNotification.warn({
                message: '系统提示',
                description: '未查询到批注信息'
              })
            })
            return
          }
        }
        DocClientSrv.viewRemark(docAnnotations.data)
      } else {
        KNotification.error({
          title: '系统错误',
          content: result.message
        })
      }
      return
    }
    const reqParam = {
      data: [
        {
          workflowId: result.data.businessId,
          documentId: docId
        }
      ]
    }
    const docAnnotations = await Api.post('doc', 'Document', 'listDocHistoryAnnotation', reqParam)
    if (docAnnotations && docAnnotations.code == EnumRequestCode.SUCCESS) {
      if (!docAnnotations.data || docAnnotations.data.length == 0) {
        await new Promise(resolve => {
          KDialog.info({
            content: `当前文档在[${result.data.processName}]时，没有批注信息`,
            onOk: () => {
              return resolve(0)
            }
          })
        })
        return
      }
      DocClientSrv.viewRemark(docAnnotations.data)
    } else {
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
    }
  }

  static async viewRemark(data: any) {
    KDialog.show({
      title: '查看批注',
      size: { width: 800, height: 400 },
      props: {
        dataSource: data
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      okText: '查阅',
      cancelText: '取消',
      content: kDocAnnotationListPanel,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const viewModel = event.viewModel as KDocAnnotationListPanelViewModel
        const row = viewModel.getSelectedRows()
        if (!row || row.length === 0) return

        // 处理文档查看
        const annotationFileIds = row.map(_ => _.markFile[0].id)
        if (row[0].document.master.documentType == EnumDocType.SW) {
          DocClientSrv.doBrowse(row[0].document.id, '批注预览：' + row[0].document.master.number, annotationFileIds)
          return
        }

        // 处理其他类型文档
        const fileType = row[0].document.master.documentType == EnumDocType.EXCEL ? 'xlsx' : 'pdf'

        const url = FileClientSrv.addParamToUrl(ConfigClientSrv.getFileApiBaseUrl + '/doc/previewAnnotation', {
          documentId: row[0].document.id,
          annotationFileIds: annotationFileIds.join(',')
        })

        CommonClientSrv.openPage(
          `批注预览：${row[0].document.master.number}`,
          KOfficeDocumentEditor,
          {
            modelName: 'Document',
            title: row[0].document.master.number,
            url: url,
            fileType: fileType,
            enableCommentRights: true
          },
          row[0].document.id
        )
      }
    })
  }

  static async doBrowse(docId: string, title: string, annotationFileIds?: Array<string>, showMark: boolean = false) {
    /** 标签页标识 */
    const tabKey = `${docId}#Document#browse`
    const result = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', {
      data: [docId, EnumDocDependencyType.BROWSE]
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data && result.data?.primary) {
        const primary = result.data?.primary as Array<Record<string, any>>
        if (primary.length > 0) {
          CommonClientSrv.openPage(
            title,
            KModelBrowser,
            {
              objParam: { id: docId, modelCode: 'Document' },
              fileId: primary[0].id,
              annotationFileIds: annotationFileIds || undefined,
              showMark: showMark
            },
            tabKey
          )
        }
      } else {
        KNotification.info({
          message: '该对象没有轻量化文件'
        })
      }
    } else {
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
    }
  }

  static async uploadPrimaryFile(file: File) {
    const req = new FormData()
    req.append('modelName', 'Document')
    req.append('attributeName', 'primary')
    req.append('files', file)
    const result = (await request.post('/kmsaasFileApi/upload', req, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })) as any
    if (result && result.result == 'SUCCESS') {
      const data = result.data
      return data[0]
    }
  }

  static async getLatestByBranch(branchIds: string[]) {
    const result = await Api.post('doc', 'Document', 'listLatestByBranch', {
      data: [branchIds]
    })

    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data && result.data.length > 0) {
        return result.data[0]
      }
    }
  }

  static async getLatestByBranchIds(branchIds: string[]) {
    const result = await Api.post('doc', 'Document', 'listLatestByBranch', {
      data: [branchIds]
    })

    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data && result.data.length > 0) {
        return result.data
      }
    }
  }

  /**
   * 编辑文件
   * @param docId 文档id
   */
  static async editFile(docId: string) {
    let docData = (await this.getDoc(docId)) as any
    // 判断是否有主文件
    if (!docData.primary) {
      KNotification.info({
        message: '未找到主文件'
      })
      return undefined
    }
    let documentId = docId
    let checkoutPath = docData.checkoutPath
    //判断状态
    const workingState = docData?.workingState
    if (workingState == EnumWorkState.CHECKED_OUT) {
      KNotification.warn({
        message: '提示',
        description: '文档已被其他人检出，不能编辑'
      })
      return new Promise((resolve, reject) => {
        reject(void 0)
      })
    }

    // 判断生命周期状态是否为工作中
    if (docData.workingState === EnumWorkState.CHECKED_IN && docData.lifecycleStateCode !== 'InWork') {
      KNotification.error({
        title: '检出失败',
        content: `文档生命周期状态不处于工作中，无法检出`
      })
      return undefined
    }
    if (docData.documentType == 'Excel' || docData.documentType == 'Word') {
      const documentId = await DocClientSrv.editFileByOffice(docData)
      return new Promise((resolve, reject) => {
        resolve(documentId)
      })
    }
    // 除了excel和word其他文档编辑需要连接代理
    const isConnected = await Agent.AgentManager.initialize()
    if (!isConnected) {
      KDialog.info({
        title: '提示',
        content: '代理未连接，不能编辑！'
      })
      return new Promise((resolve, reject) => {
        reject(void 0)
      })
    }
    if (!docData.checkoutPath) {
      // 检出
      const doc = Object.assign(new Doc(), docData)
      doc.checkoutPath = `C:\\KMSOFT\\temp\\Documents`
      const result = (await DocClientSrv.checkOutDoc(doc)) as any
      if (!result) {
        return new Promise((resolve, reject) => {
          reject(void 0)
        })
      }
      if (docData.workingCopy) {
        documentId = docData.id
      } else {
        const rootDoc = result.data.find((item: { master: { id: any } }) => item.master.id == docData.master.id)
        documentId = rootDoc.id
      }
      // 获取对象
      docData = (await this.getDoc(documentId)) as any
      checkoutPath = docData.checkoutPath
    }
    // 调用代理打开文件
    const process = await Agent.Process.create()
    await process.EnableRaisingEvents(true)
    const startinfo = await process.StartInfo()
    await startinfo.FileName(checkoutPath)
    await startinfo.UseShellExecute(true)

    await process.Start()
    return new Promise((resolve, reject) => {
      resolve(documentId)
    })
  }

  /**
   * 打印签字文件
   * @param docId 文档id
   */
  static async print(docId: string) {
    // 获取签字文件
    const result = await Api.post('doc', 'Document', 'getAttachmentDocumentByType', {
      data: [docId, EnumDocDependencyType.SIGNATURE]
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data && result.data?.primary) {
        const isConnected = await Agent.AgentManager.initialize()
        if (!isConnected) {
          DocClientSrv.primaryDownload(
            result.data.primary[0].id,
            '【签字文件】' + result.data.primary[0].name,
            result.data.rdmExtensionType
          )
          return
        }
        const doc = new Doc()
        doc.fileId = result.data.primary[0].id
        doc.fileName = '【签字文件】' + result.data.primary[0].name
        doc.modelCode = result.data.rdmExtensionType
        const downloadPath = `C:\\KMSOFT\\temp\\Documents\\`
        await this.downloadDocsByAgent([doc], downloadPath) // 调用代理打开文件
        const process = await Agent.Process.create()
        const startinfo = await process.StartInfo()
        await startinfo.FileName(await Agent.Path.Combine(downloadPath, doc.fileName!))
        await startinfo.UseShellExecute(true)
        await process.Start()
      } else {
        KNotification.info({
          message: '未找到签字文件'
        })
      }
    } else {
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
    }
  }

  /**
   * 批量删除分支
   * @param partIds
   */
  static async batchDeleteBranch(docIds: Array<any>) {
    return new Promise((resolve, reject) => {
      KModal.delete({
        title: '删除提示',
        icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
        content: '你将删除该对象的当前版本数据，请确认是否删除？',
        onOk: async () => {
          const param = {
            data: [docIds]
          }
          const result = await Api.post('doc', 'Document', 'batchDeleteBranch', param)
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success('对象版本移除成功')
            resolve(result.data)
          } else {
            KNotification.error({
              title: '对象版本移除失败',
              content: result.message!
            })
            reject(void 0)
          }
        }
      })
    })
  }

  /**
   * 查询文档数据
   * @param parts 文件夹关联对象清单
   */
  static getDocList(row: Array<KDataGridRowData>) {
    const params = {
      data: [
        {
          sort: 'DESC',
          orderBy: 'lastUpdateTime',
          filter: {
            joiner: 'and',
            conditions: [
              {
                conditionName: 'id',
                operator: 'in',
                conditionValues: row.map(item => item.targetId)
              }
            ]
          }
        },
        {
          curPage: 1,
          pageSize: 100
        }
      ]
    }
    return Api.post('doc', 'Document', 'list', params)
  }

  /**
   * 创建测试问题报告
   * @param parts 文件夹关联对象清单
   * @param folder 文件夹树节点对象
   */
  static async createChangeIssue(docs: Array<KDataGridRowData>, container: any, folder?: IKTreeNode) {
    if (docs.length === 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请勾选至少一条数据'
      })
      return
    }
    const result = await this.getDocList(docs)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      ChangeManageClientSrv.createChangeIssue(result.data, container, folder?.id)
    }
  }

  /**
   * 创建变更请求
   * @param parts 文件夹关联对象清单
   * @param folder 文件夹树节点对象
   */
  static async createChangeRequest(docs: Array<KDataGridRowData>, container: any, folder?: IKTreeNode) {
    if (docs.length === 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请勾选至少一条数据'
      })
      return
    }
    const result = await this.getDocList(docs)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      ChangeManageClientSrv.createChangeRequest(result.data, container, folder?.id)
    }
  }

  static async release(docs: Array<KDataGridRowData>) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const docIds = docs!.map(row => row.targetBranchId)
      const param = docs.map(doc => {
        return {
          objId: doc.targetBranchId,
          objClsCode: doc.targetExtensionType,
          name: doc.name,
          number: doc.number
        }
      })
      WorkflowClientSrv.checkWorkflowAndChangeManaged(param).then(res => {
        if (res) {
          KDialog.confirm({
            title: '确认发布吗？发布后不可恢复!',
            onOk: async () => {
              const result = await Api.post('doc', 'Document', 'batchReleaseByBranch', { data: [docIds] })
              if (result && result.code == EnumRequestCode.SUCCESS) {
                KNotification.success({
                  title: '系统提示',
                  content: '发布成功'
                })
                resolve(result)
              } else {
                KNotification.error({
                  title: '操作失败',
                  content: result.message || '发布失败',
                  details: result.detail
                })
                reject(void 0)
              }
            }
          })
        } else {
          reject(void 0)
        }
      })
    })
  }

  /**
   * 处理文件上传，包含文件占用检查
   * @param filePaths 要上传的文件路径数组
   * @param modelName 模型名称
   * @param attributeName 属性名称
   * @returns Promise 返回上传结果
   */
  static async handleFileUploadWithLockCheck(
    filePaths: string[],
    modelName: string = 'Document',
    attributeName: string = 'primary'
  ): Promise<any | undefined> {
    let newFileInfo: any

    while (true) {
      const lockedFiles: string[] = []
      for (const filePath of filePaths) {
        if (await FileUtil.IsFileLocked(filePath)) {
          lockedFiles.push(filePath)
        }
      }

      if (lockedFiles.length > 0) {
        const retry = await new Promise<boolean>(resolve => {
          KDialog.confirm({
            title: '文件上传失败',
            content: `以下文件被占用,请关闭文件后重试:\n${lockedFiles.join('\n')}`,
            okText: '重试',
            cancelText: '取消',
            onOk: () => resolve(true),
            onCancel: () => resolve(false)
          })
        })

        if (!retry) {
          return undefined
        }
        continue
      }
      return await FileClientSrv.batchUploadFilesByAgent({
        files: filePaths,
        modelName,
        attributeName
      })
    }
  }

  /**
   * 编辑文件
   * @param docId 文档id
   */
  static async editFileByOffice(docData: any) {
    let documentId = docData.id
    //判断状态
    const workingState = docData?.workingState
    // 未检出的要先检出
    if (workingState == EnumWorkState.CHECKED_IN) {
      // 检出
      const doc = Object.assign(new Doc(), docData)
      const result = (await DocClientSrv.checkOutDocWithoutFile(doc)) as any
      if (!result) {
        return new Promise((resolve, reject) => {
          reject(void 0)
        })
      }
      // 获取检出id
      documentId = result.data[0].id
    }
    // 调用onlyOffice打开文件
    const fileData = docData.primary[0].name.split('.')
    CommonClientSrv.openPage(
      `编辑：${docData.number}`,
      KOfficeDocumentEditor,
      {
        modelName: 'Document',
        title: docData.primary[0].name,
        id: docData.primary[0].id,
        fileType: fileData[1],
        mode: 'edit',
        onSave: (fileId: string) => this.saveFileByEdit(documentId, fileId)
      },
      documentId
    )
  }

  /**
   * onlyoffice编辑文件保存后回调
   */
  static async saveFileByEdit(docId: string, fileId: string) {
    const reqParam = { primary: [{ id: fileId }], id: docId }
    const result = await Api.post('doc', 'Document', 'update', { data: [reqParam] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success('保存成功！')
    } else {
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
    }
  }
}
