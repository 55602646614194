import {
  BaseViewModel,
  DataGridLoadResult,
  IKTreeNode,
  IQuery,
  KDataGridViewModel,
  KNotification,
  KTreeViewViewModel,
  SelectOption,
  TreeViewSelectEventArgs,
  ViewModelOptions,
  MemoryCacheFactory
} from '@kmsoft/upf-core'
import { KPersonalVerificationPlanEmitsType, KPersonalVerificationPlanPropType } from './interface'
import { nextTick, ref, watch } from 'vue'
import { Api, EnumRequestCode, EnumVerification, ObjectClientSrv, UserInfoCache } from '@kmsoft/ebf-common'

/** KPersonalVerificationPlan */
export default class KPersonalVerificationPlanViewModel extends BaseViewModel<
  KPersonalVerificationPlanEmitsType,
  KPersonalVerificationPlanPropType
> {
  /**树组件定义 */
  refTree = ref<KTreeViewViewModel>()
  /** 选中的节点 */
  selectNode = ref<IKTreeNode>()
  /** 是否显示按钮 */
  showButton = ref<boolean>(true)
  /** 流程网格组件 */
  refVerificationPlanGrid = ref<KDataGridViewModel>()
  /** 验证类型候选值 */
  verificationPlanTypeOptions = ref<Array<SelectOption>>(EnumVerification._list)
  /** 计划是否完成 */
  isComplete = ref<boolean>(false)
  /** 人员列表 */
  userList = ref<Record<string, any>[]>([])

  constructor(options: ViewModelOptions<KPersonalVerificationPlanPropType>) {
    super(options)
    watch(
      () => this.selectNode.value,
      () => {
        if (this.selectNode.value?.type === '1') {
          nextTick(() => {
            this.showButton.value = true
            this.isComplete.value = false
          })
        } else if (this.selectNode.value?.type === '2') {
          nextTick(() => {
            this.showButton.value = false
            this.isComplete.value = true
          })
        }
        this.refVerificationPlanGrid.value?.refresh()
      },
      {
        immediate: true
      }
    )
    this.getUserData()
  }

  viewDidMount() {}

  /**
   * 加载树
   */
  loadTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise((resolve, reject) => {
      resolve([
        {
          id: 'root',
          name: '我的验证计划',
          leaf: false,
          iconType: 'Node_MyProjectTask',
          children: [
            {
              id: '1',
              name: '未完成',
              leaf: true,
              type: '1',
              iconType: 'Node_MyProjectTask',
              children: []
            },
            {
              id: '2',
              name: '已完成',
              leaf: true,
              type: '2',
              iconType: 'Node_MyProjectTask',
              children: []
            }
          ] as Array<IKTreeNode>
        }
      ])
    })
  }

  /**
   * 刷新网格
   */
  setSelectNode(event: TreeViewSelectEventArgs<any>) {
    this.selectNode.value = event.node
  }

  /**
   * 网格数据加载
   */
  loadData(query: IQuery): Promise<DataGridLoadResult> {
    const pageVO = {
      maxPageSize: 100,
      curPage: query.page?.pageIndex,
      pageSize: query.page?.pageSize,
      totalRows: 0,
      totalPages: 0,
      startIndex: 0,
      endIndex: 0
    }
    return new Promise((resolve, reject) => {
      if (!this.selectNode.value) {
        reject([])
        return
      }
      const param = { data: [{ completed: this.isComplete.value }, pageVO] }
      Api.post('requirement', 'VerificationPlan', 'pageVerificationPlan', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          resolve({
            rows: res.data.data,
            total: res.data.page.totalRows
          })
        } else {
          resolve([])
        }
      })
    })
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  /**
   * 刷新
   */
  refersh() {
    this.refVerificationPlanGrid.value?.refresh()
  }

  /**
   * 完成
   */
  async complete() {
    const rows = this.refVerificationPlanGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    const rowIds = rows!.map(row => row.id)
    const reqParam = {
      data: [rowIds]
    }
    const result = await Api.post('requirement', 'VerificationPlan', 'completePlan', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.refVerificationPlanGrid.value?.refresh()
      KNotification.success({
        title: '操作成功',
        content: result.message
      })
      return result.data
    } else {
      KNotification.error({
        title: '完成失败',
        content: result.message || '完成验证计划失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 打开计划详情
   * @param row 选中行
   */
  async openPlanTab(row: any) {
    const param = {
      id: row?.id,
      modelCode: row!.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.className!)
    }
    ObjectClientSrv.openObj(param)
  }

  getProjectStatus(row: any) {
    if (row.overdue) {
      return 'clock-circle'
    }
  }

  getIconClass(row: any) {
    if (row.overdue) {
      return 'clock-circle'
    }
    return 'default'
  }

  getStatusOption(row: any) {
    if (row.overdue) {
      return '逾期'
    }
  }
}
