import { connect, withInstall } from '@kmsoft/upf-core'
import KCadWorkspaceDetailView from './KCadWorkspaceDetail.vue'
import KCadWorkspaceDetailViewModel from './KCadWorkspaceDetailViewModel'

const KCadWorkspaceDetail = connect(KCadWorkspaceDetailView, KCadWorkspaceDetailViewModel)

export default withInstall(KCadWorkspaceDetail)
export { KCadWorkspaceDetail, KCadWorkspaceDetailView, KCadWorkspaceDetailViewModel }

// 模板生成文件
// export * from './{{folderName}}'
