import { KNotification, MemoryCacheBase } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { Api, EnumRequestCode } from '../../../..'
/** 对象类缓存 */
export class UserInfoCache extends MemoryCacheBase<Array<Record<string, any>>> {
  public static cacheKey = 'UserInfoCache'
  constructor() {
    super()
    this.cacheKey = UserInfoCache.cacheKey
  }

  public async reload() {
    const res = await Api.post('common', 'UserManage', 'listUserByCurrentTenant', {
      data: []
    })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      this.cacheData = res.data.map((item: any) => {
        return { id: item.id, name: item.name }
      }) as Array<Record<string, any>>
    } else {
      KNotification.error({
        title: '获取失败',
        content: res.message,
        details: res.detail
      })
      this.cacheData = []
    }
  }

  /** 获取所有数据 */
  public getUserOptionList() {
    const option = this.cacheData.map((item: any) => {
      return {
        value: item.id,
        label: item.name?.split(' ')[0]
      }
    })
    return option
  }
}
