
import { defineView, IKDataGridCellParams } from '@kmsoft/upf-core'
import { EnumOperateType, KFileComparisonEventEmits, KFileComparisonPropOptions } from './interface'
import KFileComparisonViewModel from './KFileComparisonViewModel'

export default defineView({
  name: 'KFileComparison',
  props: KFileComparisonPropOptions,
  emits: KFileComparisonEventEmits,
  viewModel: KFileComparisonViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumOperateType }
  }
})
