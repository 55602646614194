import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KBaselineCompareDialogPropOptions = {
  ...BaseViewPropOptions,
  sourceId: VuePropTypes.string()
    .setRequired()
    .def(),
  targetId: VuePropTypes.string().def()
}

/** 参数类型 **/
export type KBaselineCompareDialogPropType = ViewPropsTypeExtract<typeof KBaselineCompareDialogPropOptions>

/** 事件 */
export const KBaselineCompareDialogEventEmits = {
  ...BaseViewEventEmits,
  cancel: () => true
}

/** 事件类型 **/
export type KBaselineCompareDialogEmitsType = ViewEmitsTypeExtract<typeof KBaselineCompareDialogEventEmits>
