import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  KNotification,
  KTreeNode,
  VuePropTypes
} from '@kmsoft/upf-core'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** 参数 **/
export const KRequirementTracePropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KRequirementTracePropType = ViewPropsTypeExtract<typeof KRequirementTracePropOptions>

/** 事件 */
export const KRequirementTraceEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KRequirementTraceEmitsType = ViewEmitsTypeExtract<typeof KRequirementTraceEventEmits>

// 页面定义
export interface IPageDefinition {
  id: string
  name: string
  symbol: string
  suffix: string
}

/**
 * 文件夹目录树根节点定义
 */
export class ProductTreeNode extends KTreeNode {
  /**
   * 图标
   */
  iconType: string

  /**
   * 是否是单节点
   */

  isSingleRoot: boolean
  /**
   * 编码
   */
  nameEn: string
  /**
   * 类型
   */
  type: string
  /**
   * 父ID
   */
  parentId: string

  constructor(node?: any) {
    super()
    this.id = node?.id
    this.key = node?.key
    this.name = node?.name
    this.leaf = node?.leafFlag
    this.iconType = node?.iconType
    this.isSingleRoot = true
    this.parentId = node?.parentId
    this.type = node?.type
  }

  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const newChild = [] as Array<KTreeNode>
    if (this.leaf) {
      return newChild
    }
    const param = {
      data: [this.id]
    }
    const result = await Api.post('requirement', 'ProductNeedsSection', 'listChildNode', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        const node = {
          id: item?.id,
          key: item?.id,
          name: item?.name,
          leafFlag: !item?.hasChildren,
          type: item?.type,
          iconType: 'folder',
          parentId: item?.parentId
        }
        const childNode = new ProductTreeNode(node as any)
        newChild.push(childNode)
      }
    }
    return newChild
  }
}
