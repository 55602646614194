import { LoadingOutlined } from '@ant-design/icons-vue'
import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KModelBrowserPropOptions = {
  ...BaseViewPropOptions,
  container: VuePropTypes.string()
    .setRequired()
    .def(),
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /** 展开模式 */
  // expandMode: VuePropTypes.createType<EnumPartNodeChildExpandMode>().def(EnumPartNodeChildExpandMode.Default),
  /** 禁用批注 */
  showMark: VuePropTypes.bool().def(false),
  annotationFileIds: VuePropTypes.array<string>().def(),
  fileType: VuePropTypes.string().def(),
  fileId: VuePropTypes.string().def(),
  url: VuePropTypes.string().def(),
  environment: VuePropTypes.string().def(),
  /**文件下载随机生成id */
  guid: VuePropTypes.string().def(),
  /** 指定类型 */
  type: VuePropTypes.string().def(),
  loading: VuePropTypes.bool().def(false),
  showTree: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KModelBrowserPropType = ViewPropsTypeExtract<typeof KModelBrowserPropOptions>

/** 事件 */
export const KModelBrowserEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KModelBrowserEmitsType = ViewEmitsTypeExtract<typeof KModelBrowserEventEmits>
