import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-95c80c0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "no-picture"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_image = _resolveComponent("k-image")!

  return (!_ctx.$vm.stateValue)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_k_icon, {
          type: "picture",
          title: "无缩略图"
        })
      ]))
    : (_openBlock(), _createBlock(_component_k_image, {
        key: 1,
        width: 120,
        style: { display: 'block' },
        preview: _ctx.preview == 1,
        src: _ctx.$vm.imageSrc
      }, null, 8, ["preview", "src"]))
}