
import { defineView, IKDataGridCellParams } from '@kmsoft/upf-core'
import { KDocEchoListPanelPropOptions, KDocEchoListPanelEventEmits, EnumOperateType } from './interface'
import KDocEchoListPanelViewModel from './KDocEchoListPanelViewModel'
import { clsCodeMap } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KDocEchoListPanel',
  props: KDocEchoListPanelPropOptions,
  emits: KDocEchoListPanelEventEmits,
  viewModel: KDocEchoListPanelViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumOperateType, clsCodeMap }
  }
})
