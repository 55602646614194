import {
  Api,
  clsCodeMap,
  EnumPurviewCode,
  EnumQueryConditionOperator,
  EnumRequestCode,
  EnumSupportObjectNumMode,
  EnumToolStripItemKeys,
  ObjectClientSrv
} from '../../../../ebf-common'
import {
  BaseViewModel,
  EnumDialogResult,
  EnumToolStripCompType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  PageManager,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KCommonValidationMetricsEmitsType, KCommonValidationMetricsPropType } from './interface'
import { ref, resolveComponent } from 'vue'
import _ from 'lodash'
import { KCreateForm, KCreateFormViewModel } from './create-form'
import { KCommonFolderManageViewModel } from '../../../../ebf-folder/src/pages/common-folder-manage'
import { KMetricsEditPanel } from './metrics-edit-panel'

/** KCommonValidationMetrics */
export default class KCommonValidationMetricsViewModel extends BaseViewModel<
  KCommonValidationMetricsEmitsType,
  KCommonValidationMetricsPropType
> {
  /** 通用文件夹管理 */
  refCommonFolderManage = ref<KCommonFolderManageViewModel>()

  constructor(options: ViewModelOptions<KCommonValidationMetricsPropType>) {
    super(options)
  }

  viewDidMount() {
    /**初始化网格工具栏 */
    this.initToolStripItem()
  }

  /**
   * 初始化工具栏
   */
  initToolStripItem() {
    const toolStripItem = this.refCommonFolderManage.value?.getToolStripItem()
    toolStripItem![0].items!.splice(1, 0, {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'common_add_cover',
      visible: true,
      purviewId: EnumPurviewCode.Add,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+shift+d',
      supportedObjNumMode: EnumSupportObjectNumMode.Any
    })
  }

  /**
   * 工具栏点击事件
   */
  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    this.refCommonFolderManage.value!.onToolStripItemClicked(event)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.create()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.add()
        break
      default:
        break
    }
  }

  /**
   * 操作栏事件
   * @param params
   */
  async onOperationClick(params: any) {
    if (!params) {
      return
    }
    const row = params.params.row
    if (params.key == 'edit') {
      const param = {
        id: row?.targetId,
        modelCode: row!.targetExtensionType,
        modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetExtensionType)
      }
      //ObjectClientSrv.openObj(param)
      const tabKey = `${param.id}#${param.modelCode}`
      const objResult = (await ObjectClientSrv.getObjBusiness(param)) as Record<string, any>
      const title = clsCodeMap.get(objResult.rdmExtensionType) + ':' + ObjectClientSrv.getObjBusinessDesc(objResult)
      PageManager.openPage(tabKey, title, KMetricsEditPanel, { objParam: param })
    }
  }

  /**
   * 创建验证指标
   */
  create() {
    //选中的文件夹
    const folder = this.refCommonFolderManage.value?.getSelectTreeNode()
    if (!folder) {
      KNotification.warn({
        message: '系统提示',
        description: '未选中文件夹'
      })
    }
    KDialog.show({
      title: '新建通用指标',
      size: { width: 900, height: 650 },
      props: { folderId: folder?.id, isGeneral: true },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KCreateForm,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KCreateFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }

        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          await this.refCommonFolderManage.value?.refresh()

          KNotification.success({
            title: '系统提示',
            content: '新建成功'
          })
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message || '新建失败',
            details: result.detail
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 添加验证指标
   */
  add() {
    const folder = this.refCommonFolderManage.value?.getSelectTreeNode()
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      objectClassManageQueryParam: { showObjClsCodes: ['ValidationMetrics'] },
      objectSearchQueryParam: { showObjClsCodes: ['ValidationMetrics'] },
      getContainer: this.refCommonFolderManage.value?.getContainer(),
      onClosing: async (event: KDialogClosingEvent) => {
        if (!event.viewInstance) {
          return
        }

        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }

        const objParams = event.viewInstance.getSelectedObjParams()

        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const modelGroup = await ObjectClientSrv.getModelGroupByCode(objParams[0]!.modelCode)
        const objIds = objParams.map((obj: { id: any }) => obj.id)
        // 获取对象信息
        const query = {
          modelCode: objParams[0]!.modelCode,
          modelGroup: modelGroup,
          filter: {
            conditions: [
              {
                conditionName: 'rdmExtensionType',
                operator: EnumQueryConditionOperator.EQUAL,
                conditionValues: [objParams[0]!.extensionType]
              },
              {
                conditionName: 'id',
                operator: EnumQueryConditionOperator.IN,
                conditionValues: objIds
              }
            ],
            joiner: 'and'
          },
          page: {
            pageIndex: 1,
            pageSize: 1000
          }
        }
        const objs = await ObjectClientSrv.listObjects(query)
        if (objs.rows.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '未查询到对象信息'
          })
          event.cancel = true
          return
        }
        const generalMetrics = objs.rows.filter(obj => obj.isGeneral)
        if (generalMetrics && generalMetrics.length > 0) {
          KNotification.warn({
            message: '系统提示',
            description: '通用指标不可重复添加'
          })
          event.cancel = true
          return
        }
        // 根据对象中是否有branch对象判断是否是版本对象，若为版本对象则取branch.id
        const objList = objs.rows.map(obj => {
          return {
            id: obj.id,
            clazz: obj.rdmExtensionType,
            className: obj.rdmExtensionType
          }
        })
        const params = {
          data: [
            {
              folderId: this.refCommonFolderManage.value?.getSelectTreeNode()?.id,
              objList: objList
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'batchCreateFolderObjLink', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '对象添加成功'
          })
          //将是否通用改为通用
          const updateParam = objs.rows.map(obj => {
            return {
              id: obj.id,
              isGeneral: true,
              folder: {
                id: folder!.id,
                clazz: 'Folder'
              }
            }
          })

          const updateResult = await Api.post('requirement', 'ValidationMetrics', 'batchUpdate', { data: [updateParam] })
          if (!updateResult || updateResult.code != EnumRequestCode.SUCCESS) {
            KNotification.error({
              title: '系统提示',
              content: updateResult.message!
            })
          }
          this.refCommonFolderManage.value?.refreshList()
          return
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
          event.cancel = true
          return
        }
      }
    })
  }
}
