import { Agent } from '@kmsoft/ebf-common'
import { DpMsgHandler } from '@kmsoft/ebf-doc/src/client-agent'
import { AppRunner, EnumApplicationRunnerExecutionOpportunity, utils } from '@kmsoft/upf-core'

/**
 * 协调插件独立弹窗准备插件
 * 1、启动代理
 * 2、遮罩
 */
export class CooperativePluginPrepareRunner implements AppRunner {
  /**
   * 执行时机是挂载之后
   * @returns
   */
  opportunity(): EnumApplicationRunnerExecutionOpportunity {
    return EnumApplicationRunnerExecutionOpportunity.AFTER_MOUNT
  }
  run(): Promise<void> {
    return new Promise(resolve => {
      // 获取当前页面的 URL
      let currentUrl = window.location.href

      // 如果URL中包含两个问号，将第二个问号替换为&
      if (currentUrl.split('?').length > 2) {
        currentUrl = currentUrl.replace(/\?([^?]*)$/, '&$1')
      }
      const agentParamObject = utils.getQueryObj(currentUrl, 'agent')
      if (agentParamObject) {
        const agentParam = agentParamObject['agent']
        // 解码 agentParam
        const decodedAgentParam = atob(agentParam)
        // 将解码后的参数字符串解析为Record对象
        const parsedParams: Record<string, string> = {}
        decodedAgentParam.split('&').forEach(param => {
          const [key, value] = param.split('=')
          if (key && value) {
            parsedParams[key] = decodeURIComponent(value)
          }
        })
        console.log('解析后的参数:', parsedParams)
        const actionType = parsedParams['actionType']
        const mask = document.getElementById('maskLayer') as HTMLElement
        if (mask) {
          mask.style.display = 'flex'
        }
        switch (actionType) {
          case 'startAgent':
            // alert('代理启动中...')
            // 启动代理
            Agent.AgentManager.initialize().then(() => {
              resolve(void 0)
            })
            break
          case 'registerDpMsg':
            // alert('代理启动并注册dpMsg...')
            // 启动代理并注册DpMsg
            Agent.AgentManager.initialize(parsedParams['agentId'])
              .then(() => {
                return DpMsgHandler.register()
              })
              .then(() => {
                resolve(void 0)
              })
            break
          default:
            console.log('匹配不到任何行为')
            mask.style.display = 'none'
            break
        }
      }
      const actionTypeObject = utils.getQueryObj(currentUrl, 'actionType')
      if (actionTypeObject) {
        const mask = document.getElementById('maskLayer') as HTMLElement
        if (mask) {
          mask.style.display = 'flex'
        }
        const actionType = actionTypeObject['actionType']
        switch (actionType) {
          case 'startAgent':
            // alert('代理启动中...')
            // 启动代理
            Agent.AgentManager.initialize().then(() => {
              resolve(void 0)
            })
            break
          case 'registerDpMsg':
            // alert('代理启动并注册dpMsg...')
            // 启动代理并注册DpMsg
            Agent.AgentManager.initialize(utils.getQueryObj(window.location.href, 'agentId')?.agentId)
              .then(() => {
                DpMsgHandler.register()
              })
              .then(() => {
                resolve(void 0)
              })
            break
          default:
            console.log('匹配不到任何行为')
            mask.style.display = 'none'
            break
        }
      }
      resolve(void 0)
    })
  }
}
