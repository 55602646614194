import { BaseViewModel, eventEmitter, PageManager, ViewModelOptions } from '@kmsoft/upf-core'
import { KHyperLinkEmitsType, KHyperLinkPropType } from './interface'
import { ref, watch } from 'vue'
import { clsCodeMap, EBF_IOC_KEY } from '../../../../client-srv'
import { ClassMetaClientSrv, ObjectClientSrv } from '../../../../index'
import { KMetricsEditPanel } from '../../../../../../ebf-requirement/src/pages/common-validation-metrics/metrics-edit-panel'

/** KHyperLinkRender */
export default class KHyperLinkViewModel extends BaseViewModel<KHyperLinkEmitsType, KHyperLinkPropType> {
  stateValue = ref<string>()
  /**原始值 */
  originalValue = ref<string>('')
  constructor(options: ViewModelOptions<KHyperLinkPropType>) {
    super(options)
    watch(
      () => options.props.value,
      () => {
        this.stateValue.value = options.props.value
      },
      {
        deep: true,
        immediate: true
      }
    )
  }

  viewDidMount() {}

  setValue(value: string, setChanged?: boolean) {
    if (setChanged) {
      this.originalValue.value = value
    }
    this.stateValue.value = value
  }
  getValue() {
    return this.stateValue.value
  }
  getModifiedValue() {
    return this.stateValue.value
  }
  isModified() {
    return this.stateValue.value != this.originalValue.value
  }
  /**
   * 打开新标签页展示对象
   */
  async openObjTab() {
    const param = {
      id: this.props.objParam?.id!,
      modelCode: this.props.objParam?.modelCode!,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(this.props.objParam?.modelCode!)
    }

    if (param.modelCode == 'ValidationMetrics') {
      const tabKey = `${param.id}#${param.modelCode}`
      const objResult = (await ObjectClientSrv.getObjBusiness(param)) as Record<string, any>
      const title =
        clsCodeMap.get(objResult.rdmExtensionType ? objResult.rdmExtensionType : objResult.targetExtensionType) +
        ':' +
        ObjectClientSrv.getObjBusinessDesc(objResult)
      PageManager.openPage(tabKey, title, KMetricsEditPanel, { objParam: param })
    } else {
      const tabKey = `${this.props.objParam?.id}#${this.props.objParam?.modelCode}#${new Date().getTime()}`
      eventEmitter.emit(EBF_IOC_KEY.CLOSE_DIALOG, 'closeDialog', {} as any)
      ObjectClientSrv.openObj(param, tabKey)
    }
  }
}
