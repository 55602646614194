import { connect, withInstall } from '@kmsoft/upf-core'
import KAddPlanDialogView from './KAddPlanDialog.vue'
import KAddPlanDialogViewModel from './KAddPlanDialogViewModel'

const KAddPlanDialog = connect(KAddPlanDialogView, KAddPlanDialogViewModel)

export default withInstall(KAddPlanDialog)
export { KAddPlanDialog, KAddPlanDialogView, KAddPlanDialogViewModel }

// 模板生成文件
// export * from './{{folderName}}'
