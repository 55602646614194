
import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KMaterialFormPropOptions, KMaterialFormEventEmits } from './interface'
import KMaterialFormViewModel from './KMaterialFormViewModel'

export default defineView({
  name: 'KMaterialForm',
  props: KMaterialFormPropOptions,
  emits: KMaterialFormEventEmits,
  viewModel: KMaterialFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      pagination: { pageIndex: 1, pageSize: 100 },
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE
    }
  }
})
