import { connect, withInstall } from '@kmsoft/upf-core'
import KRequirementChangeBeforeView from './KRequirementChangeBefore.vue'
import KRequirementChangeBeforeViewModel from './KRequirementChangeBeforeViewModel'

const KRequirementChangeBefore = connect(KRequirementChangeBeforeView, KRequirementChangeBeforeViewModel)

export default withInstall(KRequirementChangeBefore)
export { KRequirementChangeBefore, KRequirementChangeBeforeView, KRequirementChangeBeforeViewModel }

// 模板生成文件
// export * from './{{folderName}}'
