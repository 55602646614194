import { Api, EnumRequestCode, FolderTypeEnum, EnumPurviewCode, localStorageCache, sysSrv } from '@kmsoft/ebf-common'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  EnumToolStripCompType,
  KNotification,
  KTreeNode,
  ToolStripButtonItem,
  ToolStripItem,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { UserClientSrv } from '../../../client-srv'
import { EnumToolStripItemKeys, FolderTreeNode, IFolder } from '../../folder-manage/folder-tree/interface'
import { CADWorkspaceRootTreeNode } from '../cad-workspace/interface'

/** 参数 **/
export const KPersonalWorkspacePropOptions = {
  ...BaseViewPropOptions,
  /** 是否显示CAD操作按钮 */
  showCADOperation: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KPersonalWorkspacePropType = ViewPropsTypeExtract<typeof KPersonalWorkspacePropOptions>

/** 事件 */
export const KPersonalWorkspaceEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPersonalWorkspaceEmitsType = ViewEmitsTypeExtract<typeof KPersonalWorkspaceEventEmits>

/**
 * 文件夹目录树根节点定义
 */
export class PersonalWorkspaceRootTreeNode extends KTreeNode {
  /**
   * 图标
   */
  iconType: string

  /**
   * 是否是单节点
   */

  isSingleRoot: boolean
  /**
   * 编码
   */
  nameEn: string
  /**
   * 类型
   */
  type: string

  constructor(parentNode?: IFolder) {
    if (parentNode) {
      super()
      this.id = parentNode.id
      this.name = '个人工作区'
      this.leaf = parentNode.leafFlag
      this.iconType = 'home'
      this.isSingleRoot = true
      this.nameEn = parentNode.nameEn
      this.type = parentNode.type
    } else {
      super()
      this.id = '__root__'
      this.name = '全局'
      this.leaf = false
      this.iconType = 'home'
    }
  }

  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const newChild = [] as Array<KTreeNode>
    const param = {
      data: [
        this.id,
        {
          rdmPageVO: {
            maxPageSize: 1000,
            curPage: 1,
            pageSize: 1000,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          }
        }
      ]
    }
    const result = await Api.post('folder', 'Folder', 'listChildFolder', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        switch (item.type) {
          case FolderTypeEnum.CAD:
            if (sysSrv.isSSOLogin()) {
              const systemLimits = localStorageCache.getCache('KMPDM-system_limits') as string
              const userPermission: any = systemLimits ? JSON.parse(systemLimits) : []
              const f = userPermission?.find((item: any) => item.purviewName === '工作台-CAD工作区')
              if (f) {
                item.iconType = 'folder'
                const CADfolderChildNode = new CADWorkspaceRootTreeNode(item as any)
                newChild.push(CADfolderChildNode)
              }
            } else {
              item.iconType = 'folder'
              const CADfolderChildNode = new CADWorkspaceRootTreeNode(item as any)
              newChild.push(CADfolderChildNode)
            }

            break
          default:
            const folderChildNode = new FolderTreeNode(item as any)
            newChild.push(folderChildNode)
            break
        }
      }
    } else {
      KNotification.error({
        title: '获取子节点失败',
        content: result.message!,
        details: result.detail!
      })
    }
    return newChild
  }
  /**
   * 获取右键菜单
   * @returns 菜单列表
   */
  getContextMenus(): Array<ToolStripItem> {
    const workSpaceRootToolStripItem = [
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.NEW,
        /** 文字 */
        title: '新建',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.CreateFolder,
        icon: 'folder-add',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.REFRESH,
        /** 文字 */
        title: '刷新',
        /** 是否可见  */
        visible: true,
        icon: 'loading-3-quarters',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem
    ] as Array<ToolStripItem & { purviewId?: string }>
    return workSpaceRootToolStripItem.filter(item => !item.purviewId || UserClientSrv.verifyPurview(item.purviewId))
  }
}
