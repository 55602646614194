
import { defineView } from '@kmsoft/upf-core'
import { KFlowHistorySigningPropOptions, KFlowHistorySigningEventEmits } from './interface'
import KFlowHistorySigningViewModel from './KFlowHistorySigningViewModel'
import { ref } from 'vue'

export default defineView({
  name: 'KFlowHistorySigning',
  props: KFlowHistorySigningPropOptions,
  emits: KFlowHistorySigningEventEmits,
  viewModel: KFlowHistorySigningViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const activeKey = ref(['1'])
    const ProcessState = Object.freeze({
      SUSPENDED: '挂起',
      ACTIVE: '审核中',
      COMPLETED: '已完成',
      INTERNALLY_TERMINATED: '取消'
    })

    const ProcessStateColor = Object.freeze({
      SUSPENDED: '#6c757d',
      ACTIVE: '#fd7e14',
      COMPLETED: '#218838',
      INTERNALLY_TERMINATED: '#dc3545'
    })
    return { activeKey, ProcessState, ProcessStateColor }
  }
})
