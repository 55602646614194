import { AgentStaticObject } from '../../AgentStaticObject'

/**哈希工具集。*/
export class FileUtil extends AgentStaticObject {
  protected static type: string = 'KMSoft.Agent.FileUtil'

  /**
   * 计算文件 Hash 值。
   * @param argFile 文件。
   * @returns Hash 值。*/
  public static async GetFileMd5(argFile: string): Promise<string> {
    return this.invokeStaticMethod('GetFileMd5', argFile)
  }

  /**
   * 检查指定的文件是否被占用。
   * @param path 要检查的文件的完整路径。
   * @returns 如果文件被占用返回 true，否则返回 false。
   * @throws 如果文件不存在或者调用方没有所需的权限，将会抛出错误。
   */
  public static async IsFileLocked(path: string): Promise<boolean> {
    try {
      return this.invokeStaticMethod('IsFileInUse', path)
    } catch (error) {
      throw new Error(`检查文件锁定状态失败: ${error}`)
    }
  }
}
