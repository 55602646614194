import { BaseViewModel, ViewModelOptions, KNotification } from '@kmsoft/upf-core'
import { KChangePromoteActivityEmitsType, KChangePromoteActivityPropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** KChangePromoteActivity */
export default class KChangePromoteActivityViewModel extends BaseViewModel<
  KChangePromoteActivityEmitsType,
  KChangePromoteActivityPropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  sycPartPhaseSequence = ref<any[]>()
  formData = ref<any>({
    number: '',
    name: '',
    activityType: '',
    rdmExtensionType: 'PromoteActivity',
    inventoryDisposition: '',
    description: '',
    supplyingOrganizations: '',
    needDate: null,
    secondaryIds: null,
    folder: {
      id: ''
    },
    changeBeforeLinkViews: [],
    changeAfterLinkViews: [],
    extAttrs: []
  })

  constructor(options: ViewModelOptions<KChangePromoteActivityPropType>) {
    super(options)
  }

  viewDidMount() {
    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
    }

    //变更前赋值
    if (this.props.changeBeforeData) {
      this.formData.value.changeBeforeLinkViews = lodash.cloneDeep(this.props.changeBeforeData)
    }

    //当前阶段赋值
    if (this.props.extAttrs) {
      this.formData.value.extAttrs = lodash.cloneDeep(this.props.extAttrs)
    }
    this.getConfigSys()
  }

  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())

    return createObject
  }

  async getConfigSys() {
    const result = await Api.post('sys', 'ConfigSysQueryService', 'listGridConfigByCode', {
      data: ['SYS_PART_PHASE_SEQUENCE']
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.sycPartPhaseSequence.value = result.data
    }
  }

  /** 校验 */
  validate() {
    const createObject = this.getValue()
    const extAttrs = createObject?.extAttrs
    const hasExtAttrs = extAttrs.some((item: any) => !item.value)
    if (hasExtAttrs) {
      KNotification.warn({
        message: '系统提示',
        description: '当前阶段和目标阶段不能为空'
      })
      return false
    }
    if (extAttrs.length > 0) {
      const sortData = extAttrs.map((item: any) => {
        return this.sycPartPhaseSequence.value!.findIndex((it: any) => it.number == item.value)
      })
      if (sortData[0] >= sortData[1]) {
        KNotification.warn({
          message: '系统提示',
          description: '目标阶段应在当前阶段之后'
        })
        return false
      }
    }
    /** 检查改前改后对象与当前阶段和目标阶段是否一致 */
    const checkChangeBefore = createObject!.changeBeforeLinkViews.some(
      (item: any) => item.className == 'Part' && item.phase != extAttrs[0].value
    )
    const checkChangeAfter = createObject!.changeAfterLinkViews.some(
      (item: any) => item.className == 'Part' && item.phase != extAttrs[0].value
    )
    if (checkChangeBefore) {
      KNotification.warn({
        message: '系统提示',
        description: '改前对象存在零部件和当前阶段不一致的数据'
      })
      return false
    }
    if (checkChangeAfter) {
      KNotification.warn({
        message: '系统提示',
        description: '改后对象存在零部件和目标阶段不一致的数据'
      })
      return false
    }
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const createObject = this.getValue()
    //变更前对象
    createObject!.changeBeforeLinkViews = (createObject!.changeBeforeLinkViews as any[]).map(item => {
      return {
        masterId: item.targetMasterId || item?.master?.id,
        branchId: item.targetBranchId || item?.branch?.id,
        target: {
          id: item.targetId || item.id,
          className: item.targetClass || item.className,
          rdmExtensionType: item.targetExtensionType || item.rdmExtensionType
        }
      }
    })
    //变更后对象
    createObject!.changeAfterLinkViews = (createObject!.changeAfterLinkViews as any[]).map(item => {
      return {
        masterId: item.targetMasterId || item?.master?.id,
        target: {
          id: item.targetBranchId || item?.branch?.id,
          rdmExtensionType: item.targetBranchType || item?.branch?.rdmExtensionType
        }
      }
    })
    if (createObject!.secondary) {
      const secondary = createObject!.secondary.map((element: string) => {
        return { id: element }
      })
      createObject!.secondary = secondary
    }

    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }

    return await Api.post('change', 'PromoteActivity', 'create', params)
  }

  async loaded() {
    if (this.props.inBomCreate) {
      // BOM树上创建时已携带扩展属性，当前阶段不可再编辑
      const extendAttrs = ['_#CurrentPhase']
      extendAttrs.forEach(item => {
        const extendAttrsViewModel = this.getByRecursion(item) as any
        extendAttrsViewModel.setDisabled(true)
      })
    }
  }
}
