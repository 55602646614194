import { ref, watch } from 'vue'
import { BaseViewModel, KFormViewModel, SelectOption, utils, ViewModelOptions } from '@kmsoft/upf-core'
import { KClassPropertyFormEventEmitsType, KClassPropertyFormPropType, IClsPropsFormDefine } from './interface'
import { EnumClsEditorType, allDataTypeConfig } from '../enums'
import { KCustomPropsFormViewModel } from './custom-prop-form/KCustomPropsFormViewModel'
import { EditorTypeBuilder } from './EditorTypeBuilder'

export class KClassPropertyFormViewModel extends BaseViewModel<KClassPropertyFormEventEmitsType, KClassPropertyFormPropType> {
  //文件属性编码
  private FILED_PROPERTY_CODE = 'fileId'

  /** 参数表单的ref */
  refPropForm = ref<KFormViewModel<any>>()
  /** 自定义参数表单的ref */
  refCustomPropsForm = ref<KCustomPropsFormViewModel>()
  /** 表单数据 */
  formState = ref<IClsPropsFormDefine & Record<string, any>>({ displayPattern: 0 })
  /** 当前数据类型对应的编辑器类型列表 */
  optionalEditorTypeList = ref<Array<{ label: string; value: EnumClsEditorType }>>()

  /** 所有数据类型的下拉选项 */
  dataTypeOptions = ref<Array<SelectOption>>(Object.values(allDataTypeConfig))

  constructor(options: ViewModelOptions<KClassPropertyFormPropType>) {
    super(options)

    watch(
      () => options.props.dataType,
      newVal => {
        // 先切换组件
        // 数据类型对应的可用编辑方式列表
        const optionalEditorTypeList = newVal ? new EditorTypeBuilder(newVal).getEditorTypeList() : []

        this.setOPtionalEditorTypeList(optionalEditorTypeList)
      },
      { immediate: true }
    )
  }

  /** 加载完成函数 */
  viewDidMount() {
    // //todo 这里对对象类的编辑类型特殊处理
    // if (this.props.formState.editType === 23) {
    //   this.props.formState.editType = 7
    // }
    this.setValue(this.props.formState)
  }

  setValue(data: IClsPropsFormDefine & Record<string, any>) {
    // 默认显示，默认左对齐
    if (utils.isEmpty(data.alignType)) data.alignType = 1
    if (utils.isEmpty(data.visible)) data.visible = 1
    if (utils.isEmpty(data.displayPattern)) data.displayPattern = 0

    // 对字段进行转换， 1=》true, 0 -> false
    this.formState.value = {
      ...data,
      nullable: data.nullable === 1 ? true : false,
      readonly: data.readonly === 1 ? true : false,
      changeable: data.changeable === 1 ? true : false,
      visible: data.visible === 1 ? true : false,
      predefined: data.predefined === 1 ? true : false
    }

    // 编辑类型的自定义属性, 初始化的时候，通过props传参的方式进行
    const customFormState = data.editArgs && JSON.parse(data.editArgs)

    // 这个地方设置timeout, 是为了先加载props
    this.refCustomPropsForm.value!.setValue(customFormState)
  }

  // 获取表单的值，基本数据表单的值，自定义属性表单的值
  getValue() {
    // 获取基本数据表单的值
    const basePropsFormState = {
      ...this.formState.value,
      nullable: this.formState.value.nullable ? 1 : 0,
      readonly: this.formState.value.readonly ? 1 : 0,
      changeable: this.formState.value.changeable ? 1 : 0,
      visible: this.formState.value.visible ? 1 : 0,
      predefined: this.formState.value.predefined ? 1 : 0
    }

    // 获取自定义属性表单的值
    const editArgs = this.refCustomPropsForm.value!.getValue()

    // 数据组装成需要的格式
    return {
      ...basePropsFormState,
      editArgs: JSON.stringify(editArgs)
    }
  }
  /**
   * 设置文本类型的下拉选项
   * @param data
   */
  setOPtionalEditorTypeList(data: Array<{ label: string; value: EnumClsEditorType }>) {
    this.optionalEditorTypeList.value = data
  }
}
