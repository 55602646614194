import {
  MemoryCacheFactory,
  EnumDialogResult,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  SimpleViewModel,
  ViewModelOptions,
  KNotification,
  KOrderAdjustPanel
} from '@kmsoft/upf-core'
import {
  KChangeOrderActivityPlanGridDataInter,
  KChangeOrderActivityPlanGridEmitsType,
  KChangeOrderActivityPlanGridPropType
} from './interface'
import { ref } from 'vue'
import { CommonClientSrv, KObjectSelectorViewModel, ObjectClientSrv, UserInfoCache } from '@kmsoft/ebf-common'
import { KActivityPlanDetailGrid, KActivityPlanDetailGridViewModel } from './activity-plan-detail-grid'
import lodash from 'lodash'
import { KPartStructureManage } from '../../../../ebf-part/src/controls'
import { KChangeActivityEdit } from '../../pages/change-activity/change-activity-edit'
import { KActivityPlanPreDetailGrid, KActivityPlanPreDetailGridViewModel } from './activity-plan-pre-detail-grid'

/** KChangeOrderActivityPlanGrid */
export default class KChangeOrderActivityPlanGridViewModel extends SimpleViewModel<
  KChangeOrderActivityPlanGridEmitsType,
  KChangeOrderActivityPlanGridPropType
> {
  gridData = ref<KChangeOrderActivityPlanGridDataInter[]>([])
  refDataGrid = ref<KDataGridViewModel>()
  modified = ref<boolean>(false)
  userList = ref<Record<string, any>[]>([])
  constructor(options: ViewModelOptions<KChangeOrderActivityPlanGridPropType>) {
    super(options)
  }

  viewDidMount() {
    this.getUserData()
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  public setValue(newValue: any, setChanged?: boolean) {
    this.gridData.value = lodash.cloneDeep(newValue)
  }

  /** 添加活动项 **/
  addActivity(item: KChangeOrderActivityPlanGridDataInter) {
    this.modified.value = true
    this.gridData.value.push(item)
  }

  addActivityItem(id: string, items: any[]) {
    const find = this.gridData.value.find(v => v.id === id)
    if (find) {
      const affectedObjectList = find.affectedObjectList
      // 如果有重复的不添加
      const addItem = items.filter(item => !affectedObjectList?.some(v => v.id === item.id)) || []
      if (addItem.length > 0) {
        this.modified.value = true
      }
      find.affectedObjectList?.push(...addItem)
    }
  }

  /** 移除 **/
  removeData() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    const lifecycleStateCodes = selectedRows.map(item => item.lifecycleStateCode)
    if (lifecycleStateCodes.includes('Released') || lifecycleStateCodes.includes('UnderReview')) {
      KNotification.warn({
        message: '系统提示',
        description: '已发布和审核中的数据不可移除'
      })
      return
    }
    if (selectedRows.length > 0) {
      this.modified.value = true
      this.gridData.value = this.gridData.value.filter(item => !selectedRows.some(row => row.id === item.id))
    }
  }

  /**
   * 排序
   */
  public async sort() {
    const rows = this.refDataGrid.value?.getRows()
    const { result } = await KOrderAdjustPanel.show({
      rowKey: 'number',
      list: rows,
      fields: [
        { title: '编码', id: 'number' },
        { title: '名称', id: 'name' }
      ]
    })

    const sortedItems = result.sortedItems
    sortedItems.forEach((item, index) => item.sortId = index + 1);
    this.modified.value = true
    this.gridData.value = Object.values(sortedItems)
    this.refresh()
  }

  /** 查看受影响对象详情 **/
  openAffectedObjectDetail(row: KChangeOrderActivityPlanGridDataInter) {
    KDialog.show({
      title: '关联的受影响对象',
      size: { width: 800, height: 600 },
      props: {
        ...this.props,
        dataSource: row.affectedObjectList,
        onRemoveAffectedObject: (item: any) => this.removeAffectedObject(row, item)
      },
      showCancel: false,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KActivityPlanDetailGrid,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
      }
    })
  }

  openPreChangeActivityDetail(row: KChangeOrderActivityPlanGridDataInter) {
    const rows = this.refDataGrid.value?.getRows()
    const noSelfRows = rows?.filter(r => r.number !== row.number)
    KDialog.show({
      title: '前置变更活动',
      size: { width: 600, height: 400 },
      props: {
        ...this.props,
        dataSource: noSelfRows,
        preChangeActivities: row.preChangeActivities
      },
      showCancel: false,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KActivityPlanPreDetailGrid,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        if (!this.readonly.value) {
          // 获取当前弹出组件实例的ViewModel
          const selectorViewModel = event.viewModel as KActivityPlanPreDetailGridViewModel
          // 根据viewModel获取到当前选中的数据
          const selectedRows = selectorViewModel.getSelectedRows()
          row.preChangeActivities = selectedRows.map(item=>item.number) || []
          this.modified.value = true
        }
      }
    })
  }

  /** 打开变更活动 **/
  openChangeActivity(row: any) {
    if (!row.createTime) {
      KNotification.warn({
        message: '系统提示',
        description: '当前对象未创建，无法查看详情'
      })
      return
    }
    const param = {
      id: row.id,
      modelCode: 'ChangeActivity',
      modelGroup: 'change'
    }
    ObjectClientSrv.openObj(param)
  }

  /** 删除受影响对象 **/
  removeAffectedObject(row: KChangeOrderActivityPlanGridDataInter, item: any) {
    if (row.lifecycleStateCode == 'Released' || row.lifecycleStateCode == 'UnderReview') {
      KNotification.warn({
        message: '系统提示',
        description: '已发布和审核中的数据不可移除关联的对象类'
      })
      return
    }
    const findIndex = row.affectedObjectList!.findIndex((affectObject: any) => affectObject?.id === item?.id)

    if (findIndex >= 0) {
      row.affectedObjectList!.splice(findIndex, 1)
    }
  }

  /** 根据ID删除受影响对象 **/
  removeAffectedObjectByIds(ids: string[]) {
    this.gridData.value.forEach(row => {
      row.affectedObjectList = row.affectedObjectList?.filter(item => !ids.includes(item.id))
    })
  }

  /** 根据ID查询变更活动的状态 */
  getlifecycleStateById(ids: string[]) {
    const data = this.gridData.value.filter(row => row.affectedObjectList?.some(item => ids.includes(item.id)))
    const lifecycleStateCodes = data.map(item => item.lifecycleStateCode)
    return lifecycleStateCodes
  }

  /** 刷新 **/
  refresh() {}

  isModified(): boolean {
    // 编辑时默认认为修改
    return this.modified.value
  }

  getModifiedValue() {
    return this.getValue()
  }

  public getValue() {
    return this.gridData.value || []
  }
}
