import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  SimpleViewPropOptions,
  SimpleViewEventEmits,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KBasePropertyEditorPropOptions } from '@kmsoft/ebf-common'

/** 参数 **/
export const KPromoteActivityBeforeGridPropOptions = {
  ...SimpleViewPropOptions,
  ...KBasePropertyEditorPropOptions,
  /** 是否是变更后对象 **/
  isAfterObject: VuePropTypes.bool().def(false),
  /** 过滤状态 **/
  filterLifecycleState: VuePropTypes.string().def('Released'),
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KPromoteActivityBeforeGridPropType = ViewPropsTypeExtract<typeof KPromoteActivityBeforeGridPropOptions>

/** 事件 */
export const KPromoteActivityBeforeGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KPromoteActivityBeforeGridEmitsType = ViewEmitsTypeExtract<typeof KPromoteActivityBeforeGridEventEmits>
