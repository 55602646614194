
import { defineView } from '@kmsoft/upf-core'
import KFolderManage from '../../../folder-manage'
import { KCadWorkspaceDetailPropOptions, KCadWorkspaceDetailEventEmits } from './interface'
import KCadWorkspaceDetailViewModel from './KCadWorkspaceDetailViewModel'

export default defineView({
  name: 'KCadWorkspaceDetail',
  props: KCadWorkspaceDetailPropOptions,
  emits: KCadWorkspaceDetailEventEmits,
  components: { KFolderManage },
  viewModel: KCadWorkspaceDetailViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
