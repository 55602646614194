import {
  BaseViewModel,
  IKDataGridCellParams,
  IQuery,
  KDataGridCellEditableParams,
  KDataGridViewModel,
  KSelect,
  SelectOption,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { createTextVNode, createVNode, ref, watch } from 'vue'
import { EnumOperateType, KFileComparisonEmitsType, KFileComparisonPropType } from './interface'

/** KFileComparison */
export default class KFileComparisonViewModel extends BaseViewModel<KFileComparisonEmitsType, KFileComparisonPropType> {
  refDataGrid = ref<KDataGridViewModel>()
  gridData = ref<Record<string, any>[]>()
  allOptions = ref<Array<SelectOption>>([])

  constructor(options: ViewModelOptions<KFileComparisonPropType>) {
    super(options)
    this.gridData.value = options.props.comparisonResult
    watch(
      () => options.props.operateOptions,
      newVal => {
        this.allOptions.value = newVal!
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  getUpdatedRows() {
    const rows = this.refDataGrid.value?.getRows()
    //过滤忽略和不更新
    const updateRow = rows?.filter(item => item.operate == EnumOperateType.Update)
    return updateRow
  }

  async loadData(query: IQuery): Promise<any> {
    return this.gridData.value
  }

  operationCellTemplateSelector(params: IKDataGridCellParams) {
    const res = this.allOptions.value.find(x => x.value == params.cellValue)!
    return createTextVNode(res.label)
  }

  operationCellEditingTemplateSelector(params: IKDataGridCellParams) {
    const operateOption = params.row['operateOption'] as string[]
    let options = this.allOptions.value
    if (operateOption) {
      options = this.allOptions.value.filter(item => operateOption.includes(item.value as string))
    }
    return createVNode(KSelect, { options: options })
  }

  getCellEditable(params: KDataGridCellEditableParams) {
    const column = params.column.dataPropertyName
    if (column == 'operate') {
      return true
    }
    return false
  }
}
