import { KDialog, request } from '@kmsoft/upf-core'
import { ObjBusinessParam, ObjectRelation, ObjectRelationDefination, ObjectRelationGroup } from './types'
import { Api, ConfigClientSrv } from '../base'

export class ObjectRelationClientSrv {
  /**所有关联分组。*/
  public static groups: ObjectRelationGroup[] = [
    { origin: 'source', destination: 'target', displayColumn: 'name', name: '正向关联' },
    { origin: 'target', destination: 'source', displayColumn: 'rvsName', name: '反向关联' }
  ]

  /**获取所有关联定义。
   * @param groups 关联分组。
   * @param modelCode 对象类编码。
   * @param relationCode 关联编码。
   * @returns 与 {@link modelCode} 和 {@link relationCode} 相关的所有关联定义。*/
  private static async fetchDefinitions(
    groups: ObjectRelationGroup[],
    modelCode: string,
    relationCode?: string
  ): Promise<ObjectRelationDefination[]> {
    const result =
      relationCode === undefined || relationCode === null
        ? await Api.post('', 'link', 'listDefinitions', { object: modelCode })
        : await Api.post('', 'link', 'getDefinition', { modelCode: modelCode, exValue: relationCode })
    if (!result || !result.isSuccess) result?.showMessage()

    return groups.flatMap<ObjectRelationDefination>(group =>
      ((result?.data ? (Array.isArray(result.data) ? result.data : [result.data]) : []) as Record<string, any>[])
        .filter(_ => _[group.origin + 'ClsCode'] === modelCode)
        .sort(_ => _.orderId)
        .map<ObjectRelationDefination>(_ => ({
          group: group.origin,
          id: _.id,
          code: _.code,
          modelCode: _.modelCode,
          modelGroup: _.group,
          name: _[group.displayColumn],
          relationClsId: _.relationClsId,
          relationClsCode: _.relationClsCode,
          originColumnPrefix: group.origin,
          originClsId: _[group.origin + 'ClsId'],
          originClsCode: _[group.origin + 'ClsCode'],
          originAccurate: _[group.origin + 'Accurate'],
          destinationColumnPrefix: group.destination,
          destinationClsId: _[group.destination + 'ClsId'],
          destinationClsCode: _[group.destination + 'ClsCode'],
          destinationAccurate: _[group.destination + 'Accurate']
        }))
    )
  }

  /**获取所有关联定义。
   * @param modelCode 对象类编码。
   * @returns 与 {@link modelCode} 相关的所有关联定义。*/
  public static async listDefinitions(modelCode: string): Promise<ObjectRelationDefination[]> {
    return this.fetchDefinitions(this.groups, modelCode)
  }

  /**获取关联定义。
   * @param modelCode 对象类编码。
   * @param relationCode 关连编码。
   * @returns 与 {@link modelCode} 和 {@link relationCode} 相关的关联定义。*/
  public static async getDefinition(modelCode: string, relationCode?: string): Promise<ObjectRelationDefination | undefined> {
    return (await this.fetchDefinitions([this.groups[0]], modelCode, relationCode ?? ''))[0]
  }

  /**获取关联对象数据。
   * @param objParam 主对象参数。
   * @param definition 关联定义信息。
   * @returns 关联对象数据。*/
  public static async listLinkedObjects(
    objParam?: ObjBusinessParam,
    definition?: ObjectRelationDefination
  ): Promise<ObjectRelation[]> {
    if (!objParam || !definition) return []

    const request: Record<string, any> = {
      modelCode: definition.relationClsCode,
      relationId: definition.id,
      direction: definition.originColumnPrefix
    }
    request[definition.originColumnPrefix + 'ObjId'] = objParam.id
    request[definition.originColumnPrefix + 'MasterId'] = objParam.masterId
    request[definition.originColumnPrefix + 'ClsCode'] = objParam.modelCode
    request[definition.destinationColumnPrefix + 'ClsCode'] = definition.destinationClsCode
    const result = await Api.post('', 'objectManagement', 'listLinkedObjects', request)
    if (!result.isSuccess) {
      result.showMessage()
      return []
    }
    return (result.data as ObjectRelation[]) ?? []
  }

  /**添加关联。
   * @param objParam 主对象参数。
   * @param definition 关联定义信息。
   * @param businessParamGenerator 关联对象生成器。
   * @param onSuccess 成功操作。*/
  public static async addLink(
    objParam?: ObjBusinessParam,
    definition?: ObjectRelationDefination,
    businessParamGenerator?: (arg0: ObjectRelationDefination) => Promise<ObjBusinessParam[] | undefined>,
    onSuccess?: () => Promise<void>
  ): Promise<void> {
    if (!objParam) {
      KDialog.info({ title: '提示', content: '主对象不能为空！' })
      return
    }

    if (!definition) {
      KDialog.info({ title: '提示', content: '关联关系定义不能为空！' })
      return
    }

    if (!businessParamGenerator) {
      KDialog.info({ title: '提示', content: '对象生成器不能为空！' })
      return
    }

    const businessParams = await businessParamGenerator(definition)
    if (!businessParams) return

    const requests = businessParams.map(businessParam => {
      const request: Record<string, any> = {
        modelCode: definition.relationClsCode,
        relationId: definition.id
      }
      request[definition.originColumnPrefix + 'ObjId'] = objParam.id
      request[definition.originColumnPrefix + 'MasterId'] = objParam.masterId
      request[definition.originColumnPrefix + 'ClsCode'] = objParam.modelCode
      request[definition.originColumnPrefix + 'Accurate'] = definition.originAccurate
      request[definition.destinationColumnPrefix + 'ObjId'] = businessParam.id
      request[definition.destinationColumnPrefix + 'MasterId'] = businessParam.masterId
      request[definition.destinationColumnPrefix + 'ClsCode'] = businessParam.modelCode
      request[definition.destinationColumnPrefix + 'Accurate'] = definition.destinationAccurate
      return request
    })

    const result = await Api.post('', 'objectManagement', 'insertLinks', requests)
    if (!result.isSuccess) result.showMessage()
    else if (onSuccess) return onSuccess()
  }

  /**删除关联。
   * @param relations 关联数据。
   * @param deleteObject 同步删除对象。
   * @param onSuccess 成功操作。
   */
  public static async deleteLinks(relations: ObjectRelation[], deleteObject: boolean, onSuccess?: () => Promise<void>) {
    let result = await Api.post('', 'objectManagement', 'deleteLinks', {
      object: relations.map(item => ({ id: item.relationId, modelCode: item.relationClsCode }))
    })

    if (deleteObject && result.isSuccess) {
      result = await Api.post('', 'objectManagement', 'deleteObjects', {
        object: relations.map(item => ({ id: item.id, modelCode: item.modelCode }))
      })
    }
    if (!result.isSuccess) result.showMessage()
    else if (onSuccess) return onSuccess()
  }
}
