import { Api, EnumRequestCode, IClsOption, KObjectDynamicCreatePanel } from '@kmsoft/ebf-common'
import { EnumDialogResult, KDataGridRowData, KDialog, KDialogClosingEvent, KNotification } from '@kmsoft/upf-core'

export class RequirementChangeClientSrv {
  public static async create(row: KDataGridRowData, folderId: string) {
    return new Promise((resolve, reject) => {
      //校验当前数据是否是最新发布的async
      Api.post('requirement', 'ProductNeeds', 'checkCanChange', { data: [row.targetId] }).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          if (!res.data.operatable) {
            KNotification.warn({
              message: '系统提示',
              description: '当前需求不可变更'
            })
            return
          }
        } else {
          KNotification.error({
            title: '校验异常',
            content: res.message || '',
            details: res.detail
          })
          return
        }

        const clsOptions = [] as Array<IClsOption>
        KDialog.show({
          title: '创建对象',
          content: KObjectDynamicCreatePanel,
          props: {
            clsOptions: clsOptions,
            folderId: folderId,
            showObjClsCodes: ['RequirementChangeRequest'],
            defaultSelectClsCode: 'RequirementChangeRequest',
            showObjClsSelector: false
          },
          size: { width: 900, height: document.documentElement.clientHeight - 300 },
          onClosing: async (event: KDialogClosingEvent) => {
            const formViewModel = event.viewInstance as any
            if (event.dialogResult == EnumDialogResult.Cancel) return
            if (event.dialogResult == EnumDialogResult.Close) return
            const validateResult = await formViewModel.validate()
            if (!validateResult) {
              event.cancel = true
              return
            }
            const result = await formViewModel.save(row, folderId)
            if (result && result.code == EnumRequestCode.SUCCESS) {
              KNotification.success('对象创建成功')
              resolve(result)
            } else {
              if (result) {
                KNotification.error({
                  title: '操作失败',
                  content: result.message || '创建对象失败',
                  details: result.detail
                })
              }
              event.cancel = true
              reject(void 0)
            }
          }
        })
      })
    })
  }
}
