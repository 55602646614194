import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ba72618"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex1" }
const _hoisted_2 = { class: "flex-center" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex-center mt10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_secction = _resolveComponent("secction")!

  return (_openBlock(), _createBlock(_component_secction, { class: "flex-start flex-direction-column flex-between h100p" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_panel, null, {
          header: _withCtx(() => [
            _createVNode(_component_k_tool_strip, null, {
              default: _withCtx(() => [
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "移除",
                  onClick: _ctx.$vm.removeData
                }, null, 8, ["onClick"]),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "重试",
                  onClick: _ctx.$vm.tryAgain,
                  disabled: _ctx.$vm.disabledTryAgain
                }, null, 8, ["onClick", "disabled"])
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_k_data_grid, {
              ref: "refDataGrid",
              dataSource: _ctx.fileList,
              rowKey: "tempId",
              onSelectionChanged: _ctx.$vm.selectionChanged,
              getCellClass: _ctx.getCellClass
            }, {
              columns: _withCtx(() => [
                _createVNode(_component_k_data_grid_template_column, {
                  headerText: "编码",
                  dataPropertyName: "number",
                  align: "center",
                  width: "150"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_k_input, {
                      value: row.number,
                      "onUpdate:value": ($event: any) => ((row.number) = $event),
                      placeholder: "请输入编码",
                      class: "custom-input"
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_data_grid_template_column, {
                  headerText: "文档名称",
                  dataPropertyName: "name",
                  align: "center",
                  width: "150"
                }, {
                  default: _withCtx(({ row }) => [
                    _createVNode(_component_k_input, {
                      value: row.name,
                      "onUpdate:value": ($event: any) => ((row.name) = $event),
                      placeholder: "请输入文档名称",
                      class: "custom-input"
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_data_grid_text_box_column, {
                  headerText: "文件名",
                  dataPropertyName: "fileName",
                  align: "center",
                  width: "150"
                }),
                _createVNode(_component_k_data_grid_template_column, {
                  headerText: "提取进度",
                  dataPropertyName: "extractProgress",
                  align: "center",
                  width: "200"
                }, {
                  default: _withCtx(({ row }) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["mr5", row.extractProgress.icon == 'loading' ? 'icon-loading' : ''])
                      }, [
                        _createVNode(_component_k_icon, {
                          type: row.extractProgress.icon
                        }, null, 8, ["type"])
                      ], 2),
                      _createElementVNode("div", null, _toDisplayString(row.proxyUpload ? row.extractProgress.extractName : row.extractProgress.uploadName), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_k_data_grid_template_column, {
                  name: "action",
                  align: "center",
                  headerText: "操作",
                  width: "130"
                }, {
                  default: _withCtx(({row}) => [
                    _createElementVNode("a", {
                      class: "row-operation-item",
                      onClick: (e) => _ctx.$vm.generateBusinessCode(row.tempId)
                    }, "生成编码", 8, _hoisted_3)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["dataSource", "onSelectionChanged", "getCellClass"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("footer", _hoisted_4, [
        _createVNode(_component_k_button, {
          onClick: _cache[0] || (_cache[0] = () => _ctx.emit('previousStep'))
        }, {
          default: _withCtx(() => [
            _createTextVNode("返回上一步")
          ]),
          _: 1
        }),
        _createVNode(_component_k_button, {
          type: "primary",
          onClick: _cache[1] || (_cache[1] = () => _ctx.emit('nextStep')),
          disabled: _ctx.$vm.disabledImport
        }, {
          default: _withCtx(() => [
            _createTextVNode("导入选中文件")
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ]),
    _: 1
  }))
}