import { EnumFileExistStatus, FileInfo, ObjBusinessBase } from '@kmsoft/ebf-common'

export class Doc implements Record<string, any>, ObjBusinessBase {
  [key: string]: any
  modelCode: string
  id: string
  modelGroup: string

  masterId?: string | undefined
  iteratorId?: string | undefined

  attachedTables?: Record<string, Record<string, any>[]> | undefined
  attachedFiles?: Record<string, Record<string, any>[]> | undefined

  code?: string
  name?: string

  /** 文件名字 */
  fileName?: string
  /** 文件id */
  fileId?: string
  /** 当前文件所属分区 */
  location: string
  /** 上传文件的uuid */
  uid?: string
  /** 文件大小 */
  size?: string
  /** MD5 */
  md5?: string
  /** 分片数量 */
  totalChunkCount?: number
  /** 当前文件已经上传过的分片index列表，断点续传 */
  uploadedChunkIndexList?: Array<string>
  /** 文件历史状态 */
  historyStatus?: EnumFileExistStatus
  /** 原始文件名称 */
  originalFileName?: string
  /** 分区 */
  displayLocation?: string

  /** 是否根节点 */
  isRoot?: boolean

  /** 是否检出 */
  checkout?: boolean
  /**检出路径 */
  checkoutPath?: string

  localFilePath?: string

  lifecycleStateCode?: string

  workingState?: string

  /** 文档路径 */
  folder?: {
    id: string
  }
}
