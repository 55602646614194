import { IAppConfigurer, IMenuPagePathMapping, IocContainer, UPF_IOC_KEYS } from '@kmsoft/upf-core'
import { App } from 'vue'
import { FolderMenuPathMapping } from './beans'
import KWorkspaceList from './pages/workspace/product-workspace/workspace-list'
import KPublicWorkspace from './pages/workspace/public-workspace'
import KPersonalWorkspace from './pages/workspace/personal-workspace'
import KStandardWorkspace from './pages/workspace/standard-workspace'
import KElectronicComponentsWorkspace from './pages/workspace/electronic-components-workspace'
import KCommonPartManage from './pages/workspace/common-part-workspace'
import KProjectWorkspaceList from './pages/workspace/project-workspace/project-workspace-list'
import KMyManagedProductionWorkspace from './pages/workspace/my-managed-production-workspace'
import KProjectWorkspace from './pages/workspace/project-workspace'
import KPersonalHomePage from './pages/workspace/personal-homepage'
import KCADWorkspace from './pages/workspace/cad-workspace'
import KClassUiDefine from '../../ebf-common/src/controls/class-ui-define'

export * from './pages/workspace/personal-workspace'

export default {
  configVue: (app: App) => {
    app.use(KWorkspaceList)
    app.use(KPublicWorkspace)
    app.use(KPersonalWorkspace)
    app.use(KStandardWorkspace)
    app.use(KElectronicComponentsWorkspace)
    app.use(KCommonPartManage)
    app.use(KProjectWorkspaceList)
    app.use(KMyManagedProductionWorkspace)
    app.use(KProjectWorkspace)
    app.use(KPersonalHomePage)
    app.use(KCADWorkspace)
    app.use(KClassUiDefine)
  },
  configBean: (iocContainer: IocContainer) => {
    iocContainer.registerSingleton<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING, new FolderMenuPathMapping())
  }
} as IAppConfigurer
export {
  KWorkspaceList,
  KPublicWorkspace,
  KPersonalWorkspace,
  KStandardWorkspace,
  KElectronicComponentsWorkspace,
  KCommonPartManage,
  KProjectWorkspaceList,
  KMyManagedProductionWorkspace,
  KProjectWorkspace,
  KPersonalHomePage,
  KClassUiDefine,
  KCADWorkspace
}
