import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** 参数 **/
export const KPartReportMakePropOptions = {
  ...BaseViewPropOptions,
  container: VuePropTypes.createType<HTMLElement>().def(),
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KPartReportMakePropType = ViewPropsTypeExtract<typeof KPartReportMakePropOptions>

/** 事件 */
export const KPartReportMakeEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartReportMakeEmitsType = ViewEmitsTypeExtract<typeof KPartReportMakeEventEmits>
