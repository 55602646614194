import { EnumAttributeType, EnumDataType } from '@kmsoft/upf-core'
const options = [
  { displayValue: '单选', actualValue: 'single' },
  { displayValue: '多选', actualValue: 'multiple' }
]

/* 组件的基本属性 */
const comConfig = [
  // {
  //   name: 'mode',
  //   title: '模式',
  //   control: 'KSelect',
  //   options: options,
  //   defaultValue: 'single',
  //   dataType: EnumDataType.STRING,
  //   attributeType: EnumAttributeType.PROPS
  // },
  {
    name: 'optionType',
    title: '候选项配置',
    control: 'custom',
    defaultValue: '',
    visible: true,
    dataType: EnumDataType.ARRAY,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'options',
    title: '候选项',
    control: 'custom',
    defaultValue: '',
    visible: true,
    dataType: EnumDataType.ARRAY,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'optionsDisable',
    title: '禁用候选项',
    control: 'KSelect',
    defaultValue: [],
    visible: true,
    options: [],
    dataType: EnumDataType.ARRAY,
    attributeType: EnumAttributeType.PROPS
  }
]

/**控件属性配置项集合 */
const PROPS = comConfig as Array<any>

const EVENT = [
  { name: 'change', title: '值改变事件', describe: '值改变前 的说明信息...' },
  { name: 'valueChanged', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { PROPS, EVENT }
