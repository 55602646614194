import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KBaselineCompareGridDialogPropOptions = {
  ...BaseViewPropOptions,
  /** 基线主对象信息 */
  primaryObject: VuePropTypes.createType<Record<string, any>>().def({})
}

/** 参数类型 **/
export type KBaselineCompareGridDialogPropType = ViewPropsTypeExtract<typeof KBaselineCompareGridDialogPropOptions>

/** 事件 */
export const KBaselineCompareGridDialogEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KBaselineCompareGridDialogEmitsType = ViewEmitsTypeExtract<typeof KBaselineCompareGridDialogEventEmits>
