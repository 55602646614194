import {
  Api,
  CommonClientSrv,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectSelectorViewModel,
  KObjectToolStripViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  ObjectToolStripItem,
  PartViewMemoryCache,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumDialogResult,
  KDataGridRowSelectedEvent,
  KDataGridViewModel,
  KDialog,
  KNotification,
  MemoryCacheFactory,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  request
} from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { Doc } from '../../../../ebf-doc/src/beans'
import { DocClientSrv } from '../../../../ebf-doc/src/client-srv'
import { KProjectPageWrapper } from '../../../../ebf-project-manage/src/components'
import { FlowApprovalContentInter, KFlowApprovalContentEmitsType, KFlowApprovalContentPropType } from './interface'
import { EnumDocType } from '@kmsoft/ebf-common/src/types/enums'

/** KFlowApprovalContent */
export default class KFlowApprovalContentViewModel extends BaseViewModel<
  KFlowApprovalContentEmitsType,
  KFlowApprovalContentPropType
> {
  gridData = ref<FlowApprovalContentInter[]>()
  refDataGrid = ref<KDataGridViewModel>()
  showDocBtn = ref<boolean>(false)
  refToolStrip = ref<KObjectToolStripViewModel>()
  toolStripItems = ref<Array<ObjectToolStripItem>>(ToolStripHelper.getApprovalFlowToolStripItems())
  constructor(options: ViewModelOptions<KFlowApprovalContentPropType>) {
    super(options)

    watch(
      () => options.props.businessId,
      newVal => {
        this.getSourceData()
      },
      {
        immediate: true,
        deep: true
      }
    )
  }

  viewDidMount() {
    this.initToolStrip()
  }

  initToolStrip() {
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_GROUP_FILE_OPERATION, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SET_PRIMARY_OBJ, false)
  }

  getSourceData() {
    if (this.props.businessId) {
      Api.post('official', 'ProcessContentService', 'listWorkObjInfo', {
        data: [this.props.businessId]
      }).then((result: any) => {
        if (result && result.code == EnumRequestCode.SUCCESS) {
          result.data = result.data.sort((a: any, b: any) => b.masterObjectMark - a.masterObjectMark)
          this.gridData.value = result.data || []
          let rowLifecycleIsChanged = false
          result.data?.forEach((data: any) => {
            if (data.lifecycleIsChanged) {
              rowLifecycleIsChanged = data.lifecycleIsChanged
            }
          })
          if (rowLifecycleIsChanged) {
            this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD, false)
          } else {
            this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD, true)
          }
        } else {
          KNotification.error({
            title: '获取失败',
            content: result.message,
            details: result.detail
          })
        }
      })
    }
  }

  /** 添加 **/
  addData() {
    // 打开对象查找选择框，但仅限于文档跟零部件
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      // objectClassManageQueryParam: {
      //   showObjClsCodes: ['Part', 'Document']
      // },
      isMultipleSelection: true,
      // objectSearchQueryParam: { showObjClsCodes: ['Part', 'Document'] },
      getContainer: this.refDataGrid.value?.getContainer(),
      onClosing: async event => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        // 获取当前弹出组件实例的ViewModel
        const selectorViewModel = event.viewModel as KObjectSelectorViewModel
        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows() || []

        const addParams = selectedRows.map(obj => {
          if (obj.branch) {
            return {
              sourceObjId: this.props.businessId,
              workObjId: obj.branch.id,
              workObjClsCode: obj.rdmExtensionType
            }
          } else {
            return {
              sourceObjId: this.props.businessId,
              workObjId: obj.id,
              workObjClsCode: obj.rdmExtensionType
            }
          }
        })

        if (addParams.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '请至少选择一条数据进行添加'
          })
          event.cancel = true
          return
        }

        if (addParams.length) {
          Api.post('official', 'ProcessContentService', 'batchAddWorkObj', { data: [addParams] }).then(result => {
            if (result && result.code == EnumRequestCode.SUCCESS) {
              //保存成功重新获取数据
              this.getSourceData()
            } else {
              KNotification.error({
                title: '获取失败',
                content: result.message,
                details: result.detail
              })
            }
          })
        }
      }
    })
  }
  /** 移除 **/
  removeData() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    if (selectedRows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    if (selectedRows.length) {
      KDialog.confirm({
        title: '系统提示',
        content: '确认要删除该内容么？',
        onOk: async () => {
          const removeParams = selectedRows.map(item => {
            return {
              sourceObjId: this.props.businessId,
              sourceClsCode: 'sys_wf_content',
              workObjId: item.id,
              workObjClsCode: item.rdmExtensionType
            }
          })

          Api.post('official', 'ProcessContentService', 'batchRemoveWorkObj', { data: [removeParams] }).then(result => {
            if (result && result.code == EnumRequestCode.SUCCESS) {
              this.getSourceData()
            } else {
              KNotification.error({
                title: '获取失败',
                content: result.message,
                details: result.detail
              })
            }
          })
        },
        onCancel: () => {}
      })
    }
  }
  /** 刷新 **/
  refresh() {
    this.getSourceData()
  }

  /** 浏览 **/
  async browse() {
    const rows = this.refDataGrid.value?.getSelectedRows()

    if (rows && (rows.length == 0 || rows.length > 1)) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    const resDoc = await DocClientSrv.getLatestByBranch([rows![0].id])
    if (resDoc) {
      DocClientSrv.browseDoc({ id: resDoc.id, modelCode: resDoc.targetExtensionType })
    } else {
      KNotification.warn({
        message: '系统提示',
        description: '该对象未获取到文档信息'
      })
    }
  }

  /** 下载 **/
  async download() {
    const rows = this.refDataGrid.value?.getSelectedRows()
    if (rows && (rows.length == 0 || rows.length > 1)) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    const resDoc = await DocClientSrv.getLatestByBranch([rows![0].id])

    const objParam = ref<ObjBusinessParam>()
    objParam.value = {
      id: resDoc.id,
      iteratorId: '',
      modelCode: rows![0].clsCode,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(rows![0].clsCode)
    }
    if (rows![0].clsCode == 'Document') {
      const doc = Object.assign(new Doc(), objParam.value)
      doc.checkoutPath = `C:\\KMSOFT\\temp\\Documents`
      DocClientSrv.downloadDoc(doc)
    } else {
      KNotification.info({
        message: '功能开发中'
      })
    }
  }
  /** 批注 **/
  async remark() {
    const rows = this.refDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    const resDoc = await DocClientSrv.getLatestByBranch([rows![0].id])
    // 获取对象id
    const docId = resDoc.id
    // 判断文件类型
    const docData = (await DocClientSrv.getDoc(docId)) as any
    if (!docData) return
    if (docData.documentType == EnumDocType.SW) {
      DocClientSrv.doBrowse(docId, '批注：' + docData.master.number, undefined, true)
      return
    }
    DocClientSrv.doRemark(docId, docData.documentType)
  }

  /** 查看批注 **/
  async viewRemark() {
    const rows = this.refDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    const resDoc = await DocClientSrv.getLatestByBranch([rows![0].id])

    // 获取对象id
    const docId = resDoc.id
    DocClientSrv.doViewRemark(docId)
  }

  /** 查看历史批注 **/
  async viewHistoryRemark() {
    const rows = this.refDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    const resDoc = await DocClientSrv.getLatestByBranch([rows![0].id])

    // 获取对象id
    const docId = resDoc.id
    DocClientSrv.doViewRemarkHistory(docId)
  }

  async onDoubleClick(row: Record<string, any>) {
    let objId = row.sourceId
    if ((row.rdmExtensionType == 'PMS_PLAN' || row.rdmExtensionType == 'PMS_PROJECT') && this.props.processDefinitionKey) {
      //临时处理，根据流程模板key判断打开计划、任务和项目
      const host = window.location.host
      const parts = host.split('.')
      parts[0] = 'flow-admin'
      // 本地通过localhost访问，没有域名，则默认使用test.kmsaasdev.com
      if (parts.length <= 1) {
        parts[1] = 'dev.kmsaas.com'
      }
      const newHost = parts.join('.')
      const url = ''
      let title = ''
      // 打开计划
      if (this.props.processDefinitionKey == 'PMS_CFG_WF_WBS_APPROVAL') {
        CommonClientSrv.openPage(`计划: ${row.name}`, KProjectPageWrapper, {
          name: 'ApprovalWBSPanel',
          isAbsUrl: false,
          windowId: new Date().getTime(),
          objectInfo: {
            planIds: row.id,
            accessMode: 1
          }
        })
        return
      }
      // 打开项目
      if (
        this.props.processDefinitionKey == 'PMS_CFG_WF_PROJECT_INITIATION' || //项目立项流程
        this.props.processDefinitionKey == 'PMS_CFG_WF_PROJECT_CHANGE' || //项目变更流程
        this.props.processDefinitionKey == 'PMS_CFG_WF_PROJECT_TERMINATE' || //项目终止流程
        this.props.processDefinitionKey == 'PMS_CFG_WF_PROJECT_COMPLETION' || //项目结项流程
        this.props.processDefinitionKey == 'PMS_CFG_WF_PROJECT_RESTART' || //项目重启流程
        this.props.processDefinitionKey == 'PMS_CFG_WF_PROJECT_PAUSE' //项目暂停流程
      ) {
        CommonClientSrv.openPage(`项目: ${row.name}`, KProjectPageWrapper, {
          name: 'ProjectInfoPanel',
          isAbsUrl: false,
          windowId: new Date().getTime(),
          objectInfo: {
            objectId: row.id,
            versionGroup: row.attachParams.projectVersionGroupId,
            accessMode: 1
          }
        })
        return
      }
      // 打开任务
      if (
        this.props.processDefinitionKey == 'PMS_CFG_WF_TASK_SUBMIT' || //任务提交流程
        this.props.processDefinitionKey == 'PMS_CFG_WF_PLAN_RESTART' || //任务重启流程
        this.props.processDefinitionKey == 'PMS_CFG_WF_PLAN_PAUSE' || //任务暂停流程
        this.props.processDefinitionKey == 'PMS_CFG_WF_PLAN_TERMINATE' //任务终止流程
      ) {
        title = '查看任务:' + row.name
        CommonClientSrv.openPage(`任务: ${row.name}`, KProjectPageWrapper, {
          name: 'TaskInfoPanel',
          isAbsUrl: false,
          windowId: new Date().getTime(),
          objectInfo: {
            objectId: row.id,
            projectId: row.attachParams.projectId,
            versionGroup: row.attachParams.planVersionGroupId,
            accessMode: 1
          }
        })
        return
      }
      return
    }
    if (row.clsCode == 'Document') {
      const doc = await DocClientSrv.getLatestByBranch([row.id])
      objId = doc.id
    }
    const param = {
      id: objId,
      modelCode: row.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row.rdmExtensionType)
    }
    ObjectClientSrv.openObj(param)
  }

  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const rows = this.refDataGrid.value!.getSelectedRows()
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.addData()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        this.removeData()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD:
        this.downloadTemplate()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC:
        this.browse()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_REMARK:
        this.remark()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK:
        this.viewRemark()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY:
        this.viewHistoryRemark()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SET_PRIMARY_OBJ:
        this.setMasterObj()
        break
      default:
        break
    }
  }
  /**
   * 下载Excel模板
   */
  async downloadTemplate() {
    // 获取模板配置
    const fetchByCode = await Api.post('sys', 'ConfigSysQueryService', 'fetchByCode', {
      data: ['SYS_PART_IMPORT_TEMPLATE']
    })
    if (!(fetchByCode && fetchByCode.success)) {
      KNotification.warn({
        message: '系统提示',
        description: '无法获取零部件导入模板配置'
      })
      return
    }
    // 下载模板
    const param = {
      modelName: 'ZmsAllPropertyTest',
      fileIds: fetchByCode.data
    }
    const result = await request.post('/kmsaasFileApi/download', param, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })
    if (result?.status == EnumRequestCode.SUCCESS && result.data) {
      const bolb = new Blob([result.data as any], { type: 'application/octet-stream,charset=UTF-8' })
      const url = URL.createObjectURL(bolb)
      const link = document.createElement('a')
      link.href = url
      link.download = '零部件导入模板.xlsx'
      link.click()
    } else {
      KNotification.error({
        title: '操作失败',
        content: '下载零部件导入模板失败'
      })
    }
  }
  /**
   * 改变工具栏
   */
  changeToolItem(event: KDataGridRowSelectedEvent<any>) {
    const selectRows = this.refDataGrid.value?.getSelectedRows()
    if (!selectRows || selectRows.length == 0) {
      this.initToolStrip()
      return
    }
    const newModelCode = selectRows[selectRows.length - 1].clsCode
    const param = { modelCode: newModelCode, environment: 100, selectedDatas: selectRows }
    this.refToolStrip.value?.updateState(param)
  }
  /**
   * 设为主对象
   */
  setMasterObj() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    if (selectedRows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    if (selectedRows.length) {
      KDialog.confirm({
        title: '系统提示',
        content: '确认要将该对象设为主对象吗？',
        onOk: async () => {
          const params = {
            sourceObjId: this.props.businessId,
            sourceClsCode: 'sys_wf_content',
            workObjId: selectedRows[0].id,
            workObjClsCode: selectedRows[0].rdmExtensionType
          }

          Api.post('official', 'ProcessContentService', 'setMasterObjectMark', { data: [params] }).then(result => {
            if (result && result.code == EnumRequestCode.SUCCESS) {
              this.getSourceData()
            } else {
              KNotification.error({
                title: '获取失败',
                content: result.message,
                details: result.detail
              })
            }
          })
        },
        onCancel: () => {}
      })
    }
  }

  getView(row: any) {
    const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
    const partView = cacheInstance.getAll()
    if (row.targetViewId && partView.length > 0) {
      const view = partView.filter(item => item.id == row.targetViewId)[0]?.description
      return '(' + view + ')'
    }
    return ''
  }
}
