import { computed, createVNode } from 'vue'
import { defineView, VNodeProps } from '@kmsoft/upf-core'
import {
  KObjectSelectorPropOptions,
  KObjectSelectorEventEmits,
  EnumObjectQueryMode,
  ObjectQueryModeDescriptions
} from './interface'
import KObjectSelectorViewModel from './KObjectSelectorViewModel'
import { KObjectClassManage } from '../object-class-manage'
import { KObjectClassManageEmitsType, KObjectClassManagePropType } from '../object-class-manage'
import { RuleResult } from '../rule-definer'
import { KObjectSearcherEmitsType, KObjectSearcherPropType } from '../object-searcher'
import { EnumObjClassManageToolStripOptions } from '../object-class-grid'
import { KObjectSearch } from '../object-search'
import { KObjectWorkspace } from '../object-workspace'
import { KObjectWorkspacePropType, KObjectWorkspaceEmitsType } from '../object-workspace'

/** 对象选择器 */
export default defineView({
  name: 'KObjectSelector',
  inheritAttrs: false,
  props: KObjectSelectorPropOptions,
  emits: KObjectSelectorEventEmits,
  viewModel: KObjectSelectorViewModel,
  setup(props, { attrs, slots, vm }) {
    /** 查询类型 */
    const queryTypes = computed(() => {
      const queryTypeArray = []

      // 对象类型查询
      if (props.supportedQueryMode & EnumObjectQueryMode.ObjectClassManageQuery) {
        queryTypeArray.push({
          value: EnumObjectQueryMode.ObjectClassManageQuery,
          label: ObjectQueryModeDescriptions[EnumObjectQueryMode.ObjectClassManageQuery]
        })
      }

      // 对象目录查询 // 高级搜索
      if (props.supportedQueryMode & EnumObjectQueryMode.ObjectDirectoryQuery) {
        queryTypeArray.push({
          value: EnumObjectQueryMode.ObjectDirectoryQuery,
          label: ObjectQueryModeDescriptions[EnumObjectQueryMode.ObjectDirectoryQuery]
        })
      }

      // 高级搜索
      // if (props.supportedQueryMode & EnumObjectQueryMode.AdvanceSearch) {
      //   queryTypeArray.push({
      //     value: EnumObjectQueryMode.AdvanceSearch,
      //     label: ObjectQueryModeDescriptions[EnumObjectQueryMode.AdvanceSearch]
      //   })
      // }

      // // 最近打开
      // if (props.supportedQueryMode & EnumObjectQueryMode.RecentOpenObj) {
      //   queryTypeArray.push({
      //     value: EnumObjectQueryMode.RecentOpenObj,
      //     label: ObjectQueryModeDescriptions[EnumObjectQueryMode.RecentOpenObj]
      //   })
      // }

      return queryTypeArray
    })

    /**
     * 搜索
     * @param filter
     */
    const onSearch = async (filter: RuleResult): Promise<void> => {}

    return () => {
      /** 渲染头部 */
      const renderHeader = () => {
        return (
          <>
            <div style="padding:6px 4px;">
              <span>检索方式：</span>
              <k-radio-group v-model={[vm.selectedQueryMode.value, 'value']}>
                <k-radio value={EnumObjectQueryMode.ObjectDirectoryQuery}>
                  {ObjectQueryModeDescriptions[EnumObjectQueryMode.ObjectDirectoryQuery]}
                </k-radio>
                <k-radio value={EnumObjectQueryMode.ObjectClassManageQuery}>
                  {ObjectQueryModeDescriptions[EnumObjectQueryMode.ObjectClassManageQuery]}
                </k-radio>
                {/* <k-radio value={EnumObjectQueryMode.AdvanceSearch}>
                  {ObjectQueryModeDescriptions[EnumObjectQueryMode.AdvanceSearch]}
                </k-radio> */}
              </k-radio-group>
            </div>
            <div v-show={vm.isShowQuickSearch.value}>
              <K-quick-search-bar isSingleLine={true} isShowSearchButton={true} onSearch={onSearch}></K-quick-search-bar>
            </div>
          </>
        )
      }

      /** 渲染网格 */
      const renderGrids = () => {
        // 通用网格
        if (vm.isShowCommonObjectGrid.value) {
          return <k-empty description={'暂未实现'} />
        }

        // 对象类管理
        if (vm.selectedQueryMode.value === EnumObjectQueryMode.ObjectClassManageQuery) {
          const objectClassManageProps: VNodeProps<KObjectClassManagePropType, KObjectClassManageEmitsType> = {
            ref: vm.refObjectQueryComponent,
            isMultipleSelection: props.isMultipleSelection,
            toolStripOptions:
              EnumObjClassManageToolStripOptions.ShowOperation | EnumObjClassManageToolStripOptions.ShowGridOperation,
            ...props.objectClassManageQueryParam
          }

          return createVNode(KObjectClassManage, objectClassManageProps)
        }

        // 高级搜索
        if (vm.selectedQueryMode.value === EnumObjectQueryMode.AdvanceSearch) {
          const objectSearchProps: VNodeProps<KObjectSearcherPropType, KObjectSearcherEmitsType> = {
            ref: vm.refObjectQueryComponent,
            ...props.objectSearchQueryParam
          }
          return createVNode(KObjectSearch, objectSearchProps)
        }
        //对象目录
        if (vm.selectedQueryMode.value === EnumObjectQueryMode.ObjectDirectoryQuery) {
          const objectQueryProps: VNodeProps<KObjectWorkspacePropType, KObjectWorkspaceEmitsType> = {
            ref: vm.refObjectQueryComponent,
            isMultipleSelection: props.isMultipleSelection,
            ...props.objectSearchQueryParam
          }
          return createVNode(KObjectWorkspace, objectQueryProps)
        }

        return <k-empty description="暂未实现" />
      }

      /** 面板插槽 */
      const panelSlots = {
        header: renderHeader,
        default: renderGrids
      }

      return <k-panel class="k-object-query" v-slots={panelSlots} />
    }
  }
})
