import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","display":"flex"} }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { style: {"margin-left":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_tool_strip, { name: "workspaceToolStrip" }, {
          default: _withCtx(() => [
            _createVNode(_component_k_tool_strip_button_item, {
              type: "primary",
              name: "createBtn",
              ref: "refCreateBtn",
              icon: "plus",
              title: "新建",
              onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.create())
            }, null, 512),
            _createVNode(_component_k_tool_strip_button_item, {
              icon: "delete",
              name: "deleteBtn",
              title: "删除",
              disabled: _ctx.$vm.deleteDisabled,
              onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.delete())
            }, null, 8, ["disabled"]),
            _createVNode(_component_k_tool_strip_button_item, {
              icon: "edit",
              name: "editBtn",
              title: "编辑",
              disabled: _ctx.$vm.editDisabled,
              onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.edit())
            }, null, 8, ["disabled"]),
            _createVNode(_component_k_tool_strip_button_item, {
              name: "refreshBtn",
              icon: "sync",
              title: "刷新",
              onClick: _cache[3] || (_cache[3] = () => _ctx.$vm.refresh())
            })
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowModelType: _ctx.rowModelType,
        class: "product-workspace-grid",
        rowKey: "id",
        name: "workspaceGrid",
        pagination: { pageIndex: 1, pageSize: 50 },
        ref: "refWorkspaceGrid",
        onRowSelected: _cache[4] || (_cache[4] = (event) => _ctx.$vm.selectRows(event)),
        loadData: _ctx.$vm.loadData
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_template_column, {
            id: "name",
            name: "name",
            dataPropertyName: "name",
            headerText: "名称",
            align: "center"
          }, {
            default: _withCtx(({ cellValue,row }) => [
              _createElementVNode("a", {
                href: "javascript:void(0)",
                onClick: () => _ctx.$vm.openProductWorkspace(row)
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(cellValue), 1)
              ], 8, _hoisted_2)
            ]),
            _: 1
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "libraryFolderName",
            name: "libraryFolderName",
            dataPropertyName: "libraryFolderName",
            headerText: "所属产品/项目库",
            width: "150",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "bomLibraryPositionName",
            name: "bomLibraryPositionName",
            dataPropertyName: "bomLibraryPositionName",
            headerText: "零部件默认储存位置",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "docLibraryPositionName",
            name: "docLibraryPositionName",
            dataPropertyName: "docLibraryPositionName",
            headerText: "图文档默认储存位置",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "localCachePosition",
            name: "localCachePosition",
            dataPropertyName: "localCachePosition",
            headerText: "本地缓存目录",
            width: "200",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            id: "description",
            name: "description",
            dataPropertyName: "description",
            headerText: "描述",
            width: "250",
            align: "center"
          }),
          _createVNode(_component_k_data_grid_template_column, {
            id: "creator",
            name: "creator",
            dataPropertyName: "creator",
            headerText: "创建者",
            width: "150",
            align: "center"
          }, {
            default: _withCtx(({ cellValue}) => [
              _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "loadData"])
    ]),
    _: 1
  }))
}