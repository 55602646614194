
import { defineView } from '@kmsoft/upf-core'
import { KInputNumberEditorPropOptions, KInputNumberEditorEventEmits } from './interface'
import KInputNumberViewModel from './KInputNumberEditorViewModel'

export default defineView({
  name: 'KInputNumberEditor',
  props: KInputNumberEditorPropOptions,
  emits: KInputNumberEditorEventEmits,
  viewModel: KInputNumberViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
