
import { defineView } from '@kmsoft/upf-core'
import { clsCodeMap } from '@kmsoft/ebf-common'
import { KActivityPlanPreDetailGridPropOptions, KActivityPlanPreDetailGridEventEmits } from './interface'
import KActivityPlanPreDetailGridViewModel from './KActivityPlanPreDetailGridViewModel'

export default defineView({
  name: 'KActivityPlanPreDetailGrid',
  props: KActivityPlanPreDetailGridPropOptions,
  emits: KActivityPlanPreDetailGridEventEmits,
  viewModel: KActivityPlanPreDetailGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { clsCodeMap }
  }
})
