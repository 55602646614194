import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-279a9675"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "border1 h100p"
}
const _hoisted_2 = { style: {"height":"100%"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_divider = _resolveComponent("k-divider")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, { class: "panel-content" }, {
    header: _withCtx(() => [
      (!_ctx.readonly)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createVNode(_component_k_tool_strip, null, {
              default: _withCtx(() => [
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "移除",
                  icon: "minus",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.removeData()))
                }),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "刷新",
                  icon: "sync",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.refresh()))
                }),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "排序",
                  icon: "ordered-list",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.sort()))
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_2, [
        _createVNode(_component_k_data_grid, {
          dataSource: _ctx.$vm.gridData,
          ref: "refDataGrid"
        }, {
          columns: _withCtx(() => [
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "名称",
              dataPropertyName: "name",
              align: "center",
              width: "150"
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "编码",
              dataPropertyName: "number",
              align: "center",
              width: "150"
            }, {
              default: _withCtx(({ row }) => [
                _createElementVNode("a", {
                  href: void 0,
                  onClick: ($event: any) => (_ctx.$vm.openChangeActivity(row))
                }, _toDisplayString(row.number), 9, _hoisted_3)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "实体类型",
              dataPropertyName: "className",
              align: "center",
              width: "150"
            }, {
              default: _withCtx(({ cellValue}) => [
                _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "状态",
              dataPropertyName: "lifecycleStateCode",
              align: "center",
              width: "150"
            }, {
              default: _withCtx(({ cellValue }) => [
                cellValue
                  ? (_openBlock(), _createBlock(_component_k_tag, {
                      key: 0,
                      color: _ctx.EnumLifecycleStateColor[cellValue]
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.EnumLifecycleState[cellValue]), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, "--"))
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "活动类型",
              dataPropertyName: "activityType",
              align: "center",
              width: "100"
            }, {
              default: _withCtx(({ row}) => [
                _createTextVNode(_toDisplayString(row.activityType ? _ctx.EnumActivityType[row.activityType] : ''), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_combo_box_column, {
              headerText: "实施组织",
              dataPropertyName: "supplyingOrganizations",
              options: _ctx.$vm.userList,
              align: "center",
              width: "200"
            }, null, 8, ["options"]),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "需要日期",
              dataPropertyName: "needDate",
              align: "center",
              width: "150"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "创建时间",
              dataPropertyName: "createTime",
              align: "center",
              width: "150"
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "创建者",
              dataPropertyName: "creator",
              align: "center",
              width: "200"
            }, {
              default: _withCtx(({ cellValue}) => [
                _createTextVNode(_toDisplayString(cellValue?.split(' ')[0]), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              id: "action",
              name: "action",
              fixed: "right",
              align: "center",
              headerText: "操作",
              width: "200"
            }, {
              default: _withCtx(({row}) => [
                _createElementVNode("a", {
                  class: "row-operation-item",
                  onClick: (e) => _ctx.$vm.openAffectedObjectDetail(row)
                }, "受影响对象", 8, _hoisted_5),
                _createVNode(_component_k_divider, { type: _ctx.vertical }, null, 8, ["type"]),
                _createElementVNode("a", {
                  class: "row-operation-item",
                  onClick: (e) => _ctx.$vm.openPreChangeActivityDetail(row)
                }, "前置变更活动", 8, _hoisted_6)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["dataSource"])
      ])
    ]),
    _: 1
  }))
}