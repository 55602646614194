import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartReportEmitsType, KPartReportPropType } from './interface'
import { ref, watch } from 'vue'
import { ObjBusinessParam } from '@kmsoft/ebf-common'

/** KPartReport */
export default class KPartReportViewModel extends BaseViewModel<KPartReportEmitsType, KPartReportPropType> {
  refPartReport = ref<HTMLElement>()
  objParam = ref<ObjBusinessParam>()

  constructor(options: ViewModelOptions<KPartReportPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
  }

  viewDidMount() {}
}
