import { createVNode } from 'vue'
import { defineView, VNodeProps, ConstructorType } from '@kmsoft/upf-core'
import { KObjectCreatePanelFormBasePropOptions, KObjectCreatePanelFormBaseEventEmits } from './interface'
import KObjectCreatePanelFormBaseViewModel from './KObjectCreatePanelFormBaseViewModel'
import { KObjectPropertyPanel, KObjectPropertyPanelPropType } from '../../../../controls'
import { KObjectCreateOption, KObjectCreateOptionEmitsType } from '../option'
import { KObjectCreateOptionPropType, OptionValue } from '../option'
import { EnumLayoutSchemaType } from '../../../../client-srv'

export default defineView({
  name: 'KObjectCreatePanelFormBase',
  props: KObjectCreatePanelFormBasePropOptions,
  emits: KObjectCreatePanelFormBaseEventEmits,
  viewModel: KObjectCreatePanelFormBaseViewModel as ConstructorType<KObjectCreatePanelFormBaseViewModel>,
  setup(props, { emit, slots, attrs, vm }) {
    //#region 渲染

    /** 渲染头部 */
    const renderFormHeader = () => {
      /** 头部 */
      const headerVNode = vm.getHeaderContent()

      if (!headerVNode) {
        return
      }

      return <div class="form-header">{headerVNode}</div>
    }

    /** 渲染面板 */
    const renderPanel = () => {
      if (!props.modelCode) {
        return <k-empty description="请选择对象类" />
      }

      /** 参数 */
      const objectPropertyPanelProps: VNodeProps<KObjectPropertyPanelPropType> = {
        ref: vm.refObjectPropertyPanel,
        ref_key: 'refObjectPropertyPanel',
        class: 'k-object-property-create-form',
        modelCode: props.modelCode,
        modelGroup: props.modelGroup,
        schemaType: props.schemaType as any,
        loadData: vm.onLoadData,
        readonly: false
      }

      return createVNode(KObjectPropertyPanel, objectPropertyPanelProps)
    }

    /** 渲染底部功能 */
    const renderFormFooter = () => {
      /** 头部 */
      const headerVNode = vm.getFooterContent()

      if (!headerVNode) {
        return
      }

      return <div class="form-footer">{headerVNode}</div>
    }

    /** 渲染创建选项 */
    const renderFooter = () => {
      /** 选项参数 */
      const optionProps: VNodeProps<KObjectCreateOptionPropType, KObjectCreateOptionEmitsType> = {
        modelCode: props.modelCode,
        value: vm.createOptionValue.value,
        'onUpdate:value': (value: Record<string, boolean>) => {
          vm.createOptionValue.value = value
        }
      }
      return createVNode(KObjectCreateOption, optionProps)
    }
    //#endregion

    return () => {
      /** 内容 */
      const dialogContent = {
        default: () => {
          return [renderFormHeader(), renderPanel(), renderFormFooter()]
        },
        footer: renderFooter
      }

      return (
        <k-spin class="create-form-loading" spinning={vm.isLoading.value}>
          <k-dialog-content class="k-object-create-form" v-slots={dialogContent} />
        </k-spin>
      )
    }
  }
})
