import { Api, EnumRequestCode, FolderTypeEnum, EnumPurviewCode, localStorageCache, ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  EnumToolStripCompType,
  KNotification,
  KTreeNode,
  ToolStripButtonItem,
  ToolStripItem,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

import { UserClientSrv } from '../../../ebf-folder/src/client-srv'
import { RequireTreeNode } from './manage/require-tree/interface'
import { EnumToolStripItemKeys } from '../../../ebf-folder/src/pages/folder-manage/folder-tree/interface'

/** 参数 **/
export const KRequireWorkspacePropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  folderId: VuePropTypes.string().def() // 需求根结点
}

/** 参数类型 **/
export type KRequireWorkspacePropType = ViewPropsTypeExtract<typeof KRequireWorkspacePropOptions>

/** 事件 */
export const KRequireWorkspaceEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KRequireWorkspaceEmitsType = ViewEmitsTypeExtract<typeof KRequireWorkspaceEventEmits>

/**
 * 文件夹目录树根节点定义
 */
export class RequireWorkspaceRootTreeNode extends KTreeNode {
  /**
   * 图标
   */
  iconType: string

  /**
   * 是否是单节点
   */

  isSingleRoot: boolean
  /**
   * 编码
   */
  nameEn: string
  /**
   * 类型
   */
  type: string

  /**
   * 模型类型
   */
  modelCode: string
  /**
   * 生命周期状态
   */
  lifecycleStateCode: string

  constructor(opts: any) {
    super()
    this.id = opts.id
    this.name = opts.name
    this.leaf = false
    this.iconType = 'home'
    this.modelCode = opts.modelCode
    this.type = opts.type
    this.lifecycleStateCode = opts.lifecycleStateCode
  }

  async populateChildren(): Promise<Array<KTreeNode>> {
    const newChild = [] as any
    const param = {
      data: [this.id]
    }
    const result = await Api.post('requirement', `${this.modelCode}Section`, 'listChildNode', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        const folderChildNode = new RequireTreeNode(item as any)
        folderChildNode.modelCode = this.type
        newChild.push(folderChildNode)
      }
    } else {
      KNotification.error({
        title: '获取子节点失败',
        content: result.message!,
        details: result.detail!
      })
    }
    return newChild
  }
  /**
   * 获取右键菜单
   * @returns 菜单列表
   */
  getContextMenus(): Array<ToolStripItem> {
    const workSpaceRootToolStripItem = [
      {
        name: EnumToolStripItemKeys.NEW,
        title: '新建',
        visible: this.lifecycleStateCode == 'InWork',
        purviewId: EnumPurviewCode.CreateFolder,
        icon: 'folder-add',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        name: EnumToolStripItemKeys.REFRESH,
        title: '刷新',
        visible: true,
        icon: 'loading-3-quarters',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        name: EnumToolStripItemKeys.FIND,
        title: '查找',
        icon: 'search',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'ctrl+f'
      } as ToolStripButtonItem,
      {
        name: EnumToolStripItemKeys.SORT,
        title: '排序',
        icon: 'ordered-list',
        visible: this.lifecycleStateCode == 'InWork',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        name: EnumToolStripItemKeys.IMPORT,
        title: '导入',
        icon: 'import',
        visible: this.lifecycleStateCode == 'InWork',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        name: EnumToolStripItemKeys.EXPORT,
        title: '导出',
        icon: 'export',
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        name: EnumToolStripItemKeys.LOOK,
        title: '预览',
        icon: 'eye',
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem
    ] as Array<ToolStripItem & { purviewId?: string }>
    return workSpaceRootToolStripItem.filter(item => !item.purviewId || UserClientSrv.verifyPurview(item.purviewId))
  }

  setRootNodeName(name: string) {
    this.name = name
  }
}
