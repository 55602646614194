import { EnumSoft } from '../EnumSoft'

export class DdbParamSrv {
  /**
   * 获取提取图纸文件信息的参数
   * @param argSoft  应用程序类型
   * @param argIsImportModelProp  是否提取模型属性
   * @param argFilePathList  要提取属性的文件
   * @param argSearchDirPathList  搜索的路私
   * @returns
   */
  public static getReadPropertyParam(
    argSoft: EnumSoft,
    argIsImportModelProp: boolean,
    argFilePathList: string[],
    argSearchDirPathList: string[]
  ): string {
    const xml = new DOMParser()
    const doc = xml.parseFromString('<ddbdata></ddbdata>', 'text/xml')
    const parametersNode = doc.appendChild(doc.createElement('parameters'))
    switch (argSoft) {
      case EnumSoft.AUTOCAD_2D:
      case EnumSoft.KMCAD:
      case EnumSoft.CAXA_2D:
      case EnumSoft.CATIA_2D:
      case EnumSoft.INVENTOR_2D:
      case EnumSoft.PROE_2D:
      case EnumSoft.SOLIDWORKS_2D:
      case EnumSoft.UG_SOLIDEDGE_2D:
        parametersNode.setAttribute('type', '工程图展开')
        parametersNode.setAttribute('系统属性', 'yes')
        parametersNode.setAttribute('文档属性', 'yes')
        parametersNode.setAttribute('标题栏信息', 'yes')
        parametersNode.setAttribute('明细栏信息', 'yes')
        parametersNode.setAttribute('参数表', 'no')
        parametersNode.setAttribute('文档关联', 'no')
        break
      case EnumSoft.CATIA:
      case EnumSoft.INVENTOR:
      case EnumSoft.PROE:
      case EnumSoft.SOLIDWORKS:
      case EnumSoft.UG_SOLIDEDGE:
      case EnumSoft.ZW3D:
        parametersNode.setAttribute('type', '模型展开')
        parametersNode.setAttribute('系统属性', 'yes')
        parametersNode.setAttribute('文档属性', 'yes')
        parametersNode.setAttribute('零部件属性', argIsImportModelProp ? 'yes' : 'no')
        parametersNode.setAttribute('模型结构', 'yes')
        parametersNode.setAttribute('参数表', 'no')
        parametersNode.setAttribute('焊接信息', 'no')
        parametersNode.setAttribute('文档关联', 'yes')
        parametersNode.setAttribute('PMI信息', 'yes')
        parametersNode.setAttribute('缺失模型文件信息', 'yes')
        break
      case EnumSoft.NOMALTRANS:
        parametersNode.setAttribute('type', '模型展开')
        parametersNode.setAttribute('系统属性', 'no')
        parametersNode.setAttribute('零部件属性', 'yes')
        parametersNode.setAttribute('模型结构', 'yes')
        parametersNode.setAttribute('参数表', 'no')
        parametersNode.setAttribute('文档关联', 'no')
        break
      case EnumSoft.WORDTRANSLATOR:
        parametersNode.setAttribute('type', '工程图展开')
        parametersNode.setAttribute('系统属性', 'yes')
        parametersNode.setAttribute('文档属性', 'yes')
        parametersNode.setAttribute('文档关联', 'no')
        break
      default:
        break
    }
    for (const filePath of argFilePathList) {
      const parameterNode = parametersNode.appendChild(doc.createElement('parameter'))
      parameterNode.setAttribute('name', filePath)

      if (
        argSoft == EnumSoft.AUTOCAD_2D ||
        argSoft == EnumSoft.KMCAD ||
        argSoft == EnumSoft.CAXA_2D ||
        argSoft == EnumSoft.CATIA_2D ||
        argSoft == EnumSoft.INVENTOR_2D ||
        argSoft == EnumSoft.PROE_2D ||
        argSoft == EnumSoft.UG_SOLIDEDGE_2D
      ) {
        if (argSearchDirPathList != null && argSearchDirPathList.length > 0) {
          for (const dirPath of argSearchDirPathList) {
            const dirPathNode = parameterNode.appendChild(doc.createElement('path'))
            dirPathNode.setAttribute('name', dirPath)
          }
        }
      }
    }

    const s = new XMLSerializer()
    return s.serializeToString(doc)
  }

  /**
   * 获取提取图纸文件信息的参数
   * @param argFilePath  要关闭的文件
   * @returns
   */
  public static getCloseFileParam(argFilePath: string): string {
    const xml = new DOMParser()
    const doc = xml.parseFromString('<ddbdata></ddbdata>', 'text/xml')

    // 获取根节点 ddbdata
    const root = doc.documentElement

    // 在根节点下添加 parameters 节点
    const parametersNode = root.appendChild(doc.createElement('parameters'))
    parametersNode.setAttribute('CloseFile', 'yes')
    parametersNode.setAttribute('type', '工程图展开')

    // 在 parameters 节点下添加 parameter 节点
    const parameterNode = parametersNode.appendChild(doc.createElement('parameter'))
    parameterNode.setAttribute('name', argFilePath)

    // 序列化并返回字符串
    const s = new XMLSerializer()
    return s.serializeToString(doc)
  }
}
