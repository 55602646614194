
import { defineView } from '@kmsoft/upf-core'
import { KImageViewPropOptions, KImageViewEventEmits } from './interface'
import KImageViewViewModel from './KImageViewViewModel'

export default defineView({
  name: 'KImageView',
  props: KImageViewPropOptions,
  emits: KImageViewEventEmits,
  viewModel: KImageViewViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
