import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { CommonClientSrv, EnumClassTemplate } from '../../../client-srv'
import { EnumSupportObjectNumMode, EnumToolStripEnvironment, ObjectToolStripItem } from '../../object-tool-strip'
import {
  toolStripItemAdd,
  toolStripItemDivider,
  toolStripItemNew,
  toolStripItemRefresh,
  toolStripItemRemove,
  toolStripItemSort
} from '../base'
import { EnumToolStripItemKeys, EnumToolStripUseEnv } from '../types'

export const toolStripItemFileReplace: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE,
  title: '替换文件',
  icon: 'file-sync',
  visible: false,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_FROM_LOCAL_FILE,
      title: '从本地添加',
      icon: 'file-add',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_FILE_REPLACE_NEW,
      title: '从模板添加...',
      icon: 'file-search',
      visible: true, //点击时先调用接口判断是否可添加
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_QUERY,
      title: '查询',
      icon: 'search',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Edit
}

export const toolStripItemFileAdd: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_ADD,
  title: '添加',
  icon: 'file-add',
  visible: false,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_FROM_LOCAL_FILE,
      title: '从本地添加',
      icon: 'file-add',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_QUERY,
      title: '查询',
      icon: 'search',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Edit
}

export const toolStripItemBrowseDoc: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC,
  title: '浏览',
  icon: 'eye',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  shortcutKey: 'alt+b',
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Browse
}

export const toolStripItemEditDoc: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_DOC,
  title: '编辑文件',
  icon: 'edit',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Edit
}

export const toolStripItemEditProjectDoc: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROJECT_DOC,
  title: '编辑工程图',
  icon: 'edit',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  supportedEnvironment: [EnumToolStripEnvironment.ObjPanel],
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.CheckInOut
}

export const toolStripItemCheckOutDoc: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT,
  title: '检出(O)',
  icon: 'file-protect',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  // supportedObjClsTempletID: [EnumClassTemplate.DOC, EnumClassTemplate.PART],
  shortcutKey: 'alt+o',
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.CheckInOut
}

export const toolStripItemUnCheckOutDoc: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT,
  title: '取消检出',
  icon: 'DocCancelCheckOut',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  // supportedObjClsTempletID: [EnumClassTemplate.DOC, EnumClassTemplate.PART],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.UnCheckOut
}

export const toolStripItemCheckInDoc: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
  title: '检入(I)',
  icon: 'DocCheckIn',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  // supportedObjClsTempletID: [EnumClassTemplate.DOC, EnumClassTemplate.PART],
  shortcutKey: 'alt+i',
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.CheckInOut
}

export const toolStripItemDownloadDoc: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC,
  title: '下载',
  icon: 'download',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.DownLoad
}

export const toolStripItemConsult: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CONSULT,
  title: '参考',
  icon: 'save',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.DownLoad
}

export const toolStripItemHistoryVersion: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_HISTORY_VERSION,
  title: '历史版本记录',
  icon: 'minus-circle',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.HistoryManager
}

export const toolStripItemPrint: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PRINT,
  title: '打印',
  icon: 'printer',
  visible: false,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Print,
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_APP_PRINT,
      title: '程序打印',
      icon: 'DocAppPrint',
      supportedObjClsTempletID: [EnumClassTemplate.DOC],
      visible: false,
      compType: EnumToolStripCompType.CONTEXT_MENU
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_SOURCE_APP_PRINT,
      title: '原文件打印',
      icon: 'DocSourcePrint',
      supportedObjClsTempletID: [EnumClassTemplate.DOC],
      visible: false,
      compType: EnumToolStripCompType.CONTEXT_MENU
    }
  ]
}

// 批注
export const toolStripItemRemark: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_REMARK,
  title: '批注...',
  icon: 'DocRemark',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
}

export const toolStripItemViewRemark: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK,
  title: '查看批注...',
  icon: 'DocViewRemark',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
}

export const toolStripItemViewRemarkHistory: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY,
  title: '查看批注历史...',
  icon: 'DocViewRemark',
  visible: false,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
}

export const toolStripItemStrucManage: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
  title: '结构管理',
  icon: 'file-protect',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.PART]
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Browse
}

export const toolStripMoreOperation: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_MORE,
  title: '更多操作',
  icon: 'more',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  // supportedObjClsTempletID: [EnumClassTemplate.DOC],
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Print,
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWVERISON,
      icon: 'tag',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      title: '修订',
      supportedObjNumMode: EnumSupportObjectNumMode.Single
      // unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
      // purviewType: EnumPurviewType.ObjectOperation,
      // purviewId: EnumObjectPurviewType.CreateNewVersion
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
      title: '另存',
      icon: 'common_save_cover',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'alt+s'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UPDATE_STATE,
      title: '修改状态',
      icon: 'common_edit_cover',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DISCARD,
      title: '废弃',
      icon: 'commit_delete_cover',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    // toolStripItemDivider(),
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
      title: '加入流程',
      icon: 'AddToFlow',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
      title: '查看流程',
      icon: 'ViewFlowObj',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    toolStripItemDivider(),
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC,
      title: '浏览',
      icon: 'eye',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      // supportedObjClsTempletID: [EnumClassTemplate.DOC],
      // shortcutKey: 'alt+b',
      supportedObjNumMode: EnumSupportObjectNumMode.Single
      // purviewType: EnumPurviewType.ObjectOperation,
      // purviewId: EnumObjectPurviewType.Browse
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_ASSIGN_DOC,
      title: '浏览签字文件',
      icon: 'container',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC,
      title: '下载',
      icon: 'download',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      // supportedObjClsTempletID: [EnumClassTemplate.DOC],
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
      // purviewType: EnumPurviewType.ObjectOperation,
      // purviewId: EnumObjectPurviewType.DownLoad
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PRINT,
      title: '打印',
      icon: 'printer',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      // supportedObjClsTempletID: [EnumClassTemplate.DOC],
      supportedObjNumMode: EnumSupportObjectNumMode.Single
      // purviewType: EnumPurviewType.ObjectOperation,
      // purviewId: EnumObjectPurviewType.Print,
      // items: [
      //   {
      //     name: EnumToolStripItemKeys.TOOL_STRIP_APP_PRINT,
      //     title: '程序打印',
      //     icon: 'DocAppPrint',
      //     // supportedObjClsTempletID: [EnumClassTemplate.DOC],
      //     visible: true,
      //     compType: EnumToolStripCompType.CONTEXT_MENU
      //   },
      //   {
      //     name: EnumToolStripItemKeys.TOOL_STRIP_SOURCE_APP_PRINT,
      //     title: '原文件打印',
      //     icon: 'DocSourcePrint',
      //     // supportedObjClsTempletID: [EnumClassTemplate.DOC],
      //     visible: true,
      //     compType: EnumToolStripCompType.CONTEXT_MENU
      //   }
      // ]
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SIGN,
      title: '签字',
      icon: 'save',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    toolStripItemDivider(),
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_FILE,
      title: '编辑文件',
      icon: 'edit',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE,
      title: '替换文件',
      icon: 'file-storage',
      // supportedObjClsTempletID: [EnumClassTemplate.DOC],
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    // {
    //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE,
    //   title: '替换文件',
    //   icon: 'file-storage',
    //   visible: false,
    //   compType: EnumToolStripCompType.CONTEXT_MENU,
    //   // supportedObjClsTempletID: [EnumClassTemplate.DOC],
    //   supportedObjNumMode: EnumSupportObjectNumMode.Single,
    //   // purviewType: EnumPurviewType.ObjectOperation,
    //   // purviewId: EnumObjectPurviewType.Print,
    //   items: [
    //     {
    //       name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_FROM_LOCAL_FILE,
    //       title: '从本地替换',
    //       icon: 'DocAppPrint',
    //       // supportedObjClsTempletID: [EnumClassTemplate.DOC],
    //       visible: true,
    //       compType: EnumToolStripCompType.CONTEXT_MENU
    //     },
    //     {
    //       name: EnumToolStripItemKeys.TOOL_STRIP_FILE_REPLACE_NEW,
    //       title: '从模板替换',
    //       icon: 'DocSourcePrint',
    //       // supportedObjClsTempletID: [EnumClassTemplate.DOC],
    //       visible: false,
    //       compType: EnumToolStripCompType.CONTEXT_MENU
    //     },
    //     {
    //       name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_QUERY,
    //       title: '从已有文档替换',
    //       icon: 'DocSourcePrint',
    //       // supportedObjClsTempletID: [EnumClassTemplate.DOC],
    //       visible: false,
    //       compType: EnumToolStripCompType.CONTEXT_MENU
    //     }
    //   ]
    // },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_HISTORY_VERSION,
      title: '文件历史版本',
      icon: 'solution',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    toolStripItemDivider(),
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_REMARK_OPTION,
      title: '批注',
      icon: 'read',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      // supportedObjClsTempletID: [EnumClassTemplate.DOC],
      supportedObjNumMode: EnumSupportObjectNumMode.Single,
      // purviewType: EnumPurviewType.ObjectOperation,
      // purviewId: EnumObjectPurviewType.Print,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_REMARK,
          title: '批注',
          icon: 'read',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
          // shortcutKey: 'alt+s'
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK,
          title: '查看批注',
          icon: 'read',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY,
          title: '查看批注历史版本',
          icon: 'container',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    },

    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_OPTION,
      title: '删除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      // supportedObjClsTempletID: [EnumClassTemplate.DOC],
      supportedObjNumMode: EnumSupportObjectNumMode.Single,
      // purviewType: EnumPurviewType.ObjectOperation,
      // purviewId: EnumObjectPurviewType.Print,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
          title: '删除',
          icon: 'DeleteObj',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH,
          title: '删除当前版本',
          icon: 'delete',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    },

    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOC_CONNECT_TO_DP,
      title: '连接到dp',
      icon: 'file-text',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    }
  ]
}
export const changeToolStripItems: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
  title: '变更',
  icon: 'control',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
      title: '快速变更',
      icon: 'control',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_CREATE,
          title: '基于改后创建变更活动',
          icon: 'file-text',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_ADD_ACTIVITY,
          title: '基于改后添加至变更活动',
          icon: 'file-text',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_CREATE,
          title: '基于改前创建变更活动',
          icon: 'file-text',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_ADD_ACTIVITY,
          title: '基于改前添加至变更活动',
          icon: 'file-text',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    }
  ]
}

export const getDocGeneralObjectOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  if (CommonClientSrv.isWindows)
    return [
      toolStripItemCheckOutDoc,
      toolStripItemUnCheckOutDoc,
      toolStripItemCheckInDoc,
      toolStripItemStrucManage,
      toolStripMoreOperation,
      changeToolStripItems
      // toolStripItemBrowseDoc,
      // toolStripItemDownloadDoc,
      // toolStripItemEditDoc,
      // toolStripItemFileReplace,
      // toolStripItemFileAdd,
      // toolStripItemEditProjectDoc,
      // toolStripItemDivider(),
      // toolStripItemRemark,
      // toolStripItemViewRemark,
      // toolStripItemViewRemarkHistory
    ]
  else return [toolStripItemDownloadDoc, toolStripItemFileReplace, toolStripItemFileAdd]
}

export const getStructuredDocGeneralObjectOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  return [
    toolStripItemNew,
    toolStripItemAdd,
    toolStripItemRemove,
    toolStripItemSort,
    toolStripItemRefresh
    // toolStripItemBrowseDoc,
    // toolStripItemDownloadDoc,
    // toolStripItemEditDoc,
    // toolStripItemFileReplace,
    // toolStripItemFileAdd,
    // toolStripItemEditProjectDoc,
    // toolStripItemDivider(),
    // toolStripItemRemark,
    // toolStripItemViewRemark,
    // toolStripItemViewRemarkHistory
  ]
}

/** 文档操作 */
export const getDocObjectOperationToolStripItems = (env: EnumToolStripUseEnv): Array<ObjectToolStripItem> => {
  if (CommonClientSrv.isWindows)
    return [
      toolStripItemDivider(),
      toolStripItemConsult,
      ...(env == EnumToolStripUseEnv.ObjGrid ? getDocGeneralObjectOperationToolStripItems() : []),
      toolStripItemHistoryVersion,
      toolStripItemDivider(),
      toolStripItemPrint
    ]
  else
    return [
      ...(env == EnumToolStripUseEnv.ObjGrid ? getDocGeneralObjectOperationToolStripItems() : []),
      toolStripItemHistoryVersion
    ]
}
