import { defineComponent, inject, createVNode, PropType, defineAsyncComponent, resolveComponent } from 'vue'
import {
  NavigationMenuGroup,
  NavigationMenu,
  NavigationMenuActionType,
  MenuActionParam,
  IMenuPagePathMapping,
  MenuPagePath,
  MenuActionPath,
  MenuComponentPath,
  AppContext,
  UPF_IOC_KEYS,
  KWebBrowser,
  IPageManager
} from '@kmsoft/upf-core'

export default defineComponent({
  name: 'KNavigationBar',
  emits: ['selected'],
  props: {
    theme: { type: String, required: false, default: 'dark' },
    mode: { type: String, required: false, default: 'inline' },
    collapsed: { type: Boolean, required: false, default: false },
    /** 控制当前激活的菜单项目 */
    activeKey: { type: String, required: false },
    menus: { type: Array as PropType<Array<NavigationMenuGroup>>, required: true }
  },
  data() {
    return {
      openKeys: [] as string[],
      selectedKeys: [] as Array<string>,
      cachedOpenKeys: [] as string[]
    }
  },
  setup(props) {
    const menuGroups = [] as string[]
    props.menus && props.menus.forEach(menuGroup => menuGroups.push(menuGroup.id))
    return {
      /** 菜单分组集合 */
      menuGroups: menuGroups,
      /** 菜单ID和菜单项的字典映射 */
      keyMenuMap: new Map<string, NavigationMenu>(),
      /** 菜单ID和菜单项所在分组的字典映射 */
      keyGroupMap: new Map<string, NavigationMenuGroup>(),
      /** 菜单名称和菜单项的字典映射 */
      titleMenuMap: new Map<string, NavigationMenu>(),
      /** 页面导航器 */
      PageManager: inject('$PageManager') as IPageManager
    }
  },
  watch: {
    collapsed(val) {
      if (val) {
        this.cachedOpenKeys = this.openKeys.concat()
        this.openKeys = []
      } else {
        this.openKeys = this.cachedOpenKeys
      }
    },
    activeKey(value) {
      this.__activeKeyChangeHandler(value)
    }
  },
  methods: {
    /**
     * 获取激活的菜单数据
     * @returns 返回激活的菜单
     */
    activeMenu(): NavigationMenu | null {
      if (this.selectedKeys.length > 0) {
        const activeKey = this.selectedKeys[0]
        return this.keyMenuMap.get(activeKey) || null
      }
      return null
    },
    /**
     * 渲染菜单
     * @param group 菜单分组
     */
    renderItem(item: NavigationMenuGroup | NavigationMenu) {
      // 判断当前节点是group还是叶子节点
      const isLeaf = Reflect.has(item, 'actionType')

      if (isLeaf) {
        return this.renderMenuItem(item as NavigationMenu)
      } else {
        return this.renderGroupItem(item as NavigationMenuGroup)
      }
    },
    /**
     * 渲染菜单项
     * @param menu 菜单项
     * @returns JSX VNode
     */
    renderGroupItem(menuGroup: NavigationMenuGroup) {
      this.keyGroupMap.set(menuGroup.id, menuGroup)
      //存在子菜单，也同时添加子菜单映射
      if (menuGroup.children && menuGroup.children.length >= 0) {
        for (let i = 0; i < menuGroup.children.length; i++) {
          const element = menuGroup.children[i]
          this.keyGroupMap.set(element.id, menuGroup)
        }
      }

      const nodes = [] as Array<JSX.Element>
      const menus: Array<NavigationMenuGroup | NavigationMenu> = menuGroup.children

      // 渲染子菜单
      menus.forEach((item: NavigationMenu | NavigationMenuGroup) => {
        nodes.push(this.renderItem(item))
      })

      return (
        <k-sub-menu
          key={menuGroup.id}
          v-slots={{
            title: () => (
              <span>
                {this.renderIcon(menuGroup.icon)}
                <span>{menuGroup.name}</span>
              </span>
            )
          }}
        >
          {nodes}
        </k-sub-menu>
      )
    },
    /**
     * 渲染菜单项
     * @param menu 菜单项
     * @returns JSX VNode
     */
    renderMenuItem(menu: NavigationMenu) {
      this.keyMenuMap.set(menu.id, menu)
      return (
        <k-menu-item key={menu.id}>
          <div>
            {menu.icon && this.renderIcon(menu.icon)}
            <span>{menu.name}</span>
          </div>
        </k-menu-item>
      )
    },
    /**
     * 渲染图标
     */
    renderIcon(icon: any) {
      if (icon === 'none' || icon === undefined) {
        return null
      }
      return typeof icon === 'string' ? createVNode(resolveComponent('KIcon'), { type: icon }) : icon
    },
    /**
     * 选择菜单项之后，根据导航项的动作参数做对应动作
     * @param {string} key 选择的菜单主键
     */
    onSelect({ key }: { key: string }) {
      const cachedMenu = this.keyMenuMap.get(key)
      if (cachedMenu) {
        // this.$emit('selected', cachedMenu)
        this.__applyMenuAction(cachedMenu)
      } else {
        this.menus.forEach(menuGroup => {
          if (!menuGroup.children || menuGroup.children.length <= 0) return true

          menuGroup.children.forEach((menu: NavigationMenu | NavigationMenuGroup) => {
            if (menu.id != key) return true
            // this.$emit('selected', menu)

            // 判断当前节点是group还是叶子节点
            const isLeaf = Reflect.has(menu, 'actionType')
            if (isLeaf) this.__applyMenuAction(menu as NavigationMenu)
          })
        })
      }
    },
    /**
     * 保证每次总是只打开一个分组
     * @param openKeys 打开的子菜单分组键值数组
     */
    onOpenChange(openKeys: string[]) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      if (this.menuGroups.indexOf(latestOpenKey!) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    /**
     * 执行菜单的具体动作
     * @param {menu} 待处理的菜单
     */
    __applyMenuAction(menu: NavigationMenu, skipRoute?: boolean) {
      /** 获取组件映射 */
      const allMenuPathMapping = AppContext.current
        .getIocContainer()
        .getBeans<IMenuPagePathMapping>(UPF_IOC_KEYS.MENU_PAGE_PATH_MAPPING)
        .map(x => x.getMenus())
      /** 参数 */
      const actionParam = menu.actionParam ? (JSON.parse(menu.actionParam) as MenuActionParam) : undefined

      if (menu.actionType == NavigationMenuActionType.ROUTE) {
        /** 去重 */
        const menuPathMapping = [...new Set([].concat(...(allMenuPathMapping as any)))] as Array<MenuPagePath>
        const menuComponentPath = menuPathMapping.find(menu => menu.target === actionParam?.target) as
          | MenuComponentPath
          | undefined
        const component = menuComponentPath?.component ? menuComponentPath.component : null
        this.PageManager.openPage(menu.id, menu.name, component!, actionParam?.params!)
      } else if (menu.actionType == NavigationMenuActionType.LINK) {
        const params = { url: actionParam?.target!, params: { ...actionParam?.params, __p_host__: window.location.host } }
        this.PageManager.openPage(menu.id, menu.name, KWebBrowser, params)
      } else if (menu.actionType == NavigationMenuActionType.CALLBACK) {
        const menuPathMapping = [...new Set([].concat(...(allMenuPathMapping as any)))] as Array<MenuPagePath>
        const menuActionPath = menuPathMapping.find(menu => menu.target === actionParam?.target) as MenuActionPath | undefined
        if (menuActionPath && menuActionPath.callback) {
          menuActionPath.callback(actionParam)
        }
      }
    },
    /**
     * 激活的菜单主键改变后事件
     * 1、设置打开的分组、已经高亮选中项
     * 2、打开标签页
     * @param newValue 新的值
     */
    __activeKeyChangeHandler(newValue: string) {
      if (newValue) {
        const cachedMenu = this.keyMenuMap.get(newValue)
        if (cachedMenu) {
          this.selectedKeys = [cachedMenu.id]
          // 只有内嵌的菜单才需要打开菜单
          if (this.mode == 'inline') {
            const menuGroup = this.keyGroupMap.get(cachedMenu.id)
            if (menuGroup) {
              this.openKeys = [menuGroup.id]
            }
          }
          // 执行菜单项动作
          this.$nextTick(() => this.__applyMenuAction(cachedMenu, true))
        } else {
          this.selectedKeys = []
        }
      } else {
        this.selectedKeys = []
      }
    }
  },
  /**
   * 渲染组件
   * @returns virtual nodes
   */
  render() {
    const { mode, menus, theme } = this
    const menuTree = menus && menus.map(item => this.renderItem(item))
    const vModels = {
      openKeys: this.openKeys,
      // openKeys不再使用双向绑定，由openChange接管
      // 'onUpdate:openKeys': ($event: any) => (this.openKeys = $event),
      selectedKeys: this.selectedKeys,
      'onUpdate:selectedKeys': ($event: any) => (this.selectedKeys = $event)
    }
    return (
      <k-menu {...vModels} theme={theme} mode={mode} onSelect={this.onSelect} onOpenChange={this.onOpenChange}>
        {menuTree}
      </k-menu>
    )
  },
  mounted() {
    const activeMenu = this.keyMenuMap.get(this.activeKey!)
    if (activeMenu) {
      // 挂在成功之后，根据激活菜单项目设置展开的分组和高亮显示的菜单
      this.__activeKeyChangeHandler(this.activeKey!)
    } else {
      if (this.mode == 'inline') {
        if (this.menuGroups && this.menuGroups.length == 1) {
          this.openKeys = [this.menuGroups[0]]
        }
      }
    }
  }
})
