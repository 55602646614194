import {
  BaseViewModel,
  EnumDialogResult,
  EnumItemClickedCancelType,
  EnumToolStripCompType,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  MemoryCacheFactory,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KRequirementObjManageEmitsType, KRequirementObjManagePropType } from './interface'
import { ref, watch } from 'vue'
import {
  Api,
  CommonClientSrv,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectClassGridViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  PartViewMemoryCache
} from '@kmsoft/ebf-common'
import { KAddPlanDialog, KAddPlanDialogViewModel } from '../add-plan-dialog'
import { KProjectPageWrapper } from '../../../../ebf-project-manage/src/components'

/** KRequirementObjManage */
export default class KRequirementObjManageViewModel extends BaseViewModel<
  KRequirementObjManageEmitsType,
  KRequirementObjManagePropType
> {
  /** 标签号 */
  tabId = ref<string>('1')
  /** 计划任务网格 */
  refPlanTaskGrid = ref<KObjectClassGridViewModel>()
  /** 业务对象网格 */
  refBusinessObjDataGrid = ref<KObjectClassGridViewModel>()
  /** 需求关系网格(产品需求作为目标) */
  refReqLinkDataGrid = ref<KObjectClassGridViewModel>()
  /** 需求关系网格(原始需求作为源) */
  refReqProductLinkDataGrid = ref<KObjectClassGridViewModel>()
  /** 结构关系网格 */
  refContainLinkDataGrid = ref<KObjectClassGridViewModel>()
  /** 变更请求网格 */
  refChangeRequestDataGrid = ref<KObjectClassGridViewModel>()
  /** 对象参数 */
  objParam = ref<ObjBusinessParam>()
  /** 是否产品需求 */
  isProduct = ref<boolean>(true)

  statusList = [
    { label: '未开始', value: 10 },
    { label: '进行中', value: 20 },
    { label: '已完成', value: 30 },
    { label: '暂停', value: 40 },
    { label: '终止', value: 50 }
  ]

  planStatusList = [
    { label: '已创建', value: 10 },
    { label: '评审中', value: 20 },
    { label: '已发布', value: 30 },
    { label: '评审终止', value: 40 }
  ]

  planTypeList = [
    { label: '一般计划', value: 10 },
    { label: '独立任务', value: 20 },
    { label: '里程碑计划', value: 30 },
    { label: '阶段计划', value: 40 },
    { label: '项目计划', value: 0 }
  ]

  constructor(options: ViewModelOptions<KRequirementObjManagePropType>) {
    super(options)
    this.objParam.value = this.props.objParam
    this.isProduct.value =
      this.objParam.value.modelCode == 'ProductNeeds' || this.objParam.value.modelCode == 'ProductNeedsSection'
    if (!this.isProduct.value) {
      this.tabId.value = '4'
    }
    watch(
      () => this.props.objParam,
      newValue => {
        if (newValue && newValue.id) {
          this.objParam.value = newValue
          this.refPlanTaskGrid.value?.refresh()
          this.refBusinessObjDataGrid.value?.refresh()
          this.refReqLinkDataGrid.value?.refresh()
        }
      }
    )
  }

  viewDidMount() {}

  /** 标题点击事件 */
  titleClick(key: string) {
    if (key == '3' && !this.isProduct.value) {
      this.tabId.value = '4'
      return
    }
    this.tabId.value = key
  }

  /** 工具栏 */
  toolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
      title: '添加',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  /** 结构关系工具栏 */
  containLinkToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  /** 结构关系工具栏 */
  requirementChangeRequestToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  /** 计划任务工具栏点击事件 */
  async onPlanTaskToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        this.addPlanTask()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        this.removePlanTask()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refPlanTaskGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /** 业务对象工具栏点击事件 */
  async onBusinessObjToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        this.addObj()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        this.removeObj()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refBusinessObjDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /** 需求关系工具栏点击事件 */
  async onReqLinkToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        this.addReqLink()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        this.removeReqLink()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refReqLinkDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /** 需求关系工具栏点击事件 */
  async onReqProductLinkToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        this.addReqLink()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        this.removeReqLink()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refReqProductLinkDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /** 结构关系工具栏点击事件 */
  async onContainLinkToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refContainLinkDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /** 变更请求工具栏点击事件 */
  async onRequirementChangeRequestToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refChangeRequestDataGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /** 加载计划任务 */
  async loadPlanTaskData() {
    const obj = this.objParam.value
    const params = {
      data: [
        {
          id: obj?.id,
          clazz: obj?.modelCode
        }
      ]
    }
    const result = await Api.post('requirement', 'ProductNeeds', 'listPlanActivityBySource', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取计划任务失败',
        details: result.detail
      })
      return []
    }
  }

  /** 加载业务对象 */
  async loadBusinessObjData() {
    const obj = this.objParam.value
    const params = {
      data: [
        {
          id: obj?.id,
          clazz: obj?.modelCode
        }
      ]
    }
    const result = await Api.post('requirement', 'ProductNeeds', 'listObjBySource', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取业务对象失败',
        details: result.detail
      })
      return []
    }
  }

  /** 加载需求项(产品需求作为目标)关系 */
  async loadReferenceData() {
    const obj = this.objParam.value
    const params = {
      data: [
        {
          id: obj?.id,
          clazz: obj?.modelCode
        }
      ]
    }
    const result = await Api.post('requirement', 'ProductNeeds', 'listReqTraceabilityLinkByTarget', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取业务对象失败',
        details: result.detail
      })
      return []
    }
  }

  /** 加载需求项(原始需求作为源)关系 */
  async loadReferenceProductData() {
    const obj = this.objParam.value
    const params = {
      data: [
        {
          id: obj?.id,
          clazz: obj?.modelCode
        }
      ]
    }
    const result = await Api.post('requirement', 'ProductNeeds', 'listReqTraceabilityLinkBySource', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取业务对象失败',
        details: result.detail
      })
      return []
    }
  }

  /** 加载结构关系 */
  async loadContainLinkData() {
    const obj = this.objParam.value
    const params = {
      data: [
        {
          id: obj?.id,
          clazz: obj?.modelCode
        }
      ]
    }
    const result = await Api.post('requirement', 'Requirement', 'listReqContainLinkBySource', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取结构关系失败',
        details: result.detail
      })
      return []
    }
  }

  /** 加载变更请求 */
  async loadRequestChageData() {
    const obj = this.objParam.value
    const params = {
      data: [obj?.id]
    }
    const result = await Api.post('requirement', 'RequirementChangeRequest', 'listByRequirement', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取结构关系失败',
        details: result.detail
      })
      return []
    }
  }

  /** 添加计划任务 */
  addPlanTask() {
    const objParam = this.props.objParam
    KDialog.show({
      title: '添加计划任务',
      size: { width: 800, height: 600 },
      props: {},
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KAddPlanDialog,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KAddPlanDialogViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const selectedRows = formViewModel.getValue() as any
        if (selectedRows.length > 1) {
          KNotification.warn({
            message: '系统提示',
            description: '只可关联一个计划任务'
          })
          event.cancel = true
          return
        }
        const row = selectedRows.length == 0 ? null : selectedRows[0]

        if (row) {
          const result = await Api.post('requirement', 'ProductNeeds', 'addPlanActivity', {
            data: [
              {
                id: objParam.id,
                clazz: objParam.modelCode
              },
              {
                id: row?.CID,
                clazz: 'PlanActivity'
              }
            ]
          })
          if (result && result.success) {
            //刷新网格
            this.refPlanTaskGrid.value?.refresh()
            KNotification.success({ title: '添加成功', content: result.message })
          } else {
            KNotification.error({ title: '添加失败', content: result.message, details: result.detail })
            event.cancel = true
            return
          }
        }
        return
      }
    })
  }

  /** 移除计划任务 */
  removePlanTask() {
    const rows = this.refPlanTaskGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认移除吗？移除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [rowIds]
        }

        Api.post('requirement', 'ProductNeeds', 'removePlanActivity', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refPlanTaskGrid.value?.removeSelectedRows()
            KNotification.success({
              title: '移除成功',
              content: result.message
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '移除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  /** 添加业务对象 */
  addObj() {
    const objectClassTreeProps = {
      showObjClsCodes: ['Part', 'Document'],
      defaultSelectClsCode: 'Part'
    }
    const objParam = this.props.objParam
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: objectClassTreeProps,
      objectSearchQueryParam: objectClassTreeProps,
      getContainer: this.props.container || this.refBusinessObjDataGrid.value?.getContainer(),
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows() as any
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParams = objParams.map((x: { id: any; rdmExtensionType: any }) => {
          return {
            id: x.id,
            clazz: x.rdmExtensionType
          }
        })
        const result = await Api.post('requirement', 'ProductNeeds', 'addObj', {
          data: [
            {
              id: objParam.id,
              clazz: objParam.modelCode
            },
            targetParams
          ]
        })
        if (result && result.success) {
          //刷新网格
          this.refBusinessObjDataGrid.value?.refresh()
          KNotification.success({ title: '添加成功', content: result.message })
        } else {
          KNotification.error({ title: '添加失败', content: result.message, details: result.detail })
          event.cancel = true
          return
        }
      }
    })
  }

  /** 移除业务对象 */
  removeObj() {
    const rows = this.refBusinessObjDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认移除吗？移除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [rowIds]
        }

        Api.post('requirement', 'ProductNeeds', 'removeObj', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refBusinessObjDataGrid.value?.removeSelectedRows()
            KNotification.success({
              title: '移除成功',
              content: result.message
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '移除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  /** 添加需求项关系 */
  addReqLink() {
    let objectClassTreeProps
    if (this.isProduct.value) {
      // 当前对象是产品需求，只能关联原始需求
      objectClassTreeProps = {
        showObjClsCodes: ['CustomerNeeds', 'CustomerNeedsSection'],
        defaultSelectClsCode: 'CustomerNeeds'
      }
    } else {
      // 当前对象是原始需求，只能关联产品需求
      objectClassTreeProps = {
        showObjClsCodes: ['ProductNeeds', 'ProductNeedsSection'],
        defaultSelectClsCode: 'ProductNeeds'
      }
    }
    const objParam = this.props.objParam
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: objectClassTreeProps,
      objectSearchQueryParam: objectClassTreeProps,
      getContainer:
        this.props.container ||
        this.refReqLinkDataGrid.value?.getContainer() ||
        this.refReqProductLinkDataGrid.value?.getContainer(),
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        if (objParams.length > 1) {
          KNotification.warn({
            message: '系统提示',
            description: '每次只可关联一条数据'
          })
          event.cancel = true
          return
        }
        const param = objParams[0]
        const selectRdmExtensionType = param.rdmExtensionType
        let data = []
        if (this.isProduct.value) {
          // 当前对象是产品需求，只能关联原始需求
          if (selectRdmExtensionType != 'CustomerNeeds' && selectRdmExtensionType != 'CustomerNeedsSection') {
            KNotification.warn({
              message: '系统提示',
              description: '只可选择关联原始需求(项)'
            })
            event.cancel = true
            return
          }
          data = [
            {
              id: param.id,
              clazz: param.rdmExtensionType
            },
            {
              id: objParam.id,
              clazz: objParam.modelCode
            }
          ]
        } else {
          // 当前对象是原始需求，只能关联产品需求
          if (selectRdmExtensionType != 'ProductNeeds' && selectRdmExtensionType != 'ProductNeedsSection') {
            KNotification.warn({
              message: '系统提示',
              description: '只可选择关联产品需求(项)'
            })
            event.cancel = true
            return
          }
          data = [
            {
              id: objParam.id,
              clazz: objParam.modelCode
            },
            {
              id: param.id,
              clazz: param.rdmExtensionType
            }
          ]
        }
        const result = await Api.post('requirement', 'ProductNeeds', 'addReqTraceabilityLink', {
          data: data
        })
        if (result && result.success) {
          //刷新网格
          if (this.isProduct.value) {
            this.refReqLinkDataGrid.value?.refresh()
          } else {
            this.refReqProductLinkDataGrid.value?.refresh()
          }
          KNotification.success({ title: '添加成功', content: result.message })
        } else {
          KNotification.error({ title: '添加失败', content: result.message, details: result.detail })
          event.cancel = true
          return
        }
      }
    })
  }

  /** 移除需求项关系 */
  removeReqLink() {
    const rows = this.isProduct.value
      ? this.refReqLinkDataGrid.value?.getSelectedRows()
      : this.refReqProductLinkDataGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认移除吗？移除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = {
          data: [rowIds]
        }

        Api.post('requirement', 'ProductNeeds', 'removeReqTraceabilityLink', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            if (this.isProduct.value) {
              this.refReqLinkDataGrid.value?.removeSelectedRows()
            } else {
              this.refReqProductLinkDataGrid.value?.removeSelectedRows()
            }

            KNotification.success({
              title: '移除成功',
              content: result.message
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '移除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  /** 打开任务对象 */
  async openPlanTaskTab(row: any) {
    if (!row.executionStatus) {
      KNotification.warn({
        message: '系统提示',
        description: `计划任务(${row.planActivity.name})尚未分派`
      })
      return
    }
    CommonClientSrv.openPage(`我的项目: ${row.planActivity.name}`, KProjectPageWrapper, {
      name: 'TaskInfoPanel',
      isAbsUrl: false,
      windowId: new Date().getTime(),
      objectInfo: {
        classCode: '',
        objectId: row.planActivity.id,
        projectVersionGroup: row.planActivity.projectVersionGroupId,
        versionGroup: row.planActivity.versionGroupId,
        accessMode: 1
      }
    })
  }

  /** 打开业务对象 */
  async openObjTab(row: any) {
    const param = {
      id: row?.target.id,
      modelCode: row?.target.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.target.className!)
    }
    ObjectClientSrv.openObj(param)
  }

  /** 打开需求对象 */
  async openReqTab(row: any) {
    const param = {
      id: row?.source.id,
      modelCode: row?.source.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.source.className!)
    }
    ObjectClientSrv.openObj(param)
  }

  /** 打开需求对象(原始需求作为源) */
  async openReqProductTab(row: any) {
    const param = {
      id: row?.target.id,
      modelCode: row?.target.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.target.className!)
    }
    ObjectClientSrv.openObj(param)
  }

  /** 打开结构关系对象 */
  async openNeedsSectionTab(row: any) {
    const param = {
      id: row?.targetNeedsSection.id,
      modelCode: row?.targetNeedsSection.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.targetNeedsSection.className!)
    }
    ObjectClientSrv.openObj(param)
  }

  /** 获取零部件视图 */
  getView(row: any) {
    const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
    const partView = cacheInstance.getAll()
    if (row.partViewId && partView.length > 0) {
      const view = partView.filter(item => item.id == row.partViewId)[0]?.description
      return '(' + view + ')'
    }
    return ''
  }

  /** 打开结构关系对象 */
  async openRequestChange(row: any) {
    const param = {
      id: row?.id,
      modelCode: row?.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.className!)
    }
    ObjectClientSrv.openObj(param)
  }
}
