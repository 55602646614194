import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KCreateCadWorkspacePropOptions = {
  ...BaseViewPropOptions,
  handleType: VuePropTypes.string().def('add'),
  libraryType: VuePropTypes.string().def(),
  formData: VuePropTypes.createType<ICadWorkspaceBaseProp>().def()
}

/** 参数类型 **/
export type KCreateCadWorkspacePropType = ViewPropsTypeExtract<typeof KCreateCadWorkspacePropOptions>

/** 事件 */
export const KCreateCadWorkspaceEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCreateCadWorkspaceEmitsType = ViewEmitsTypeExtract<typeof KCreateCadWorkspaceEventEmits>

export interface ICadWorkspaceBaseProp {
  /**工作区id */
  id?: string
  /**工作区名称 */
  name?: string
  libraryFolderId: string
  libraryFolderName?: string
  bomLibraryPosition?: string
  docLibraryPosition?: string
  bomLibraryPositionName?: string
  docLibraryPositionName?: string
  localCachePosition: string
  /**描述 */
  description?: string
}
