import * as Base from '@kmsoft/ebf-common'
import { AppContext, eventEmitter } from '@kmsoft/upf-core'
import lodash from 'lodash'
import * as Vue from 'vue'
import * as Enums from './enums'
import { EnumMenuType } from './enums'
import * as Inherits from './inherits'
import * as Types from './types'
import * as Utils from './utils'

/** 包文件浏览器 */
export default Vue.defineComponent({
  name: 'KVueBrowser',
  props: Inherits.KVueBrowserInherits.props,
  emits: Inherits.KVueBrowserInherits.emits,
  setup(props, { attrs, emit, expose, slots }) {
    /** iFrame 引用 */
    const refFrame = Vue.ref<HTMLIFrameElement>()
    /** iframe 名称 */
    const frameName = Vue.ref('')
    /** 应用Id */
    const appId = '3DAst'
    /** 标记Id */
    let markId = ''
    /** iframe 通信 */
    const iFrameEventManager = Base.IFrameEventManager.register({
      appId: appId,
      get targetWindow() {
        return refFrame.value!.contentWindow!
      },
      options: {
        extraData: () => {
          return { markId: markId }
        }
      }
    })
    /** 是否在加载 */
    const isLoading = Vue.ref(true)
    /** 浏览地址 */
    // const serverUrl = DataSrv.configSrv.hoopsServer
    let host = window.location.host
    const pathname = window.location.pathname
    const pmsServer = 'kmvue'
    const hosts = host.split('.')
    const pathnames = pathname.split('/')
    pathnames[3] = pmsServer
    if (hosts.length <= 1) {
      // 本地环境host设为dev地址
      host = 'plm.kmsoft.com.cn'
    }
    const newUrl = `${host}${pathname == '/' ? '/pdm/ebf/kmvue/' : pathnames.join('/')}`
    const serverUrl = `http://${newUrl}index.html`
    /** iframe 地址 */
    const iframeUrl = Vue.ref('')

    /** 初始化 */
    const init = async () => {
      isLoading.value = true

      if (!props.fileUrl) {
        iframeUrl.value = ''
        isLoading.value = false
        return
      }

      markId = lodash.uniqueId(`${appId}_`)

      const params: Types.ModelBrowserUrlParams = {
        fileType: props.fileType,
        xmlUrl: props.fileUrl,
        token: '123',
        appId: appId,
        markId: markId
      }

      /** 用户名 */
      // const userName = DataSrv.loginSrv.getUserName()
      const userName = AppContext.current.getIdentity()?.name.split(' ')[0]

      frameName.value = `frame_${markId}`
      iframeUrl.value = `${serverUrl}?username=${userName}&fileInfo=${JSON.stringify(params)}`
      isLoading.value = false
    }

    /** 隐藏多余菜单 */
    const hideExtraMenu = () => {
      const visibleMenus = []
      if (!props.showRemark) {
        visibleMenus.push(EnumMenuType.Mark)
      }
      if (props.showTree) {
        setMenusVisble(true, [EnumMenuType.Tree])
      }
      setMenusVisble(false, visibleMenus)
      //隐藏office快照
      hideOfficeFileSnapshot(true)
    }

    /**
     * 根据路径获取节点Id
     * @param path 路径
     * @returns
     */
    const getNodeIdsByPath = (path: string | Array<string>) => {
      /** 无论是单个参数还是数组参数都转换为数组参数 */
      let array: any = [path]
      if (lodash.isArray(path)) {
        array = path
      }

      /** node列表 */
      const nodeItem: Array<number> = []
      const cannotFindPathArray: Array<string> = []

      for (const pathItem of array) {
        /** 通过逗号拆分 */
        const pathArray = pathItem.split(',')

        // 如果路径不包含根节点 那么在数组首位增加根节点
        if (!pathArray.includes('root')) {
          pathArray.unshift('root')
        }

        /** 通过路径获取节点 */
        const nodeModel = Utils.getNodeIdByPath(props.structData, pathArray)

        if (nodeModel) {
          nodeItem.push(...Utils.getChildNodeIds(nodeModel))
        } else {
          cannotFindPathArray.push(pathArray)
        }
      }

      if (cannotFindPathArray.length > 0) {
        console.warn(`节点不存在`, cannotFindPathArray)
      }

      return nodeItem
    }

    /**
     * 设置高亮
     * @param nodeIds
     * @param highlighted
     */
    const setNodesHighlight = (path: string | Array<string>, highlighted: boolean) => {
      const nodeItem = getNodeIdsByPath(path)

      if (nodeItem.length <= 0) {
        return
      }

      sendMsg('setNodesHighlight', {
        nodeIds: nodeItem,
        highlighted: highlighted
      })
    }

    /**
     * 设置可见
     * @param nodeIds
     * @param hided
     */
    const setNodesVisible = (path: string | Array<string>, hided: boolean) => {
      const nodeItem = getNodeIdsByPath(path)

      if (nodeItem.length <= 0) {
        return
      }

      sendMsg('setNodesVisible', {
        nodeIds: nodeItem,
        // 实际是visible 为true时显示，false 时隐藏
        hided: !hided
      })
    }

    /**
     * 设置模型半透明
     * @param path
     * @param transparency
     * @returns
     */
    const setNodesTransparency = (path: string, transparency: number) => {
      const nodeItem = getNodeIdsByPath(path)

      if (nodeItem.length <= 0) {
        return
      }

      for (const iterator of nodeItem) {
        sendMsg('setNodesTransparency', {
          path: iterator,
          transparency: transparency
        })
      }
    }

    /**
     * 启用批注
     * @param enable
     */
    const enableMark = (enable: boolean) => {
      sendMsg('enableMark', { enable: enable })
    }

    /** 清空批注 */
    const clearComments = () => {
      sendMsg('clearComments', {})
    }

    /**
     * 加载批注
     * @param items
     */
    const addComments = (items: Array<Types.CommentItem>) => {
      sendMsg('loadComments', { data: items })
    }

    /** 重置 */
    const reset = () => {
      sendMsg('reset', {})
    }

    /**
     * 隐藏PMI信息
     * @param hided
     */
    const setPMIHided = (hided: boolean) => {
      sendMsg('setPMIHided', { hided: hided })
    }

    /**
     * 隐藏office快照
     * @param hided
     */
    const hideOfficeFileSnapshot = (hided: boolean) => {
      sendMsg('hideOfficeFileSnapshotButton', { hide: hided })
    }

    /** 截图 */
    const snapShot = () => {
      sendMsg('snapShot', {})
    }

    /** 爆炸图 */
    const explode = () => {
      sendMsg('explode', {})
    }

    /** 隐藏菜单 */
    const setMenusVisble = (visible: boolean, menuIds: Array<Enums.EnumMenuType>) => {
      sendMsg('setMenusVisble', { visible: visible, menuIds: menuIds })
    }

    expose({
      setNodesHighlight,
      setNodesVisible,
      setNodesTransparency,
      enableMark,
      clearComments,
      addComments,
      reset,
      setPMIHided,
      snapShot,
      explode,
      setMenusVisble,
      hideOfficeFileSnapshot
    })

    /**
     * 向iframe发送消息
     * @param action
     * @param data
     */
    const sendMsg = (action: string, data: Record<string, any>) => {
      iFrameEventManager.dispatchEvent(action, data)
    }

    /** iframe 加载完成 */
    const onIframeLoad = (event: Event) => {
      registEvent()
      if (props.fileType != Enums.EnumKmVueFileType.Model && props.fileType != Enums.EnumKmVueFileType.Part) {
        onLoadedSuccess({ markId: '' })
      }
    }

    /**
     * 保存批注事件
     * @param params
     */
    const onSaveComment = async (params: Types.SaveCommentsParams) => {
      emit('saveComment', params)
    }

    /**
     * 模型加载完成
     * @param params
     */
    const onLoadedSuccess = (params: Types.LoadedParams) => {
      emit('loaded', params)
      hideExtraMenu()
    }

    /**
     * 设置节点高亮事件
     * @param params
     */
    const onSetNodesHighlighted = (params: Types.SetNodesHighlightedParams) => {
      const pathArray = Utils.getPathByNodeId(props.structData, params.nodeIds[0])
      //emit('setNodesHighlighted', { ...params, path: pathArray })
      if (pathArray) {
        eventEmitter.emit(Base.EBF_IOC_KEY.SET_NODE_HIGH_LIGHT, 'setSelectNode', {
          ...params,
          container: props.container,
          path: pathArray,
          environment: props.environment
        } as any)
      }
    }

    /**
     * 设置节点隐藏事件
     * @param params
     */
    const onSetNodesHide = (params: Types.SetNodesHideParams) => {
      const pathArray = Utils.getPathByNodeId(props.structData, params.nodeIds[0])
      emit('setNodesVisible', { ...params, path: pathArray, visible: false })
    }

    /**
     * 设置节点可见
     * @param params
     */
    const onSetNodesVisibility = (params: Types.SetNodesHideParams) => {
      const pathArray = Utils.getPathByNodeId(props.structData, params.nodeIds[0])
      emit('setNodesVisible', { ...params, path: pathArray, visible: true })
    }

    /**
     * 双击事件
     * @param params
     */
    const onDoubleClickNodesIds = (params: Types.DoubleClickNodeParams) => {
      const pathArray = Utils.getPathByNodeId(props.structData, params.nodeIds[0])
      emit('doubleClickNode', { ...params, path: pathArray })
    }

    /** 模型浏览事件 */
    const events = {
      SaveComments: onSaveComment,
      loadedSuccess: onLoadedSuccess,
      setNodesVisibleFinished: () => {},
      setNodesHighlighted: onSetNodesHighlighted,
      setNodesHide: onSetNodesHide,
      setNodesVisibility: onSetNodesVisibility,
      doubleClickNodesIds: onDoubleClickNodesIds
    }

    /** 注册 iframe 消息监听 */
    const registEvent = () => {
      for (const key in events) {
        /** 事件名称 */
        const eventName = key
        /** 执行回调 */
        const callback = (events as Record<string, any>)[key]
        // 注册事件
        iFrameEventManager.addEventListener(eventName, data => {
          if (data.markId == markId) {
            callback(data)
          }
        })
      }
    }

    Vue.watch(
      () => props.fileUrl,
      () => {
        init()
      },
      {
        immediate: true
      }
    )

    return () => {
      if (isLoading.value) {
        return <k-loading desc="正在加载文件" />
      }

      return (
        <k-skeleton active loading={!iframeUrl.value}>
          <iframe
            class="k-vue-browser"
            ref={refFrame}
            name={frameName.value}
            style="width:100%;height:100%;border:none;display:block;"
            src={iframeUrl.value}
            onLoad={onIframeLoad}
          />
        </k-skeleton>
      )
    }
  }
})
