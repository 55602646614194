
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { EventArgs, eventEmitter, GlobalException, request } from '@kmsoft/upf-core'
import { AppContext } from '@kmsoft/upf-core'
import { IAuthenticationInterceptor, IIdentityProvider, IIdentityStorage, UPF_IOC_KEYS } from '@kmsoft/upf-core'
import { EBF_IOC_KEY, sysSrv } from '@kmsoft/ebf-common'

export default defineComponent({
  name: 'KLogin',
  inheritAttrs: false,
  setup() {
    /** 当前引用 */
    let vm = getCurrentInstance()?.proxy as any
    let isLogging = ref(false)
    /** 错误提示 */
    let errMessage = ref('')
    /** 加载进度 */
    let loadingPercent = ref(0)
    /**logo */
    let logoImg = ref(require('../assets/images/km_logo.svg'))
    /**大标题平台名称 */
    let sysTitle = ref(AppContext.current.getEnvironment().getAppName())
    /**提示语 */
    let remind = ref('*注:禁止存储、处理、传输涉密信息')
    /**底部左侧信息 */
    let footerMsg_left = ref('客户端组件')
    /**底部中间信息 */
    let footerMsg_center = ref('联系运维')
    /**底部右侧信息 */
    let footerMsg_right = ref('操作手册')
    let clientExeUrl = ref('./system/KmUpgradeClient.sfx.exe')
    /** 表单 */
    let form = ref({
      model: { userName: '', password: '' },
      rules: {
        userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    })

    /**
     * 获取登录认证拦截器
     * @returns 拦截器数组
     */
    const getAuthenticationInterceptors = (): Array<IAuthenticationInterceptor> => {
      let authentication: Array<IAuthenticationInterceptor> = []
      try {
        authentication = AppContext.current
          .getIocContainer()
          .getBeans<IAuthenticationInterceptor>(UPF_IOC_KEYS.AUTHENTICATION_INTERCEPTOR)
        return authentication
      } catch (error) {
        return authentication
      }
    }

    /**
     * 登录点击回调
     * @returns 异步 Promise
     */
    const onLogin = async () => {
      isLogging.value = true

      /** 登录信息 */
      const principal = {
        userName: form.value.model.userName,
        password: form.value.model.password
      }

      try {
        // 1.获取身份认证票器
        const identity = await AppContext.current
          .getIocContainer()
          .getBean<IIdentityProvider>(UPF_IOC_KEYS.IDENTITY_PROVIDER)
          .getIdentity(principal)

        if (identity) {
          // 2.获取身份认证拦截器
          const authentication = getAuthenticationInterceptors()

          // 登录前
          for (const iterator of authentication) {
            await iterator.beforeAuthenticate(principal)
          }

          AppContext.current
            .getIocContainer()
            .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
            .set(identity)

          // 登录后
          for (const iterator of authentication) {
            await iterator.authenticated(identity)
          }
          if (sysSrv.isAgentLogin(window.location.href)) {
            eventEmitter.emit(EBF_IOC_KEY.AGENT_LOGIN, 'agentAfterLoad', (identity.token as unknown) as EventArgs)
          } else {
            // 跳转主页
            AppContext.current.getApplication().router.vueRouter.replace('./personalWorkspace')
          }
        } else {
          const ex = GlobalException.getSuggestionException('登录认证失败')
          // await eventEmitter.emit('', ex)
        }
      } catch (ex) {
        // await eventEmitter.emit('', ex)
        errMessage.value = (ex as any).toString()
      } finally {
        loadingPercent.value = 100
        isLogging.value = false
      }
    }

    /**鼠标移入input事件 */
    let onInput = (type: string) => {
      if (type === '0') {
        isShowClearUserName.value = form.value.model.userName.length > 0 ? true : false
      } else {
        isShowClearPassWord.value = form.value.model.password.length > 0 ? true : false
      }
    }

    /**用户名输入框移出事件 */
    let onInputOut = (type: string) => {
      if (type === '0') {
        isShowClearUserName.value = false
      } else {
        isShowClearPassWord.value = false
      }
    }

    /**清空输入框 */
    let clearValue = (type: string) => {
      if (type === '0') {
        form.value.model.userName = ''
      } else {
        form.value.model.password = ''
      }
    }

    /**用户名清除按钮 */
    let isShowClearUserName = ref(false)
    /**密码清除按钮 */
    let isShowClearPassWord = ref(false)

    /**用户名change事件*/
    let useNameChange = (value: string) => {
      console.log('username value:', value)
      // form.value.model.userName = value
    }

    /**密码change事件 */
    let passwordChange = (value: string) => {
      // form.value.model.password = value
    }
    let downloadFile = async () => {
      const url = '/kmsaasFileApi/download'
      const params = {
        modelName: 'DocumentMarkRecord',
        fileIds: '654779511799287808',
        attributeName: 'MarkFile'
      }

      try {
        const response = await request.post(url, params, {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        })

        if (response!.status != 200) {
          throw new Error('Network response was not ok')
        }
        const blob = new Blob([response!.data], { type: response!.headers['content-type'] })
        const downloadUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = 'KmUpgradeClient.sfx.exe' // 你想要下载的文件名
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(downloadUrl) // 释放 URL 对象
      } catch (error) {
        console.error('Download failed:', error)
      }
    }

    return {
      refForm: null,
      errMessage,
      sysTitle,
      remind,
      footerMsg_left,
      footerMsg_right,
      footerMsg_center,
      isLogging,
      loadingPercent,
      onLogin,
      onInput,
      onInputOut,
      clearValue,
      form,
      logoImg,
      isShowClearUserName,
      isShowClearPassWord,
      useNameChange,
      passwordChange,
      downloadFile,
      clientExeUrl
    }
  }
})
