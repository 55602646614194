import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KOfficeDocumentEditorPropOptions = {
  ...BaseViewPropOptions,
  mode: VuePropTypes.string().def('view'),
  enableCommentRights: VuePropTypes.bool().def(false),
  url: VuePropTypes.string().def(''),
  callbackUrl: VuePropTypes.string().def(''),
  fileType: VuePropTypes.string().def('docx'),
  title: VuePropTypes.string()
    .setRequired()
    .def('test.docx'),
  modelName: VuePropTypes.string()
    .setRequired()
    .def('Document'),
  id: VuePropTypes.string()
    .setRequired()
    .def(''),
  attributeName: VuePropTypes.string().def('primary'),
  onSave: VuePropTypes.func<any>().def()
}

/** 参数类型 **/
export type KOfficeDocumentEditorPropType = ViewPropsTypeExtract<typeof KOfficeDocumentEditorPropOptions>

/** 事件 */
export const KOfficeDocumentEditorEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KOfficeDocumentEditorEmitsType = ViewEmitsTypeExtract<typeof KOfficeDocumentEditorEventEmits>

export const config = {
  documentType: 'word',
  document: {
    title: '',
    url: '',

    // 当前用户对于当前文档的操作权限
    permissions: {
      print: false,
      download: true //用户是否可以下载
    },
    fileType: 'docx', //文件类型
    //onlyoffice用key做文件缓存索引，推荐每次都随机生成一下，不然总是读取缓存
    key: 'Document-693859505938632704'
  },
  editorConfig: {
    // 编辑器常规配置
    customization: {
      // 自动保存可以关闭，常规ctrl+s更好用
      autosave: false,
      compactToolbar: true,
      forcesave: true,
      toolbarNoTabs: true,
      help: false,
      compactHeader: true,
      hideRightMenu: true,
      logo: {
        //自定义logo配置
        image: '',
        imageDark: '',
        url: '',
        visible: false
      }
    },
    enableCommentRights: true,
    mode: 'view', //view为只能浏览  edit为编辑
    //这个回调及其的重要
    callbackUrl: '',
    // 菜单显示语言
    lang: 'zh-CN',
    // 当前操作用户信息
    user: {
      name: '',
      id: ''
    }
  },
  events: {
    onDocumentStateChange: (event: any) => console.log(event) // 修改文档时调用的函数
  }
}
