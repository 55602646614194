import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../object-tool-strip'
import { EnumToolStripItemKeys } from '../types'
import { EnumPurviewCode } from '../../../common'

/**
 * 获取文件夹工具栏
 * @returns 文件夹工具栏
 */
export const getFolderGeneralObjectOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  return baseToolItem
}

/**
 * 基础工具栏
 */
export const baseToolItem = [
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OBJ,
    title: '对象',
    icon: 'DropObjLife',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    cadShow: true,
    supportedObjNumMode: EnumSupportObjectNumMode.Any,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
        title: '创建',
        icon: 'NewObj',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+n',
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BATCH_CREATE_DOCUMENTS,
        title: '批量创建文档',
        shortcutKey: 'alt+b',
        icon: 'NewObj',
        visible: true,
        purviewId: EnumPurviewCode.BatchCreateDocuments,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD,
        title: '添加',
        icon: 'common_add_cover',
        visible: true,
        purviewId: EnumPurviewCode.Add,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+shift+d',
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_CAD,
        title: '添加至CAD工作区',
        icon: 'plus-circle',
        supportedObjClsTempletID: ['Part', 'Document'],
        visible: false,
        purviewId: EnumPurviewCode.AddToEmpWorkspace,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        shortcutKey: 'alt+f'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
        title: '另存',
        supportedObjClsTempletID: ['Part', 'Document'],
        icon: 'ObjSaveAs',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        shortcutKey: 'alt+s'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
        title: '删除对象',
        icon: 'DeleteObj',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        shortcutKey: 'alt+r'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH,
        title: '删除此版本',
        supportedObjClsTempletID: [
          'Part',
          'StandardPart',
          'Material',
          'FinishedGoods',
          'Componentsandparts',
          'Structuralsemifinishedproducts',
          'Electronicsemifinishedproducts',
          'Electroniccomponents',
          'Document',
          'OrdinaryDocument'
        ],
        icon: 'DeleteObj',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        shortcutKey: 'alt+shift+r'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH,
        title: '发布',
        supportedObjClsTempletID: ['Part', 'Document', 'OrdinaryDocument'],
        icon: 'Publish',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        shortcutKey: 'alt+p'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_OBSOLETE,
        title: '废弃',
        supportedObjClsTempletID: [
          'Part',
          'StandardPart',
          'Material',
          'FinishedGoods',
          'Componentsandparts',
          'Structuralsemifinishedproducts',
          'Electronicsemifinishedproducts',
          'Electroniccomponents',
          'Document',
          'OrdinaryDocument'
        ],
        icon: 'file-protect',
        visible: false,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        shortcutKey: 'alt+d'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_COPY,
        title: '复制',
        icon: 'copy',
        visible: true,
        purviewId: EnumPurviewCode.Copy,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        shortcutKey: 'ctrl+c'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CUT,
        title: '剪切',
        icon: 'scissor',
        visible: true,
        purviewId: EnumPurviewCode.Cut,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'ctrl+x',
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PASTE,
        title: '粘贴',
        icon: 'diff',
        visible: true,
        purviewId: EnumPurviewCode.Paste,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'ctrl+v',
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_OBJ_LINK,
        title: '移除',
        icon: 'DeleteObj',
        visible: true,
        purviewId: EnumPurviewCode.ObjLinkRemove,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+r',
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE,
        title: '移动',
        icon: 'switcher',
        visible: true,
        purviewId: EnumPurviewCode.Move,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+m',
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
        title: '结构管理',
        icon: 'file-protect',
        visible: false,
        supportedObjClsTempletID: ['Part', 'Document'],
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'ctrl+p',
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE,
        title: '创建需求变更请求',
        icon: 'file-protect',
        visible: false,
        supportedObjClsTempletID: ['ProductNeeds'],
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'ctrl+p',
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_OBJ_GROUP_OPERATION,
    title: '版本',
    icon: 'vertical-layout',
    visible: false,
    cadShow: true,
    supportedObjClsTempletID: ['Part', 'Document'],
    compType: EnumToolStripCompType.CONTEXT_MENU,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT,
        title: '检出(O)',
        icon: 'DocCheckOut',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+o',
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT,
        title: '取消检出',
        icon: 'DocCancelCheckOut',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
        title: '检入(I)',
        icon: 'DocCheckIn',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+i',
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE,
        title: '修订',
        icon: 'tag',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_GRID_OPERATION,
    title: '列表',
    icon: 'DropGridOp',
    visible: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    cadShow: true,
    supportedObjNumMode: EnumSupportObjectNumMode.Any,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
        title: '刷新',
        icon: 'sync',
        visible: true,
        purviewId: EnumPurviewCode.RefreshList,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'f5',
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FIND,
        title: '搜索',
        icon: 'search',
        visible: true,
        purviewId: EnumPurviewCode.Search,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'ctrl+f',
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD,
        title: '零部件导入模板下载',
        icon: 'download',
        visible: true,
        purviewId: EnumPurviewCode.DownloadPartTemplate,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_IMPORT,
        title: '零部件导入',
        icon: 'import',
        visible: true,
        purviewId: EnumPurviewCode.ImportParts,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Any
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CONVERT,
        title: '批量转通用件',
        icon: 'save',
        visible: false,
        purviewId: EnumPurviewCode.BatchConvertToCommonParts,
        supportedObjClsTempletID: ['Part'],
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_FILE_OPERATION,
    title: '文件',
    icon: 'profile',
    visible: false,
    supportedObjClsTempletID: ['Part', 'Document'],
    cadShow: true,
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC,
        title: '浏览',
        icon: 'eye',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC,
        title: '下载',
        icon: 'download',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        compType: EnumToolStripCompType.BUTTON,
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_FILE,
        title: '编辑文件',
        icon: 'edit',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        compType: EnumToolStripCompType.BUTTON,
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE,
        title: '替换文件',
        icon: 'file-storage',
        supportedObjClsTempletID: ['Document'],
        visible: true,
        cadShow: true,
        compType: EnumToolStripCompType.CONTEXT_MENU
      },
      // {
      //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE,
      //   title: '替换文件',
      //   icon: 'file-storage',
      //   visible: false,
      //   supportedObjClsTempletID: ['Document'],
      //   compType: EnumToolStripCompType.CONTEXT_MENU,
      //   supportedObjNumMode: EnumSupportObjectNumMode.Single,
      //   items: [
      //     {
      //       name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_FROM_LOCAL_FILE,
      //       title: '从本地替换',
      //       icon: 'DocAppPrint',
      //       supportedObjClsTempletID: ['Document'],
      //       visible: true,
      //       compType: EnumToolStripCompType.CONTEXT_MENU
      //     },
      //     {
      //       name: EnumToolStripItemKeys.TOOL_STRIP_FILE_REPLACE_NEW,
      //       title: '从模板替换',
      //       icon: 'DocSourcePrint',
      //       // supportedObjClsTempletID: ['Document'],
      //       visible: false,
      //       compType: EnumToolStripCompType.CONTEXT_MENU
      //     },
      //     {
      //       name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_QUERY,
      //       title: '从已有文档替换',
      //       icon: 'DocSourcePrint',
      //       // supportedObjClsTempletID: ['Document'],
      //       visible: false,
      //       compType: EnumToolStripCompType.CONTEXT_MENU
      //     }
      //   ]
      // },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_HISTORY_VERSION,
        title: '文件历史版本',
        icon: 'solution',
        visible: false,
        purviewId: EnumPurviewCode.VersionHistory,
        supportedObjClsTempletID: ['Document'],
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_REMARK,
        title: '批注',
        icon: 'read',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        compType: EnumToolStripCompType.BUTTON
        // shortcutKey: 'alt+s'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK,
        title: '查看批注',
        icon: 'read',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY,
        title: '查看批注历史版本',
        icon: 'container',
        visible: false,
        supportedObjClsTempletID: ['Document'],
        cadShow: true,
        supportedObjNumMode: EnumSupportObjectNumMode.Single,
        compType: EnumToolStripCompType.BUTTON
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_MANAGED,
    title: '变更',
    icon: 'unordered-list',
    visible: false,
    supportedObjClsTempletID: ['Part', 'Document'],
    compType: EnumToolStripCompType.CONTEXT_MENU,
    supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FORMAL_CHANGE,
        title: '正式变更',
        icon: 'unordered-list',
        visible: true,
        compType: EnumToolStripCompType.CONTEXT_MENU,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ISSUE,
            title: '创建变更问题报告',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_REQUEST,
            title: '创建变更申请',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ORDER,
            title: '创建变更单',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
          }
        ]
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_QUICK_CHANGE,
        title: '快速变更',
        icon: 'control',
        visible: true,
        supportedObjClsTempletID: ['Part', 'Document'],
        compType: EnumToolStripCompType.CONTEXT_MENU,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_CREATE,
            title: '基于改后创建变更活动',
            icon: 'file-text',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_ADD_ACTIVITY,
            title: '基于改后添加至变更活动',
            icon: 'file-text',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_CREATE,
            title: '基于改前创建变更活动',
            icon: 'file-text',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_ADD_ACTIVITY,
            title: '基于改前添加至变更活动',
            icon: 'file-text',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
          }
        ]
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_GROUP_WORK_FLOW,
    title: '流程',
    icon: 'ViewFlowObj',
    visible: false,
    supportedObjClsTempletID: [
      'Part',
      'Document',
      'ManagedBaseline',
      'ChangeActivity',
      'ChangeIssue',
      'ChangeOrder',
      'ChangeRequest',
      'Distribution',
      'CustomerNeeds',
      'ProductNeeds',
      'RequirementChangeRequest'
    ],
    compType: EnumToolStripCompType.CONTEXT_MENU,
    cadShow: true,
    supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
    items: [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
        title: '加入流程',
        icon: 'AddToFlow',
        visible: true,
        cadShow: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
        title: '查看流程',
        icon: 'ViewFlowObj',
        visible: true,
        cadShow: true,
        compType: EnumToolStripCompType.BUTTON,
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      }
    ]
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS,
    title: '使用统计',
    icon: 'table',
    visible: false,
    purviewId: EnumPurviewCode.StatUsages,
    supportedObjClsTempletID: ['Part'],
    compType: EnumToolStripCompType.BUTTON,
    supportedObjNumMode: EnumSupportObjectNumMode.Single
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE,
    title: '基线对比',
    icon: 'file-protect',
    visible: false,
    supportedObjClsTempletID: ['ManagedBaseline'],
    compType: EnumToolStripCompType.BUTTON,
    shortcutKey: 'alt+o'
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDPART,
    title: '相关零部件',
    icon: 'pic-left',
    visible: false,
    cadShow: true,
    compType: EnumToolStripCompType.BUTTON
  },
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDDOC,
    title: '相关文档',
    icon: 'pic-right',
    visible: false,
    cadShow: true,
    compType: EnumToolStripCompType.BUTTON
  }
] as Array<ObjectToolStripItem>
