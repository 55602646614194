import lodash from 'lodash'
import { EnumControl } from '../type'
import categoryElementConfig from './categoryElementConfig'
import checkboxElementConfig from './checkboxElementConfig'
import customComponentElementConfig from './customComponentElementConfig'
import customControlElementConfig from './customControlElementConfig'
import dateTimeElementConfig from './dateTimeElementConfig'
import enCodeElementConfig from './enCodeElementConfig'
// import fileBrowserElementConfig from './fileBrowserElementConfig'
import fileUploadAgentElementConfig from './fileUploadAgentElementConfig'
import fileUploadElementConfig from './fileUploadElementConfig'
import filterGridElementConfig from './filterGridElementConfig'
import folderElementConfig from './folderElementConfig'
import gridElementConfig from './gridElementConfig'
import gridObjectElementConfig from './gridObjectSelectElementConfig'
import inputElementConfig from './inputElementConfig'
import lifecycleElementConfig from './lifecycleElementConfig'
import numberElementConfig from './numberElementConfig'
import objectSelectElementConfig from './objectSelectElementConfig'
import radioElementConfig from './radioElementConfig'
// import richtextelementConfig from './richtextelementConfig'
import selectElementConfig from './selectElementConfig'
import textAreaElementConfig from './textAreaElementConfig'
import userSelectElementConfig from './userSelectElementConfig'
import viewElementConfig from './viewElementConfig'
import changeActivityElementConfig from './changeActivityElementConfig'
import imageElementConfig from './imageElementConfig'

/* 深拷贝，避免赋自定义值时出现引用 */
const ElementConfigCollection = new Map<any, any>()
ElementConfigCollection.set(EnumControl.INPUT, lodash.cloneDeep(inputElementConfig))
  .set(EnumControl.SELECT, lodash.cloneDeep(selectElementConfig))
  .set(EnumControl.NUMBER, lodash.cloneDeep(numberElementConfig))
  .set(EnumControl.GRID, lodash.cloneDeep(gridElementConfig))
  .set(EnumControl.DATE_TIME, lodash.cloneDeep(dateTimeElementConfig))
  .set(EnumControl.TEXT_AREA, lodash.cloneDeep(textAreaElementConfig))
  .set(EnumControl.FILTER_GRID, lodash.cloneDeep(filterGridElementConfig))
  .set(EnumControl.CUSTOM, lodash.cloneDeep(customComponentElementConfig))
  .set(EnumControl.CHECKBOX, lodash.cloneDeep(checkboxElementConfig))
  .set(EnumControl.OBJECT_SELECT, lodash.cloneDeep(objectSelectElementConfig))
  .set(EnumControl.GRID_OBJ_SELECT, lodash.cloneDeep(gridObjectElementConfig))
  .set(EnumControl.CLASS_SELECT, lodash.cloneDeep(selectElementConfig))
  .set(EnumControl.RADIO_GROUP, lodash.cloneDeep(radioElementConfig))
  .set(EnumControl.CUSTOM_CONTROL_GRID, lodash.cloneDeep(customControlElementConfig))
  .set(EnumControl.CATEGORY_SELECTOR, lodash.cloneDeep(categoryElementConfig))
  .set(EnumControl.ENCODERS_EDITOR, lodash.cloneDeep(enCodeElementConfig))
  .set(EnumControl.FILED_UPLOAD_EDITOR, lodash.cloneDeep(fileUploadElementConfig))
  .set(EnumControl.FILED_UPLOAD_AGENT_EDITOR, lodash.cloneDeep(fileUploadAgentElementConfig))
  .set(EnumControl.FOLDER_SELECTOR, lodash.cloneDeep(folderElementConfig))
  .set(EnumControl.VIEW_SELECTOR, lodash.cloneDeep(viewElementConfig))
  .set(EnumControl.LIFECYCLE_EDITOR, lodash.cloneDeep(lifecycleElementConfig))
  .set(EnumControl.CHANGE_ACTIVITY__GRID, lodash.cloneDeep(changeActivityElementConfig))
  .set(EnumControl.USER_EDITOR, lodash.cloneDeep(userSelectElementConfig))
  .set(EnumControl.IMG_EDITOR, lodash.cloneDeep(imageElementConfig))
// .set(EnumControl.CREATE_DISPLAY_EDITOR, lodash.cloneDeep(userSelectElementConfig))
// .set(EnumControl.RICH_TEXT_EDITOR, lodash.cloneDeep(richtextelementConfig))
export default ElementConfigCollection
