import { BaseViewModel, ViewModelOptions, KNotification } from '@kmsoft/upf-core'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { IFolder } from '../../../../pages/folder-manage/folder-tree/interface'
import { KCadWorkspaceDetailEmitsType, KCadWorkspaceDetailPropType, ProductWorkRootNode } from './interface'

/** KCadWorkspaceDetail */
export default class KCadWorkspaceDetailViewModel extends BaseViewModel<
  KCadWorkspaceDetailEmitsType,
  KCadWorkspaceDetailPropType
> {
  constructor(options: ViewModelOptions<KCadWorkspaceDetailPropType>) {
    super(options)
  }

  viewDidMount() {}

  async loadTreeData() {
    const param = {
      data: [this.props.workspaceId]
    }
    const res = await Api.post('folder', 'Folder', 'getFolderById', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: res.data?.id,
        name: res.data?.name,
        leafFlag: res.data.leafFlag,
        nameEn: res.data.nameEn,
        type: res.data.type
      } as IFolder
      return [new ProductWorkRootNode(node)]
    } else {
      KNotification.error({
        title: '查询失败',
        content: res.message!
      })
      return Promise.resolve([])
    }
  }
}
