import { BaseViewModel, ViewModelOptions, KFormViewModel, KNotification } from '@kmsoft/upf-core'
import { Api, EnumRequestCode, Agent } from '@kmsoft/ebf-common'
import { KCreateCadWorkspaceEmitsType, KCreateCadWorkspacePropType, ICadWorkspaceBaseProp } from './interface'
import { ref, watch } from 'vue'
import _ from 'lodash'

/** KCreateCadWorkspace */
export default class KCreateCadWorkspaceViewModel extends BaseViewModel<
  KCreateCadWorkspaceEmitsType,
  KCreateCadWorkspacePropType
> {
  /** 基本信息表单 */
  refBasePropForm = ref<KFormViewModel<ICadWorkspaceBaseProp>>()
  isAgent = ref<boolean>(false)
  /** 基本信息 */
  formData = ref<ICadWorkspaceBaseProp>({
    name: '',
    libraryFolderId: '',
    bomLibraryPosition: '',
    docLibraryPosition: '',
    localCachePosition: '',
    description: ''
  })
  /** 校验规则 */
  rules = {
    name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
    libraryFolderId: [{ required: true, message: '所属产品/项目库不能为空', trigger: 'blur' }],
    bomLibraryPosition: [{ required: true, message: '零部件默认储存位置不能为空', trigger: 'blur' }],
    docLibraryPosition: [{ required: true, message: '图文档默认储存位置不能为空', trigger: 'blur' }]
  }
  libraryType = ref<string>('')
  /** 工作区对象下拉树选择 */
  libraryTypeTreeData = ref<any[]>([])
  /** 默认展开树节点 */
  defaultExpandedKey = ref<string[]>([])
  /** 零部件存储位置下拉树选择 */
  partPositionTreeData = ref<any[]>([])
  /** 零部件默认展开树节点 */
  partPositionExpandedKey = ref<string[]>([])
  /** 文档存储位置下拉树选择 */
  docPositionTreeData = ref<any[]>([])
  /** 文档默认展开树节点 */
  docPositionExpandedKey = ref<string[]>([])
  originBomLibraryPosition = ref<string>(this.props.formData?.bomLibraryPosition || '')
  originDocLibraryPosition = ref<string>(this.props.formData?.docLibraryPosition || '')
  constructor(options: ViewModelOptions<KCreateCadWorkspacePropType>) {
    super(options)
    watch(
      () => this.props.handleType,
      (newValue: string) => {
        if (newValue == 'edit') {
          this.formData.value = {
            name: this.props.formData?.name,
            libraryFolderId: this.props.formData?.libraryFolderId!,
            bomLibraryPosition: this.props.formData?.bomLibraryPositionName!,
            docLibraryPosition: this.props.formData?.docLibraryPositionName!,
            localCachePosition: this.props.formData?.localCachePosition!,
            description: this.props.formData?.description
          }
          this.libraryType.value = this.props.libraryType!
          const node = {
            id: this.props.formData!.libraryFolderId,
            pId: 0,
            title: this.props.formData!.libraryFolderName,
            isLeaf: false,
            value: this.props.formData!.libraryFolderId,
            type: this.props.libraryType!
          }
          this.getListChildFolder(this.props.formData!.libraryFolderId, node)
        }
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    this.getAgent()
    this.getTreeList()
  }

  async getAgent() {
    const res = await Agent.AgentManager.initialize()
    if (res) {
      this.isAgent.value = true
    } else {
      this.isAgent.value = false
    }
  }

  getProductList() {
    const param = {
      data: [
        {
          filter: {},
          isNeedTotal: true,
          sort: 'DESC',
          orderBy: 'createTime'
        },
        {
          maxPageSize: 1000,
          curPage: 1,
          pageSize: 100,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    return Api.post('folder', 'Folder', 'listProductFolder', param)
  }

  getProjectList() {
    const param = {
      data: [
        {
          filter: {},
          isNeedTotal: true,
          sort: 'DESC',
          orderBy: 'createTime'
        },
        {
          maxPageSize: 1000,
          curPage: 1,
          pageSize: 100,
          totalRows: 0,
          totalPages: 0,
          startIndex: 0,
          endIndex: 0
        }
      ]
    }
    return Api.post('folder', 'Folder', 'listProjectWorkspace', param)
  }

  getTreeList() {
    const productList = this.getProductList()
    const projectList = this.getProjectList()
    Promise.all([productList, projectList]).then(res => {
      if (res[0].code == EnumRequestCode.SUCCESS && res[1].code == EnumRequestCode.SUCCESS) {
        const productNodes = res[0].data.data.map((data: any) => {
          return {
            id: data.id,
            pId: 2,
            title: data.name,
            isLeaf: true,
            value: data?.id,
            type: 'product'
          }
        })
        const projectNodes = res[1].data.data.map((data: any) => {
          return {
            id: data.id,
            pId: 3,
            title: data.name,
            isLeaf: true,
            value: data?.id,
            type: 'project'
          }
        })
        const node = [
          {
            id: '1',
            pId: 0,
            title: '产品/项目库',
            isLeaf: false,
            value: '1',
            disabled: true
          },
          {
            id: '2',
            pId: 1,
            title: '产品库',
            isLeaf: res[0].data.page.totalRows == 0 ? true : false,
            value: '2',
            disabled: true
          },
          {
            id: '3',
            pId: 1,
            title: '项目库',
            isLeaf: res[1].data.page.totalRows == 0 ? true : false,
            value: '3',
            disabled: true
          }
        ]
        this.libraryTypeTreeData.value = [...node, ...productNodes, ...projectNodes]
        this.defaultExpandedKey.value = [node[0].id, node[1].id]
      } else {
        KNotification.error({
          title: '系统提示',
          content: '获取产品/项目库数据异常'
        })
      }
    })
  }

  selectLibraryType(value: string, node: any) {
    this.libraryType.value = node.type
    this.formData.value.bomLibraryPosition = ''
    this.formData.value.docLibraryPosition = ''
    this.getListChildFolder(value, node)
  }

  selectBomLibraryPosition(value: string) {
    this.originBomLibraryPosition.value = value
  }

  selectDocLibraryPosition(value: string) {
    this.originDocLibraryPosition.value = value
  }

  async selectPath() {
    if (this.isAgent.value) {
      const dialog = new Agent.FolderBrowserDialog()
      if ((await dialog.ShowDialog()) === Agent.DialogResult.OK) {
        this.formData!.value.localCachePosition = dialog.SelectedPath
      }
      return undefined
    } else {
      KNotification.warn({
        message: '系统提示',
        description: '未检测到本地代理'
      })
    }
  }

  async getListChildFolder(id: string, node: any) {
    const parentNode = _.cloneDeep(node)
    parentNode.isLeaf = false
    this.partPositionTreeData.value = [parentNode]
    this.partPositionExpandedKey.value = [parentNode.id]
    this.docPositionTreeData.value = [parentNode]
    this.docPositionExpandedKey.value = [parentNode.id]
  }

  /**
   * 异步加载零部件存储位置子节点
   */
  async loadPartTreeData(treeNode: Record<string, any>) {
    return new Promise((resolve, reject) => {
      const param = {
        data: [
          treeNode.id,
          {
            rdmPageVO: {
              maxPageSize: 1000,
              curPage: 1,
              pageSize: 1000,
              totalRows: 0,
              totalPages: 0,
              startIndex: 0,
              endIndex: 0
            }
          }
        ]
      }
      Api.post('folder', 'Folder', 'listChildFolder', param).then(result => {
        if (result && result.code == EnumRequestCode.SUCCESS) {
          const node = this.partPositionTreeData.value
          for (const item of result.data!) {
            node.push({
              id: item.id!,
              key: item.rawFullPath,
              pId: treeNode.id,
              title: item.name,
              isLeaf: item.leafFlag,
              value: item.rawFullPath
            })
          }
          if (node.length == 1) {
            node[0].isLeaf = true
          }
          this.partPositionTreeData.value = node
          resolve(true)
        } else {
          KNotification.error({
            title: '获取子节点失败',
            content: result.message!,
            details: result.detail!
          })
        }
        resolve([])
      })
    })
  }

  /**
   * 异步加载文档存储位置子节点
   */
  async loadDocTreeData(treeNode: Record<string, any>) {
    return new Promise((resolve, reject) => {
      const param = {
        data: [
          treeNode.id,
          {
            rdmPageVO: {
              maxPageSize: 1000,
              curPage: 1,
              pageSize: 1000,
              totalRows: 0,
              totalPages: 0,
              startIndex: 0,
              endIndex: 0
            }
          }
        ]
      }
      Api.post('folder', 'Folder', 'listChildFolder', param).then(result => {
        if (result && result.code == EnumRequestCode.SUCCESS) {
          const node = this.docPositionTreeData.value
          for (const item of result.data!) {
            node.push({
              id: item.id!,
              key: item.rawFullPath,
              pId: treeNode.id,
              title: item.name,
              isLeaf: item.leafFlag,
              value: item.rawFullPath
            })
          }
          if (node.length == 1) {
            node[0].isLeaf = true
          }
          this.docPositionTreeData.value = node
          resolve(true)
        } else {
          KNotification.error({
            title: '获取子节点失败',
            content: result.message!,
            details: result.detail!
          })
        }
        resolve([])
      })
    })
  }

  async getFormValue() {
    try {
      const validate = await this.refBasePropForm.value?.validate()
      const value = this.refBasePropForm.value?.formState
      /** 参数 */
      const param = {
        ...value,
        bomLibraryPosition: this.originBomLibraryPosition.value,
        docLibraryPosition: this.originDocLibraryPosition.value,
        libraryType: this.libraryType.value
      }
      return param
    } catch (error) {
      return false
    }
  }
}
