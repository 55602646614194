import {
  BaseViewModel,
  EnumItemChangeCancelType,
  EnumToolStripCompType,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KPlanEditPanelEmitsType, KPlanEditPanelPropType } from './interface'
import {
  Api,
  EnumObjClassManageToolStripOptions,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  ObjBusinessParam
} from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KPlanEditPanel */
export default class KPlanEditPanelViewModel extends BaseViewModel<KPlanEditPanelEmitsType, KPlanEditPanelPropType> {
  /** 属性面板工具栏 */
  toolStripOptions = ref<EnumObjClassManageToolStripOptions>(EnumObjClassManageToolStripOptions.ShowEdit)
  /** 属性 */
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  /** 对象参数 */
  objParam = ref<ObjBusinessParam>()

  categorySelectorViewModel = ref<any>(null)

  constructor(options: ViewModelOptions<KPlanEditPanelPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
  }

  viewDidMount() {}

  /** 加载计划数据 */
  async loadData() {
    const result = (await Api.post('requirement', 'VerificationPlan', 'get', { data: [this.props.objParam.id] })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      let data = result.data
      data = {
        ...data,
        validationMetricsViews: []
      }
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取验证计划详情失败',
        details: result.detail
      })
      return
    }
  }

  /** 计划操作按钮 */
  toolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.update()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refObjectProperty.value?.refresh()
        break
      default:
        break
    }
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY:
        if (event.value) {
          this.refObjectProperty.value?.setReadOnly(false)
          this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        } else {
          this.doSave()
          event.itemCancelType = EnumItemChangeCancelType.CancelAll
          this.refObjectProperty.value?.setReadOnly(true)
          this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        }
        break
      default:
        break
    }
  }

  /** 编辑保存 */
  async doSave() {
    if (await this.validate()) {
      const value = this.refObjectProperty.value?.getModifiedValue()! as any
      if (Object.keys(value).length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '没有修改数据'
        })
        return
      }
      if (value.secondary) {
        const secondary = value.secondary.map((element: string) => {
          return { id: element }
        })
        value.secondary = secondary
      }
      const reqParam = { ...value, id: this.objParam.value?.id }
      console.log('doSave', reqParam)
      const result = await Api.post('requirement', 'VerificationPlan', 'update', { data: [reqParam] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        KNotification.success('操作成功！')
        return
      }
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
      this.refObjectProperty.value?.refresh()
      return
    }
  }

  /** 更新 */
  async update() {
    if (await this.validate()) {
      const value = this.refObjectProperty.value?.getModifiedValue()! as any
      if (Object.keys(value).length) {
        const objParam = this.refObjectProperty.value?.props.objParam
        const reqParam = { ...value, id: objParam?.id }

        const result = await Api.post('requirement', 'VerificationPlan', 'update', { data: [reqParam] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectProperty.value?.refresh()
          KNotification.success('操作成功！')
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message,
            details: result.detail
          })
        }
      } else {
        KNotification.warn({
          message: '系统提示',
          description: '没有修改数据'
        })
      }
    }
  }

  /** 校验 */
  async validate() {
    try {
      return this.refObjectProperty.value?.validate()
    } catch (error) {
      console.log('验证错误', error)
      return false
    }
  }

  /**面板加载完成 */
  loaded() {}
}
