
import { defineView, ToolStripItemClickedEventArgs } from '@kmsoft/upf-core'
import { KDocRelationObjEventEmits, KDocRelationObjPropOptions } from './interface'
import KDocRelationObjViewModel from './KDocRelationObjViewModel'
import { KModelBrowser } from '@kmsoft/km-vue'

export default defineView({
  name: 'KDocRelationObj',
  props: KDocRelationObjPropOptions,
  emits: KDocRelationObjEventEmits,
  viewModel: KDocRelationObjViewModel,
  components: { KModelBrowser },
  setup() {
    return {}
  }
})
