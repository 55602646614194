import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_class_grid, {
      ref: "refObjGrid",
      modelCode: "Part",
      modelGroup: "part",
      schemaType: "Part_search",
      toolStripItems: _ctx.$vm.toolStripItems,
      pagination: false,
      selectFirstRow: false,
      loadData: _ctx.$vm.loadData,
      onRowSelected: _ctx.$vm.changeToolItem,
      onToolStripItemClicked: _ctx.$vm.onToolStripItemClicked
    }, null, 8, ["toolStripItems", "loadData", "onRowSelected", "onToolStripItemClicked"])
  ]))
}