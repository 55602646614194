
import { defineView } from '@kmsoft/upf-core'
import { KCollectRelatedPartPropOptions, KCollectRelatedPartEventEmits } from './interface'
import KCollectRelatedPartViewModel from './KCollectRelatedPartViewModel'
import { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KCollectRelatedPart',
  props: KCollectRelatedPartPropOptions,
  emits: KCollectRelatedPartEventEmits,
  viewModel: KCollectRelatedPartViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleState,
      clsCodeMap,
      EnumLifecycleStateColor,
      workingStateOptions: [
        {
          label: '工作中',
          value: 'INWORK'
        },
        {
          label: '已检入',
          value: 'CHECKED_IN'
        },
        {
          label: '已检出',
          value: 'CHECKED_OUT'
        }
      ]
    }
  }
})
