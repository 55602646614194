import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KStructuredDocFormPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KStructuredDocFormPropType = ViewPropsTypeExtract<typeof KStructuredDocFormPropOptions>

/** 事件 */
export const KStructuredDocFormEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KStructuredDocFormEmitsType = ViewEmitsTypeExtract<typeof KStructuredDocFormEventEmits>
