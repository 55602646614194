import { connect, withInstall } from '@kmsoft/upf-core'
import KCollectRelatedObjectView from './KCollectRelatedObject.vue'
import KCollectRelatedObjectViewModel from './KCollectRelatedObjectViewModel'

const KCollectRelatedObject = connect(KCollectRelatedObjectView, KCollectRelatedObjectViewModel)

export default withInstall(KCollectRelatedObject)
export { KCollectRelatedObject, KCollectRelatedObjectView, KCollectRelatedObjectViewModel }

// 模板生成文件
// export * from './{{folderName}}'
