
import { VNodeProps, defineView } from '@kmsoft/upf-core'
import { KDocStructureManagePropOptions, KDocStructureManageEventEmits } from './interface'
import KDocStructureManageViewModel from './KDocStructureManageViewModel'
import { KDocStructureTreeEmitsType, KDocStructureTreePropType } from '../doc-structure-tree'
import {
  KObjectPanelEmitsType,
  KObjectPanelPropType,
  ObjectPanelTabChangeEvent,
  ObjectPanelRetrieveEvent,
  EnumObjectPanelTabParamType
} from '@kmsoft/ebf-common'

export default defineView({
  name: 'KDocStructureManage',
  props: KDocStructureManagePropOptions,
  emits: KDocStructureManageEventEmits,
  viewModel: KDocStructureManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      /**渲染文档结构树 */
      const renderTree = () => {
        const docStructurePanelSlots = {
          header: () => {
            const popoverSlots = {
              default: () => {
                const title = vm.structureFilterName.value ? ',' + vm.structureFilterName.value : ''
                return (
                  <k-button type="text" size="small">
                    <k-icon type="filter" />
                    过滤：{vm.structureViewName.value}
                    {title}
                    <k-icon type="down" />
                  </k-button>
                )
              },
              content: () => {
                return (
                  <k-space direction="vertical" style="width:300px">
                    <k-form>
                      <k-form-item label="版本规则">
                        <k-select
                          placeholder="版本规则"
                          options={vm.structureViewOptions.value}
                          disabled={props.disabledView}
                          v-model={[vm.structureViewName.value, 'value']}
                        ></k-select>
                      </k-form-item>
                      {/* <k-form-item label="过滤条件">
                        <k-select
                          placeholder="过滤条件"
                          options={vm.structureFilterOptions.value}
                          allowClear={true}
                          disabled={props.disabledFilter}
                          v-model={[vm.structureFilterName.value, 'value']}
                        ></k-select>
                      </k-form-item> */}
                    </k-form>
                  </k-space>
                )
              }
            }

            return <k-popover trigger="click" placement="bottomLeft" v-slots={popoverSlots} />
          },
          default: () => {
            const partStructureViewMode = vm.structureViews.value.find(f => f.viewName == vm.structureViewName.value)

            if (!vm.isInitialized.value) {
              return <k-spin />
            }

            const treeProps: VNodeProps<KDocStructureTreePropType, KDocStructureTreeEmitsType> = {
              ref: vm.refDocStructureTree,
              objParams: props.objParam,
              viewName: vm.structureViewName.value,
              filterName: vm.structureFilterName.value,
              isMultipleSelection: props.isMultipleSelection,
              checkStrictly: props.checkStrictly,
              disabledContextMenu: props.disabledContextMenu,
              partStructureViewMode: partStructureViewMode,
              onBeforeSelect: vm.onBeforeNodeSelect,
              onAfterSelect: vm.onAfterSelect,
              onReady: vm.onTreeReady,
              onAfterCheck: vm.onTreeAfterCheck,
              onNodeRefresh: vm.onNodeRefresh
            }

            return <k-doc-structure-tree {...treeProps} />
          }
        }

        return (
          <k-split-pane>
            <k-panel hideHeader={props.suppressView} v-slots={docStructurePanelSlots} />
          </k-split-pane>
        )
      }
      /** 对象面板 */
      const renderObjectPanel = () => {
        if (props.suppressObjectPanel) {
          return
        }
        /** 对象面板参数 */
        const objectPanelProps: VNodeProps<KObjectPanelPropType, KObjectPanelEmitsType> = {
          ref: vm.refObjectPanel,
          objParam: vm.selectObjParam.value,
          suppressedTabs: props.suppressedTabs,
          suppressOperation: props.suppressOperation,
          suppressObjectLife: props.suppressObjectLife,
          attachParams: {
            [EnumObjectPanelTabParamType.NoShowTree]: true,
            [EnumObjectPanelTabParamType.ExpandMode]: vm.nodeChildExpandMode.value!,
            [EnumObjectPanelTabParamType.RootPartObjParam]: props.objParam,
            [EnumObjectPanelTabParamType.SelectedNode]: vm.selectedNode.value,
            [EnumObjectPanelTabParamType.ComponentEnviroment]: 'KDocStructureManage',
            [EnumObjectPanelTabParamType.RootNode]: vm.docStructureTree?.treeView?.getRootNodes()[0]
          },
          // serviceAttachParams: props.serviceAttachParams?.serviceIdentity
          //   ? props.serviceAttachParams
          //   : {
          //       serviceIdentity: 'Part',
          //       attachParam: {}
          //     },
          isHighestOpen: false,
          onDataUpdated: vm.onObjectPanelDataUpdated,
          onRetrieve: (event: ObjectPanelRetrieveEvent<any>) => {
            emit('objectPanelRetrieve', event)
          },
          onTabChange: (event: ObjectPanelTabChangeEvent) => {
            emit('objectPanelTabChange', event)
          }
        }
        return (
          <k-split-pane collapse={!props.showObjectPanel} size={70}>
            <k-object-panel {...objectPanelProps} />
          </k-split-pane>
        )
      }
      return (
        <k-split-container class="k-doc-structure-manage">
          <k-split-pane>
            <k-split-container horizontal={true}>{renderTree()}</k-split-container>
          </k-split-pane>
          {renderObjectPanel()}
        </k-split-container>
      )
    }
  }
})
