import { join, toUpper } from 'lodash'
import { Exception } from '@kmsoft/upf-core'
import { Agent } from '@kmsoft/ebf-common'

/**
 * Ddb中间文件检查和修正
 */
export class CheckAndAmendSrv {
  /**
   * 获取中间文件生成信息
   * @param argFilePaths 图纸文件信息
   * @param argFileSearchDirPaths 搜索路径信息
   * @param argConfigFilePath Ddb配置文件路径
   * @param argMidFilePath 中间文件路径
   * @returns 中间文件信息
   */
  public getMidFileCreatingInfo(
    argFilePaths: string[],
    argFileSearchDirPaths: string[],
    argConfigFilePath: string,
    argMidFilePath: string
  ): string {
    const infoList: string[] = []
    infoList.push(`DDB提取图纸文件信息结果文件路径:${argMidFilePath}`)
    infoList.push(`DDB集成配置文件路径:${argConfigFilePath}`)

    // 图纸文件信息
    if (argFilePaths && argFilePaths.length > 0) {
      if (argFilePaths.length === 1) {
        infoList.push(`图纸文件路径${argFilePaths[0]}`)
      } else {
        infoList.push('图纸文件路径')
        argFilePaths.forEach(f => {
          infoList.push(f)
        })
      }
    }

    // 搜索路径信息
    if (argFileSearchDirPaths && argFileSearchDirPaths.length > 0) {
      if (argFileSearchDirPaths.length === 1) {
        infoList.push(`图纸文件搜索目录路径：${argFileSearchDirPaths[0]}`)
      } else {
        infoList.push('图纸文件搜索目录路径：')
        argFileSearchDirPaths.forEach(f => {
          infoList.push(f)
        })
      }
    }

    const result = join(infoList, '\r\n')
    return result
  }

  /**
   *检查和修正中间文件
   1.检查：中间文件必须存在，并是合法的xml格式。
   2.检查：中间文件中Xml节点Id必须非空、非重。
   3.检查：中间文件中Xml节点对应必须合法（Part-Doc按入参可/不可为1:0、可为1:1、可为1:n；Doc-Part不可为1:0、可为1:1、不可为1:n）。
   4.修正：中间文件中不能含零部件无关Xml节点。
   5.检查和修正：零部件的关键属性必须非空。
   * @param argMidFilePath
   * @param argIsAllowPartRefNonDoc
   * @param argMidFileCreatingInfo
   */
  public async CheckAndAmendMidFile(argMidFilePath: string, argIsAllowPartRefNonDoc: boolean, argMidFileCreatingInfo: string) {
    // 中间文件必须存在 并且是合法的xml格式
    if (!(await Agent.File.Exists(argMidFilePath)))
      throw new Exception(`DDB提取图纸文件信息结果文件生成失败,请检查当前集成环境是否正确。\r\n${argMidFileCreatingInfo}`)

    let doc: Document
    const xmlDoc = await Agent.XmlDocument.create()
    try {
      xmlDoc.Load(argMidFilePath)
      const ddbConfigXmlStr = await xmlDoc.OuterXml()

      const xml = new DOMParser()
      doc = xml.parseFromString(ddbConfigXmlStr, 'text/xml')
    } catch (error) {
      throw new Exception(`DDB提取图纸文件信息结果文件错误,非法的xml格式。 \r\n${argMidFileCreatingInfo}`)
    }

    if (doc.querySelectorAll('bomData/part').length == 0)
      throw new Exception(`读取中间文件内容出错,请检查DDB配置和图纸文件! \r\n${argMidFileCreatingInfo}`)

    // 检查：中间文件中Xml节点Id必须非空、非重
    // document节点
    const docNodeMap: Map<string, Element> = new Map<string, Element>()
    const docNodeList = doc.querySelectorAll('bomData/document')
    docNodeList.forEach(node => {
      const docId = node.getAttribute('id')
      if (!docId) {
        throw new Exception(`DDB提取图纸文件信息结果错误,某个文档无id或id为空。 + "\r\n${argMidFileCreatingInfo}`)
      }
      if (docNodeMap.get(docId)) {
        throw new Exception(`DDB提取图纸文件信息结果错误,多个文档id同为${docId}。\r\n${argMidFileCreatingInfo}`)
      }

      docNodeMap.set(docId, node)
    })

    // part节点
    const partNodeMap: Map<string, Element> = new Map<string, Element>()
    const partNodeList = doc.querySelectorAll('bomData/part')
    partNodeList.forEach(node => {
      const partId = node.getAttribute('id')
      if (!partId) {
        throw new Exception(`DDB提取图纸文件信息结果错误,某个零部件无id或id为空。 + "\r\n${argMidFileCreatingInfo}`)
      }
      if (partNodeMap.get(partId)) {
        throw new Exception(`DDB提取图纸文件信息结果错误,多个零部件id同为${partId}。\r\n${argMidFileCreatingInfo}`)
      }
      if (docNodeMap.get(partId)) {
        throw new Exception(`DDB提取图纸文件信息结果错误,某个零部件和某个文档id同为${partId}。\r\n${argMidFileCreatingInfo}`)
      }

      partNodeMap.set(partId, node)
    })

    // 根partId
    const rootPartIdList: string[] = []
    const rootPartIdNodeList = doc.querySelectorAll('bomData/summary/rootPartId')
    rootPartIdNodeList.forEach(rootPartIdNode => {
      const rootPartId = rootPartIdNode.innerHTML
      if (!rootPartId) throw new Exception(`DDB提取图纸文件信息结果错误,某个根零部件无id或id为空。\r\n${argMidFileCreatingInfo}`)
      if (!partNodeMap.get(rootPartId))
        throw new Exception(`DDB提取图纸文件信息结果错误,某个根零部件id(${rootPartId})非法。\r\n${argMidFileCreatingInfo}`)
    })

    // part的结构信息
    const refChildPartNodeList = doc.querySelectorAll('bomData/part/structure/childPart')
    refChildPartNodeList.forEach(childPartNode => {
      const refId = childPartNode.getAttribute('refId')

      if (!refId)
        throw new Exception(`DDB提取图纸文件信息结果错误,某个零部件的子件对应无refId或refId为空。\r\n${argMidFileCreatingInfo}`)

      if (!partNodeMap.get(refId))
        throw new Exception(`DDB提取图纸文件信息结果错误,某个零部件对应子件id(${refId})非法。\r\n${argMidFileCreatingInfo}`)
    })

    // part的文档信息
    let refRelateObjectNodeList = doc.querySelectorAll('bomData/part/relation[type="文档关联"]/relateObject')
    refRelateObjectNodeList.forEach(relateObjectNode => {
      const refId = relateObjectNode.getAttribute('refId')
      if (!refId)
        throw new Exception(`DDB提取图纸文件信息结果错误,某个零部件的文档对应无refId或refId为空。\r\n${argMidFileCreatingInfo}`)

      if (!docNodeMap.get(refId))
        throw new Exception(`DDB提取图纸文件信息结果错误,某个零部件对应文档id(${refId})非法。\r\n${argMidFileCreatingInfo}`)
    })

    // 中间文件中Xml节点对应必须合法
    // Part-Doc 可为1：1，是否可为1：0根据参数决定
    // Doc-Part 可为1：1，不可为1：0，不可为1：n
    const partDocMap: Map<string, string[]> = new Map<string, string[]>()
    for (const partIdNode of partNodeMap) {
      const partId = partIdNode[0]
      const partNode = partIdNode[1]

      // part的文档关联数据
      refRelateObjectNodeList = partNode.querySelectorAll('relation[@type="文档关联"]/relateObject')

      if (refRelateObjectNodeList.length == 0) {
        // part没有对应的doc

        if (argIsAllowPartRefNonDoc) {
          partDocMap.set(partId, [])
        } else {
          throw new Exception(`DDB提取图纸文件信息结果错误,某个零部件(id:${partId})不对应任何文档。\r\n${argMidFileCreatingInfo}`)
        }
      } else {
        // part有对应的Doc

        for (const relateObjectNode of refRelateObjectNodeList) {
          // 前端已判断过refId是否为空
          const refId = relateObjectNode.getAttribute('refId')!

          let partDocIdList: string[] = []
          if (partDocMap.get(partId)) {
            partDocIdList = partDocMap.get(partId)!
          } else {
            partDocMap.set(partId, partDocIdList)
          }

          // Part引用的各Doc必须非重
          if (partDocIdList.indexOf(refId) >= 0)
            throw new Exception(
              `DDB提取图纸文件信息结果错误,某个零部件(id:%{partId})重复对应相同文档(id:${refId})。\r\n${argMidFileCreatingInfo}`
            )

          // Doc-Part不可为1:n
          // for (const partDoc of partDocMap) {
          //   if (partDoc[0] !== partId && partDoc[1].indexOf(refId) >= 0) {
          //     throw new Exception(
          //       `DDB提取图纸文件信息结果错误,某个零部件(id:${partId})和其它零部件对应相同文档(id:${refId})。\r\n${argMidFileCreatingInfo}`
          //     )
          //   }
          // }

          partDocIdList.push(refId)
        }
      }
    }

    // 遍历Doc
    for (const docIdNode of docNodeMap) {
      const docId = docIdNode[0]
      const docNode = docIdNode[0]

      // Doc-Part不可为1：0
      let existInPart: boolean = false
      for (const partDoc of partDocMap) {
        if (partDoc[1].indexOf(docId) >= 0) {
          existInPart = true
          break
        }
      }
      // Doc-Part不可为1：0
      if (!existInPart)
        throw new Exception(`DDB提取图纸文件信息结果错误,某个文档(id:{docId})不对应任何零部件。\r\n${argMidFileCreatingInfo}`)
    }

    // 将被移除的Doc节点
    const toBeRemoveDocNodeList: Element[] = []
    // 将被移除的Part节点
    const toBeRemovePartNodeList: Element[] = []
    // 将被移除的Part的Struct中的ChildPart节点
    const toBeRemoveRefChildPartNodeList: Element[] = []

    // 修正：中间文件中不能含零部件无关节点
    // 遍历Doc
    for (const docIdNode of docNodeMap) {
      const docId = docIdNode[0]
      const docNode = docIdNode[1]
      const docType = docNode.getAttribute('type')

      if (docType === 'asmpmi' || docType === 'prtpmi' || docType === 'pmi') {
        let partId: string = ''
        for (const partDoc of partDocMap) {
          if (partDoc[1].indexOf(docId) >= 0) {
            partId = partDoc[0]
            break
          }
        }

        // part节点
        const partNode = partNodeMap.get(partId)!

        // doc节点 将被移除
        toBeRemoveDocNodeList.push(docNode)
        toBeRemovePartNodeList.push(partNode)

        if (docType === 'asmpmi' || docType === 'prtpmi') {
          const parentPartRefChildPartNodeList = doc.querySelectorAll(`bomData/part/structure/childPart[refId="${partId}"]`)
          parentPartRefChildPartNodeList.forEach(childPartNode => {
            toBeRemoveRefChildPartNodeList.push(childPartNode)
          })
        }
      }
    }

    // 移除Doc节点
    for (const docNode of toBeRemoveDocNodeList) {
      docNode.parentNode?.removeChild(docNode)
    }
    // 移除Part节点
    for (const partNode of toBeRemovePartNodeList) {
      partNode.parentNode?.removeChild(partNode)
    }
    // 移除Part的Struct中的ChildPart节点
    for (const childPartNode of toBeRemoveRefChildPartNodeList) {
      childPartNode.parentNode?.removeChild(childPartNode)
    }

    this.CheckAndAmendSymbolName(doc, argMidFilePath, argMidFileCreatingInfo)

    const s = new XMLSerializer()
    xmlDoc.Save(s.serializeToString(doc))
  }

  /**
   * 检查和修正中间文件
   * 1、检查和修正：零部件的关键属性必须非空
   * @param doc
   * @param argMidFilePath
   * @param argMidFileCreatingInfo
   */
  private CheckAndAmendSymbolName(doc: Document, argMidFilePath: string, argMidFileCreatingInfo: string) {
    // ToDo
  }
}
