import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  SimpleViewEventEmits,
  SimpleViewPropOptions
} from '@kmsoft/upf-core'

export interface FileInter {
  /** 文件id **/
  id?: string
  name?: string
  fileSize?: number | string
  fileSuffix?: string | null
}

export const fileSuffixIconMapping: Record<string, { icon: string; fileSuffixList: string[] }> = {
  image: {
    icon: 'file-image',
    fileSuffixList: ['jpg', 'png', 'jpeg', 'gif', 'bmp']
  },
  pdf: {
    icon: 'file-pdf',
    fileSuffixList: ['pdf']
  },
  markdown: {
    icon: 'file-markdown',
    fileSuffixList: ['md']
  },
  ppt: {
    icon: 'file-ppt',
    fileSuffixList: ['ppt', 'pptx']
  },
  excel: {
    icon: 'file-excel',
    fileSuffixList: ['xls', 'xlsx']
  },
  word: {
    icon: 'file-word',
    fileSuffixList: ['doc', 'docx']
  },
  zip: {
    icon: 'file-zip',
    fileSuffixList: ['zip', 'rar', '7z', 'gz', 'bz2', 'tar', 'iso', 'cab', 'dmg', 'jar', 'apk']
  },
  other: {
    icon: 'file-done',
    fileSuffixList: []
  }
}

/** 参数 **/
export const KObjectFileUploadPropOptions = {
  ...BaseViewPropOptions,
  ...SimpleViewPropOptions,
  /** 高度最小100， 默认100**/
  height: VuePropTypes.createType<string | number>().def(100),
  /** 编辑时，如果传入文件id集合，通过远程获取文件，默认false，通过传入文件对象 **/
  isRemoteGetFile: VuePropTypes.bool().def(false),
  modelName: VuePropTypes.string()
    .setRequired()
    .def(),
  attributeName: VuePropTypes.string()
    .setRequired()
    .def(),
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KObjectFileUploadPropType = ViewPropsTypeExtract<typeof KObjectFileUploadPropOptions>

/** 事件 */
export const KObjectFileUploadEventEmits = {
  ...BaseViewEventEmits,
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KObjectFileUploadEmitsType = ViewEmitsTypeExtract<typeof KObjectFileUploadEventEmits>
