import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "full",
  ref: "refPartReport"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_part_report_make = _resolveComponent("k-part-report-make")!
  const _component_k_tab_pane = _resolveComponent("k-tab-pane")!
  const _component_k_part_report_buy = _resolveComponent("k-part-report-buy")!
  const _component_k_tabs = _resolveComponent("k-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_tabs, {
      activeKey: _ctx.activeKey,
      "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_k_tab_pane, {
          key: "1",
          tab: "自制件汇总"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_part_report_make, {
              objParam: _ctx.$vm.objParam,
              container: _ctx.$vm.refPartReport.value
            }, null, 8, ["objParam", "container"])
          ]),
          _: 1
        }),
        _createVNode(_component_k_tab_pane, {
          key: "3",
          tab: "外购件汇总"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_part_report_buy, {
              objParam: _ctx.$vm.objParam,
              container: _ctx.$vm.refPartReport.value
            }, null, 8, ["objParam", "container"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["activeKey"])
  ], 512))
}