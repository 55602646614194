import { connect, withInstall } from '@kmsoft/upf-core'
import KRequirementTraceView from './KRequirementTrace.vue'
import KRequirementTraceViewModel from './KRequirementTraceViewModel'

const KRequirementTrace = connect(KRequirementTraceView, KRequirementTraceViewModel)

export default withInstall(KRequirementTrace)
export { KRequirementTrace, KRequirementTraceView, KRequirementTraceViewModel }

// 模板生成文件
// export * from './{{folderName}}'
