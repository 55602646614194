import { IKEditorAPI, UploadFileInfo } from '@kmsoft/ebf-common'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { AgentDdb } from '../../../..'
import { AddFilePropertyReadOption } from '../../interface'

//#region
/** 创建文档对象时，主文档添加选项 */
export type MainFileAddOptions = {
  showLocalFile: boolean
  showDocObj: boolean
  showTemplate: boolean
}

export type DocFileInfo = {
  fileInfo: UploadFileInfo
  properties?: Record<string, any>
  fileProperty?: AgentDdb.FileProperty
  name: string
}
//#endregion

/** 参数 **/
export const KCreatePanelDocSelectorPropOptions = {
  ...BaseViewPropOptions,
  /**
   * 编辑器API
   * @description 编辑器对外操作 API
   */
  api: VuePropTypes.createType<IKEditorAPI>()
    .setRequired()
    .def(),
  /** 对象类 */
  modelCode: VuePropTypes.string()
    .setRequired()
    .def(''),
  /** 对象类属性 */
  attributeName: VuePropTypes.string().def(''),
  /** 值 */
  value: VuePropTypes.createType<DocFileInfo>().def(),
  /** 是否创建零部件 */
  isCreatePart: VuePropTypes.bool().def(false),
  /** 主文件添加选项 */
  mainFileAddOptions: VuePropTypes.createType<MainFileAddOptions>()
    .setRequired()
    .def({ showDocObj: true, showLocalFile: true, showTemplate: true }),
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KCreatePanelDocSelectorPropType = ViewPropsTypeExtract<typeof KCreatePanelDocSelectorPropOptions>

/** 事件 */
export const KCreatePanelDocSelectorEventEmits = {
  ...BaseViewEventEmits,
  /** v-model */
  'update:value': (value: DocFileInfo) => true,
  options: (value: AddFilePropertyReadOption) => true
}

/** 事件类型 **/
export type KCreatePanelDocSelectorEmitsType = ViewEmitsTypeExtract<typeof KCreatePanelDocSelectorEventEmits>
