
import { defineView, IKTreeNode, TreeViewSelectEventArgs } from '@kmsoft/upf-core'
import { KRequirementChangeAfterPropOptions, KRequirementChangeAfterEventEmits } from './interface'
import KRequirementChangeAfterViewModel from './KRequirementChangeAfterViewModel'

export default defineView({
  name: 'KRequirementChangeAfter',
  props: KRequirementChangeAfterPropOptions,
  emits: KRequirementChangeAfterEventEmits,
  viewModel: KRequirementChangeAfterViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
