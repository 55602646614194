import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px","display":"flex","flex-direction":"column"} }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { style: {"flex":"1"} }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_divider = _resolveComponent("k-divider")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_tool_strip, {
        ref: _ctx.$vm.refObjClsToolStrip
      }, {
        default: _withCtx(() => [
          (_ctx.$vm.dataLoaded && !_ctx.$vm.insertDocTemplateEnable)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 0,
                name: "btnInsert",
                title: "新建",
                onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.doInsert())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.insertDocTemplateEnable)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 1,
                name: "btnInsertDocTemplate",
                title: "新建模板",
                onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.doInsertDocTemplate())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 2,
                name: "btnDelete",
                title: "删除",
                onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.doDelete())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 3,
                name: "btnUp",
                title: "上移",
                onClick: _cache[3] || (_cache[3] = () => _ctx.$vm.doUp()),
                disabled: !_ctx.$vm.upEnabled
              }, null, 8, ["disabled"]))
            : _createCommentVNode("", true),
          (_ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 4,
                name: "btnDown",
                title: "下移",
                onClick: _cache[4] || (_cache[4] = () => _ctx.$vm.doDown()),
                disabled: !_ctx.$vm.downEnabled
              }, null, 8, ["disabled"]))
            : _createCommentVNode("", true),
          (_ctx.$vm.dataLoaded && _ctx.mode === _ctx.EnumTabType.SYS)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 5,
                name: "btnCheck",
                title: "校验",
                onClick: _cache[5] || (_cache[5] = () => _ctx.$vm.check())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 6,
                name: "btnSave",
                title: "保存",
                onClick: _cache[6] || (_cache[6] = () => _ctx.$vm.save())
              }))
            : _createCommentVNode("", true),
          (_ctx.$vm.dataLoaded)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 7,
                name: "btnRefresh",
                title: "刷新",
                onClick: _cache[7] || (_cache[7] = () => _ctx.$vm.refresh())
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.$vm.showGrid)
        ? (_openBlock(), _createBlock(_component_k_data_grid, {
            key: 0,
            ref: _ctx.$vm.refObjClsGrid,
            autoIndex: true,
            pagination: false,
            rowKey: "id",
            isMultipleSelection: false,
            rowModeType: "clientSide",
            onRowSelected: _cache[8] || (_cache[8] = (selectedEvent) => _ctx.$vm.selectRow(selectedEvent))
          }, {
            columns: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.columns, (column) => {
                return (_openBlock(), _createBlock(_component_k_data_grid_text_box_column, {
                  key: column.code,
                  id: column.code,
                  name: column.code,
                  hide: column.name == '文件Id',
                  dataPropertyName: column.code,
                  headerText: column.name,
                  width: "120px",
                  align: "center"
                }, null, 8, ["id", "name", "hide", "dataPropertyName", "headerText"]))
              }), 128)),
              (_ctx.$vm.showUpload)
                ? (_openBlock(), _createBlock(_component_k_data_grid_template_column, {
                    key: 0,
                    dataPropertyName: "fileId",
                    headerText: "操作",
                    width: "120px",
                    id: "action",
                    name: "action",
                    align: "center"
                  }, {
                    default: _withCtx(({row}) => [
                      _createElementVNode("a", {
                        class: "row-operation-item",
                        onClick: (e) => _ctx.$vm.uploadFile(row)
                      }, "上传", 8, _hoisted_4),
                      _createVNode(_component_k_divider, {
                        type: _ctx.EnumDividerType.VERTICAL
                      }, null, 8, ["type"]),
                      _createElementVNode("a", {
                        class: "row-operation-item",
                        onClick: (e) => _ctx.$vm.downloadFile(row)
                      }, "下载", 8, _hoisted_5)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 512))
        : _createCommentVNode("", true)
    ])
  ]))
}