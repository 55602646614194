import { PROPERTY_CONFIG_ITEM_COLLECTION } from '../propsConfigItemCollection'
import { EnumAttributeType, EnumDataType } from '@kmsoft/upf-core'
import { getCandidates } from '../utils'
import { KControlAttribute } from '@kmsoft/upf-core'
import { LAYOUT_CONFIG_ITEM_COLLECTION } from '../layoutConfigItemCollection'

/* 组件的基本属性 */
const comConfig = [
  {
    name: 'readonly',
    title: '是否只读',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'size',
    title: '大小型号',
    control: 'KSelect',
    options: getCandidates(['small', 'middle', 'large']),
    defaultValue: 'middle',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'min',
    title: '最小值',
    control: 'KInputNumber',
    defaultValue: null,
    dataType: EnumDataType.INT,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'max',
    title: '最大值',
    control: 'KInputNumber',
    defaultValue: null,
    dataType: EnumDataType.INT,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'precision',
    title: '数值精度，小数位数',
    control: 'KInputNumber',
    defaultValue: 0,
    dataType: EnumDataType.DECIMAL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'prefix',
    title: '前缀图标',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'suffix',
    title: '后缀图标',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'allowClear',
    title: '可清空',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  }
]
/* 属性配置项集合 */
const BASE = [
  PROPERTY_CONFIG_ITEM_COLLECTION.TITLE,
  PROPERTY_CONFIG_ITEM_COLLECTION.DISABLED,
  {
    name: 'dataSource',
    title: '数据源',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.BASE
  },
  {
    name: 'field',
    title: '字段',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.BASE
  }
  // ...comConfig
] as Array<KControlAttribute>
/**控件属性 */
const PROPS = comConfig
/* 布局配置项集合 */
const LAYOUT = [
  LAYOUT_CONFIG_ITEM_COLLECTION.ROW_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.COL_SPAN,
  LAYOUT_CONFIG_ITEM_COLLECTION.ENTIRE_ROW,
  LAYOUT_CONFIG_ITEM_COLLECTION.FILL
] as Array<KControlAttribute>

const EVENT = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'valueChanged', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { BASE, PROPS, LAYOUT, EVENT }
