import { AppContext, BaseViewModel, KDataGridViewModel, PageManager, request, utils, ViewModelOptions } from '@kmsoft/upf-core'
import { ColorEnum, KPersonalHomePageEmitsType, KPersonalHomePagePropType, MessageTypeEnum, TaskStatusEnum } from './interface'
import { markRaw, nextTick, ref } from 'vue'
import * as echarts from 'echarts'
import { Api, CommonClientSrv, EnumRequestCode, sysSrv } from '@kmsoft/ebf-common'
import { KProductWorkspace } from '../product-workspace'
import { KProjectPageWrapper } from '../../../../../ebf-project-manage/src/components/project-page-wrapper'
import {
  KCADWorkspace,
  KCommonPartManage,
  KElectronicComponentsWorkspace,
  KPersonalWorkspace,
  KStandardWorkspace,
  KWorkspaceList
} from '../../../index'
import { KWorkflowView } from '../../../../../ebf-workflow/src/pages/workflow-view'
import moment from 'moment'
import _ from 'lodash'

/**个人首页 */
export default class KPersonalHomePageViewModel extends BaseViewModel<KPersonalHomePageEmitsType, KPersonalHomePagePropType> {
  /** 卡片DOM */
  monitorBoxRef = ref<HTMLElement>()
  cardListRef = ref<HTMLElement>()
  /** tab标签 */
  tabslist = ref<Array<any>>([
    { key: '1', value: '我管理的产品库', count: '' },
    { key: '2', value: '全部产品库', count: '' }
  ])
  masktablist = ref<Array<any>>([
    { key: '1', value: '全部', count: 0 },
    { key: '2', value: '流程任务', count: 0 },
    { key: '3', value: '项目任务', count: 0 }
  ])
  /**任务列表网格 */
  refTaskGrid = ref<KDataGridViewModel>()
  /** 产品分类*/
  activeKey = ref<string>('1')
  /** 任务分类*/
  activeTaskKey = ref<string>('1')
  /** 产品列表清单 */
  productList = ref<any>(null)
  /** 搜索任务关键字 */
  taskKeyWord = ref<string>('')
  /** 消息关键字 */
  messageKeyWord = ref<string>('')
  /** 快速访问入口列表 */
  entryList = ref<any>([
    {
      name: '产品工作区',
      value: 'product-area'
    },
    {
      name: '项目列表',
      value: 'working-area'
    },
    {
      name: 'CAD工作区',
      value: 'cad-area'
    },
    {
      name: '个人工作区',
      value: 'person-area'
    },
    {
      name: '标准件库',
      value: 'standard-library'
    },
    {
      name: '元器件库',
      value: 'electronic-components-library'
    },
    {
      name: '通用件库',
      value: 'general-library'
    }
  ])
  /** 全部任务 */
  allTaskListData = ref<any>([])
  /** 流程任务 */
  flowTaskListData = ref<any>([])
  /** 项目任务 */
  projectTaskListData = ref<any>([])
  /** 全部任务集合 */
  cloneTaskData = ref<any>({})
  /** 流程任务图表 */
  flowEcharts = ref<any>(null)
  /** 任务图表 */
  projectEcharts = ref<any>(null)
  /** 任务列表默认展示15条 */
  pageSize = ref<number>(15)

  /** 消息列表 */
  checkMessage = ref<string>('all')
  messageList = ref<any>([])
  messageTypeList = ref<Array<any>>([
    { key: 'all', value: '全部', count: '' },
    { key: 'ed', value: '已读', count: 0 },
    { key: 'un', value: '未读', count: 0 }
  ])
  tabActiveKey = ref<string>('all')
  taskActiveKey = ref<string>('1')
  messageShowList = ref<Array<Record<string, any>>>([])
  messageReadList = ref<Array<Record<string, any>>>([])
  messageUnReadList = ref<Array<Record<string, any>>>([])
  /** 弹窗显示及详情信息 */
  visible = ref<boolean>(false)
  messageDetail = ref<any>({})
  messageType = ref<string>('')

  contentProps = ref<any>()
  wrapperProps = ref<any>()
  valueList = ref<Array<Record<string, any>>>([])

  constructor(options: ViewModelOptions<KPersonalHomePagePropType>) {
    super(options)
  }

  viewDidMount() {
    this.queryProductList()
    this.queryTaskList()
    this.queryMessageList()
    nextTick(() => {
      ;(document.getElementById('flow-chart') as any).setAttribute('_echarts_instance_', '')
      this.flowEcharts.value = markRaw(echarts.init(document.getElementById('flow-chart')))
      ;(document.getElementById('pjt-chart') as any).setAttribute('_echarts_instance_', '')
      this.projectEcharts.value = markRaw(echarts.init(document.getElementById('pjt-chart')))
    })

    window.addEventListener('resize', function() {
      markRaw(echarts.getInstanceByDom(document.getElementById('flow-chart') as any) as any).resize()
      markRaw(echarts.getInstanceByDom(document.getElementById('pjt-chart') as any) as any).resize()
    })
  }

  /** 点击tabs */
  async tabClick(val: string) {
    this.activeKey.value = val
    this.queryProductList()
  }

  /** 点击左滑动 3个卡片*/
  scrollLeft() {
    const allLength = this.productList.value.length * 200
    const boxLength: any = this.monitorBoxRef.value?.clientWidth
    if (allLength < boxLength) return
    const leftMove = Math.abs(parseInt(window.getComputedStyle(this.cardListRef.value as HTMLElement, null)?.left))
    if (leftMove + boxLength - 540 < boxLength) {
      // 到最开始的时候
      ;(this.cardListRef.value as any).style.left = '0px'
    } else {
      ;(this.cardListRef.value as any).style.left = '-' + (leftMove - 540) + 'px'
    }
  }

  /** 点击右滑动 3个卡片 */
  scrollRight() {
    const allLength = this.productList.value.length * 200
    const boxLength: any = this.monitorBoxRef.value?.clientWidth
    if (allLength < boxLength) return
    const leftMove = Math.abs(parseInt(window.getComputedStyle(this.cardListRef.value as HTMLElement, null)?.left))
    if (leftMove + boxLength + 540 > allLength) {
      ;(this.cardListRef.value as HTMLElement).style.left = '-' + (allLength - boxLength) + 'px'
    } else {
      ;(this.cardListRef.value as HTMLElement).style.left = '-' + (leftMove + 540) + 'px'
    }
  }

  /** 点击产品卡片跳转 */
  productClick(val: any) {
    PageManager.openPage(utils.uuid(), `产品库（` + val.name + `)`, KProductWorkspace, {
      workspaceId: val.id,
      workspaceName: val.name
    })
  }

  /** 快速访问跳转页面 */
  jumpToPage(val: any) {
    switch (val.value) {
      case 'product-area':
        PageManager.openPage(utils.uuid(), val.name, KWorkspaceList, {})
        break
      case 'working-area':
        PageManager.openPage(utils.uuid(), val.name, KProjectPageWrapper, { name: 'projectManage' })
        break
      case 'cad-area':
        PageManager.openPage(utils.uuid(), val.name, KCADWorkspace, {})
        break
      case 'person-area':
        PageManager.openPage(utils.uuid(), val.name, KPersonalWorkspace, {})
        break
      case 'standard-library':
        PageManager.openPage(utils.uuid(), val.name, KStandardWorkspace, {})
        break
      case 'electronic-components-library':
        PageManager.openPage(utils.uuid(), val.name, KElectronicComponentsWorkspace, {})
        break
      case 'general-library':
        PageManager.openPage(utils.uuid(), val.name, KCommonPartManage, {})
        break
      default:
        break
    }
  }

  /** 查询产品 */
  async queryProductList() {
    this.productList.value = null
    const param = {
      data: [
        {
          filter: {},
          isNeedTotal: true,
          orderBy: 'createTime',
          sort: 'DESC'
        },
        {
          curPage: 1,
          maxPageSize: 1000,
          pageSize: 50
        }
      ]
    }
    let genre = ''
    switch (this.activeKey.value) {
      case '1': // 收藏产品
        genre = 'listMyManagedProductFolder'
        break
      case '2': // 全部产品
        genre = 'listProductFolder'
        break
      default:
        break
    }
    const res = await Api.post('folder', 'Folder', genre, param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      this.productList.value = res.data.data || []
    }
  }

  /** 查询任务列表 流程任务与项目任务*/
  async queryTaskList() {
    // 流程任务查询参数
    const optFlow = {
      data: [
        {
          userId: AppContext.current.getIdentity()?.id,
          currentPage: 1,
          pageSize: 1000
        }
      ]
    }
    // 项目列表查询参数
    const optPjt = {
      classCode: 'PMS_PLAN',
      filters: [
        {
          key: 'CCODE',
          operator: 'eq',
          value: 'EXECUTE'
        }
      ],
      sort: {
        orders: []
      },
      page: {
        pageIndex: 1,
        pageSize: 1000
      },
      rowGroupCols: {}
    }
    const p1 = Api.post('official', 'ProcessInstanceService', 'listPendingTaskHome', optFlow) as any
    const host = window.location.host
    const hosts = host.split('.')
    const pmsServer = `http://pms.${hosts.length <= 1 ? 'dev.kmsaasdev.com' : hosts.slice(1).join('.')}`
    const url = pmsServer + '/kmPmsApi/planTask/listMyTask'
    const p2 = request.post(url, optPjt) as any

    const enumList: any = {
      '10': 'NO_START',
      '20': 'ON_DOING',
      '30': 'COMPLETED',
      '40': 'TIME_OUT',
      '50': 'END'
    }
    Promise.all([p1, p2])
      .then((res: any) => {
        res[1]?.rows.map((it: any) => {
          it.taskId = it.id
          it.processName = it.taskName
          it.assigner = it.resourceName
          // 这里判断是否逾期
          if (it.executionStatus !== '30' && moment(new Date()).isAfter(moment(it.planEndTime))) {
            it.status = 'OVERDUE'
          } else {
            it.status = enumList[it.executionStatus]
          }
        })
        this.cloneTaskData.value = {
          allTask: res[0]?.data.dataList.concat(res[1].rows),
          flowTask: res[0].data.dataList,
          projectTask: res[1]?.rows || []
        }
        this.masktablist.value[0].count = res[0]?.data.dataList.length + res[1]?.total || 0
        this.masktablist.value[1].count = this.cloneTaskData.value.flowTask.length
        this.masktablist.value[2].count = res[1]?.total || 0

        // 全部任务
        this.allTaskListData.value = this.cloneTaskData.value.allTask.slice(0, this.pageSize.value)
        this.flowTaskListData.value = this.cloneTaskData.value.flowTask.slice(0, this.pageSize.value)

        this.projectTaskListData.value = this.cloneTaskData.value.projectTask.slice(0, this.pageSize.value)

        this.initEcharts(this.cloneTaskData.value.flowTask, '1') // 流程任务
        this.initEcharts(this.cloneTaskData.value.projectTask, '2') // 项目任务
      })
      .catch()
  }

  /**枚举转正 */
  translate(val: string, dec: string) {
    return dec === 'text' ? (TaskStatusEnum as any)[val] : (ColorEnum as any)[val]
  }

  /** 查询消息列表 */
  async queryMessageList() {
    const param = {
      data: [
        {
          page: {
            currentPage: 1,
            pageSize: 500
          },
          content: this.messageKeyWord.value
        }
      ]
    }
    const res = await Api.post('notify', 'NotifyService', 'listMessage', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      this.messageList.value = res.data.dataList?.reverse()
      this.messageList.value.map((item: any) => {
        item.sendTime = moment(item.sendTime).format('YYYY-MM-DD HH:mm:ss')
      })

      this.messageTypeList.value[0].count = this.messageList.value.length
      this.messageTypeList.value[1].count = this.messageList.value.filter((it: any) => it.readStatus === 1).length
      this.messageTypeList.value[2].count = this.messageList.value.filter((it: any) => it.readStatus === 0).length

      this.filterlist()
    }
  }

  /** 网格点击*/
  openWorkflowPage(row: any) {
    if (row.projectId) {
      console.log('项目信息', row)
      // 项目任务
      CommonClientSrv.openPage(`我的项目: ${row.processName}`, KProjectPageWrapper, {
        name: 'TaskInfoPanel',
        isAbsUrl: false,
        windowId: new Date().getTime(),
        objectInfo: {
          classCode: '',
          objectId: row.id,
          projectId: row.projectId,
          projectVersionGroup: row.projectVersionGroup,
          versionGroup: row.versionGroup,
          accessMode: 2
        }
      })
    } else {
      // 流程任务
      CommonClientSrv.openPage(
        '流程查看：' + row.processName,
        KWorkflowView,
        {
          taskId: row.taskId,
          processInstanceId: row.processInstanceId
        },
        row.id
      )
    }
  }

  /** 初始化echarts图表 */
  initEcharts(data: any, type: string) {
    const chartData = []
    const colorList = []
    for (const obj in TaskStatusEnum) {
      const trate = data.filter((it: any) => it.status === obj)
      if (trate.length) {
        chartData.push({
          name: (TaskStatusEnum as any)[obj],
          value: trate.length
        })
        colorList.push((ColorEnum as any)[obj])
      }
    }
    const option = {
      title: [
        {
          text: type === '1' ? '流程任务' : '项目任务',
          left: 'center',
          textStyle: {
            fontSize: 16
          }
        },
        {
          text: type === '1' ? this.cloneTaskData.value.flowTask.length : this.cloneTaskData.value.projectTask.length,
          x: '48%',
          y: '40%',
          textAlign: 'center',
          textStyle: {
            fontSize: 16,
            fontWeight: 'normal'
          }
        },
        {
          text: '总数',
          x: '48%',
          y: '50%',
          textAlign: 'center',
          textStyle: {
            fontSize: 14
          }
        }
      ],
      tooltip: {
        trigger: 'item',
        padding: [10, 10],
        formatter: '{b} : {c} '
      },
      color: colorList,
      legend: {
        bottom: '0',
        left: 'center',
        show: false
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'outside'
          },
          labelLine: {
            normal: {
              length: 20,
              length2: 30,
              lineStyle: {
                width: 1
              }
            }
          },
          data: chartData
        }
      ]
    }
    type === '1' ? this.flowEcharts.value.setOption(option) : this.projectEcharts.value.setOption(option)
  }

  /**打开信息弹窗 */
  openDetail(val: any) {
    this.visible.value = true
    this.messageType.value = (MessageTypeEnum as any)[val.messageType]
    this.messageDetail.value = val
  }

  /** 已阅读邮件 */
  async sureRead() {
    if (!this.messageDetail.value.readStatus) {
      const param = {
        data: [
          {
            ids: [this.messageDetail.value.id],
            readStatus: 1
          }
        ]
      }
      const res = await Api.post('notify', 'NotifyService', 'updateMessageStatus', param)
      if (res && res.code == EnumRequestCode.SUCCESS) {
        this.messageShowList.value.map((it: any) => {
          if (it.id === this.messageDetail.value.id) {
            it.readStatus = 1
          }
        })
        if (this.checkMessage.value === 'un') {
          this.messageShowList.value = this.messageShowList.value.filter((it: any) => it.readStatus === 0)
        }
        this.queryMessageList()
      }
    }
  }

  /** 过滤消息列表 */
  filterlist() {
    this.messageShowList.value = _.cloneDeep(this.messageList.value)
    this.messageReadList.value = _.cloneDeep(this.messageList.value).filter((it: any) => it.readStatus === 1)
    this.messageUnReadList.value = _.cloneDeep(this.messageList.value).filter((it: any) => it.readStatus === 0)
  }

  /** 翻页 */
  handedPageChange(page: number) {
    const start = (page - 1) * this.pageSize.value
    const end = (page - 1) * this.pageSize.value + this.pageSize.value
    const deepData = _.cloneDeep(this.cloneTaskData.value)
    switch (this.taskActiveKey.value) {
      case '1':
        this.allTaskListData.value = deepData.allTask.slice(start, end)
        // eslint-disable-next-line no-fallthrough
        break
      case '2':
        this.flowTaskListData.value = deepData.flowTask.slice(start, end)
        break
      case '3':
        this.projectTaskListData.value = deepData.projectTask.slice(start, end)
        break
      default:
        break
    }
  }

  taskTabClick(tab: string) {
    this.taskActiveKey.value = tab
  }
}
