
import { defineView } from '@kmsoft/upf-core'
import { KPartReportMakePropOptions, KPartReportMakeEventEmits } from './interface'
import KPartReportMakeViewModel from './KPartReportMakeViewModel'
import _ from 'lodash'

export default defineView({
  name: 'KPartReportMake',
  props: KPartReportMakePropOptions,
  emits: KPartReportMakeEventEmits,
  viewModel: KPartReportMakeViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
