import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "full",
  style: {"display":"flex","flex-direction":"column"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_property_panel = _resolveComponent("k-object-property-panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_object_property_panel, {
      class: "custom-need",
      ref: "refObjectCreatePanel",
      modelCode: "ProductNeedsSection",
      modelGroup: "requirement",
      schemaType: "new",
      loadData: () => _ctx.$vm.formData
    }, null, 8, ["loadData"])
  ]))
}