import { Api, EnumClassTemplate, EnumRequestCode, ObjectClientSrv, ObjBusinessParam, EBF_IOC_KEY } from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumDialogResult,
  IKTreeNode,
  KDataGridRowData,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  KTabsViewModel,
  KTreeViewViewModel,
  TreeViewExpandEventArgs,
  ValueChangeEventArgs,
  ViewModelOptions,
  utils,
  AppContext
} from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import lodash from 'lodash'
import { KModelBrowserViewModel } from '@kmsoft/km-vue/src/components'
import { KBaselineGrid, KBaselineGridViewModel } from '../../../../../ebf-baseline/src/pages/baseline-grid'
import { KPartBomCompareModeForm, KPartBomCompareModeFormViewModel } from './form/part-bom-compare-mode-form'
import { KVersionGroupObjectSelectForm, KVersionGroupObjectSelectFormViewModel } from './form/version-group-object-select-form'
import { KPartBomCompareEmitsType, KPartBomComparePropType } from './interface'
import { KBomCompareResultPanel, KBomCompareResultPanelViewModel } from './panel/bom-compare-result-panel'
import { IRootTreeNodeParam } from './panel/bom-compare-result-panel/interface'
import { KObjectViewSelectorViewModel } from '@kmsoft/ebf-common/src/controls/object-view-selector'

/** KPartBomCompare */
export default class KPartBomCompareViewModel extends BaseViewModel<KPartBomCompareEmitsType, KPartBomComparePropType> {
  /**左侧树组件 */
  refLeftFolderTree = ref<KTreeViewViewModel>()
  /**左侧树根节点Id */
  leftTreeRootNode = ref<any>()
  /**右侧树组件 */
  refRightFolderTree = ref<KTreeViewViewModel>()
  /**右侧树根节点Id */
  rightTreeRootNode = ref<any>()
  /**比较结果网格 */
  refCompareDataGrid = ref<KBomCompareResultPanelViewModel>()
  /**比较结果选中Id */
  compareResultSelectedId = ref<string>()
  /**比较方式 */
  comparisonMode = ref<string | undefined>()
  /**比较方式名称 */
  comparisonModeName = ref<string>('')
  /**差异件是否展开 */
  expandDifferentialParts = ref<boolean>(false)
  /**是否忽略分支节点 */
  ignoringBranchNodes = ref<boolean>(false)
  /**左侧树展开类型 */
  leftTreeExpandType = ref<string>('LATEST_WITHOUT_WORKING_COPY')
  /**右侧树展开类型 */
  rightTreeExpandType = ref<string>('LATEST_WITHOUT_WORKING_COPY')
  /** 左侧树基线Id */
  leftBaselineId = ref<string>()
  /** 右侧树基线Id */
  rightBaselineId = ref<string>()
  /** 左侧树视图 */
  refLeftView = ref<string>()
  /** 右侧树视图 */
  refRightView = ref<string>()
  refGlobalTab = ref<KTabsViewModel>()
  refBomCompare = ref<HTMLElement>()
  /**
   * 左树根节点对象
   */
  leftTreeRootNodeParam = ref<IRootTreeNodeParam>()
  /**
   * 右树根节点对象
   */
  rightTreeRootNodeParam = ref<IRootTreeNodeParam>()
  /**
   * 比较结果
   */
  refCompareResult = ref<Array<KDataGridRowData>>([])
  /**
   * 比较规则是否应用到根
   */
  applyToRoot = ref<boolean>(false)
  /**
   * 展示可视化
   */
  isShowView = ref<boolean>(false)
  refLeftBrowser = ref<KModelBrowserViewModel>()
  leftDocObjParam = ref<ObjBusinessParam>()
  showLeftBrowser = ref<boolean>(false)
  showRightView = ref<boolean>(false)
  refRightBrowser = ref<KModelBrowserViewModel>()
  rightDocObjParam = ref<ObjBusinessParam>()
  showRightBrowser = ref<boolean>(false)
  leftHighlightNodes: Array<Record<string, any>> = []
  rightHighlightNodes: Array<Record<string, any>> = []

  /** 左侧树视图选择器 */
  refLeftViewSelector = ref<KObjectViewSelectorViewModel>()
  /** 右侧树视图选择器 */
  refRightViewSelector = ref<KObjectViewSelectorViewModel>()

  actualLeftTreeRootNode = ref<any>()

  leftViewDisabled = ref<boolean>(false)
  rightViewDisabled = ref<boolean>(false)
  leftLoading = ref<boolean>(false)
  rightLoading = ref<boolean>(false)

  constructor(options: ViewModelOptions<KPartBomComparePropType>) {
    super(options)
    if (
      options.props.leftPart &&
      !(Object.keys(options.props.leftPart).length === 0 && options.props.leftPart.constructor === Object)
    ) {
      const leftPart = options.props.leftPart!
      this.leftTreeRootNode.value = {
        id: leftPart.id,
        modelCode: leftPart.className,
        extensionType: leftPart.extensionType
      }
      this.actualLeftTreeRootNode.value = this.leftTreeRootNode.value
      this.refLeftView.value = leftPart.view
    }
    if (
      options.props.rightPart &&
      !(Object.keys(options.props.rightPart).length === 0 && options.props.rightPart.constructor === Object)
    ) {
      const rightPart = options.props.rightPart!
      this.rightTreeRootNode.value = { id: rightPart.id, modelCode: rightPart.className, extensionType: rightPart.extensionType }
      this.refRightView.value = rightPart.view
    }
    this.applyToRoot.value = options.props.applyToRoot
    watch(
      () => this.leftTreeRootNode.value,
      () => {
        this.refLeftFolderTree.value?.refresh()
      },
      {
        immediate: true
      }
    ),
      watch(
        () => this.rightTreeRootNode.value,
        () => {
          this.refLeftFolderTree.value?.refresh()
          this.refRightFolderTree.value?.refresh()
        },
        {
          immediate: true
        }
      ),
      watch(
        () => this.refCompareResult.value,
        () => {
          this.refLeftFolderTree.value?.refresh()
          this.refRightFolderTree.value?.refresh()
        },
        {
          immediate: true
        }
      )
  }

  viewDidMount() {
    AppContext.current
      .getIocContainer()
      .registerSingleton(
        EBF_IOC_KEY.SET_NODE_HIGH_LIGHT,
        { setSelectNode: this.setSelectNodes.bind(this) },
        { name: EBF_IOC_KEY.SET_NODE_HIGH_LIGHT.toString(), allowReplace: true }
      )
  }

  async setSelectNodes(event: any) {
    if (event.container == 'partBomCompareLeft') {
      const path = event.path as string[]
      if (path) {
        const node = this.refLeftFolderTree.value?.getRootNodes() as IKTreeNode[]
        const selectKey = await this.getNodeKey(path, node[0])
        this.refLeftFolderTree.value?.setSelectedNode(selectKey)
      }
    } else if (event.container == 'partBomCompareRight') {
      const path = event.path as string[]
      if (path) {
        const node = this.refRightFolderTree.value?.getRootNodes() as any
        const selectKey = await this.getNodeKey(path, node)
        this.refRightFolderTree.value?.setSelectedNode(selectKey)
      }
    }
  }

  async getNodeKey(path: string[], rootNode: IKTreeNode) {
    let node: Record<string, any> | undefined = {}
    const childPath = path
      .filter(item => item != 'root')
      .map(item => ({ docId: item.split('_')[0], findNumber: item.split('_')[1] }))
    const reqParam = {
      data: [childPath]
    }

    const result = await Api.post('part', 'PartDescribeLink', 'swap2PartPath', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      result.data.forEach((element: { findNumber: string; partId: any }) => {
        node = rootNode!.children?.find(
          (item: any) => item.userObject.part.id == element.partId && item.userObject.findNumber == element.findNumber
        )
      })
      return node.uniqueIdentifier
    } else {
      KNotification.warn({ message: '找不到对应的零部件' })
      return ''
    }
  }

  /**
   * 初始化可视化图形
   */
  async initLeftDocObjParam() {
    const rootNode = this.refLeftFolderTree.value?.getRootNodes()[0]
    if (!rootNode) return
    const partId = rootNode.userObject.part.id
    const result = await Api.post('part', 'Part', 'getDescribeDocumentByPart', {
      data: [{ clazz: 'Part', id: partId }]
    })
    if (result && result.code == EnumRequestCode.SUCCESS && result.data.primary) {
      this.leftDocObjParam.value = { id: result.data.id, modelCode: 'Document' }
      this.showLeftBrowser.value = true
    } else {
      this.showLeftBrowser.value = false
    }
  }
  async initRightDocObjParam() {
    const rootNode = this.refRightFolderTree.value?.getRootNodes()[0]
    if (!rootNode) return
    this.showRightView.value = true
    const partId = rootNode.userObject.part.id
    const result = await Api.post('part', 'Part', 'getDescribeDocumentByPart', {
      data: [{ clazz: 'Part', id: partId }]
    })
    if (result && result.code == EnumRequestCode.SUCCESS && result.data.primary) {
      this.rightDocObjParam.value = { id: result.data.id, modelCode: 'Document' }
      this.showRightBrowser.value = true
    } else {
      this.showRightBrowser.value = false
    }
  }
  /**
   * 左侧树加载数据
   */
  loadLeftTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise(resolve => {
      if (!this.leftTreeRootNode.value || this.leftTreeRootNode.value.id == '') {
        resolve([])
        return
      }
      let param = {
        data: [
          {
            root: {
              id: this.leftTreeRootNode.value.id,
              clazz: this.leftTreeRootNode.value.extensionType
            },
            expandType: 'FORWARD_EXPANSION',
            queryRule: {
              type: this.leftTreeExpandType.value
            },
            view: this.refLeftView.value,
            maxLevel: 99,
            ignoreInternalNode: this.ignoringBranchNodes.value,
            applyToRoot: this.applyToRoot.value
          }
        ]
      } as any
      if (this.comparisonMode.value == 'AGGREGATE_LIST') {
        param = {
          data: [
            {
              root: {
                id: this.leftTreeRootNode.value.id,
                clazz: this.leftTreeRootNode.value.extensionType
              },
              expandType: 'AGGREGATE_LIST',
              queryRule: {
                type: this.leftTreeExpandType.value
              },
              view: this.refLeftView.value,
              maxLevel: 99,
              ignoreInternalNode: this.ignoringBranchNodes.value,
              applyToRoot: this.applyToRoot.value
            }
          ]
        }
      }

      // 如果展开类型是基线则参数加上基线信息
      if (this.leftTreeExpandType.value == 'BASELINE') {
        param.data[0].queryRule.baseline = {
          id: this.leftBaselineId.value,
          clazz: 'ManagedBaseline'
        }
      }
      this.leftLoading.value = true
      Api.post('part', 'Part', 'expandForTree', param)
        .then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
            if (res.data.length > 0) {
              const rootNode = res.data.filter((data: any) => !data.pid)
              this.actualLeftTreeRootNode.value = rootNode[0].userObject.part
              if (rootNode) {
                const treeArray = this.buildTree(res.data, rootNode.id)
                resolve(treeArray)
                return
              }
            }
          } else {
            KNotification.error({
              title: '系统错误',
              content: res.message
            })
          }
          resolve([])
        })
        .finally(() => {
          this.leftLoading.value = false
        })
    })
  }
  /**
   * 右侧树加载数据
   */
  loadRightTreeData(): Promise<Array<IKTreeNode>> {
    return new Promise(resolve => {
      if (!this.rightTreeRootNode.value || this.rightTreeRootNode.value!.id == '') {
        resolve([])
        return
      }
      let param = {
        data: [
          {
            root: {
              id: this.rightTreeRootNode.value.id,
              clazz: this.rightTreeRootNode.value.extensionType
            },
            expandType: 'FORWARD_EXPANSION',
            queryRule: {
              type: this.rightTreeExpandType.value
            },
            view: this.refRightView.value,
            maxLevel: 99,
            ignoreInternalNode: this.ignoringBranchNodes.value
          }
        ]
      } as any
      if (this.comparisonMode.value == 'AGGREGATE_LIST') {
        param = {
          data: [
            {
              root: {
                id: this.rightTreeRootNode.value.id,
                clazz: this.rightTreeRootNode.value.extensionType
              },
              expandType: 'AGGREGATE_LIST',
              queryRule: {
                type: this.rightTreeExpandType.value
              },
              view: this.refRightView.value,
              maxLevel: 99,
              ignoreInternalNode: this.ignoringBranchNodes.value
            }
          ]
        }
      }
      // 如果展开类型是基线则参数加上基线信息
      if (this.rightTreeExpandType.value == 'BASELINE') {
        param.data[0].queryRule.baseline = {
          id: this.rightBaselineId.value,
          clazz: 'ManagedBaseline'
        }
      }
      this.rightLoading.value = true
      Api.post('part', 'Part', 'expandForTree', param)
        .then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
            if (res.data.length > 0) {
              const rootNode = res.data.filter((data: any) => data.userObject.part.id == this.rightTreeRootNode.value.id)
              if (rootNode) {
                const treeArray = this.buildTree(res.data, rootNode.id)
                resolve(treeArray)
                return
              }
            }
          } else {
            KNotification.error({
              title: '系统错误',
              content: res.message
            })
          }
          resolve([])
        })
        .finally(() => {
          this.rightLoading.value = false
        })
    })
  }

  /**
   * 构建树
   */
  buildTree(array: Array<IKTreeNode>, pid: string): Array<IKTreeNode> {
    const result = [] as Array<IKTreeNode>
    array.forEach(item => {
      if (item.pid == pid) {
        item.children = this.buildTree(array, item.id)
        result.push(item)
      }
    })
    return result
  }
  /**
   * 打开对象选择器搜索part
   */
  search() {
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      objectClassManageQueryParam: { showObjClsCodes: [EnumClassTemplate.PART], defaultSelectClsCode: 'Part' },
      isMultipleSelection: false,
      objectSearchQueryParam: {
        isMultipleSelection: false,
        showObjClsCodes: [EnumClassTemplate.PART],
        defaultSelectClsCode: 'Part'
      },
      getContainer: this.refBomCompare.value,
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }

        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }

        const objParams = event.viewInstance.getSelectedObjParams()
        const selectedRows = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const objParam = objParams[0]
        this.rightTreeRootNode.value = {
          id: objParam.id,
          modelCode: objParam.modelCode,
          extensionType: objParam.extensionType
        }
        this.refRightView.value = selectedRows[0].partView.name
        this.isShowView.value = false
        this.showRightBrowser.value = false
      }
    })
  }

  /**
   * 关闭右侧bom树
   */
  close() {
    this.rightTreeRootNode.value = null
    this.compareResultSelectedId.value = ''
    this.comparisonMode.value = undefined
    this.comparisonModeName.value = ''
    this.refCompareResult.value = []
    this.showRightView.value = false
    this.refLeftFolderTree.value?.refresh()
  }
  /**
   * 选择右侧bom树数据源
   */
  selectBom() {
    KDialog.show({
      title: '同版本组对象列表',
      content: KVersionGroupObjectSelectForm,
      props: {
        partId: this.actualLeftTreeRootNode.value.id
      },
      size: { width: 1000, height: 760 },
      getContainer: this.refBomCompare.value,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as KVersionGroupObjectSelectFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const selectRow = formViewModel.getSelectRow()
        if (!selectRow || selectRow.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '请选中一条数据'
          })
          event.cancel = true
          return
        }
        this.rightTreeRootNode.value = {
          id: selectRow[0].id,
          modelCode: selectRow[0].className,
          extensionType: selectRow[0].rdmExtensionType
        }
        this.rightTreeExpandType.value = 'LATEST_WITHOUT_WORKING_COPY'
        this.refRightView.value = selectRow[0].partView.name
        this.isShowView.value = false
        this.showRightBrowser.value = false
      }
    })
  }
  /**
   * 比较bom树
   */
  compareBom() {
    KDialog.show({
      title: '比较',
      content: KPartBomCompareModeForm,
      props: {
        partId: this.rightTreeRootNode.value!.id
      },
      size: { width: 500, height: 320 },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as KPartBomCompareModeFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        this.isShowView.value = false
        const selectRow = formViewModel.getFormValue()

        this.expandDifferentialParts.value = selectRow?.expandDifferentialParts ? selectRow?.expandDifferentialParts : false
        this.ignoringBranchNodes.value = selectRow?.ignoringBranchNodes ? selectRow?.ignoringBranchNodes : false

        this.comparisonMode.value = selectRow?.comparisonMode
        this.comparisonModeName.value = selectRow?.comparisonModeName!
        this.leftTreeRootNodeParam.value = {
          root: { id: this.leftTreeRootNode.value.id, modelCode: this.leftTreeRootNode.value.extensionType },
          queryRule: {
            type: this.leftTreeExpandType.value,
            baseline:
              this.leftTreeExpandType.value == 'BASELINE'
                ? { id: this.leftBaselineId.value!, modelCode: 'ManagedBaseline' }
                : undefined
          },
          applyToRoot: this.applyToRoot.value,
          view: this.refLeftView.value
        }

        this.rightTreeRootNodeParam.value = {
          root: { id: this.rightTreeRootNode.value.id, modelCode: this.rightTreeRootNode.value.extensionType },
          queryRule: {
            type: this.rightTreeExpandType.value,
            baseline:
              this.rightTreeExpandType.value == 'BASELINE'
                ? { id: this.rightBaselineId.value!, modelCode: 'ManagedBaseline' }
                : undefined
          },
          view: this.refRightView.value
        }

        const activeMenuKey = this.refGlobalTab.value?.getActiveKey()
        if (selectRow?.openNewTabShowResult || !activeMenuKey || activeMenuKey == '') {
          this.openPage()
          return
        }
        const result = this.refGlobalTab.value?.existsTab(activeMenuKey)
        if (result) {
          const vm = this.getByRecursion(activeMenuKey) as KBomCompareResultPanelViewModel
          vm.reload(
            this.leftTreeRootNodeParam.value,
            this.rightTreeRootNodeParam.value,
            this.comparisonMode.value!,
            this.expandDifferentialParts.value,
            this.ignoringBranchNodes.value
          )
        } else {
          this.openPage()
          return
        }
      }
    })
  }

  /**
   * 可视化
   */
  showView() {
    this.initLeftDocObjParam()
    this.initRightDocObjParam()
    this.isShowView.value = true
  }

  leftAfterSelect(event: any) {
    if (this.isShowView.value) {
      this.setLeftHighlightNodes(event.node)
      this.setLeftHighlighted()
    }
  }
  rightAfterSelect(event: any) {
    if (this.isShowView.value) {
      this.setRightHighlightNodes(event.node)
      this.setRightHighlighted()
    }
  }

  setLeftHighlightNodes(nodes: IKTreeNode): void {
    // 清除所有高亮节点
    if (this.leftHighlightNodes.length > 0) {
      this.leftHighlightNodes.forEach(node => (node.type = ''))
    }

    if (!nodes) {
      return
    }

    const data = {
      key: nodes.key!,
      type: 'leftUnique'
    }

    // 标记高亮
    this.refLeftFolderTree.value?.modifyNode([...this.leftHighlightNodes, data])
    this.leftHighlightNodes = [data]
  }
  setRightHighlightNodes(nodes: IKTreeNode): void {
    // 清除所有高亮节点
    if (this.rightHighlightNodes.length > 0) {
      this.rightHighlightNodes.forEach(node => (node.type = ''))
    }

    if (!nodes) {
      return
    }

    const data = {
      key: nodes.key!,
      type: 'leftUnique'
    }

    // 标记高亮
    this.refRightFolderTree.value?.modifyNode([...this.rightHighlightNodes, data])
    this.rightHighlightNodes = [data]
  }

  getTreeNodePath(node: IKTreeNode) {
    const partIds = [] as { partId: string; findNumber: string }[]
    const partId = node!.userObject.part.id
    const findNumber = node!.userObject.findNumber ? node.userObject.findNumber : '1'
    partIds.push({ partId: partId, findNumber: findNumber })
    if (node.__parent) {
      const ids = this.getTreeNodePath(node.__parent)
      partIds.unshift(...ids)
    }
    return partIds
  }

  /**
   * 显示高亮
   */
  async setLeftHighlighted() {
    const selectedNode = this.refLeftFolderTree.value?.getSelectedNode()
    if (selectedNode) {
      const partIds = this.getTreeNodePath(selectedNode)
      // const partObjParams = partIds.map(id => ({ partId: id }))
      const result = await Api.post('part', 'PartDescribeLink', 'swap2DocPath', { data: [partIds] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        const data = result.data as Record<string, any>[]
        this.refLeftBrowser.value?.setNodesHighlight(data.map(refObj => refObj.docId).join(','))
      } else {
        KNotification.warning({ title: '提示', content: `找不到描述文档` })
        return
      }
    }
  }
  async setRightHighlighted() {
    const selectedNode = this.refRightFolderTree.value?.getSelectedNode()
    if (selectedNode) {
      const partIds = this.getTreeNodePath(selectedNode)
      // const partObjParams = partIds.map(id => ({ partId: id }))
      const result = await Api.post('part', 'PartDescribeLink', 'swap2DocPath', { data: [partIds] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        const data = result.data as Record<string, any>[]
        this.refRightBrowser.value?.setNodesHighlight(data.map(refObj => refObj.docId).join(','))
      } else {
        KNotification.warning({ title: '提示', content: `找不到描述文档` })
        return
      }
    }
  }

  /**
   *
   * @param rows 获取比较结果
   */
  getCompareResult(rows: Array<KDataGridRowData>) {
    this.refCompareResult.value = rows
  }

  changeTreeNodeColor() {
    const rows = this.refCompareResult.value
    if (rows && rows.length > 0 && this.rightTreeRootNode.value) {
      // 改变节点颜色
      rows.forEach(row => {
        switch (row.desc) {
          case '同等件':
            {
              // 根据路径获取节点信息
              const leftNode = this.refLeftFolderTree.value?.getNode(row.masterIdFullPath)

              const rightNode = this.refRightFolderTree.value?.getNode(row.masterIdFullPath)
              // 设置节点类型
              if (leftNode && rightNode && (row.leftVersion != row.rightVersion || row.leftQuantity != row.rightQuantity)) {
                leftNode.type = 'difference'
                rightNode.type = 'difference'
              }
            }
            break
          case '左树独有':
            {
              // 根据路径获取节点信息
              const leftNode = this.refLeftFolderTree.value?.getNode(row.masterIdFullPath)
              // 设置节点类型
              if (leftNode) {
                leftNode.type = 'leftUnique'
              }
            }
            break
          case '右树独有':
            {
              // 根据路径获取节点信息
              const rightNode = this.refRightFolderTree.value?.getNode(row.masterIdFullPath)
              // 设置节点类型
              if (rightNode) {
                rightNode.type = 'rightUnique'
              }
            }
            break
          default:
            break
        }
      })
    }
  }

  /**
   * 设置右侧树选中
   */
  setRightNodeSelected(event: TreeViewExpandEventArgs<any>) {
    if (!this.rightTreeRootNode.value || this.rightTreeRootNode.value.id == '') {
      return
    }
    this.compareResultSelectedId.value = event.node.uniqueIdentifier
  }

  /**
   * 右侧树选中事件
   */
  setLeftNodeSelected(event: TreeViewExpandEventArgs<any>) {
    if (!this.refLeftFolderTree.value || this.leftTreeRootNode.value.id == '') {
      return
    }
    this.compareResultSelectedId.value = event.node.uniqueIdentifier
  }

  /**
   * 设置树选中
   */
  setSelectTreeNode(rows: Array<KDataGridRowData>) {
    if (rows.length > 0) {
      const leftNode = this.refLeftFolderTree.value?.getNode(rows[0].masterIdFullPath)
      const rightNode = this.refRightFolderTree.value?.getNode(rows[0].masterIdFullPath)
      /**清除选中 */

      if (leftNode) {
        this.refLeftFolderTree.value?.expandTo(leftNode.uniqueIdentifier)
        this.refLeftFolderTree.value?.setSelectedNode(leftNode.uniqueIdentifier)
      } else {
        this.refLeftFolderTree.value?.clearSelected()
      }
      if (rightNode) {
        this.refRightFolderTree.value?.expandTo(rightNode.uniqueIdentifier)
        this.refRightFolderTree.value?.setSelectedNode(rightNode.uniqueIdentifier)
      } else {
        this.refRightFolderTree.value?.clearSelected()
      }
    }
  }
  /**
   * 刷新左侧树
   */
  refreshLeftTree(args: ValueChangeEventArgs<any>) {
    if (args.newValue == null) {
      return
    }
    this.leftViewDisabled.value = args.newValue == 'BASELINE'
    // 如果选择基线，则打开基线选择弹框
    if (args.newValue == 'BASELINE') {
      //todo 打开基线选择弹框
      KDialog.show({
        title: '选择基线',
        size: { width: 800, height: 600 },
        props: {
          primaryObjectId: this.leftTreeRootNode.value.id
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KBaselineGrid,
        getContainer: this.refBomCompare.value,
        onClosing: async (event: KDialogClosingEvent) => {
          const formViewModel = event.viewModel as KBaselineGridViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          const selectRow = formViewModel.getSelectRow()
          if (!selectRow) {
            KNotification.warning({
              title: '系统提示',
              content: '请选择一个基线'
            })
            event.cancel = true
            return
          }
          this.leftBaselineId.value = selectRow.id
          this.refLeftFolderTree.value?.refresh()
        }
      })
      return
    }
    this.refLeftFolderTree.value?.refresh()
  }
  /**
   * 视图选择后刷新左侧树
   */
  refreshLeftTreeAfterView(args: ValueChangeEventArgs<any>) {
    if (args.newValue == null) {
      return
    }
    if (!this.leftTreeRootNode.value) {
      return
    }
    this.refLeftView.value = args.newValue
    this.refLeftFolderTree.value?.refresh()
  }

  /**
   * 视图选择后刷新右侧树
   */
  refreshRightTreeAfterView(args: ValueChangeEventArgs<any>) {
    if (args.newValue == null) {
      return
    }
    if (!this.rightTreeRootNode.value) {
      return
    }
    this.refRightView.value = args.newValue
    this.refRightFolderTree.value?.refresh()
  }
  /**
   * 刷新右侧树
   */
  refreshRightTree(args: ValueChangeEventArgs<any>) {
    if (args.newValue == null) {
      return
    }
    this.rightViewDisabled.value = args.newValue == 'BASELINE'
    if (!this.rightTreeRootNode.value) {
      return
    }
    // 如果选择基线，则打开基线选择弹框
    if (args.newValue == 'BASELINE') {
      //todo 打开基线选择弹框
      KDialog.show({
        title: '选择基线',
        size: { width: 800, height: 600 },
        props: {
          primaryObjectId: this.rightTreeRootNode.value.id
        },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KBaselineGrid,
        getContainer: this.refBomCompare.value,
        onClosing: async (event: KDialogClosingEvent) => {
          const formViewModel = event.viewModel as KBaselineGridViewModel
          if (event.dialogResult == EnumDialogResult.Cancel) return
          if (event.dialogResult == EnumDialogResult.Close) return
          const selectRow = formViewModel.getSelectRow()
          if (!selectRow) {
            KNotification.warning({
              title: '系统提示',
              content: '请选择一个基线'
            })
            event.cancel = true
            return
          }
          this.rightBaselineId.value = selectRow.id
          this.refRightFolderTree.value?.refresh()
        }
      })
      return
    }
    this.refRightFolderTree.value?.refresh()
  }

  openPage() {
    if (!this.refGlobalTab.value) return
    const key = utils.uuid()

    if (!this.refGlobalTab.value?.existsTab(key)) {
      let title = this.comparisonModeName.value
      if (this.comparisonMode.value == 'MULTI') {
        const expandDifferentialParts = this.expandDifferentialParts.value ? '差异件展开' : '差异件不展开'
        title = title + '-' + expandDifferentialParts
      }
      if (this.comparisonMode.value == 'AGGREGATE_LIST') {
        const ignoringBranchNodes = this.ignoringBranchNodes.value ? '忽略分支节点' : '不忽略分支节点'
        title = title + '-' + ignoringBranchNodes
      }
      this.refGlobalTab.value?.addTab(key, title, KBomCompareResultPanel, {
        ref: 'refBomResult',
        name: key,
        source: this.leftTreeRootNodeParam.value,
        target: this.rightTreeRootNodeParam.value,
        comparisonMode: this.comparisonMode.value,
        expandDifferentialParts: this.expandDifferentialParts.value,
        ignoringBranchNodes: this.ignoringBranchNodes.value,
        onLoadReady: (rows: Array<KDataGridRowData>) => this.getCompareResult(rows)
      })
    }
    this.refGlobalTab.value?.active(key)
  }
}
