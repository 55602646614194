import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes,
  SimpleViewPropOptions,
  SimpleViewEventEmits
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPersonalCreateChangeRequestGridPropOptions = {
  ...SimpleViewPropOptions,
  /** 是否是变更后对象 **/
  isAfterObject: VuePropTypes.bool().def(false),
  /** 过滤状态 **/
  filterLifecycleState: VuePropTypes.string().def('Released')
}

/** 参数类型 **/
export type KPersonalCreateChangeRequestGridPropType = ViewPropsTypeExtract<typeof KPersonalCreateChangeRequestGridPropOptions>

/** 事件 */
export const KPersonalCreateChangeRequestGridEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KPersonalCreateChangeRequestGridEmitsType = ViewEmitsTypeExtract<typeof KPersonalCreateChangeRequestGridEventEmits>
