import { BaseViewModel, KDataGridModelUpdatedEvent, ViewModelOptions } from '@kmsoft/upf-core'
import { KCadRelationDocEmitsType, KCadRelationDocPropType, EnumWorkState } from './interface'
import { Api, EnumRequestCode, KObjectClassGridViewModel, EnumWorkingState, EnumLifecycleState } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KCadRelationDoc */
export default class KCadRelationDocViewModel extends BaseViewModel<KCadRelationDocEmitsType, KCadRelationDocPropType> {
  refObjGrid = ref<KObjectClassGridViewModel>()

  /**
   * 外部选择的零部件对象ID和类型
   */
  partId = ref<string>('')
  rdmExtensionType = ref<string>()

  /** 网格数据 */
  gridData = ref<any[]>([])

  constructor(options: ViewModelOptions<KCadRelationDocPropType>) {
    super(options)
  }

  // [key: string]: any; // 添加索引签名

  viewDidMount() {}

  async loadData(qry: any) {
    const data = this.props.parts.map(item => {
      return {
        id: item.targetId
      }
    })
    const param = {
      data: [data]
    }

    const result = await Api.post('part', 'PartDescribeLink', 'listDocumentByParts', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const docs = result.data.filter(
        (data: any) => data.docViewDTO.lifecycleStateCode == 'InWork' && data.docViewDTO.workingState == EnumWorkState.CHECKED_IN
      )
      return docs
    }
  }

  getSelectedRows() {
    return this.refObjGrid.value?.getSelectedRows() || []
  }
}
