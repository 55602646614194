import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","margin":"10px 0"} }
const _hoisted_2 = { style: {"display":"flex","position":"relative","top":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_doc_structure_tree = _resolveComponent("k-doc-structure-tree")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_checkbox = _resolveComponent("k-checkbox")!
  const _component_k_dialog_content = _resolveComponent("k-dialog-content")!

  return (_openBlock(), _createBlock(_component_k_dialog_content, { class: "k-object-create-form" }, {
    default: _withCtx(() => [
      _createVNode(_component_k_doc_structure_tree, {
        ref: "refTreeView",
        isMultipleSelection: _ctx.$vm.isMultipleSelection,
        disabledContextMenu: "",
        objParams: { modelCode: _ctx.$props.doc?.modelCode, id: _ctx.$props.doc?.id },
        disablePredicate: (node)=>_ctx.$vm.disablePredicate(node),
        onReady: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.treeReady()))
      }, null, 8, ["isMultipleSelection", "objParams", "disablePredicate"]),
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_input, {
          value: _ctx.$vm.selectedFilePath,
          disabled: ""
        }, {
          addonBefore: _withCtx(() => [
            _createTextVNode(" 路径： ")
          ]),
          _: 1
        }, 8, ["value"]),
        _createVNode(_component_k_button, {
          onClick: _ctx.$vm.selectPath,
          disabled: _ctx.$vm.isDisabled
        }, {
          default: _withCtx(() => [
            _createTextVNode("浏览")
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ], 512), [
        [_vShow, _ctx.$vm.isShowPath]
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_k_checkbox, {
            checked: _ctx.$vm.isCheckedFileProperty,
            "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.isCheckedFileProperty) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode("提取属性")
            ]),
            _: 1
          }, 8, ["checked"])
        ], 512), [
          [_vShow, _ctx.$vm.isShowProperty]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_k_checkbox, {
            checked: _ctx.$vm.isCreatePart,
            "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.isCreatePart) = $event)),
            "&&": "",
            disabled: !_ctx.$vm.isCheckedFileProperty
          }, {
            default: _withCtx(() => [
              _createTextVNode("提取零部件结构")
            ]),
            _: 1
          }, 8, ["checked", "disabled"])
        ], 512), [
          [_vShow, _ctx.$vm.isShowCreatePart]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(_component_k_checkbox, {
            checked: _ctx.$vm.isDeleteLocalFile,
            "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.isDeleteLocalFile) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode("是否删除本地文件")
            ]),
            _: 1
          }, 8, ["checked"])
        ], 512), [
          [_vShow, _ctx.$vm.isShowDeleteLocalFile]
        ])
      ])
    ]),
    _: 1
  }))
}