import { EnumAttributeType, EnumDataType } from '@kmsoft/upf-core'

/* 属性配置项集合 */
const PROPS = [
  {
    name: 'rows',
    title: '输入框行跨度',
    control: 'KInputNumber',
    defaultValue: '',
    visible: true,
    dataType: EnumDataType.ARRAY,
    attributeType: EnumAttributeType.PROPS
  }
] as Array<any>

const EVENT = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'valueChanged', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { PROPS, EVENT }
