import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeActivityRelationshipEmitsType, KChangeActivityRelationshipPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode, KObjectClassGridViewModel } from '@kmsoft/ebf-common'

/** KChangeActivityRelationship */
export default class KChangeActivityRelationshipViewModel extends BaseViewModel<
  KChangeActivityRelationshipEmitsType,
  KChangeActivityRelationshipPropType
> {
  refChangeOrderGrid = ref<KObjectClassGridViewModel>()
  activeKey = ref<Array<string>>(['1'])

  /**
   * 变更单
   */
  plmLinkSources = ref<Array<Record<string, any>>>([])
  constructor(options: ViewModelOptions<KChangeActivityRelationshipPropType>) {
    super(options)
  }

  viewDidMount() {
    this.getRelationship()
  }

  titleClick(key: string) {
    if (!this.activeKey.value.includes(key)) {
      this.activeKey.value.push(key)
    }
  }

  /**
   * 查询关联关系
   */
  async getRelationship() {
    const reqParam = {
      data: [this.props.objParam?.id]
    }
    const result = await Api.post('change', 'ChangeActivity', 'getLinks', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.plmLinkSources.value = result.data?.plmLinkSources || []

      this.plmLinkSources.value.forEach((item: any) => {
        this.refChangeOrderGrid.value?.insertRow(item)
      })
      return
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  loadChangeOrderData() {
    return this.plmLinkSources.value
  }
}
