import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KObjectAddWorkflowPropOptions = {
  ...BaseViewPropOptions,
  /**对象参数 */
  objParams: VuePropTypes.createType<Array<objParam>>().def([])
}

/** 参数类型 **/
export type KObjectAddWorkflowPropType = ViewPropsTypeExtract<typeof KObjectAddWorkflowPropOptions>

/** 事件 */
export const KObjectAddWorkflowEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectAddWorkflowEmitsType = ViewEmitsTypeExtract<typeof KObjectAddWorkflowEventEmits>

/**
 * 对象参数
 */
export interface objParam {
  /**
   * 对象id
   */
  objID: string
  /**
   * 对象类
   */
  objClsCode: string
  /**
   * 名称
   */
  name: string
  /**
   * 编码
   */
  number: string
  /**
   * 版本
   */
  version: string
  /**
   * 生命周期状态
   */
  status: string
  /**
   * 视图id
   */
  targetViewId?: string
}

/**
 * 工作流
 */
export interface IWorkflow {
  /**
   * 流程模板Id
   */
  templateId: string
  /**
   * 流程模板key
   */
  templateKey?: string
  /**
   * 流程模板版本
   */
  templateVersion: string
  /**
   * 流程名称
   */
  name: string
  /**
   * 流程编码
   */
  code: string
  /**
   * 描述
   */
  describe?: string
}

/**
 * 执行人
 */
export interface IExecutor {
  /**
   * 执行力占位符
   */
  assignVariable: string
  /**
   * 流程活动名称
   */
  name: string
  /**
   * 执行人Id
   */
  executorIds?: Array<string>
  /**
   * 执行人名称
   */
  executorName?: string
}
