import { Api, EnumRequestCode, EnumPurviewCode } from '@kmsoft/ebf-common'
import {
  BaseViewEventEmits,
  BaseViewPropOptions,
  EnumToolStripCompType,
  KTreeNode,
  ToolStripButtonItem,
  ToolStripItem,
  TreeViewLoadDataFunc,
  TreeViewSelectEventArgs,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { UserClientSrv } from '../../../client-srv'
/** 参数 **/
export const KFolderTreePropOptions = {
  ...BaseViewPropOptions,
  folderId: VuePropTypes.string().def(),
  /** 展开节点时加载子节点的数据库请求方法，返回promise **/
  loadData: VuePropTypes.func<TreeViewLoadDataFunc>().def()
}

/** 参数类型 **/
export type KFolderTreePropType = ViewPropsTypeExtract<typeof KFolderTreePropOptions>

/** 事件 */
export const KFolderTreeEventEmits = {
  ...BaseViewEventEmits,
  afterSelect: (event: TreeViewSelectEventArgs<any>) => true
}

/** 事件类型 **/
export type KFolderTreeEmitsType = ViewEmitsTypeExtract<typeof KFolderTreeEventEmits>

/**
 * 普通文件夹目录树节点定义
 */
export class FolderTreeNode extends KTreeNode {
  /**
   * 编码
   */
  nameEn: string
  /**
   * 图标
   */
  iconType: string
  /**
   * 备注
   */
  description: string
  /**
   * 创建人id
   */
  creator: string

  /***
   * 创建时间
   */
  createTime: string

  /**
   * 类型
   */
  type: string

  /**
   * 父id
   */
  parentId: string
  /**
   * 根节点类型
   */
  rootNodeType?: string

  constructor(data: IFolder) {
    super()
    this.id = data.id
    this.nameEn = data.nameEn
    this.name = data.name
    this.leaf = data.leafFlag
    this.iconType = 'folder'
    this.description = data.description
    this.createTime = data.createTime
    this.creator = data.creator
    this.type = data.type
    this.parentId = data.parentId
    this.rootNodeType = data.rootNodeType
  }
  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    const newChild = [] as Array<KTreeNode>
    if (this.leaf) {
      return newChild
    }
    const param = {
      data: [
        this.id,
        {
          rdmPageVO: {
            maxPageSize: 1000,
            curPage: 1,
            pageSize: 1000,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          }
        }
      ]
    }
    const result = await Api.post('folder', 'Folder', 'listChildFolder', param)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      for (const item of result.data!) {
        item.rootNodeType = this.rootNodeType
        const childNode = new FolderTreeNode(item as any)
        newChild.push(childNode)
      }
    }
    return newChild
  }
  /**
   * 获取右键菜单
   * @returns 菜单列表
   */
  getContextMenus(): Array<ToolStripItem> {
    const workSpaceRootToolStripItem = [
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.NEW,
        /** 文字 */
        title: '新建',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.CreateFolder,
        icon: 'folder-add',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.DELETE,
        /** 文字 */
        title: '删除',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.DeleteFolder,
        icon: 'delete',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.EDIT,
        /** 文字 */
        title: '属性',
        /** 是否可见  */
        visible: true,
        purviewId: EnumPurviewCode.ModifyFolder,
        icon: 'compute-prop',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.COPY,
        /** 文字 */
        title: '复制',
        /** 是否可见  */
        visible: true,
        icon: 'copy',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.CUT,
        /** 文字 */
        title: '剪切',
        /** 是否可见  */
        visible: true,
        icon: 'scissor',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.PASTE,
        /** 文字 */
        title: '粘贴',
        /** 是否可见  */
        visible: true,
        icon: 'diff',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem,
      {
        /** 工具栏菜单项id */
        name: EnumToolStripItemKeys.REFRESH,
        /** 文字 */
        title: '刷新',
        /** 是否可见  */
        visible: true,
        icon: 'loading-3-quarters',
        compType: EnumToolStripCompType.BUTTON
      } as ToolStripButtonItem
    ] as Array<ToolStripItem & { purviewId?: string }>
    return workSpaceRootToolStripItem.filter(item => !item.purviewId || UserClientSrv.verifyPurview(item.purviewId))
  }
}

export enum EnumToolStripItemKeys {
  /** 查询 */
  FIND = 'find',
  /** 新建 */
  NEW = 'new',
  /** 编辑 */
  EDIT = 'edit',
  /** 收藏 */
  COLLECTION = 'collection',
  /** 新窗口中打开 */
  OPEN_NEW_TAB = 'openNewTab',
  /** 删除 */
  DELETE = 'delete',
  /** 重命名 */
  RENAME = 'rename',
  /** 复制 */
  COPY = 'copy',
  /** 剪切 */
  CUT = 'cut',
  /** 粘贴 */
  PASTE = 'paste',
  /** 排序 */
  SORT = 'sort',
  /** 分工 */
  DIVIDE_WORK = 'divideWork',
  /** 共享 */
  SHARE = 'share',
  /** 刷新 */
  REFRESH = 'refresh',
  /** 取消收藏 */
  CANCEL_COLLECTION = 'cancel_collection',
  /** 取消共享 */
  CANCEL_SHARE = 'cancel_share',
  /** 导入 */
  IMPORT = 'import',
  /** 导出 */
  EXPORT = 'export',
  /** 预览 */
  LOOK = 'look'
}

/**
 * 属性目录定义
 */
export interface IFolder {
  /**
   * 目录Id
   */
  id: string
  /**
   * 父目录Id
   */
  parentId: string
  /**
   * 目录名称
   */
  name: string
  /**
   * 编码
   */
  nameEn: string
  /**
   * 类型
   */
  type: string
  /**
   * 根节点类型
   */
  rootNodeType?: string
  /**
   * 描述
   */
  description: string

  /**
   * 创建时间
   */
  createTime: string

  /**
   * 创建人id
   */
  creator: string

  /**
   * 创建人名称
   */
  creatorName: string
  /**
   * 是否叶子节点
   */
  leafFlag: boolean
  /**
   * 图标
   */
  iconType?: string
  disabled?: boolean
}

export enum CopyOrCutEnum {
  /**
   * 没有发生复制剪切的操作
   */
  NOTHING = 0,
  /**
   * 在剪切中
   */
  IN_CUT = 1,
  /**
   * 在粘贴中
   */
  IN_COPY = 2
}

/**
 * 查询数据返回类型
 */
export interface IQueryData {
  /**
   * 目录Id
   */
  directoryId: string
  /**
   * 目录路径
   */
  directoryPath: string
}
