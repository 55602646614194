import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { KPartCreatePromoteEmitsType, KPartCreatePromotePropType } from './interface'
import { ref } from 'vue'

/** KPartCreatePromote */
export default class KPartCreatePromoteViewModel extends BaseViewModel<KPartCreatePromoteEmitsType, KPartCreatePromotePropType> {
  currentPhase = ref<string>('Prototype')
  targetingPhase = ref<string>('Engineering')
  currentDisabled = ref<boolean>(false)
  disabled = ref<boolean>(false)
  constructor(options: ViewModelOptions<KPartCreatePromotePropType>) {
    super(options)
  }

  viewDidMount() {
    this.getPartData()
  }

  async getPartData() {
    if (this.props.phase && (this.props.phase == 'Prototype' || this.props.phase == 'Engineering')) {
      this.currentDisabled.value = true
      this.currentPhase.value = this.props.phase
      this.change({ newValue: this.props.phase })
    }
  }

  change(event: Record<string, string>) {
    if (event.newValue == 'Engineering') {
      this.targetingPhase.value = 'Manufacturing'
      this.disabled.value = true
    } else {
      this.targetingPhase.value = 'Engineering'
      this.disabled.value = false
    }
  }

  getValue() {
    return {
      currentPhase: this.currentPhase.value,
      targetingPhase: this.targetingPhase.value
    }
  }
}
