import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { objParam } from '../interface'

/** 参数 **/
export const KCollectRelatedObjectPropOptions = {
  ...BaseViewPropOptions,
  /**对象参数 */
  objParams: VuePropTypes.createType<Array<objParam>>().def([]),
  /** 流程模板key */
  templateKey: VuePropTypes.string()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KCollectRelatedObjectPropType = ViewPropsTypeExtract<typeof KCollectRelatedObjectPropOptions>

/** 事件 */
export const KCollectRelatedObjectEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KCollectRelatedObjectEmitsType = ViewEmitsTypeExtract<typeof KCollectRelatedObjectEventEmits>
