import { BaseViewModel, KDataGridRowData, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { EnumAssemblyMode, EnumPhase, EnumSource, KPartMaterialFormEmitsType, KPartMaterialFormPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import label from '@kmsoft/upf-core/src/web-controls/label'
import { PartStructureTreeNode } from '../part-structure-tree'

/** KPartMaterialForm */
export default class KPartMaterialFormViewModel extends BaseViewModel<KPartMaterialFormEmitsType, KPartMaterialFormPropType> {
  /**属性 */
  refMaterialProp = ref('source')
  /**属性值候选项 */
  refMaterialPropValueOptions = ref([
    { label: '自制', value: 'Make' },
    { label: '采购', value: 'Buy' },
    { label: '购买-单一供应源', value: 'Singlesource' }
  ])
  /**属性值 */
  refMaterialPropValue = ref('Make')
  /**属性和值选项映射 */
  propAndValuesMap = {
    source: EnumSource,
    assemblyMode: EnumAssemblyMode,
    phase: EnumPhase
  }

  constructor(options: ViewModelOptions<KPartMaterialFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 属性改变
   */
  materialPropChange() {
    const currentProp = this.refMaterialProp.value
    let enumType
    if (currentProp == 'source') {
      enumType = EnumSource
    } else if (currentProp == 'assemblyMode') {
      enumType = EnumAssemblyMode
    } else if (currentProp == 'phase') {
      enumType = EnumPhase
    } else {
      this.refMaterialPropValue.value = ''
      return
    }
    const options: { label: string; value: any }[] = []
    Object.entries(enumType).forEach(([key, value]) => {
      options.push({ label: value, value: key })
    })
    this.refMaterialPropValueOptions.value = options
    this.refMaterialPropValue.value = options[0].value
  }

  /**
   * 更新物料属性
   * @param 零部件id
   */
  async batchUpdateMateriaProp(checkedNodes: Array<Record<string, any>>) {
    if (!checkedNodes || checkedNodes.length <= 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择零部件'
      })
      return true
    }
    const prop = this.refMaterialProp.value
    const value = this.refMaterialPropValue.value
    const ids: any[] = []
    const masterIds: any[] = []
    checkedNodes.forEach(checkedNode => {
      ids.push(checkedNode.partObjParam.id)
      masterIds.push(checkedNode.partObjParam.masterId)
    })
    const param = {
      ids: [...new Set(ids)],
      masterIds: [...new Set(masterIds)],
      prop: {
        [prop]: value
      },
      partViewName: checkedNodes[0].view
    }

    const result = await Api.post('part', 'Part', 'batchUpdateMaterialProp', { data: [param] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success('操作成功！')
    } else {
      KNotification.error({
        title: '系统错误',
        content: result.message
      })
    }
  }
}
