import { toDisplayString as _toDisplayString, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17ce041b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "global-sideNav-title" }
const _hoisted_2 = { class: "collapse-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_navigation_bar = _resolveComponent("k-navigation-bar")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_layout_sider = _resolveComponent("k-layout-sider")!

  return (_ctx.showSiderLayout)
    ? (_openBlock(), _createBlock(_component_k_layout_sider, {
        key: 0,
        class: "global-sideNav",
        width: "200px",
        collapsedWidth: 56,
        collapsible: true,
        collapsed: _ctx.collapsed,
        trigger: null
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _withDirectives(_createElementVNode("div", {
              class: "global-sideNav-title-text",
              style: _normalizeStyle({ fontSize: _ctx.globalStyleObj?.fontSizeLG + 'px' })
            }, _toDisplayString(_ctx.appName), 5), [
              [_vShow, !_ctx.collapsed]
            ])
          ]),
          _createVNode(_component_k_navigation_bar, {
            class: "global-navigation-bar",
            collapsed: _ctx.collapsed,
            menus: _ctx.menusRef,
            activeKey: _ctx.menuActiveKey,
            theme: _ctx.theme,
            mode: "inline"
          }, null, 8, ["collapsed", "menus", "activeKey", "theme"]),
          _createElementVNode("div", _hoisted_2, [
            (_ctx.collapsed)
              ? (_openBlock(), _createBlock(_component_k_icon, {
                  key: 0,
                  type: "menu-unfold",
                  color: _ctx.collapseBtnColor,
                  width: 20,
                  height: 20,
                  onClick: _ctx.onExpand
                }, null, 8, ["color", "onClick"]))
              : (_openBlock(), _createBlock(_component_k_icon, {
                  key: 1,
                  type: "menu-fold",
                  color: _ctx.collapseBtnColor,
                  width: 20,
                  height: 20,
                  onClick: _ctx.onCollapse
                }, null, 8, ["color", "onClick"]))
          ])
        ]),
        _: 1
      }, 8, ["collapsed"]))
    : _createCommentVNode("", true)
}