import { BaseViewModel, ViewModelOptions, request, SimpleViewModel, MemoryCacheFactory } from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { KAutoCompleteSelectorOption, KAutoCompleteSelectorViewModel } from '../../auto-complete-selector'
import { KUserSelectorEmitsType, KUserSelectorPropType } from './interface'
import { Api, ConfigClientSrv, UserInfoCache } from '../../../../client-srv'
/** 用户选择器 */
export default class KUserSelectorViewModel extends SimpleViewModel<KUserSelectorEmitsType, KUserSelectorPropType> {
  /** 选中值（实际值）*/
  selectedValue = ref<string | Array<string>>('')
  /**原始值 */
  originalValue = ref<string | Array<string>>()
  /** 用户列表 **/
  userList = ref<any[]>([])

  constructor(options: ViewModelOptions<KUserSelectorPropType>) {
    super(options)

    watch(
      () => options.props.value,
      newValue => {
        this.setValue(newValue)
      },
      {
        immediate: true
      }
    )
  }

  /**
   * 挂在完成
   */
  viewDidMount() {
    this.getUserData()
  }

  filterOption(input: any, option: any) {
    return option?.label?.includes(input)
  }

  /**
   * 获取选中值(用户id)
   */
  getValue(): string | Array<string> | null {
    // const selectOptions = this.refAutoComplete.value?.getValue()
    // if (this.props.isMultipleSelection) {
    //   const value = (selectOptions as Array<KAutoCompleteSelectorOption>)?.map(o => o.value)
    //   return value || []
    // } else {
    //   return (selectOptions as KAutoCompleteSelectorOption)?.value || null
    // }
    return this.selectedValue.value
  }

  /**
   * 设置选中值
   * @param value 选中值(用户id)
   */
  setValue(value: string | Array<string> | boolean, setChanged?: boolean) {
    if (this.props.mode == 'multiple' && value == true) {
      value = []
    }
    this.selectedValue.value = value as string
    if (setChanged) {
      this.originalValue.value = value as string
    }
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  getModifiedValue() {
    return this.getValue()
  }
  isModified() {
    return this.selectedValue.value != this.originalValue.value
    // return this.refAutoComplete.value?.isModified()
  }
}
