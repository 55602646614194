
import { defineView, IKTreeNode, TreeViewSelectEventArgs } from '@kmsoft/upf-core'
import { KRequirementChangeBeforePropOptions, KRequirementChangeBeforeEventEmits } from './interface'
import KRequirementChangeBeforeViewModel from './KRequirementChangeBeforeViewModel'

export default defineView({
  name: 'KRequirementChangeBefore',
  props: KRequirementChangeBeforePropOptions,
  emits: KRequirementChangeBeforeEventEmits,
  viewModel: KRequirementChangeBeforeViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
