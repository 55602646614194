export enum EnumSoft {
  /**AutoCAD */
  AUTOCAD_2D = 5,

  /**CAXA_2D */
  CAXA_2D = 6,

  /**NOMALTRANS */
  NOMALTRANS = 7,

  /**PROE模型图 */
  PROE = 8,

  /**PROE_2D模型图 */
  PROE_2D = 9,

  /**SolidWorks模型图 */
  SOLIDWORKS = 10,

  /**SolidWorks工程图 */
  SOLIDWORKS_2D = 11,

  /**UG_SOLIDEDGE */
  UG_SOLIDEDGE = 12,

  /**UG_SOLIDEDGE_2D工程图 */
  UG_SOLIDEDGE_2D = 13,

  /**CATIA模型图 */
  CATIA = 14,

  /**CATIA_2D工程图 */
  CATIA_2D = 15,

  /**INVENTOR模型图 */
  INVENTOR = 16,

  /**INVENTOR_2D工程图 */
  INVENTOR_2D = 17,

  /**WORDTRANSLATOR */
  WORDTRANSLATOR = 19,

  /**KMCAD */
  KMCAD = 22,

  /**CADENCE */
  CADENCE = 28,

  /**ZW3D */
  ZW3D = 31
}
