import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin":"5px"} }
const _hoisted_2 = {
  key: 0,
  class: "flex-start-start h100p"
}
const _hoisted_3 = { class: "flex1 h100p" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_flow_history_signing = _resolveComponent("k-flow-history-signing")!
  const _component_k_empty = _resolveComponent("k-empty")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_k_tool_strip_button_item, {
          title: "刷新",
          icon: "sync",
          onClick: _ctx.$vm.getSigningHistoryData
        }, null, 8, ["onClick"])
      ]),
      (_ctx.$vm.signingHistoryList.length != 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("section", _hoisted_3, [
              _createVNode(_component_k_flow_history_signing, {
                selectFirstRow: "",
                signingHistoryList: _ctx.$vm.signingHistoryList,
                refresh: _ctx.$vm.getSigningHistoryData
              }, null, 8, ["signingHistoryList", "refresh"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_k_empty, {
              description: "暂无签审历史",
              style: {"position":"absolute","top":"50%","height":"calc(100% - 100px)","transform":"translateY(-50%)"}
            })
          ]))
    ]),
    _: 1
  }))
}