
import { defineView, KDataGridRowSelectedEvent } from '@kmsoft/upf-core'
import { KAddPlanDialogPropOptions, KAddPlanDialogEventEmits } from './interface'
import KAddPlanDialogViewModel from './KAddPlanDialogViewModel'
import option from '@kmsoft/ebf-common/src/controls/object-create-panel/controls/option'

export default defineView({
  name: 'KAddPlanDialog',
  props: KAddPlanDialogPropOptions,
  emits: KAddPlanDialogEventEmits,
  viewModel: KAddPlanDialogViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
