import { connect, withInstall } from '@kmsoft/upf-core'
import KActivityPlanPreDetailGridView from './KActivityPlanPreDetailGrid.vue'
import KActivityPlanPreDetailGridViewModel from './KActivityPlanPreDetailGridViewModel'

const KActivityPlanPreDetailGrid = connect(KActivityPlanPreDetailGridView, KActivityPlanPreDetailGridViewModel)

export default withInstall(KActivityPlanPreDetailGrid)
export { KActivityPlanPreDetailGrid, KActivityPlanPreDetailGridView, KActivityPlanPreDetailGridViewModel }

// 模板生成文件
// export * from './{{folderName}}'
