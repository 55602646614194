
import { defineView } from '@kmsoft/upf-core'
import { KCadRelationDocPropOptions, KCadRelationDocEventEmits } from './interface'
import KCadRelationDocViewModel from './KCadRelationDocViewModel'

export default defineView({
  name: 'KCadRelationDoc',
  props: KCadRelationDocPropOptions,
  emits: KCadRelationDocEventEmits,
  viewModel: KCadRelationDocViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
