import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KImageViewPropOptions = {
  ...BaseViewPropOptions,
  preview: VuePropTypes.number().def(0)
}

/** 参数类型 **/
export type KImageViewPropType = ViewPropsTypeExtract<typeof KImageViewPropOptions>

/** 事件 */
export const KImageViewEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KImageViewEmitsType = ViewEmitsTypeExtract<typeof KImageViewEventEmits>
