import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KRequireSearchFromPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KRequireSearchFromPropType = ViewPropsTypeExtract<typeof KRequireSearchFromPropOptions>

/** 事件 */
export const KRequireSearchFromEventEmits = {
  ...BaseViewEventEmits,
  close: () => true,
  findNext: () => true,
  findPrevious: () => true,
  selectAll: () => true,
  cancelSelectAll: () => true,
  refershData: () => true,
  findTop: () => true,
  findBottom: () => true
}

/** 事件类型 **/
export type KRequireSearchFromEmitsType = ViewEmitsTypeExtract<typeof KRequireSearchFromEventEmits>
