import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KBottomBarPropOptions = {
  ...BaseViewPropOptions,
  enableExcelExport: VuePropTypes.bool().def(true),
  exportSelect: VuePropTypes.bool().def(true),
  enablePageSizeChange: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KBottomBarPropType = ViewPropsTypeExtract<typeof KBottomBarPropOptions>

/** 事件 */
export const KBottomBarEventEmits = {
  ...BaseViewEventEmits,
  pageSizeChange: (isMaxPageSize: boolean) => true,
  refresh: () => true,
  excelExport: () => true,
  excelSelectExport: () => true
}

/** 事件类型 **/
export type KBottomBarEmitsType = ViewEmitsTypeExtract<typeof KBottomBarEventEmits>
