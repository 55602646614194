import { AppContext, utils } from '@kmsoft/upf-core'
import { Api } from '../../index'

class SystemSrv {
  isSSOLogin() {
    let url = location.href
    // 如果URL中包含两个或两个以上问号，只将第二个问号替换为&
    const questionMarkCount = (url.match(/\?/g) || []).length
    if (questionMarkCount >= 2) {
      url = url.replace(/\?/g, (match, index, original) => {
        if (index === original.indexOf('?', original.indexOf('?') + 1)) {
          return '&'
        }
        return match
      })
    }
    const ulrList = utils.getQueryObjList(url) as Array<any>
    const state = ulrList.find(obj => Object.prototype.hasOwnProperty.call(obj, 'state'))
    const code = ulrList.find(obj => Object.prototype.hasOwnProperty.call(obj, 'code'))
    const loginType = !!(state && code)
    if (loginType || url.includes('plm.kmsoft.com')) {
      return true
    }
    const mode = AppContext.current.getEnvironment().getProperty<string>('server.authentication.mode')
    const modeStorage = sessionStorage.getItem('KMPDM-mode')
    if ((mode && mode.toUpperCase() === 'SSO') || (modeStorage && modeStorage.toUpperCase() === 'SSO')) {
      if (!modeStorage) {
        sessionStorage.setItem('KMPDM-mode', mode)
      }
      return true
    }
    return false
  }

  isAgentLogin(url: string) {
    const ulrList = utils.getQueryObjList(url) as Array<any>
    const type = ulrList.find(obj => Object.prototype.hasOwnProperty.call(obj, 'loginType'))
    const loginType = !!(type && type.loginType.includes('agentLogin'))
    if (loginType) {
      return true
    }
    return false
  }

  getProperty(key: string) {
    return AppContext.current.getEnvironment().getProperty(key)
  }

  async compareIdentity() {
    const identity = AppContext.current.getIdentity()
    let currentUrl = window.location.href
    // 如果URL中包含两个或两个以上问号，只将第二个问号替换为&
    const questionMarkCount = (currentUrl.match(/\?/g) || []).length
    if (questionMarkCount >= 2) {
      currentUrl = currentUrl.replace(/\?/g, (match, index, original) => {
        if (index === original.indexOf('?', original.indexOf('?') + 1)) {
          return '&'
        }
        return match
      })
    }

    // 获取 'code' 参数
    const { code } = utils.getQueryObj(currentUrl, 'code') || {}
    //根据code获取token
    // 定义要发送的数据
    const postData = {
      grantType: this.getProperty('url.grantType'),
      clientId: this.getProperty('url.clientId'),
      clientSecret: this.getProperty('url.clientSecret'),
      code: code,
      redirectUri: this.getProperty('url.redirectUri')
    }
    const res = await Api.post('common', 'SSOService', 'getUserInformation', { data: [postData] })
    return res.data.userId == identity!.id
  }
}
export const sysSrv = new SystemSrv()
