import { connect, withInstall } from '@kmsoft/upf-core'
import KRequirementChangeAfterView from './KRequirementChangeAfter.vue'
import KRequirementChangeAfterViewModel from './KRequirementChangeAfterViewModel'

const KRequirementChangeAfter = connect(KRequirementChangeAfterView, KRequirementChangeAfterViewModel)

export default withInstall(KRequirementChangeAfter)
export { KRequirementChangeAfter, KRequirementChangeAfterView, KRequirementChangeAfterViewModel }

// 模板生成文件
// export * from './{{folderName}}'
