import { connect, withInstall } from '@kmsoft/upf-core'
import KStructuredDocFormView from './KStructuredDocForm.vue'
import KStructuredDocFormViewModel from './KStructuredDocFormViewModel'

const KStructuredDocForm = connect(KStructuredDocFormView, KStructuredDocFormViewModel)

export default withInstall(KStructuredDocForm)
export { KStructuredDocForm, KStructuredDocFormView, KStructuredDocFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'
