import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_object_property_edit_panel = _resolveComponent("k-object-property-edit-panel")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createBlock(_component_k_split_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_pane, {
        size: 15,
        minSize: 15,
        maxSize: 50
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_panel, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_tree_view, {
                ref: _ctx.$vm.refTree,
                nodeKey: "id",
                objParam: _ctx.$vm.objParam,
                autoSelectLastNode: false,
                defaultExpandedLevel: 0,
                autoSelectFirstNodeAfterDefaultExpand: false,
                onAfterSelect: _ctx.$vm.onAfterSelect,
                loadData: () => _ctx.$vm.loadTreeData(),
                onReady: _ctx.$vm.onReady
              }, null, 8, ["objParam", "onAfterSelect", "loadData", "onReady"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_split_pane, null, {
        default: _withCtx(() => [
          (_ctx.$vm.TreeNodeParam?.id)
            ? (_openBlock(), _createBlock(_component_k_object_property_edit_panel, {
                key: 0,
                ref: _ctx.$vm.refObjectPanel,
                readonly: true,
                showToolStripItems: false,
                objParam: _ctx.$vm.TreeNodeParam,
                toolStripItems: [],
                load: () => _ctx.$vm.loadData()
              }, null, 8, ["objParam", "load"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}