import { MemoryCacheBase } from '@kmsoft/upf-core'
/** 对象类缓存 */
export class PmsWebCache extends MemoryCacheBase<Array<Record<string, any>>> {
  public static cacheKey = 'PmsWebCache'
  constructor() {
    super()
    this.cacheKey = PmsWebCache.cacheKey
  }

  public async reload() {
    this.cacheData = [{ isAddEventListener: false }]
  }

  /** 设置缓存 */
  public setIsAddEventListener() {
    this.cacheData = [{ isAddEventListener: true }]
  }

  /** 获取缓存 */
  public getIsAddEventListener() {
    return this.cacheData[0].isAddEventListener
  }
}
