
import { defineView } from '@kmsoft/upf-core'
import { KStructuredDocFormEventEmits, KStructuredDocFormPropOptions } from './interface'
import KStructuredDocFormViewModel from './KStructuredDocFormViewModel'

export default defineView({
  name: 'KStructuredDocForm',
  props: KStructuredDocFormPropOptions,
  emits: KStructuredDocFormEventEmits,
  viewModel: KStructuredDocFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
