import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ef26da4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"calc(100% - 48px)"} }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mr5" }
const _hoisted_4 = { class: "actions" }
const _hoisted_5 = { class: "showTxt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_dialog_content = _resolveComponent("k-dialog-content")!

  return (_openBlock(), _createBlock(_component_k_dialog_content, {
    class: "k-change-request-creater",
    dense: ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("a", _hoisted_5, "只显示非标准件、元器件、材料的工作中且已检入的零件", 512), [
          [_vShow, _ctx.$vm.showTxt]
        ]),
        _createVNode(_component_k_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.onCancel()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1
        }),
        _createVNode(_component_k_button, {
          type: "primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$vm.onConfirm()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("确认")
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_k_split_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_split_pane, {
            size: 15,
            minSize: 15,
            maxSize: 50
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_panel, null, {
                default: _withCtx(() => [
                  _createVNode(_component_k_tree_view, {
                    ref: _ctx.$vm.refPartTree,
                    nodeKey: "id",
                    defaultExpandedLevel: 0,
                    autoSelectLastNode: false,
                    autoSelectFirstNodeAfterDefaultExpand: false,
                    onLoaded: _ctx.$vm.loadGridData
                  }, null, 8, ["onLoaded"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_k_split_pane, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_panel, null, {
                default: _withCtx(() => [
                  _createElementVNode("section", _hoisted_1, [
                    _createVNode(_component_k_data_grid, {
                      ref: "refObjGrid",
                      rowKey: "index",
                      onModelUpdated: _ctx.$vm.setDefaultSelectRows
                    }, {
                      columns: _withCtx(() => [
                        _createVNode(_component_k_data_grid_text_box_column, {
                          headerText: "编码",
                          dataPropertyName: "number",
                          align: "left",
                          width: "180"
                        }),
                        _createVNode(_component_k_data_grid_text_box_column, {
                          headerText: "名称",
                          dataPropertyName: "name",
                          align: "left",
                          width: "180"
                        }),
                        _createVNode(_component_k_data_grid_text_box_column, {
                          headerText: "版本",
                          dataPropertyName: "versionInfo",
                          align: "left",
                          width: "80"
                        }),
                        _createVNode(_component_k_data_grid_template_column, {
                          headerText: "实体类型",
                          dataPropertyName: "rdmExtensionType",
                          align: "left",
                          width: "120"
                        }, {
                          default: _withCtx(({cellValue, row}) => [
                            _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue) + _ctx.$vm.getView(row)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_k_data_grid_template_column, {
                          headerText: "生命周期状态",
                          dataPropertyName: "lifecycleStateCode",
                          align: "center",
                          width: "120"
                        }, {
                          default: _withCtx(({ cellValue }) => [
                            cellValue
                              ? (_openBlock(), _createBlock(_component_k_tag, {
                                  key: 0,
                                  color: _ctx.EnumLifecycleStateColor[cellValue]
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.EnumLifecycleState[cellValue]), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_2, "--"))
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_k_data_grid_combo_box_column, {
                          id: "workingState",
                          name: "workingState",
                          dataPropertyName: "workingState",
                          headerText: "工作状态",
                          options: _ctx.workingStateOptions,
                          width: "120",
                          align: "center"
                        }, null, 8, ["options"]),
                        _createVNode(_component_k_data_grid_text_box_column, {
                          headerText: "数量",
                          dataPropertyName: "sumQuantity",
                          align: "left",
                          width: "100"
                        }),
                        _createVNode(_component_k_data_grid_text_box_column, {
                          headerText: "层级",
                          dataPropertyName: "currentLevel",
                          align: "left",
                          width: "80"
                        }),
                        _createVNode(_component_k_data_grid_template_column, {
                          headerText: "路径",
                          align: "left",
                          width: "400"
                        }, {
                          default: _withCtx(({row}) => [
                            _createElementVNode("span", _hoisted_3, [
                              _createVNode(_component_k_icon, {
                                type: "cluster",
                                onClick: (e) => _ctx.$vm.viewPartStructure(row)
                              }, null, 8, ["onClick"])
                            ]),
                            _createTextVNode(" " + _toDisplayString(row.displayPath), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onModelUpdated"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}