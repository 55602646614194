
import { defineView } from '@kmsoft/upf-core'
import { KRequireSearchFromPropOptions, KRequireSearchFromEventEmits } from './interface'
import KRequireSearchFromViewModel from './KRequireSearchFromViewModel'

export default defineView({
  name: 'KRequireSearchFrom',
  props: KRequireSearchFromPropOptions,
  emits: KRequireSearchFromEventEmits,
  viewModel: KRequireSearchFromViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
