import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../object-tool-strip'
import { EnumToolStripItemKeys } from '../types'
import { CommonClientSrv, EnumClassTemplate } from '../../../client-srv'
import { toolStripItemDivider, toolStripItemEdit, toolStripItemStartEdit } from '../base'

export const toolStripItemStructManage: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
  title: '结构管理',
  icon: 'file-protect',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  shortcutKey: 'ctrl+p',
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.PART]
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Browse
}

export const toolStripItemStrucCompare: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_COMPARE,
  title: '结构比较',
  icon: 'Part_StructureCompare',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.PART]
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Browse
}

export const toolStripItemWhereUse: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WHERE_USE,
  title: '何处使用...',
  icon: 'Part_Lookat',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.PART],
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WHERE_USE_SINGLE_LEVEL,
      title: '单层...',
      icon: 'Part_Single',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjClsTempletID: [EnumClassTemplate.PART]
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WHERE_USE_MULTI_LEVEL,
      title: '多层...',
      icon: 'Part_Multi',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjClsTempletID: [EnumClassTemplate.PART]
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_WHERE_USE_CHECK_CIRCLE,
      title: '检测环引用...',
      icon: 'DetectionCircleUse',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjClsTempletID: [EnumClassTemplate.PART]
    }
  ]
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Browse
}

export const toolStripItemBatchEdit: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BATCH_EDIT,
  title: '批量编辑属性',
  icon: 'Part_Edit',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.PART]
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Edit
}

export const toolStripItemBatchCopy: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BATCH_COPY,
  title: '批量复制',
  icon: 'Part_Copy',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  supportedObjClsTempletID: [EnumClassTemplate.PART]
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.SaveAs
}

export const partObjectOperationToolStripItems: Array<ObjectToolStripItem> = [
  toolStripItemStructManage,
  toolStripItemStrucCompare,
  // {
  //   id: EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATION_COMPARE,
  //   text: '关联比较',
  //   icon: 'Part_RelationCompare',
  //   enable: true,
  //   visible: true,
  //   compType: EnumToolStripCompType.BUTTON,
  //   supportedObjClsTempletID: [EnumClassTemplate.PART],
  //   supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
  // },
  toolStripItemWhereUse,
  toolStripItemBatchEdit,
  toolStripItemBatchCopy
]

export const bomViewObjectOperationToolStripItems: Array<ObjectToolStripItem> = [
  {
    name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_BOM_VIEW,
    title: '创建BOM视图',
    icon: 'PartBOM',
    visible: false,
    shortcutKey: 'ctrl+b',
    compType: EnumToolStripCompType.BUTTON,
    supportedObjNumMode: EnumSupportObjectNumMode.Single,
    //功能未开发，暂时隐藏
    supportedObjClsTempletID: [] //[EnumClassTemplate.PART]
  }
]

export const toolStripItemEditPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PART,
  title: '编辑',
  icon: 'edit',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.PART],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.Edit
}

export const toolStripItemCheckOutPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT_PART,
  title: '检出(O)',
  icon: 'file-protect',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.PART],
  shortcutKey: 'alt+o',
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.CheckInOut
}

export const toolStripItemUnCheckOutPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT_PART,
  title: '取消检出',
  icon: 'file-text',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.PART],
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.UnCheckOut
}

export const toolStripItemCheckInPart: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN_PART,
  title: '检入(I)',
  icon: 'file-sync',
  visible: true,
  compType: EnumToolStripCompType.BUTTON,
  supportedObjClsTempletID: [EnumClassTemplate.PART],
  shortcutKey: 'alt+i',
  supportedObjNumMode: EnumSupportObjectNumMode.Single
  // purviewType: EnumPurviewType.ObjectOperation,
  // purviewId: EnumObjectPurviewType.CheckInOut
}

export const toolStripMoreOperation: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_MORE,
  title: '更多操作',
  icon: 'more',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  supportedObjNumMode: EnumSupportObjectNumMode.Single,
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
      title: '加入流程',
      icon: 'AddToFlow',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
      title: '查看流程',
      icon: 'ViewFlowObj',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    toolStripItemDivider(),
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC,
      title: '浏览',
      icon: 'eye',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      // supportedObjClsTempletID: [EnumClassTemplate.DOC],
      // shortcutKey: 'alt+b',
      supportedObjNumMode: EnumSupportObjectNumMode.Single
      // purviewType: EnumPurviewType.ObjectOperation,
      // purviewId: EnumObjectPurviewType.Browse
    },
    toolStripItemDivider(),
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PART_VIEW_VERSION,
      title: '创建视图版本',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    toolStripItemDivider(),
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PART_EMULATION,
      title: '创建仿真',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    toolStripItemDivider(),
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PART_MATERIAL_QUERY,
      title: '物料查询',
      icon: 'search',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]
}

export const changeToolStripItems: ObjectToolStripItem = {
  name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
  title: '变更',
  icon: 'control',
  visible: true,
  compType: EnumToolStripCompType.CONTEXT_MENU,
  items: [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
      title: '快速变更',
      icon: 'control',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_CREATE,
          title: '基于改后创建变更活动',
          icon: 'file-text',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_ADD_ACTIVITY,
          title: '基于改后添加至变更活动',
          icon: 'file-text',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_CREATE,
          title: '基于改前创建变更活动',
          icon: 'file-text',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_ADD_ACTIVITY,
          title: '基于改前添加至变更活动',
          icon: 'file-text',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    }
  ]
}

export const getPartGeneralObjectOperationToolStripItems = (): Array<ObjectToolStripItem> => {
  return [
    toolStripItemCheckOutPart,
    toolStripItemUnCheckOutPart,
    toolStripItemCheckInPart,
    toolStripItemStructManage,
    toolStripMoreOperation,
    changeToolStripItems
  ]
}
