import { KNotification, MemoryCacheBase } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { Api, EnumRequestCode } from '../../../..'
/** 对象类缓存 */
export class EnumListCache extends MemoryCacheBase<Array<Record<string, any>>> {
  public static cacheKey = 'EnumListCache'
  constructor() {
    super()
    this.cacheKey = EnumListCache.cacheKey
  }

  public async reload() {
    const res = await Api.post('official', 'EnumService', 'listAll', {})
    if (res && res.code == EnumRequestCode.SUCCESS) {
      this.cacheData = res.data! as Array<Record<string, any>>
    } else {
      KNotification.error({
        title: '系统提示',
        content: '枚举列表获取失败',
        details: res.message
      })
      this.cacheData = []
    }
  }

  /** 获取所有数据 */
  public getAll() {
    return lodash.cloneDeep(this.cacheData)
  }
  /** 根据枚举code获取枚举信息 */
  public getEnumByCode(code: string) {
    const list = this.cacheData.find(item => item.code == code)
    const enumValueList = list?.enumValueList?.filter((x: { valid: number }) => x.valid == 1)
    return lodash.cloneDeep(enumValueList)
  }
}
