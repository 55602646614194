import {
  BaseViewModel,
  EnumAttributeType,
  EnumLabelPosition,
  EnumNodeType,
  EnumType,
  KContainer,
  KControl,
  KLayoutElementEvent,
  KSchema,
  PageManager,
  ViewModelOptions,
  KDialog,
  KDialogClosingEvent,
  KMessage,
  IKTreeNode,
  EnumDialogResult,
  LayoutRenderElement,
  KControlAttribute,
  KGridColumn,
  KDataGridColumn,
  KDataGridTextBoxColumn
} from '@kmsoft/upf-core'
import { KObjectClassGridBaseColumn, EditorFactory } from '@kmsoft/ebf-common'
import { KLayoutDesignerEventEmitsTypes, KLayoutDesignerPropType } from './interface'
import { computed, nextTick, ref, resolveComponent, watch, VNode } from 'vue'
import {
  KDataElementTree,
  KDesignerControl,
  PageDesignerDataSourceProperty,
  PageDesignerDataSourcePropClass
} from '../types/metadata'
import _ from 'lodash'
import { KDynamicView, DynamicViewGetControlOptionsParams, DynamicViewControlOptions } from '@kmsoft/upf-view-engine'
import { BASE_CONTROL, DEFAULT_CONTROL, ElementConfigCollection, controlDescriptionToDesignerControl } from '../config'
import KSchemaExport from './common/export-modal'
import KSchemaExportViewModel from './common/export-modal/KSchemaExportViewModel'
import lodash from 'lodash'
import { KViewPropertyConfigPanelViewModel } from './controls/config/'

/** 设计器 */
export default class LayoutDesignerViewModel extends BaseViewModel<KLayoutDesignerEventEmitsTypes, KLayoutDesignerPropType> {
  //#region 组件引用
  /** 方案导出弹框实例 */
  refSchemaExportModal = ref<KSchemaExportViewModel>()
  /** 配置面板 */
  refDesignerConfig = ref<KViewPropertyConfigPanelViewModel>()
  refPreviewContainer = ref<HTMLElement>()
  //#endregion

  localSchema = ref<KSchema>()

  /** 选中的元素 */
  selectedElement = ref<LayoutRenderElement>()
  /** 选中的控件 */
  selectedControl = ref<KDesignerControl>()

  /**控件实例 */
  instance: any
  /** 数据元素属性集合 */
  dataSourceElementList = computed(() => {
    // 如果没有数据源
    if (!this.props.dataSource) {
      return []
    }
    const result = this.__convert2DesignerControl(this.props.dataSource.children, [])
    return result
  })
  /** 设计器控件 */
  designerMetaControl = computed(() => {
    /** 配置 */
    const configCollection = ElementConfigCollection

    const designerMeta = []

    //#region 默认控件
    /** 默认控件 */
    const defaultControl = lodash.cloneDeep(DEFAULT_CONTROL)
    designerMeta.push(...defaultControl)
    //#endregion

    //#region 外部传入配置
    /** 外部传入配置 */
    const designerMetaControl = lodash.cloneDeep(this.props.designerMetaControl)
    designerMeta.push(...designerMetaControl)
    //#endregion

    // 循环控件
    for (const iterator of designerMeta) {
      // 如果已经设置了属性 则不补全属性
      if (iterator.attrs.length > 0) {
        continue
      }

      /** 通过组件名查找组件 */
      const component = resolveComponent(iterator.control)

      // 如果可以获取到组件
      if (typeof component == 'object') {
        /** 组件信息 */
        const controlMeta = controlDescriptionToDesignerControl(component)

        // 如果从组件上取到信息
        if (controlMeta) {
          Object.assign(iterator, controlMeta)
          continue
        }
      }

      /** 查找配置 */
      const config = configCollection.get(iterator.control)

      if (!config) {
        continue
      }

      iterator.attrs = [...config.BASE, ...config.LAYOUT, ...config.PROPS]
      iterator.events = [...config.EVENT]
    }

    return designerMeta
  })

  constructor(options: ViewModelOptions<KLayoutDesignerPropType>) {
    super(options)
    watch(
      () => this.props.schema,
      (newVal: KSchema) => {
        this.loadSchema()
      },
      { deep: true }
    )
  }

  viewDidMount(): void {
    this.loadSchema()
    this.__setDefaultSelectElement()
  }

  /**保存 */
  getSchema = () => {
    return _.cloneDeep(this.localSchema.value)
  }

  /**
   * 重置方案（接口）
   */
  resetSchema(schema: KSchema) {
    this.localSchema.value = this.__getLocalSchema(schema)
  }

  /**导出 */
  export() {
    //布局方案导出时取出页面模板容器
    const getSchema = _.cloneDeep(this.localSchema.value)
    KDialog.show({
      title: '导出布局方案',
      size: { width: 350, height: 500 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KSchemaExport,
      extraButtons: ['保存至剪切板'],
      props: { schema: getSchema },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult === EnumDialogResult.Cancel) return
        const vm = event.viewModel as KSchemaExportViewModel
        const schema = vm.getValue()!
        const input = document.createElement('input')
        input.value = JSON.stringify(schema)
        document.body.appendChild(input)
        input.select() // 选择对象
        document.execCommand('Copy')
        document.body.removeChild(input)
        KMessage.success('已保存至剪切板!')
      }
    })
  }

  preview() {
    if (!this.localSchema.value) {
      return
    }

    const getSchema = _.cloneDeep(this.localSchema.value)
    PageManager.openPage('preview' + _.uniqueId.toString(), this.props.title + this.props.name, KDynamicView, {
      name: getSchema.name,
      schema: getSchema as KSchema,
      onAfterSchemaLoaded: (vm: any) => {},
      getControlOptions: (
        params: DynamicViewGetControlOptionsParams & { meta: { controls: DynamicViewGetControlOptionsParams[] } }
      ): DynamicViewControlOptions | undefined => {
        if (params.meta.control == 'KFilterGrid' || params.meta.control == 'KDataGrid') {
          return {
            props: {
              columnResolver: (column: any) => this.onGirdResolveCustomColumn(column as KGridColumn)
            },
            slots: {}
          }
        } else if (params.meta.control == 'KSegment') {
          params.meta.controls?.forEach((item: any) => {
            item.props.isDesigner = true
          })
        } else {
          // 设计模式下防止按钮可实际触发
          return {
            props: {
              isDesigner: true
            },
            slots: {}
          }
        }
      }
    })
  }

  /**
   * 元素选中事件
   * @param element 当前选中元素
   * @param updateSelf 更新自身标识
   */
  previewElementSelected(element: LayoutRenderElement | undefined, updateSelf?: boolean) {
    if (!updateSelf && element?.name == this.selectedElement.value?.name) {
      return false
    }

    this.selectedElement.value = element

    if (element) {
      const controlInfo = this.getLocalControlInfo(
        (element as KControl).dataSource!,
        (element as KControl).field!,
        element.control
      )!

      this.selectedControl.value = controlInfo
    }
  }

  /**
   *
   * 层级树节点选中事件
   * @param node 选中的节点
   */
  hierarchyTreeNodeSelected(node: IKTreeNode) {
    if (node) {
      const selectedElement = this.__getElementFromSchema([this.localSchema.value], node.id)
      if (selectedElement) {
        this.previewElementSelected(selectedElement)
      } else {
        this.previewElementSelected(undefined)
      }
    }
  }

  /**
   * 配置项内容改变触发的事件
   * @param controlName 控件名称
   * @param name 属性名称
   * @param type 属性类型
   * @param value 修改的值
   **/
  elementPropertyChange(controlName: string, name: string, type: string, value: any) {
    if (!this.localSchema.value) {
      return
    }

    /** 更新方案中指定元素的配置 */
    nextTick(() => {
      this.__updateSpecificElementConfig([this.localSchema.value!], controlName, name, type, value)
    })
  }

  /**获取schema */
  loadSchema() {
    const schema = this.props.schema // as KSchema
    const result = this.__getLocalSchema(schema)
    this.localSchema.value = result
  }

  /**
   * 数据元素中的所有属性拉平放到集合中
   * @param nodes 数据元素集合
   **/
  __convert2DesignerControl = (
    nodes: Array<PageDesignerDataSourcePropClass | PageDesignerDataSourceProperty>,
    result: Array<KDesignerControl>
  ) => {
    nodes &&
      nodes.forEach(node => {
        if (node.nodeType && node.nodeType === EnumNodeType.PROPERTY) {
          const res = {
            name: node.name,
            title: node.title,
            attrs: (node as PageDesignerDataSourceProperty).attrs,
            events: (node as PageDesignerDataSourceProperty).events!,
            type: EnumType.CONTROL,
            dataType: (node as PageDesignerDataSourceProperty).dataType
          } as KDesignerControl
          result.push(res)
        }
        if (
          (node as PageDesignerDataSourcePropClass).children &&
          (node as PageDesignerDataSourcePropClass).children?.length > 0
        ) {
          this.__convert2DesignerControl((node as PageDesignerDataSourcePropClass).children, result)
        }
      })
    return result
  }

  /**
   * 获取本地布局方案
   * 若外部传入方案则以外部为准，反之生成默认方案
   * @param schema 布局方案
   */
  __getLocalSchema = (schema: KSchema): KSchema => {
    if (schema && Object.keys(schema).length > 0) {
      this.previewElementSelected(schema, false)
      return _.cloneDeep(schema)
    } else {
      const result = this.__getDefaultSchema()
      return _.cloneDeep(result)
    }
  }

  /**
   * 获取默认方案
   */
  __getDefaultSchema = (): KSchema => {
    const result = {
      name: this.props.name,
      title: this.props.title,
      type: EnumType.PAGE,
      control: 'KDynamicView',
      visible: true,
      readonly: false,
      disabled: false,
      nullable: true,
      props: {
        /** 最小列宽度 */
        minColWidth: 260,
        /** 行间距 */
        rowGap: 10,
        /** 列间距 */
        colGap: 10,
        /** 列数 */
        colCount: 3,
        labelWidth: 50,
        labelPosition: EnumLabelPosition.LEFT
      },
      events: [],
      styles: [] as Array<string>,
      controls: [] as Array<KContainer | KControl>,
      subPages: [] as Array<KSchema>
    } as KSchema
    this.previewElementSelected(result, false)
    return _.cloneDeep(result)
  }

  /**
   * 获取当前控件信息
   * @param dataSource 数据源
   * @param filed 字段
   * @param control 控件
   **/
  getLocalControlInfo(dataSource: string, field: string, control: string): KDesignerControl | undefined {
    let res: KDesignerControl

    const controlByDataSource = this.__getControlByDataSource(dataSource, field, control)

    if (controlByDataSource) {
      return controlByDataSource
    }

    const outterControl = this.designerMetaControl.value.find(x => x.control === control)

    if (outterControl) {
      return outterControl
    }

    return undefined
  }

  /**
   * 构建自定义列节点
   * @param column 渲染器当前遍历的列
   * @override
   * @private
   */
  onGirdResolveCustomColumn(column: KGridColumn): VNode | KDataGridColumn {
    /** 网格的基础属性,默认属性渲染器会自动处理 */
    const commonColumnInstanceValues: Partial<KObjectClassGridBaseColumn> = {
      code: column.title
    }

    /** 网格列 */
    let gridColumn: KDataGridColumn = new KDataGridTextBoxColumn()

    /** 通过工厂获取列 */
    const resolveColumn = EditorFactory.getColumn(column!.control)

    // 如果获取到列定义
    if (resolveColumn) {
      gridColumn = lodash.cloneDeep(resolveColumn)
    }

    // 批量应用参数
    Object.assign(gridColumn, commonColumnInstanceValues)

    // 从全局工厂返回
    return gridColumn
  }

  /**
   * 通过数据源和字段查找控件
   * @param dataSource 数据源
   * @param filed 字段
   * @param control 控件
   **/
  __getControlByDataSource = (dataSource: string, field: string, control: string) => {
    let res = undefined
    for (const x of this.dataSourceElementList.value) {
      const originFiled = x.attrs.find(y => y.name === 'field')?.defaultValue
      const originDataSource = x.attrs.find(y => y.name === 'dataSource')?.defaultValue
      const originControl = x.attrs.find(y => y.name === 'control')?.defaultValue
      if (originFiled === field && originDataSource === dataSource && originControl === control) {
        res = x
        break
      }
    }
    return res
  }

  /**
   * 从方案中获取指定元素（递归）
   * @param elements 元素集合
   * @param elementCode 元素编码（唯一标识）
   */
  __getElementFromSchema = (elements: Array<any>, elementCode: string): KContainer | KControl | KSchema | undefined => {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      /** 找到则返回 */
      if (element.name === elementCode) return element
      /** 没找到且当前元素没有子元素，则跳出循环 */
      if (!('controls' in element)) continue
      /** 当前元素有子元素，则递归查找 */
      if (element.controls.length > 0) {
        const subElement = this.__getElementFromSchema(element.controls, elementCode)
        if (subElement) return subElement
      }
    }
  }
  /**
   * 更新指定元素的配置（递归）
   * @param elements 元素集合
   * @param elementCode 需要更改配置的元素编码
   * @param configItemName 配置项名称，如 name,title,rowSpan等
   * @param value 配置项最新值
   */
  __updateSpecificElementConfig = (
    elements: Array<KContainer | KControl | KSchema>,
    controlName: string,
    name: string,
    type: string,
    value: any
  ) => {
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]

      if (element.name == controlName) {
        //判断修改属性的类型
        if (type === EnumAttributeType.BASE) {
          /** 执行到这里说明configItemName为name或title */
          ;(element as Record<string, any>)[name] = value
          //若修改的属性为"组件类型",则同时修改属性
          if (name === 'control') {
            const controlInfo = this.getLocalControlInfo(
              (element as KControl).dataSource!,
              (element as KControl).field!,
              element.control
            )!

            this.selectedControl.value = controlInfo
          }
          return
        }

        if (type === EnumAttributeType.LAYOUT) {
          /** 执行到这里说明configItemName为name或title */
          ;(element as Record<string, any>).layout[name] = value
          return
        }

        if (type === EnumAttributeType.PROPS) {
          /** 执行到这里说明configItemName为name或title */
          ;(element as Record<string, any>).props[name] = value
          return
        }

        if (type === 'event') {
          console.log('element', element)
          const event = (element as Record<string, any>).events.find((x: KLayoutElementEvent) => x.name === name)
          if (event) {
            event.code = value
          } else {
            const res = { name: name, code: value }
            ;(element as Record<string, any>).events.push(res)
          }
          return
        }

        if (type === 'custom') {
          ;(element as Record<string, any>).props = JSON.parse(value)
        }
      }

      /** 若当前元素有children,则继续递归 */
      if ((element as KContainer).controls && Reflect.has(element, 'controls')) {
        this.__updateSpecificElementConfig((element as KContainer).controls, controlName, name, type, value)
      }
    }
  }

  /**
   * 属性合并
   * @param originAttrs 旧属性集合
   * @param newAttrs 新属性集合
   **/
  __mergeConstrainAttrs = (originAttrs: Array<KControlAttribute>, newAttrs: Array<KControlAttribute>) => {
    if (newAttrs) {
      newAttrs.forEach(x => {
        const originAttr = originAttrs.find(y => x.name === y.name)
        if (originAttr) {
          x.disabled = originAttr.disabled
          x.defaultValue = originAttr.defaultValue
        }
      })
      return newAttrs
    }

    return []
  }

  /**获取数据源 */
  __getDataSource = () => {
    const data = this.props.dataSource as KDataElementTree
    if (data) return data.dataSource
    return ''
  }

  /**
   * 设置默认选中元素
   */
  __setDefaultSelectElement = () => {
    const schema = this.localSchema.value
    this.selectedElement.value = schema
  }
}
