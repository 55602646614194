import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeOrderCreateEmitsType, KChangeOrderCreatePropType } from './interface'
import { nextTick, ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, loadingHandle } from '@kmsoft/ebf-common'
import { KChangeOrderActivityPlanGridDataInter } from '../../../controls/change-order-activity-plan-grid/interface'
import lodash from 'lodash'

/** KChangeOrderCreate */
export default class KChangeOrderCreateViewModel extends BaseViewModel<KChangeOrderCreateEmitsType, KChangeOrderCreatePropType> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<any>({
    number: '',
    name: '',
    type: 'DESIGN_CHANGE',
    secondaryIds: null,
    description: '',
    complexity: 'Low',
    needDate: null,
    folder: {
      id: '',
      clazz: ''
    },
    plmChangeOrderAffectedViews: [],
    plmChangeActivityCreateDTOS: []
  })

  constructor(options: ViewModelOptions<KChangeOrderCreatePropType>) {
    super(options)
  }

  viewDidMount() {
    // todo 找不到对应映射
    // this.formData.value.type = 'OTHER'

    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
    }

    // 受影响对象赋值
    if (this.props.affectedObjData) {
      this.formData.value.plmChangeOrderAffectedViews = lodash.cloneDeep(this.props.affectedObjData)
    }
  }
  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())
    return createObject
  }

  /** 校验 */
  async validate() {
    let flag = this.refObjectCreatePanel.value?.validateFormValue()
    const createObject = this.getValue()
    // 受影响对象
    if (createObject?.plmChangeOrderAffectedViews?.length > 0) {
      const invalidItem = createObject!.plmChangeOrderAffectedViews.find((m: { handlingMethod: any }) => !m.handlingMethod)
      if (invalidItem) {
        KNotification.warning({
          title: '属性空',
          content: `处理方式不能为空`
        })
        flag = false
      }
    }
    return flag
  }

  async save() {
    const createObject = this.getValue()
    // 受影响对象
    let plmChangeOrderAffectedViews = []
    if (createObject?.plmChangeOrderAffectedViews?.length > 0) {
      plmChangeOrderAffectedViews = createObject!.plmChangeOrderAffectedViews.map((item: any) => {
        return {
          target: {
            id: item.targetId || item.id,
            rdmExtensionType: item.targetExtensionType || item.rdmExtensionType
          },
          handlingMethod: item.handlingMethod
        }
      })
    }

    createObject!.plmChangeOrderAffectedViews = plmChangeOrderAffectedViews

    // 变更活动计划
    let plmChangeActivityCreateDTOS = []
    if (createObject?.plmChangeActivityCreateDTOS?.length > 0) {
      plmChangeActivityCreateDTOS = createObject!.plmChangeActivityCreateDTOS.map(
        (item: KChangeOrderActivityPlanGridDataInter) => {
          const changeBeforeLinkViews = (item.affectedObjectList || []).map((affectedObject: any) => {
            return {
              branchId: affectedObject.targetBranchId || affectedObject?.branch?.id,
              masterId: affectedObject.targetMasterId || affectedObject?.master?.id,
              inventoryDisposition: affectedObject.handlingMethod,
              target: {
                id: affectedObject.targetId || affectedObject.id,
                className: affectedObject.className || affectedObject.targetClass,
                rdmExtensionType: affectedObject.targetExtensionType || affectedObject.rdmExtensionType
              }
            }
          })

          return {
            number: item.number,
            name: item.name,
            sortId: item.sortId || 0,
            preChangeActivities: item.preChangeActivities || [],
            activityType: item.activityType,
            inventoryDisposition: item.inventoryDisposition,
            description: item.description,
            supplyingOrganizations: item.supplyingOrganizations,
            needDate: item.needDate,
            changeBeforeLinkViews
          }
        }
      )
      plmChangeActivityCreateDTOS.forEach((item: { sortId: any }, index: number) => (item.sortId = index + 1))
    }

    createObject!.plmChangeActivityCreateDTOS = plmChangeActivityCreateDTOS

    // 如果是从变更请求创建而来，添加关联关系
    if (this.props.changeRequestData) {
      createObject!.changeRequestViews = [
        {
          id: this.props.changeRequestData.id,
          rdmExtensionType: this.props.changeRequestData.rdmExtensionType
        }
      ]
    }

    if (createObject!.secondary) {
      const secondary = createObject!.secondary.map((element: string) => {
        return { id: element }
      })
      createObject!.secondary = secondary
    }

    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }
    loadingHandle.show()
    const result = await Api.post('change', 'ChangeOrder', 'create', params)
    loadingHandle.remove()
    return result
  }
}
