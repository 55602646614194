import {
  ConstructorType,
  defineView,
  exposeComponentEvents,
  KDataGridEventEmitsType,
  KDataGridPropOptions,
  KDataGridPropType,
  KFilterGridEventEmits,
  KFilterGridEventEmitsType,
  KFilterGridPropType,
  KGridColumn,
  VNodeProps
} from '@kmsoft/upf-core'
import { DynamicViewControlOptions, DynamicViewGetControlOptionsParams, KDynamicViewPropType } from '@kmsoft/upf-view-engine'
import { createCommentVNode, createVNode, onMounted, StyleValue } from 'vue'
import { KObjectToolStripEventEmitsType, KObjectToolStripPropType, ObjectToolStripItem } from '../../object-tool-strip'
import '../style/style.less'
import { KObjectClassGridBaseEventEmits, KObjectClassGridBasePropOptions } from './interface'
import { KObjectClassGridBaseViewModel } from './KObjectClassGridBaseViewModel'
import lodash from 'lodash'
import bottomBar, { KBottomBar } from '../bottom-bar'
import { EnumRenderLayoutType } from '@kmsoft/upf-view-engine/src/render'

/** 对象类网格 */
export default defineView({
  inheritAttrs: false,
  viewModel: KObjectClassGridBaseViewModel as ConstructorType<KObjectClassGridBaseViewModel>,
  name: 'KObjectClassGridBase',
  props: KObjectClassGridBasePropOptions,
  emits: KObjectClassGridBaseEventEmits,
  setup(props, { slots, emit, expose, attrs, vm }) {
    //#region 生命周期函数
    onMounted(async () => {
      // exportConfig = await userConfigSrv.getConfig(EnumUserConfigKey.exportConfig)
    })
    //#endregion

    const methods = {
      refToolStrip: vm.refToolStrip,
      get toolStripEnvironment() {
        return props.toolStripEnvironment
      }
    }

    expose(methods)

    const exposedEvents = exposeComponentEvents(vm.refFilterGridRender, Object.keys(KFilterGridEventEmits), emit)
    //#endregion

    return () => {
      // 如果没有布局
      if (!vm.gridSchema.value) {
        return <k-empty description="不存在布局" />
      }

      /** 渲染工具栏 */
      const renderToolStrip = () => {
        /** 工具栏VNode */
        let toolStripVNode = createCommentVNode()

        /** 如果显示工具栏 */
        if (props.showToolStrip || props.showContextMenu) {
          const toolStripProps: VNodeProps<KObjectToolStripPropType, KObjectToolStripEventEmitsType> = {
            ref: vm.refToolStrip,
            enableShortcut: true,
            hideText: props.hideToolStripItemText,
            items: vm.toolStripItems.value,
            // scriptContext: vm.getContext,
            onItemClicked: vm.onToolStripItemClicked,
            onItemChange: vm.onToolStripItemChange,
            onItemInput: vm.onToolStripItemInput,
            onItemClickedBefore: vm.onItemClickedBefore,
            'onUpdate:items': (value: Array<ObjectToolStripItem>) => {
              vm.toolStripItems.value = value
            }
          }

          toolStripVNode = <k-object-tool-strip v-show={props.showToolStrip} {...toolStripProps} />
        }

        return toolStripVNode
      }

      /** 网格属性 */
      const getGridOptions = (): DynamicViewControlOptions => {
        /** props Key */
        const propsKeys = Object.keys(KDataGridPropOptions)
        /** 挑选属性 */
        const pickProps = lodash.pick(props, propsKeys) as Record<string, any>

        /** 网格参数 */
        const gridProps: VNodeProps<KFilterGridPropType, KFilterGridEventEmitsType> = {
          ...pickProps,
          ...exposedEvents,
          columnResolver: column => vm.onGirdResolveCustomColumn(column as KGridColumn),
          loadData: vm.onGridLoadData,
          // onCellContextMenu: vm.onCellContextMenu,
          onCellValueChanged: vm.onCellValueChanged,
          onPaginationChanged: vm.onPaginationChanged,
          onRowDoubleClick: vm.onRowDoubleClick,
          onSelectionChanged: vm.onSelectionChanged,
          onReady: vm.onReady,
          onModelUpdated: vm.onModelUpdated,
          operationColumn: vm.buildOptionsColumn()
        }

        return {
          props: gridProps,
          slots: {
            header: renderToolStrip,
            bottomBar: () =>
              createVNode(KBottomBar, {
                enableExcelExport: true,
                onRefresh: () => {
                  vm.refresh()
                },
                onExcelSelectExport: () => {
                  vm.exportSelectAsExcel()
                },
                onExcelExport: () => {
                  vm.exportAsExcel()
                }
              })
          }
        }
      }

      /** 渲染器参数 */
      const dynamicViewProps: VNodeProps<KDynamicViewPropType> = {
        ref: vm.refDynamicView,
        name: vm.gridSchema.value.name,
        schema: vm.gridSchema.value,
        bizCtx: {},
        pageValue: {},
        readonly: props.readonly,
        layoutType: EnumRenderLayoutType.FLEX,
        getControlOptions: (params: DynamicViewGetControlOptionsParams): DynamicViewControlOptions | undefined => {
          switch (params.meta.control) {
            case 'KFilterGrid':
              return getGridOptions()
            default:
              return
          }
        }
      }
      if (!vm.gridSchema.value) {
        return <k-empty description="不存在布局" />
      } else {
        return (
          <section class="border1 h100p flow-detail">
            <div
              ref={vm.refContainer}
              class={['k-object-class-grid-base', vm.classAttr, attrs.class]}
              style={attrs.style as StyleValue}
            >
              <div class="grid-container" v-focus>
                {' '}
                {vm.showDynamicView && <k-dynamic-view {...dynamicViewProps}></k-dynamic-view>}
              </div>
            </div>
          </section>
        )
      }
    }
  }
})
