import { IIdentity, IIdentityProvider, IPrincipal } from '@kmsoft/upf-core'

export default class IdentityProvider implements IIdentityProvider {
  getIdentity(ticket: string | IPrincipal): Promise<IIdentity> {
    return new Promise((resolve, reject) => {
      if ((ticket as IPrincipal).userName == 'admin' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '673485653161287680',
          code: 'SYS_ADMIN',
          name: 'SYS_ADMIN',
          token: '673485653161287680/SYS_ADMIN/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'kmpdm' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '673462271329968128',
          code: 'kmpdm a15ac1c83d7940fab43ef41ae58c093a',
          name: 'kmpdm a15ac1c83d7940fab43ef41ae58c093a',
          token: '673462271329968128/kmpdm a15ac1c83d7940fab43ef41ae58c093a/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'kmsoft2022' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '673282395209732096',
          code: 'kmsoft2022 1008600000140151862',
          name: 'kmsoft2022 1008600000140151862',
          token: '673282395209732096/kmsoft2022 1008600000140151862/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'luodw' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '653160748062613504',
          code: 'luodw',
          name: 'luodw',
          token: '653160748062613504/luodw/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'yinp' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '653160811044282368',
          code: 'yinp',
          name: 'yinp',
          token: '653160811044282368/yinp/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'mengxy' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '653160843524972544',
          code: 'mengxy',
          name: 'mengxy',
          token: '653160843524972544/mengxy/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'taos' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '653160878593548288',
          code: 'taos',
          name: 'taos',
          token: '653160878593548288/taos/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'wangt' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '653160919412510720',
          code: 'wangt',
          name: 'wangt',
          token: '653160919412510720/wangt/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'kmpdm166' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '1008600000144211299',
          code: 'kmpdm@ipdc.huaweiapaas.com 1008600000144211299',
          name: 'kmpdm@ipdc.huaweiapaas.com 1008600000144211299',
          token: '1008600000144211299/kmpdm@ipdc.huaweiapaas.com 1008600000144211299/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'luodewang' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '1008600000400385811',
          code: 'luodewang@ipdc.huaweiapaas.com 1008600000400385811',
          name: 'luodewang@ipdc.huaweiapaas.com 1008600000400385811',
          token: '1008600000400385811/luodewang@ipdc.huaweiapaas.com 1008600000400385811/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else if ((ticket as IPrincipal).userName == 'zhaomousheng' && (ticket as IPrincipal).password == 'km153246') {
        const identity: IIdentity = {
          id: '1008600000136578825',
          code: 'zhaomousheng@ipdc.huaweiapaas.com 1008600000136578825',
          name: 'zhaomousheng@ipdc.huaweiapaas.com 1008600000136578825',
          token: '1008600000136578825/zhaomousheng@ipdc.huaweiapaas.com 1008600000136578825/-1',
          exprisTime: 1000 * 60 * 60 * 24 * 365
        }
        resolve(identity)
      } else {
        reject('登录失败')
      }
      // Promise.all([ticket, auth.getPublicKey()])
      //   .then(res => {
      //     const principal = res[0] as IPrincipal
      //     const data = res[1]
      //     // const [({ userName, password } as IPrincipal), { data: publicKey }] = res
      //     const encrypt = new JSEncrypt({ log: false })
      //     // 创建登录凭据
      //     encrypt.setPublicKey(data.data!)
      //     const encryptPwd = encrypt.encrypt(principal.password) as string
      //     const loginCmd: auth.LoginRequest = {
      //       tenantCode: '',
      //       account: principal.userName,
      //       password: encryptPwd
      //     }
      //     return loginCmd
      //   })
      //   .then(res => {
      //     auth.login(res).then(res => {
      //       if (res.isSuccess) {
      //         const data = res.data as any
      //         const identity: IIdentity = {
      //           id: String(data.userId),
      //           code: data.userCode,
      //           name: data.userName,
      //           token: data.token,
      //           exprisTime: data.expiredTime
      //         }
      //         resolve(identity)
      //       } else {
      //         reject(res.message)
      //       }
      //     })
      //   })
    })
  }
}
