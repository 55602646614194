import { connect, withInstall } from '@kmsoft/upf-core'
import KCollectRelatedPartView from './KCollectRelatedPart.vue'
import KCollectRelatedPartViewModel from './KCollectRelatedPartViewModel'

const KCollectRelatedPart = connect(KCollectRelatedPartView, KCollectRelatedPartViewModel)

export default withInstall(KCollectRelatedPart)
export { KCollectRelatedPart, KCollectRelatedPartView, KCollectRelatedPartViewModel }

// 模板生成文件
// export * from './{{folderName}}'
