import { connect, withInstall } from '@kmsoft/upf-core'
import KCreateCadWorkspaceView from './KCreateCadWorkspace.vue'
import KCreateCadWorkspaceViewModel from './KCreateCadWorkspaceViewModel'

const KCreateCadWorkspace = connect(KCreateCadWorkspaceView, KCreateCadWorkspaceViewModel)

export default withInstall(KCreateCadWorkspace)
export { KCreateCadWorkspace, KCreateCadWorkspaceView, KCreateCadWorkspaceViewModel }

// 模板生成文件
// export * from './{{folderName}}'
