import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_col = _resolveComponent("k-col")!
  const _component_k_row = _resolveComponent("k-row")!
  const _component_k_tree_select = _resolveComponent("k-tree-select")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    name: "baseForm",
    ref: _ctx.$vm.refBasePropForm,
    rules: _ctx.$vm.rules,
    model: _ctx.$vm.formData,
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_row, { span: "24" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_col, { span: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_form_item, {
                label: "名称",
                name: "name"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_input, {
                    name: "name",
                    value: _ctx.$vm.formData!.name,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formData!.name) = $event)),
                    maxlength: 64
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_row, { span: "24" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_col, { span: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_form_item, {
                label: "所属产品/项目库",
                name: "libraryFolderId"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_tree_select, {
                    name: "libraryFolderId",
                    value: _ctx.$vm.formData!.libraryFolderId,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formData!.libraryFolderId) = $event)),
                    treeExpandedKeys: _ctx.$vm.defaultExpandedKey,
                    "onUpdate:treeExpandedKeys": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.defaultExpandedKey) = $event)),
                    style: {"width":"100%"},
                    "tree-data-simple-mode": "",
                    "tree-icon": true,
                    "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                    "tree-data": _ctx.$vm.libraryTypeTreeData,
                    "allow-clear": "",
                    "show-search": "true",
                    treeNodeFilterProp: "title",
                    "tree-default-expand-all": false,
                    onSelect: _ctx.$vm.selectLibraryType
                  }, null, 8, ["value", "treeExpandedKeys", "tree-data", "onSelect"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_row, { span: "24" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_col, { span: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_form_item, {
                label: "零部件默认储存位置",
                name: "bomLibraryPosition"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_tree_select, {
                    name: "bomLibraryPosition",
                    value: _ctx.$vm.formData!.bomLibraryPosition,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.formData!.bomLibraryPosition) = $event)),
                    treeExpandedKeys: _ctx.$vm.partPositionExpandedKey,
                    "onUpdate:treeExpandedKeys": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.partPositionExpandedKey) = $event)),
                    style: {"width":"100%"},
                    "tree-data-simple-mode": "",
                    "tree-icon": true,
                    "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                    loadData: (node)=>_ctx.$vm.loadPartTreeData(node),
                    "tree-data": _ctx.$vm.partPositionTreeData,
                    "allow-clear": "",
                    "show-search": "true",
                    treeNodeFilterProp: "title",
                    "tree-default-expand-all": false,
                    onSelect: _ctx.$vm.selectBomLibraryPosition
                  }, null, 8, ["value", "treeExpandedKeys", "loadData", "tree-data", "onSelect"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_row, { span: "24" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_col, { span: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_form_item, {
                label: "图文档默认储存位置",
                name: "docLibraryPosition"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_tree_select, {
                    name: "docLibraryPosition",
                    value: _ctx.$vm.formData!.docLibraryPosition,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.formData!.docLibraryPosition) = $event)),
                    treeExpandedKeys: _ctx.$vm.docPositionExpandedKey,
                    "onUpdate:treeExpandedKeys": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.$vm.docPositionExpandedKey) = $event)),
                    style: {"width":"100%"},
                    "tree-data-simple-mode": "",
                    "tree-icon": true,
                    "dropdown-style": { maxHeight: '400px', overflow: 'auto' },
                    loadData: (node)=>_ctx.$vm.loadDocTreeData(node),
                    "tree-data": _ctx.$vm.docPositionTreeData,
                    "allow-clear": "",
                    "show-search": "true",
                    treeNodeFilterProp: "title",
                    "tree-default-expand-all": false,
                    onSelect: _ctx.$vm.selectDocLibraryPosition
                  }, null, 8, ["value", "treeExpandedKeys", "loadData", "tree-data", "onSelect"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_row, { span: "24" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_col, { span: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_form_item, {
                label: "本地缓存目录",
                name: "localCachePosition",
                class: "localCachePosition"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_input, {
                    name: "localCachePosition",
                    value: _ctx.$vm.formData!.localCachePosition,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.$vm.formData!.localCachePosition) = $event)),
                    disabled: ""
                  }, null, 8, ["value"]),
                  _createVNode(_component_k_button, {
                    onClick: _ctx.$vm.selectPath
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("浏览")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_k_row, { span: "24" }, {
        default: _withCtx(() => [
          _createVNode(_component_k_col, { span: "24" }, {
            default: _withCtx(() => [
              _createVNode(_component_k_form_item, {
                label: "描述",
                name: "description"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_textarea, {
                    rows: 3,
                    multiline: true,
                    visible: true,
                    name: "description",
                    value: _ctx.$vm.formData!.description,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.$vm.formData!.description) = $event)),
                    maxlength: 512
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["rules", "model"]))
}