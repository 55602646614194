import { KTreeNode, KTreeViewViewModel } from '@kmsoft/upf-core'
import { MetaClass, ClassMetaClientSrv, EnumClassTemplate, EnumModelCategoryType } from '../../../client-srv'
import KObjectClassTreeViewModel from '../KObjectClassTreeViewModel'
import _ from 'lodash'

/**
 * 对象节点
 */
export default class KTreeNodeObjClass extends KTreeNode {
  constructor(objClsProp: MetaClass, designer: boolean, hideObjClsCodes?: string[]) {
    super()
    this.designer = designer
    this.____transformTreeNodeObjClassData(objClsProp, hideObjClsCodes)
    this.LoadIconByTempletID()
  }

  /**设计模式 */
  designer: boolean
  /**对象类元数据定义属性 */
  objClassMetaProperty: MetaClass

  get parentTree() {
    return this.host as KObjectClassTreeViewModel
  }

  /**
   * 对象类节点的模板类型
   * @returns
   */
  get classTemplate(): string {
    return this.objClassMetaProperty.classTemplate
  }

  /**
   * 对象类节点的模板类型
   * @returns
   */
  get code(): string | undefined {
    return this.objClassMetaProperty.code
  }

  /**
   * 模型类型
   * @returns
   */
  get category() {
    return this.objClassMetaProperty.category
  }

  /** 根据功能过滤 */
  get feature() {
    return this.objClassMetaProperty.feature
  }

  /**节点依赖的树对象 */
  __getHost?: () => KTreeViewViewModel | undefined

  /**节点依赖的树对象 */
  get host(): KTreeViewViewModel | undefined {
    return this.__getHost?.()
  }

  /**
   * 展开节点
   * @param populateType
   */
  async populateChildren(): Promise<Array<KTreeNode>> {
    const nodeArray = new Array<KTreeNode>()
    const arry: Array<MetaClass> = await ClassMetaClientSrv.getChildClasses(this.id)

    for (let index = 0; index < arry.length; index++) {
      const element = _.cloneDeep(arry[index])

      if (this.parentTree.__isNodeHide(element.code)) {
        continue
      }

      nodeArray.push(new KTreeNodeObjClass(element, this.designer))
    }
    return nodeArray
  }

  /**
   * 加载对应的Icon
   */
  LoadIconByTempletID(): void {
    const objClasType = this.classTemplate
    this.iconType = this.__countyToCityMap(objClasType)
  }

  /**
   * 获取图标
   * @param objClasType 对象结点的模板类型
   * @param state 节点使用状态
   * @returns
   */
  __countyToCityMap(objClasType: string): string {
    switch (objClasType) {
      case EnumClassTemplate.ORG:
        return 'solution'
      case EnumClassTemplate.USER:
        return 'team'
      case EnumClassTemplate.PART:
        return 'setting'
      case EnumClassTemplate.DOC:
        return 'file-text'
      case EnumClassTemplate.COMMONOBJ:
        return 'big-class-model'
      case EnumClassTemplate.FLOWOBJ:
        return 'apartment'
      case EnumClassTemplate.FLOWSTEPOBJ:
        return 'apartment'
      case EnumClassTemplate.PROJECT:
        return 'ObjClass_Project_Used'
      case EnumClassTemplate.BOMVIEW:
        return 'ObjClass_Bom_Used'
      default:
        return 'big-class-model'
    }
  }

  /**
   * 转化tree需要的数据格式(对象类)
   * @param params
   */
  async ____transformTreeNodeObjClassData(params: MetaClass, hideObjClsCodes?: string[]): Promise<void> {
    this.id = params.id!
    this.name = params.name
    this.objClassMetaProperty = params
    let value = _.cloneDeep(await ClassMetaClientSrv.getChildClasses(params.id!))
    if (hideObjClsCodes && hideObjClsCodes.length > 0) {
      value = value.filter(item => hideObjClsCodes.indexOf(item.code) == -1)
    }
    this.isLeaf = _.cloneDeep(value.length <= 0)
    this.leaf = _.cloneDeep(value.length <= 0)
    // if (!this.designer) {
    this.disabled = params.code == 'ChangeItem' || params.code == 'Requirement'
    // }
  }
}
