import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e8041c3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border1 h100p" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { style: {"margin-left":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_collapse = _resolveComponent("k-collapse")!

  return (_openBlock(), _createBlock(_component_k_collapse, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event)),
    ghost: "",
    class: _normalizeClass([_ctx.activeKey.length ? 'h100p' : ''])
  }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createVNode(_component_k_data_grid, {
          dataSource: _ctx.signingHistoryList,
          "row-key": "processInstanceId",
          isMultipleSelection: false,
          onRowChanged: _ctx.$vm.rowChanged,
          selectFirstRow: _ctx.selectFirstRow
        }, {
          columns: _withCtx(() => [
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "流程名称",
              dataPropertyName: "processName",
              id: "processName",
              name: "processName",
              align: "center",
              width: "350"
            }, {
              default: _withCtx(({ cellValue,row }) => [
                _createElementVNode("a", {
                  href: _ctx.$vm.openProcessDetail(row),
                  target: "_blank"
                }, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(cellValue), 1)
                ], 8, _hoisted_2)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              dataPropertyName: "state",
              headerText: "流程状态",
              align: "center",
              width: "auto"
            }, {
              default: _withCtx(({ cellValue }) => [
                cellValue
                  ? (_openBlock(), _createBlock(_component_k_tag, {
                      key: 0,
                      color: _ctx.ProcessStateColor[cellValue as keyof typeof ProcessStateColor]
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.ProcessState[cellValue as keyof typeof ProcessState]), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "开始时间",
              dataPropertyName: "startTime",
              align: "center",
              width: "120"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "结束时间",
              dataPropertyName: "endTime",
              align: "center",
              width: "120"
            })
          ]),
          _: 1
        }, 8, ["dataSource", "onRowChanged", "selectFirstRow"])
      ])
    ]),
    _: 1
  }, 8, ["activeKey", "class"]))
}