import { EnumAttributeType, EnumDataType, KControlAttribute } from '@kmsoft/upf-core'
import { BOOLEAN_CANDIDATE } from './interface'
import { IViewConfigItem } from '../../types'

/** 配置项名称枚举 */
export enum EnumPropsConfigItemName {
  /** 元素标题 */
  TITLE = 'title',
  /** 可为空 */
  REQUIRED = 'required',
  /** 可编辑 */
  DISABLED = 'disabled',
  /** 组件参数 */
  CONTROL_ARGUMENT = 'controlArgument',
  /** 元素名称（唯一标识） */
  NAME = 'name',
  /** 组件类型 */
  CONTROL = 'control',
  /** 可见 */
  VISIBLE = 'visible'
}

/* 所有属性 */
export const PROPERTY_CONFIG_ITEM_COLLECTION = {
  TITLE: {
    name: EnumPropsConfigItemName.TITLE,
    title: '标题',
    control: 'KInput',
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    dataType: EnumDataType.STRING
  } as KControlAttribute,
  NAME: {
    name: EnumPropsConfigItemName.NAME,
    title: '名称(唯一标识)',
    control: 'KInput',
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    dataType: EnumDataType.STRING,
    disabled: true
  } as KControlAttribute,
  CONTROL: {
    name: EnumPropsConfigItemName.CONTROL,
    title: '组件类型',
    control: 'KInput',
    options: [],
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    dataType: EnumDataType.STRING,
    disabled: true
  } as KControlAttribute,
  NULLABLE: {
    name: EnumPropsConfigItemName.REQUIRED,
    title: '是否可为空',
    control: 'KSelect',
    attributeType: EnumAttributeType.BASE,
    dataType: EnumDataType.BOOL,
    options: BOOLEAN_CANDIDATE,
    defaultValue: true,
    disabled: false
  } as KControlAttribute,
  DISABLED: {
    name: EnumPropsConfigItemName.DISABLED,
    title: '是否禁用',
    control: 'KSelect',
    attributeType: EnumAttributeType.BASE,
    dataType: EnumDataType.BOOL,
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    disabled: false
  },
  VISIBLE: {
    name: EnumPropsConfigItemName.VISIBLE,
    title: '是否可见',
    control: 'KSelect',
    attributeType: EnumAttributeType.BASE,
    dataType: EnumDataType.BOOL,
    options: BOOLEAN_CANDIDATE,
    defaultValue: true,
    disabled: false
  },
  READONLY: {
    name: 'readonly',
    title: '是否只读',
    control: 'KSelect',
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.BASE,
    disabled: false
  } as KControlAttribute,
  CUSTOM: (configItem: IViewConfigItem) => __formatCustomItem(configItem)
}

function __formatCustomItem(configItem: IViewConfigItem) {
  configItem.defaultValue = configItem.defaultValue ? configItem.defaultValue : ''
  return configItem
}
