import { MemoryCacheFactory } from '@kmsoft/upf-core'
import { isArray } from 'lodash'
import { Api } from '../base'
import {
  // ClassAttachedDocLinkMemoryCache,
  // ClassAttachedTableLinkMemoryCache,
  // ClassLifecycleMemoryCache,
  ClassMetaMemoryCache
  // LayoutClassTabMemoryCache
} from '../cache'
import { ClassMeta, ClassMetaProperty, EnumEntityClass, MetaClass, MetaClassTab, MetaProperty } from './types'

/** 对象类服务 */
export class ClassMetaClientSrv {
  /** 缓存所有对象类 */
  static localAllClass: Array<MetaClass>

  //#region 缓存取数据
  /**
   * 获取所有对象类定义
   * @returns
   */
  static async getAllClass(): Promise<Array<MetaClass>> {
    if (this.localAllClass) {
      return this.localAllClass
    }
    //TODO:移除元数据缓存，由后端提供接口
    const metaClasses: Array<MetaClass> = []
    const cacheInstance = MemoryCacheFactory.get<ClassMetaMemoryCache>(ClassMetaMemoryCache.cacheKey)
    const classMetas = cacheInstance.getAll()
    if (!classMetas) {
      return metaClasses
    }
    for (const classMeta of classMetas) {
      const metaClass: MetaClass = this.convertClassMetaToMetaClass(classMeta)
      metaClasses.push(metaClass)
    }

    this.localAllClass = metaClasses

    return metaClasses
  }

  /**获取指定对象类 */
  static async getBusinessObjectClassByCode(code: string | Array<string>): Promise<Array<MetaClass>> {
    const array = isArray(code) ? code : [code]
    /** 所有对象类 */
    const allClass = await this.getAllClass()
    return array.length ? allClass.filter(item => array.includes(item.code)) : allClass
  }
  /**获取对象类属性 */
  static async getClassProperties(modelCode: string): Promise<Array<MetaProperty> | undefined> {
    const param = { data: [{ entityNameEn: modelCode }] }
    const res = await Api.post('common', 'DataModelManagement', 'getAllAttribute', param)
    if (res && res.success) {
      return res.data
    }
    return undefined
  }
  /**获取对象类属性 */
  static async getClassPropertiesContainsMaster(modelCode: string): Promise<Array<MetaProperty> | undefined> {
    const param = { data: [{ entityNameEn: modelCode }] }
    const res = await Api.post('common', 'DataModelManagement', 'getAllAttributeContainMaster', param)
    if (res && res.success) {
      res.data.masterAttributes.forEach((item: any) => {
        item.columnName = `master.${item.columnName}`
      })
      return [...res.data.attributes, ...res.data.masterAttributes]
    }
    return undefined
  }

  /**
   * 获取所有编辑器
   * @returns
   */
  static getAllEditors() {
    //TODO:查询所有属性编辑方式
    const cacheInstance = {} as any //MemoryCacheFactory.get<ClassPropertyEditorMemoryCache>(ClassPropertyEditorMemoryCache.cacheKey)
    return cacheInstance.getAll()
  }

  //#endregion

  //#region API取数据
  /**
   * 获取对象类标签页
   * @param modelCode 对象类 Code
   * @returns
   */
  static async getObjTabsAsync(modelCode: string): Promise<Array<MetaClassTab>> {
    const tabsResult = await Api.post('common', 'layout', 'classTab', { modelCode: modelCode })
    if (tabsResult.isSuccess) {
      return tabsResult.data! as Array<MetaClassTab>
    }

    return []
  }
  //#endregion

  /**
   * 获取对象类
   * @param modelCode 对象类 Code
   * @returns
   */
  static async getClassByClsCode(modelCode: string): Promise<MetaClass | undefined> {
    /** 所有对象类 */
    const allClass = await this.getAllClass()

    return allClass.find(a => a.code == modelCode)
  }
  /**
   * 获取对象类
   * @param modelCode 对象类 Code
   * @returns
   */
  static async getClassById(id: string): Promise<MetaClass | undefined> {
    /** 所有对象类 */
    const allClass = await this.getAllClass()

    return allClass.find(a => a.id == id) || undefined
  }

  /**
   * 获取子对象类定义
   * @param id 对象类 id
   * @returns
   */
  static async getChildClasses(id: string) {
    /** 所有对象类 */
    const allClass = await this.getAllClass()

    return allClass.filter(a => a.parentClassCode == id)
  }
  /**
   * 获取=所有子节点
   * @param code 对象类 code
   * @returns
   */
  static async getClassAndChild(code: string) {
    /** 所有对象类 */
    const allClass = await this.getAllClass()

    /**先查自己 */
    const findSelf = await this.getClassByClsCode(code)
    // 找到所有子对象
    const children = allClass.filter(item => item.parentClassCode === findSelf?.id)

    // 创建结果数组
    const result = [...children]

    // 递归处理子对象
    for (const child of children) {
      result.push(...(await this.getClassAndChild(child.code)))
    }

    return result
  }

  /**
   * 将后端元数据组装为前端使用的元数据
   * @param property
   */
  private static convertClassMetaPropertyToMetaProperty(property: ClassMetaProperty): MetaProperty {
    return {
      ...property,
      editor: {} as any //this.getEditorById(property.classCode, property.id)!
    }
  }

  /**
   * 组装对象类定义
   * @param classMeta
   * @returns
   */
  private static convertClassMetaToMetaClass(classMeta: ClassMeta) {
    const properties = classMeta.properties
    const newProps: Array<MetaProperty> = []

    for (const key in properties) {
      const property = properties[key]
      newProps.push(this.convertClassMetaPropertyToMetaProperty(property))
    }

    const metaClass: MetaClass = {
      ...classMeta,
      properties: newProps,
      // tabs: this.getAllTabs().filter(a => a.classCode == classMeta.code),
      // attachedTables: this.getAllAttachedTableLinks().filter(a => a.sourceClsCode == classMeta.code),
      // attachedDocs: this.getAllAttachedDocLinks().filter(a => a.sourceClsCode == classMeta.code),
      relations: []
    }

    return metaClass
  }
  static async getDesignerEntityCode(code: string): Promise<string | undefined> {
    const currentEntity = await this.getClassByClsCode(code)
    if (
      !currentEntity ||
      (currentEntity.entityClass !== EnumEntityClass.ENTITY && currentEntity.entityClass !== EnumEntityClass.SOFT_ENTITY)
    )
      return code
    if (currentEntity?.entityClass == EnumEntityClass.ENTITY) {
      return currentEntity.code
    } else {
      //找父节点
      const parentEntity = await this.getClassById(currentEntity?.parentClassCode!)
      return this.getDesignerEntityCode(parentEntity?.code!)
    }
  }
  //#endregion
}
