import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { IKInputNumberDefinition, KInputNumberEditorEmitsType, KInputNumberEditorPropType } from './interface'
import { ref, watch } from 'vue'

/** KInputNumber */
export default class KInputNumberEditorViewModel extends BaseViewModel<KInputNumberEditorEmitsType, KInputNumberEditorPropType> {
  formState = ref<IKInputNumberDefinition>()
  constructor(options: ViewModelOptions<KInputNumberEditorPropType>) {
    super(options)

    watch(
      () => options.props.propertyId,
      () => {
        // 判断editArgs是否为空
        const data = options.props.editArgs ? JSON.parse(options.props.editArgs) : {}
        this.formState.value = data as IKInputNumberDefinition
      },
      { immediate: true }
    )
  }

  viewDidMount() {}

  setValue(value: IKInputNumberDefinition) {
    this.formState.value = value
  }
  getValue() {
    return this.formState.value
  }
}
