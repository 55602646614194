
import {
  EnumDataGridAutoSizeColumnsMode,
  EnumDataGridRowModelType,
  EnumDataGridRowSpanType,
  TreeViewSelectEventArgs,
  IKTreeNode,
  defineView
} from '@kmsoft/upf-core'
import { clsCodeMap } from '@kmsoft/ebf-common'
import { KRequirementTracePropOptions, KRequirementTraceEventEmits } from './interface'
import KRequirementTraceViewModel from './KRequirementTraceViewModel'

export default defineView({
  name: 'KRequirementTrace',
  props: KRequirementTracePropOptions,
  emits: KRequirementTraceEventEmits,
  viewModel: KRequirementTraceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      clsCodeMap,
      EnumDataGridRowModelType,
      autoSizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL,
      rowSpanMode: EnumDataGridRowSpanType.SAME_ROW
    }
  }
})
