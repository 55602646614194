import { KNotification, KSchema } from '@kmsoft/upf-core'
import { EnumLayoutSchemaType, MetaClassTab } from '../meta'
import { Api, EnumRequestCode } from '../base'

/** 布局服务 */
export class LayoutClientSrv {
  static async getSchemeById(directory: string, modelCode: string, type: EnumLayoutSchemaType): Promise<KSchema> {
    //获取当前浏览器host
    const host = window.location.origin
    const url = `${host}/${directory}/${modelCode}/${type}.json`
    let schema = {} as KSchema
    const res = await LayoutClientSrv.loadSchema(url, modelCode) //layout.fetchLayout({ classCode: modelCode, type: type as any })
    if (res) schema = res as KSchema
    return schema
  }
  static async getTabsById(directory: string, modelCode: string): Promise<Array<MetaClassTab>> {
    const host = window.location.origin
    const url = `${host}/${directory}/${modelCode}/tab.json`
    let schema = [] as Array<MetaClassTab>
    const res = await LayoutClientSrv.loadSchema(url, modelCode)
    if (res) {
      schema = (res as unknown) as Array<MetaClassTab>
    }
    return schema
  }
  static loadSchema(url: string, modelCode: string) {
    let schema = (null as unknown) as KSchema | undefined
    const xhr = new XMLHttpRequest()
    xhr.open('get', url + '?' + new Date().getTime(), false)
    xhr.onload = (e: ProgressEvent) => {
      const httpRequest = e.currentTarget as XMLHttpRequest
      if (httpRequest.status == 200) {
        schema = JSON.parse(httpRequest.responseText)
      } else {
        schema = undefined
      }
    }
    xhr.onerror = () => {
      console.log(`获取布局方案【${modelCode}】出错`)
    }
    xhr.send()
    return schema
  }
  static async getSchemaByCode(clsCode: string, schemaCode: string): Promise<KSchema> {
    const params = {
      data: [
        {
          code: schemaCode,
          clsCode: clsCode
        }
      ]
    }
    const result: any = await Api.post('official', 'ClsLayoutDefService', 'getClsLayoutSchema', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const schema = JSON.parse(result.data)
      if (schema) {
        return schema as KSchema
      }
      return {} as KSchema
    } else {
      return {} as KSchema
    }
  }
  static async getSchemaByType(modelCode: string, type: EnumLayoutSchemaType) {
    const params = {
      data: [
        {
          type: type,
          clsCode: modelCode
        }
      ]
    }
    const result: any = await Api.post('official', 'ClsLayoutDefService', 'getClsLayoutSchemaByType', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const schema = result.data !== '' ? JSON.parse(result.data) : {}
      if (schema) {
        return schema as KSchema
      }
      return {} as KSchema
    } else {
      return {} as KSchema
    }
  }
}
