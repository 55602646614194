import { KNotification, KTreeNode, KTreeViewViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KObjectClassTreeEventEmitsTypes, KObjectClassTreePropType } from './interface'
import KTreeNodeObjClass from './node/KTreeNodeObjClass'
import { MetaClass } from '../../client-srv'

/** 导航管理 */
export default class KObjectClassTreeViewModel extends KTreeViewViewModel<
  KObjectClassTreeEventEmitsTypes,
  KObjectClassTreePropType
> {
  /**基础树控件引用 */
  refTreeView = ref<KTreeViewViewModel>()

  get selectedNode(): KTreeNodeObjClass | undefined {
    const node = this.refTreeView.value?.getSelectedNode()
    if (!node || node.id === '__root__') return
    return node as KTreeNodeObjClass
  }
  get selectedObjClsCode(): string | undefined {
    return this.selectedNode?.code
  }
  constructor(options: ViewModelOptions<KObjectClassTreePropType>) {
    super(options)
  }

  /** 加载完成函数 */
  viewDidMount() {}
  /**
   * 展开并选中指定对象类节点
   * @param modelCode
   * @returns
   */
  selectNode = async (modelCode: string): Promise<boolean> => {
    if (!modelCode) {
      console.warn('modelCode 为空')
      return false
    }
    //TODO:移除元数据依赖,由后端提供接口
    const path = ''
    const rootNodes = this.refTreeView.value?.getRootNodes() as Array<KTreeNode>
    console.log(path, rootNodes)

    for (let i = 0; i < rootNodes.length; i++) {
      const rootNode = rootNodes[i]
      const idx = path.indexOf(rootNode.id)
      const newPath = path.substring(idx)
      await this.refTreeView.value?.expandByPath(rootNode, newPath, true)
      const selectedNode = this.refTreeView.value?.getSelectedNode()
      //找到了，则直接返回

      if (selectedNode?.id == modelCode) return true
    }
    //未找到可能因为业务场景隐藏了
    //TODO:移除元数据依赖,由后端提供接口获取
    const objClsAttrib = {} as any
    KNotification.info({ message: `对象类“${objClsAttrib?.name}”已找到，但是不在当前对象类树中` })
    return false
  }

  /**是否需要隐藏 */
  __isNodeHide(argNodeId: string) {
    /** MEMaterial, Accessory为系统外的类型，直接过滤掉不显示 */
    if (['MEMaterial', 'Accessory'].includes(argNodeId)) {
      return true
    }
    if (!this.props.hideObjClsCodes || this.props.hideObjClsCodes.length == 0) {
      return false
    }
    return this.props.hideObjClsCodes.indexOf(argNodeId) > -1
  }

  __isNeedAdd(argNodeId: string) {
    if (this.props.showObjClsCodes && this.props.showObjClsCodes.length > 0) {
      if (this.props.showObjClsCodes.indexOf(argNodeId) !== -1) {
        return true
      }
    }
    if (this.props.showObjClsCodes && this.props.showObjClsCodes.length > 0) {
      if (this.props.showObjClsCodes.indexOf(argNodeId) !== -1) {
        return true
      }
    }
    return false
  }

  /**
   * 注册方法--节点能否添加到树中
   * @param argSubNodes 要添加的子节点集合
   */
  __getCanAddNodes = async (argSubNodes: Array<KTreeNodeObjClass>): Promise<Array<KTreeNode>> => {
    const addNode: Array<KTreeNodeObjClass> = []

    for (const subNode of argSubNodes) {
      if (subNode instanceof KTreeNodeObjClass && this.canAddClsNode(subNode as KTreeNodeObjClass)) {
        addNode.push(subNode)
      }
    }
    return addNode
  }
  // /**过滤 */
  // __getFilterNodes = async (argSubNodes: Array<MetaClass>): Promise<Array<MetaClass>> => {
  //   let addNode: Array<MetaClass> = []
  //   if (this.props.showObjClsCodes && this.props.showObjClsCodes.length > 0) {
  //     argSubNodes.filter(x => {
  //       if (this.props.showObjClsCodes.indexOf(x.code!) !== -1) {
  //         addNode.push(x)
  //       }
  //     })
  //   } else {
  //     addNode = argSubNodes
  //   }
  //   return addNode
  // }
  /**获取选中节点---多选 */
  getCheckedNodes() {
    return this.refTreeView.value!.getCheckedNodes()
  }
  /**获取选中节点---单选 */
  getSelectedNode() {
    return this.refTreeView.value!.getSelectedNode()
  }

  /**
   * 判断节点在本UC中能否添加到树中
   * @param argSubNode
   */
  private canAddClsNode(argSubNode: KTreeNodeObjClass): boolean {
    // 根据对象类模板过滤
    if (this.props.classTemplates && this.props.classTemplates.length > 0) {
      if (this.props.classTemplates.indexOf(argSubNode.classTemplate) == -1) {
        return false
      }
    }

    // 根据模型类型过滤
    if (this.props.modelCategory) {
      if (argSubNode.category != this.props.modelCategory) {
        return false
      }
    }

    // 根据显示的对象类Code过滤
    if (this.props.showObjClsCodes && this.props.showObjClsCodes.length > 0) {
      if (this.props.showObjClsCodes.indexOf(argSubNode.code!) == -1) {
        return false
      }
    }

    // 根据隐藏的对象类Code过滤
    if (this.props.hideObjClsCodes && this.props.hideObjClsCodes.length > 0) {
      if (this.props.hideObjClsCodes.indexOf(argSubNode.code!) > -1) {
        return false
      }
    }

    // 如果根据特征过滤
    if (this.props.feature) {
      // 循环特征
      for (const key in this.props.feature) {
        // 获取过滤值
        const featureValue = Reflect.get(this.props.feature, key)

        if (featureValue == undefined) {
          continue
        }

        // 获取节点值
        const nodeFeatureValue = Reflect.get(argSubNode.feature, key)

        // 如果过滤值和节点值不一致
        if (featureValue != nodeFeatureValue) {
          return false
        }
      }
    }
    return true
  }
}
