import {
  BaseViewModel,
  EnumToolStripCompType,
  IKTreeNode,
  KDataGridViewModel,
  KDialog,
  KNotification,
  KToolStripInputItemViewModel,
  KToolStripSelectItemViewModel,
  KTreeNode,
  KTreeViewViewModel,
  SelectOption,
  SelectOptionValue,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  ValueChangeEventArgs,
  ViewModelOptions,
  utils
} from '@kmsoft/upf-core'
import { KIssueReportRelationPartEmitsType, KIssueReportRelationPartPropType } from './interface'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  EnumVersionShowType,
  EnumLifecycleState,
  EnumWorkingState
} from '@kmsoft/ebf-common'
import KIssueReportPartStru from '../issue-report-part-stru'
import { nextTick, ref } from 'vue'

/** KIssueReportRelationPart */
export default class KIssueReportRelationPartViewModel extends BaseViewModel<
  KIssueReportRelationPartEmitsType,
  KIssueReportRelationPartPropType
> {
  /**
   * 视图下拉选项组件
   */
  public refViewSelect = ref<KToolStripSelectItemViewModel>()
  /**
   * 当前选择的层级
   */
  selectedLevel = ref<string>('1')
  /**
   * 版本下拉选项对象
   */
  public refVersionSelect = ref<KToolStripSelectItemViewModel>()
  /**
   * 选择的版本下拉选项
   */
  selectedQuerySpec = ref<string>('LATEST_WITHOUT_WORKING_COPY')

  /**
   * 外部选择的零部件对象ID和类型
   */
  partId = ref<string>('')
  rdmExtensionType = ref<string>()
  expandType = ref<string>()

  /** 树组件 */
  refPartTree = ref<KTreeViewViewModel>()
  /**
   * 所有树节点集合缓存起来
   */
  allNodes = ref<Record<string, any>>()
  /**
   * 当前选择的树节点对象
   */
  selectedNode = ref<KTreeNode>()

  /** 网格组件 */
  refObjGrid = ref<KDataGridViewModel>()
  /** 网格数据 */
  gridData = ref<any[]>([])

  public get querySpecOptions(): SelectOption[] {
    const result: SelectOption[] = []
    result.push({ label: '最新版本', value: 'LATEST_WITHOUT_WORKING_COPY' })
    result.push({ label: '最新发布版本', value: 'LATEST_RELEASED' })
    return result
  }

  public changeQuerySpec(value: any) {
    this.selectedQuerySpec.value = value.newValue
  }

  constructor(options: ViewModelOptions<KIssueReportRelationPartPropType>) {
    super(options)
    this.partId.value = this.props.objParam.id
    this.rdmExtensionType.value = this.props.objParam.rdmExtensionType
    this.expandType.value = this.props.objParam.expandType
  }

  // [key: string]: any; // 添加索引签名

  viewDidMount() {
    this.loadTreeData()
  }

  /**
   * 加载零部件向父递归查询树
   */
  async loadTreeData() {
    let data = [] as any
    const result = (await this.expandForGrid()) as any
    // 刷新图标视图
    const iconView = result!.map(
      (resultData: {
        userObject: any
        hasChildren: any
        pid: any
        name: any
        number: any
        id: any
        displayPath: any
        currentLevel: any
        index: any
        versionInfo: any
      }) => {
        const part =
          this.expandType.value == 'FORWARD_EXPANSION'
            ? resultData.userObject.bomLink.targetPart
            : resultData.userObject.bomLink.sourcePart
        return {
          id: resultData.id,
          hasChildren: resultData.hasChildren,
          parentId: resultData.pid,
          name: resultData.name,
          number: part.number,
          partId: part.id,
          partRdmExtensionType: part.rdmExtensionType,
          displayPath: resultData.displayPath,
          currentLevel: resultData.currentLevel,
          sumQuantity: resultData.userObject.bomLink.quantity,
          userObject: resultData.userObject,
          index: utils.uuid(),
          versionInfo: `${part.version}.${part.iteration}`
        }
      }
    ) as any
    if (iconView && iconView.length > 0) {
      for (let index = 0; index < iconView.length; index++) {
        data.push(iconView[index])
      }
    }
    // 缓存所有节点对象, 后续需要通过节点ID收缩树节点对象
    this.allNodes.value = data
    this.fillIcon(data)
    data = this.toTree(data)
    this.refPartTree.value?.setValue(data)
    if (data != null && data.length > 0) {
      this.refPartTree.value?.setSelectedNode(data[0].id)
    }
  }

  fillIcon(arr: Array<IKTreeNode>) {
    arr.forEach((node: any) => {
      node.iconType = node.hasChildren ? 'setting' : 'layout-item'
    })
  }

  /**
   * 将父子结构一维数组转换成children[]形式
   * @param arr 源数据集合
   * @returns 目标数据集合(默认只有一个对象)
   */
  toTree(arr: Array<IKTreeNode>): Array<IKTreeNode> {
    const tree = [] as Array<IKTreeNode>
    const codeMap = new Map()
    const map = new Map()

    arr.forEach((node: any) => {
      codeMap.set(node.id, { ...node })
      map.set(node.id + '_' + node.parentId, { ...node, children: [] })
    })

    map.forEach((node, _, map) => {
      const parentNode = codeMap.get(node.parentId)
      const parentId = node.parentId + '_' + parentNode?.parentId
      const parent = map.get(parentId)

      if (parent) {
        parent.children.push(node)
        parent.leaf = false
      } else {
        tree.push(node)
      }
    })

    return tree
  }

  /**
   * 网格展开
   */
  async expandForGrid() {
    const partId = this.partId.value
    // 获取对象
    const part = (await this.getPart(partId)) as any
    const maxLevel = this.selectedLevel.value === '' ? 1 : parseInt(this.selectedLevel.value)
    const param = {
      data: [
        {
          root: {
            id: partId,
            clazz: this.rdmExtensionType.value
          },
          expandType: this.expandType.value == 'FORWARD_EXPANSION' ? this.expandType.value : 'REVERSE_EXPANSION',
          queryRule: { type: this.selectedQuerySpec.value },
          view: part.partView?.name,
          maxLevel: maxLevel == 0 ? 99 : maxLevel
        }
      ]
    }
    const result = await Api.post('part', 'Part', 'expandForGrid', param)
    return new Promise((resolve, reject) => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        resolve(result.data)
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
        reject(void 0)
      }
    })
  }

  /**
   * 获取零部件
   */
  async getPart(id: string) {
    const result = (await Api.post('part', 'Part', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }

  /**
   * 树节点选择事件处理函数
   * @param event 事件对象
   */
  async afterSelect(event: TreeViewSelectEventArgs<any>) {
    // 缓存选中节点
    this.selectedNode.value = event.node
    this.requestGridData()
  }

  /**
   * 查询表格数据
   * @returns 表格数据行
   */
  async requestGridData() {
    // 不再发起查询, 直接从左侧树上找, 找当前选择节点的所有子节点
    const level = parseInt(this.selectedLevel.value) == 0 ? 99 : parseInt(this.selectedLevel.value)

    const xList = [] as any
    this.getRowDataFromNodeChildren(this.selectedNode.value, xList, level, 0)

    // 如果选择最新发布, 还要从节点清单从过滤一次
    // if (this.selectedQuerySpec.value === 'LATEST_RELEASED') {
    //   xList = xList.filter((item: any) => item.lifecycleStateEnum === 'Released')
    // }

    this.gridData.value = xList
    // this.gridDataFilter()
    this.refObjGrid.value?.clear()
    this.refObjGrid.value?.setDataSource(this.gridData.value)
    // 默认设置所有勾选
    setTimeout(() => {
      if (this.gridData.value.length > 0) {
        this.gridData.value.forEach((item: any) => {
          this.refObjGrid.value?.setSelectedRow(item.index, true)
        })
      }
    }, 30)
  }

  getRowDataFromNode(xNode: any) {
    const part =
      this.expandType.value == 'FORWARD_EXPANSION' ? xNode.userObject.bomLink.targetPart : xNode.userObject.bomLink.sourcePart
    return {
      ...part,
      index: xNode.index,
      displayPath: xNode.displayPath,
      currentLevel: xNode.currentLevel,
      sumQuantity: xNode.sumQuantity,
      versionInfo: xNode.versionInfo
    }
  }

  getRowDataFromNodeChildren(xNode: any, xList: any[], totalLevel: number, curLevel: number) {
    if (xNode === undefined || xNode === null) {
      return
    }
    if (curLevel > totalLevel + 1) {
      return
    }
    xList.push(this.getRowDataFromNode(xNode))
    const children = xNode.children || []
    if (children !== null && children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        this.getRowDataFromNodeChildren(children[i], xList, totalLevel, curLevel + 1)
      }
    }
  }

  gridDataFilter() {
    // 去重
    const gridDataMap = this.gridData.value.reduce((acc, current) => {
      // 版本
      current.versionInfo = `${current.version}.${current.iteration}`
      // 状态
      current.lifecycleStateDesc = current.lifecycleStateCode ? EnumLifecycleState[current.lifecycleStateCode] : '--'
      // 检入标记
      current.workingStateDesc = current.workingState
        ? EnumWorkingState[current.workingState as keyof typeof EnumWorkingState]
        : '--'

      acc[current.id] = current
      return acc
    }, {})
    // 零部件只筛选出"已发布"数据
    this.gridData.value = Object.values(gridDataMap)
  }

  /**
   * 查询零部件下级结构
   * @param row 数据行
   */
  public viewPartStructure(row: any) {
    KDialog.show({
      title: '零部件树结构',
      size: { width: 600, height: 600 },
      showApply: false,
      showConfirm: false,
      cancelText: '关闭',
      maximizeBox: false,
      minimizeBox: false,
      content: KIssueReportPartStru,
      props: {
        objParam: {
          id: row.id,
          rdmExtensionType: row.rdmExtensionType,
          modelCode: 'ChangeIssue',
          modelGroup: 'change'
        }
      }
    })
  }

  /**
   * 初始化视图下拉选项
   */
  public get viewOptions(): SelectOption[] {
    const result: SelectOption[] = []
    result.push({ label: '设计', value: 'Design' })
    return result
  }

  /**
   * 点击【查询】按钮
   */
  public search() {
    this.loadTreeData()

    // // 先收起所有节点
    // this.collapseAllNodes()
    // // 展开节点
    const nodes = this.refPartTree.value?.getRootNodes() || []
    const level = parseInt(this.selectedLevel.value)
    this.refPartTree.value?.expandLevel(nodes, 99)

    // // 如果当前节点在可见范围内
    // if (!this.checkIfCurrentSelectNodeWithinRange(parseInt(this.selectedLevel.value))) {
    //   this.selectedNode = ref()
    // }
    this.requestGridData()
  }

  /**
   * 收缩所有的树节点
   */
  collapseAllNodes() {
    this.allNodes.value?.forEach((item: any) => {
      this.refPartTree.value?.collapse(item.index)
    })
  }

  checkIfCurrentSelectNodeWithinRange(maxShowLevel: number): boolean {
    if (this.selectedNode.value === undefined || this.selectedNode.value === null) {
      return false
    }
    let level = 0
    let node = this.selectedNode.value
    let parentNode = this.refPartTree.value?.getParentNode(node?.id as any)
    while (parentNode != null) {
      level = level + 1
      node = parentNode as any
      parentNode = this.refPartTree.value?.getParentNode(node?.id as any)
    }
    return level <= maxShowLevel + 1
  }

  calcNodeLevel(node: any) {}

  getSelectedRows() {
    return this.refObjGrid.value?.getSelectedRows() || []
  }

  getView(row: any) {
    if (row.partView?.description) {
      return '(' + row.partView?.description + ')'
    }
    return ''
  }
}
