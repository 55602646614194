import { AppContext, BaseViewModel, ViewModelOptions, eventEmitter } from '@kmsoft/upf-core'
import { KAgentMonitorAppletEmitsType, KAgentMonitorAppletPropType } from './interface'
import { EBF_IOC_KEY, EnumWebSocketStatus, webSocketClientSrv } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KAgentMonitorApplet */
export default class KAgentMonitorAppletViewModel extends BaseViewModel<
  KAgentMonitorAppletEmitsType,
  KAgentMonitorAppletPropType
> {
  /** 状态消息 */
  message = ref('')
  /** 状态 */
  status = ref<EnumWebSocketStatus>(EnumWebSocketStatus.Disconnected)
  setIntervalId: string | number | NodeJS.Timeout | null | undefined = null
  constructor(options: ViewModelOptions<KAgentMonitorAppletPropType>) {
    super(options)
  }

  viewDidMount() {
    this.setIntervalId = setInterval(this.initAgentStatus, 10000)
    AppContext.current.getIocContainer().registerSingleton(EBF_IOC_KEY.AGENT_LINK, { updateAgentStatus: this.updateAgentStatus })
  }

  handleHoverChange(visible: boolean) {
    if (visible) {
      if (this.setIntervalId) {
        clearInterval(this.setIntervalId)
      }
    }
  }

  async initAgentStatus() {
    const isConnected = await webSocketClientSrv.isConnected()
    const status = isConnected ? EnumWebSocketStatus.Connected : EnumWebSocketStatus.Disconnected
    eventEmitter.emit(EBF_IOC_KEY.AGENT_LINK, 'updateAgentStatus', status as any)
  }
  /** 代理连接事件 */
  updateAgentStatus = (event: EnumWebSocketStatus): void => {
    this.status.value = event
    switch (event) {
      case EnumWebSocketStatus.Connected:
        this.message.value = '已连接'
        break
      case EnumWebSocketStatus.Connecting:
        this.message.value = '连接中'
        break
      case EnumWebSocketStatus.Disconnected:
        this.message.value = '未连接'
        break
      default:
        this.message.value = '未连接'
    }
  }
}
