import { BaseViewModel, IKForm, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KStructuredDocFormEmitsType, KStructuredDocFormPropType } from './interface'

/** KStructuredDocForm */
export default class KStructuredDocFormViewModel extends BaseViewModel<KStructuredDocFormEmitsType, KStructuredDocFormPropType> {
  /** 表单引用 */
  refForm = ref<IKForm<Record<string, any>> | null>(null)
  /** 表单值 */
  formValue = ref<Record<string, any>>({})

  constructor(options: ViewModelOptions<KStructuredDocFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  getFormValue() {
    return this.formValue.value
  }

  /** 校验 */
  async validate() {
    return this.refForm.value?.validate()
  }
}
