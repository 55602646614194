
import { defineView } from '@kmsoft/upf-core'
import { KProductNeedsSectionCreatePropOptions, KProductNeedsSectionCreateEventEmits } from './interface'
import KProductNeedsSectionCreateViewModel from './KProductNeedsSectionCreateViewModel'

export default defineView({
  name: 'KProductNeedsSectionCreate',
  props: KProductNeedsSectionCreatePropOptions,
  emits: KProductNeedsSectionCreateEventEmits,
  viewModel: KProductNeedsSectionCreateViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
