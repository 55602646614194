import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { class: "split-div" }
const _hoisted_3 = ["href", "onClick"]
const _hoisted_4 = {
  key: 0,
  class: "full requirement-obj-manage"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { style: {"margin-left":"5px"} }
const _hoisted_7 = {
  key: 1,
  class: "full requirement-obj-manage"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { style: {"margin-left":"5px"} }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  key: 2,
  class: "full requirement-obj-manage"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { style: {"margin-left":"5px"} }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = {
  key: 3,
  class: "full requirement-obj-manage"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { style: {"margin-left":"5px"} }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = {
  key: 4,
  class: "full requirement-obj-manage"
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { style: {"margin-left":"5px"} }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = {
  key: 5,
  class: "full requirement-obj-manage"
}
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { style: {"margin-left":"5px"} }
const _hoisted_27 = { key: 1 }
const _hoisted_28 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_list_item = _resolveComponent("k-list-item")!
  const _component_k_list = _resolveComponent("k-list")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_split_container = _resolveComponent("k-split-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_split_container, { class: "default-theme" }, {
      default: _withCtx(() => [
        _createVNode(_component_k_split_pane, { size: 15 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_k_list, {
                bordered: "",
                "data-source": _ctx.data
              }, {
                renderItem: _withCtx(({ item }) => [
                  (item.key == '3' || item.key == '5' || (item.key != '3' && _ctx.$vm.isProduct))
                    ? (_openBlock(), _createBlock(_component_k_list_item, {
                        key: 0,
                        class: _normalizeClass(_ctx.$vm.tabId == item.key || (item.key == '3' && _ctx.$vm.tabId == '4') ? 'select-list' : '')
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("a", {
                            href: item.id,
                            onClick: ($event: any) => (_ctx.$vm.titleClick(item.key))
                          }, _toDisplayString(item.title), 9, _hoisted_3)
                        ]),
                        _: 2
                      }, 1032, ["class"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_k_split_pane, null, {
          default: _withCtx(() => [
            (_ctx.$vm.tabId == '1')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_k_object_tool_strip, {
                    items: _ctx.$vm.toolStripItems,
                    onItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onPlanTaskToolStripItemClicked(event))
                  }, null, 8, ["items"]),
                  _createVNode(_component_k_data_grid, {
                    class: "plan-task-grid",
                    rowKey: "id",
                    name: "refPlanTaskGrid",
                    ref: _ctx.$vm.refPlanTaskGrid,
                    pagination: false,
                    rowModelType: _ctx.rowModelType,
                    loadData: _ctx.$vm.loadPlanTaskData,
                    autosizeColumnsMode: _ctx.autosizeColumnsMode
                  }, {
                    columns: _withCtx(() => [
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "name",
                        name: "name",
                        dataPropertyName: "planName",
                        headerText: "任务名称",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue,row }) => [
                          _createElementVNode("a", {
                            href: "javascript:void(0)",
                            onClick: () => _ctx.$vm.openPlanTaskTab(row)
                          }, [
                            _createElementVNode("span", _hoisted_6, _toDisplayString(cellValue), 1)
                          ], 8, _hoisted_5)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "rdmExtensionType",
                        name: "rdmExtensionType",
                        dataPropertyName: "planActivity",
                        headerText: "实体类型",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue.rdmExtensionType)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_combo_box_column, {
                        id: "type",
                        name: "type",
                        dataPropertyName: "type",
                        headerText: "类别",
                        readonly: false,
                        options: _ctx.$vm.planTypeList,
                        align: "left"
                      }, null, 8, ["options"]),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "version",
                        name: "version",
                        dataPropertyName: "version",
                        headerText: "版本号",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "planStartDateTime",
                        name: "planStartDateTime",
                        dataPropertyName: "planStartDateTime",
                        headerText: "预计开始时间",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "planEndDateTime",
                        name: "planEndDateTime",
                        dataPropertyName: "planEndDateTime",
                        headerText: "预计结束时间",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_combo_box_column, {
                        id: "lifeCycleId",
                        name: "lifeCycleId",
                        dataPropertyName: "lifeCycleId",
                        headerText: "计划状态",
                        readonly: false,
                        options: _ctx.$vm.planStatusList,
                        align: "left"
                      }, null, 8, ["options"]),
                      _createVNode(_component_k_data_grid_combo_box_column, {
                        id: "executionStatus",
                        name: "executionStatus",
                        dataPropertyName: "executionStatus",
                        headerText: "任务状态",
                        readonly: false,
                        options: _ctx.$vm.statusList,
                        align: "left"
                      }, null, 8, ["options"]),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "inChargeResourceName",
                        name: "inChargeResourceName",
                        dataPropertyName: "inChargeResourceName",
                        headerText: "任务负责人",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "creator",
                        name: "creator",
                        dataPropertyName: "creator",
                        headerText: "创建者",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "createTime",
                        name: "createTime",
                        dataPropertyName: "createTime",
                        headerText: "创建时间",
                        align: "left"
                      })
                    ]),
                    _: 1
                  }, 8, ["rowModelType", "loadData", "autosizeColumnsMode"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.$vm.tabId == '2')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_k_object_tool_strip, {
                    items: _ctx.$vm.toolStripItems,
                    onItemClicked: _cache[1] || (_cache[1] = (event)=>_ctx.$vm.onBusinessObjToolStripItemClicked(event))
                  }, null, 8, ["items"]),
                  _createVNode(_component_k_data_grid, {
                    class: "business-obj-grid",
                    rowKey: "id",
                    name: "refBusinessObjDataGrid",
                    ref: _ctx.$vm.refBusinessObjDataGrid,
                    pagination: false,
                    rowModelType: _ctx.rowModelType,
                    loadData: _ctx.$vm.loadBusinessObjData,
                    autosizeColumnsMode: _ctx.autosizeColumnsMode
                  }, {
                    columns: _withCtx(() => [
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "name",
                        name: "name",
                        dataPropertyName: "targetName",
                        headerText: "对象名称",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "number",
                        name: "number",
                        dataPropertyName: "targetNumber",
                        headerText: "对象编码",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue,row }) => [
                          _createElementVNode("a", {
                            href: "javascript:void(0)",
                            onClick: () => _ctx.$vm.openObjTab(row)
                          }, [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(cellValue), 1)
                          ], 8, _hoisted_8)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "rdmExtensionType",
                        name: "rdmExtensionType",
                        dataPropertyName: "targetRdmExtensionType",
                        headerText: "实体类型",
                        align: "left"
                      }, {
                        default: _withCtx(({ row }) => [
                          _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(row.targetRdmExtensionType) + _ctx.$vm.getView(row)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "version",
                        name: "version",
                        dataPropertyName: "version",
                        headerText: "业务版本",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "lifecycleStateCode",
                        name: "lifecycleStateCode",
                        dataPropertyName: "lifecycleStateCode",
                        headerText: "生命周期状态",
                        width: "140",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue }) => [
                          cellValue
                            ? (_openBlock(), _createBlock(_component_k_tag, {
                                key: 0,
                                color: _ctx.EnumLifecycleStateColor[cellValue]
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.EnumLifecycleStateSimple[cellValue]), 1)
                                ]),
                                _: 2
                              }, 1032, ["color"]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_10, "--"))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "description",
                        name: "description",
                        dataPropertyName: "description",
                        headerText: "描述",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "creator",
                        name: "creator",
                        dataPropertyName: "creator",
                        headerText: "创建者",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "createTime",
                        name: "createTime",
                        dataPropertyName: "createTime",
                        headerText: "创建时间",
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "action",
                        name: "action",
                        fixed: "right",
                        align: "left",
                        headerText: "操作",
                        width: "auto"
                      }, {
                        default: _withCtx(({row}) => [
                          _createElementVNode("a", {
                            class: "row-operation-item",
                            onClick: (e) => _ctx.$vm.openObjTab(row)
                          }, "查看", 8, _hoisted_11)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rowModelType", "loadData", "autosizeColumnsMode"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.$vm.tabId == '3')
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _createVNode(_component_k_object_tool_strip, {
                    items: _ctx.$vm.toolStripItems,
                    onItemClicked: _cache[2] || (_cache[2] = (event)=>_ctx.$vm.onReqLinkToolStripItemClicked(event))
                  }, null, 8, ["items"]),
                  _createVNode(_component_k_data_grid, {
                    class: "req-reference-grid",
                    rowKey: "id",
                    name: "refReqLinkDataGrid",
                    ref: _ctx.$vm.refReqLinkDataGrid,
                    pagination: false,
                    rowModelType: _ctx.rowModelType,
                    loadData: _ctx.$vm.loadReferenceData,
                    autosizeColumnsMode: _ctx.autosizeColumnsMode
                  }, {
                    columns: _withCtx(() => [
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "title",
                        name: "title",
                        dataPropertyName: "title",
                        headerText: "标题",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "number",
                        name: "number",
                        dataPropertyName: "number",
                        headerText: "编码",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue,row }) => [
                          _createElementVNode("a", {
                            href: "javascript:void(0)",
                            onClick: () => _ctx.$vm.openReqTab(row)
                          }, [
                            _createElementVNode("span", _hoisted_14, _toDisplayString(cellValue), 1)
                          ], 8, _hoisted_13)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "rdmExtensionType",
                        name: "rdmExtensionType",
                        dataPropertyName: "sourceRdmExtensionType",
                        headerText: "实体类型",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "description",
                        name: "description",
                        dataPropertyName: "description",
                        headerText: "描述",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "creator",
                        name: "creator",
                        dataPropertyName: "creator",
                        headerText: "创建者",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "createTime",
                        name: "createTime",
                        dataPropertyName: "createTime",
                        headerText: "创建时间",
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "action",
                        name: "action",
                        fixed: "right",
                        align: "left",
                        headerText: "操作",
                        width: "auto"
                      }, {
                        default: _withCtx(({row}) => [
                          _createElementVNode("a", {
                            class: "row-operation-item",
                            onClick: (e) => _ctx.$vm.openReqTab(row)
                          }, "查看", 8, _hoisted_15)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rowModelType", "loadData", "autosizeColumnsMode"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.$vm.tabId == '4')
              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                  _createVNode(_component_k_object_tool_strip, {
                    items: _ctx.$vm.toolStripItems,
                    onItemClicked: _cache[3] || (_cache[3] = (event)=>_ctx.$vm.onReqProductLinkToolStripItemClicked(event))
                  }, null, 8, ["items"]),
                  _createVNode(_component_k_data_grid, {
                    class: "req-reference-product-grid",
                    rowKey: "id",
                    name: "refReqProductLinkDataGrid",
                    ref: _ctx.$vm.refReqProductLinkDataGrid,
                    pagination: false,
                    rowModelType: _ctx.rowModelType,
                    loadData: _ctx.$vm.loadReferenceProductData,
                    autosizeColumnsMode: _ctx.autosizeColumnsMode
                  }, {
                    columns: _withCtx(() => [
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "title",
                        name: "title",
                        dataPropertyName: "title",
                        headerText: "标题",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "number",
                        name: "number",
                        dataPropertyName: "number",
                        headerText: "编码",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue,row }) => [
                          _createElementVNode("a", {
                            href: "javascript:void(0)",
                            onClick: () => _ctx.$vm.openReqProductTab(row)
                          }, [
                            _createElementVNode("span", _hoisted_18, _toDisplayString(cellValue), 1)
                          ], 8, _hoisted_17)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "rdmExtensionType",
                        name: "rdmExtensionType",
                        dataPropertyName: "targetRdmExtensionType",
                        headerText: "实体类型",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "description",
                        name: "description",
                        dataPropertyName: "description",
                        headerText: "描述",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "creator",
                        name: "creator",
                        dataPropertyName: "creator",
                        headerText: "创建者",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "createTime",
                        name: "createTime",
                        dataPropertyName: "createTime",
                        headerText: "创建时间",
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "action",
                        name: "action",
                        fixed: "right",
                        align: "left",
                        headerText: "操作",
                        width: "auto"
                      }, {
                        default: _withCtx(({row}) => [
                          _createElementVNode("a", {
                            class: "row-operation-item",
                            onClick: (e) => _ctx.$vm.openReqProductTab(row)
                          }, "查看", 8, _hoisted_19)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rowModelType", "loadData", "autosizeColumnsMode"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.$vm.tabId == '5')
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createVNode(_component_k_object_tool_strip, {
                    items: _ctx.$vm.containLinkToolStripItems,
                    onItemClicked: _cache[4] || (_cache[4] = (event)=>_ctx.$vm.onContainLinkToolStripItemClicked(event))
                  }, null, 8, ["items"]),
                  _createVNode(_component_k_data_grid, {
                    class: "contain-link-grid",
                    rowKey: "id",
                    name: "refContainLinkDataGrid",
                    ref: _ctx.$vm.refContainLinkDataGrid,
                    pagination: false,
                    rowModelType: _ctx.rowModelType,
                    loadData: _ctx.$vm.loadContainLinkData,
                    autosizeColumnsMode: _ctx.autosizeColumnsMode
                  }, {
                    columns: _withCtx(() => [
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "title",
                        name: "title",
                        dataPropertyName: "targetNeedsSection.title",
                        headerText: "标题",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "number",
                        name: "number",
                        dataPropertyName: "targetNeedsSection.number",
                        headerText: "编码",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue,row }) => [
                          _createElementVNode("a", {
                            href: "javascript:void(0)",
                            onClick: () => _ctx.$vm.openNeedsSectionTab(row)
                          }, [
                            _createElementVNode("span", _hoisted_22, _toDisplayString(cellValue), 1)
                          ], 8, _hoisted_21)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "rdmExtensionType",
                        name: "rdmExtensionType",
                        dataPropertyName: "targetNeedsSection.rdmExtensionType",
                        headerText: "实体类型",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "description",
                        name: "description",
                        dataPropertyName: "description",
                        headerText: "描述",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "creator",
                        name: "creator",
                        dataPropertyName: "creator",
                        headerText: "创建者",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "createTime",
                        name: "createTime",
                        dataPropertyName: "createTime",
                        headerText: "创建时间",
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "action",
                        name: "action",
                        fixed: "right",
                        align: "left",
                        headerText: "操作",
                        width: "auto"
                      }, {
                        default: _withCtx(({row}) => [
                          _createElementVNode("a", {
                            class: "row-operation-item",
                            onClick: (e) => _ctx.$vm.openNeedsSectionTab(row)
                          }, "查看", 8, _hoisted_23)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rowModelType", "loadData", "autosizeColumnsMode"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.$vm.tabId == '6')
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createVNode(_component_k_object_tool_strip, {
                    items: _ctx.$vm.requirementChangeRequestToolStripItems,
                    onItemClicked: _cache[5] || (_cache[5] = (event)=>_ctx.$vm.onRequirementChangeRequestToolStripItemClicked(event))
                  }, null, 8, ["items"]),
                  _createVNode(_component_k_data_grid, {
                    class: "requirement-change-request-grid",
                    rowKey: "id",
                    name: "refContainLinkDataGrid",
                    ref: _ctx.$vm.refChangeRequestDataGrid,
                    pagination: false,
                    rowModelType: _ctx.rowModelType,
                    loadData: _ctx.$vm.loadRequestChageData,
                    autosizeColumnsMode: _ctx.autosizeColumnsMode
                  }, {
                    columns: _withCtx(() => [
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "rdmExtensionType",
                        name: "rdmExtensionType",
                        dataPropertyName: "rdmExtensionType",
                        headerText: "实体类型",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "number",
                        name: "number",
                        dataPropertyName: "number",
                        headerText: "编码",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue,row }) => [
                          _createElementVNode("a", {
                            href: "javascript:void(0)",
                            onClick: () => _ctx.$vm.openRequestChange(row)
                          }, [
                            _createElementVNode("span", _hoisted_26, _toDisplayString(cellValue), 1)
                          ], 8, _hoisted_25)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "title",
                        name: "title",
                        dataPropertyName: "title",
                        headerText: "标题",
                        readonly: false,
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "lifecycleStateCode",
                        name: "lifecycleStateCode",
                        dataPropertyName: "lifecycleStateCode",
                        headerText: "生命周期状态",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue }) => [
                          cellValue
                            ? (_openBlock(), _createBlock(_component_k_tag, {
                                key: 0,
                                color: _ctx.EnumLifecycleStateColor[cellValue]
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.EnumLifecycleState[cellValue]), 1)
                                ]),
                                _: 2
                              }, 1032, ["color"]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_27, "--"))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "creator",
                        name: "creator",
                        dataPropertyName: "creator",
                        headerText: "需要日期",
                        align: "left"
                      }, {
                        default: _withCtx(({ cellValue}) => [
                          _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_k_data_grid_text_box_column, {
                        id: "createTime",
                        name: "createTime",
                        dataPropertyName: "createTime",
                        headerText: "负责人",
                        align: "left"
                      }),
                      _createVNode(_component_k_data_grid_template_column, {
                        id: "action",
                        name: "action",
                        fixed: "right",
                        align: "left",
                        headerText: "操作",
                        width: "auto"
                      }, {
                        default: _withCtx(({row}) => [
                          _createElementVNode("a", {
                            class: "row-operation-item",
                            onClick: (e) => _ctx.$vm.openRequestChange(row)
                          }, "查看", 8, _hoisted_28)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rowModelType", "loadData", "autosizeColumnsMode"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}