
import { defineView } from '@kmsoft/upf-core'
import { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KDistributionContentObjGridPropOptions, KDistributionContentObjGridEventEmits } from './interface'
import KDistributionContentObjGridViewModel from './KDistributionContentObjGridViewModel'

export default defineView({
  name: 'KDistributionContentObjGrid',
  props: KDistributionContentObjGridPropOptions,
  emits: KDistributionContentObjGridEventEmits,
  viewModel: KDistributionContentObjGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor }
  }
})
