import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KAddPlanDialogPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KAddPlanDialogPropType = ViewPropsTypeExtract<typeof KAddPlanDialogPropOptions>

/** 事件 */
export const KAddPlanDialogEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KAddPlanDialogEmitsType = ViewEmitsTypeExtract<typeof KAddPlanDialogEventEmits>
