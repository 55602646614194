
import { defineView } from '@kmsoft/upf-core'
import { KBaselineCompare } from '../../pages/baseline-compare'
import { KBaselineCompareDialogPropOptions, KBaselineCompareDialogEventEmits } from './interface'
import KBaselineCompareDialogViewModel from './KBaselineCompareDialogViewModel'

export default defineView({
  name: 'KBaselineCompareDialog',
  props: KBaselineCompareDialogPropOptions,
  emits: KBaselineCompareDialogEventEmits,
  components: { KBaselineCompare },
  viewModel: KBaselineCompareDialogViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
