import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KFlowHistorySigningEmitsType, KFlowHistorySigningPropType } from './interface'
import { WorkflowClientSrv } from '../../client-srv/workflow-client-srv/WorkflowClientSrv'

/** KFlowHistorySigning */
export default class KFlowHistorySigningViewModel extends BaseViewModel<
  KFlowHistorySigningEmitsType,
  KFlowHistorySigningPropType
> {
  constructor(options: ViewModelOptions<KFlowHistorySigningPropType>) {
    super(options)
  }

  viewDidMount() {}

  rowChanged({ row }: { row: any }) {
    this.emit('rowChanged', row)
  }

  openProcessDetail(row: any) {
    return WorkflowClientSrv.openProcessDetail(row)
  }
}
