import { KBasePropertyEditorPropOptions, ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KPlanValidationMetricsGridPropOptions = {
  ...BaseViewPropOptions,
  ...KBasePropertyEditorPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KPlanValidationMetricsGridPropType = ViewPropsTypeExtract<typeof KPlanValidationMetricsGridPropOptions>

/** 事件 */
export const KPlanValidationMetricsGridEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPlanValidationMetricsGridEmitsType = ViewEmitsTypeExtract<typeof KPlanValidationMetricsGridEventEmits>
