import { BaseViewModel, KDataGridViewModel, KDialog, KNotification, SimpleViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KActivityPlanPreDetailGridEmitsType, KActivityPlanPreDetailGridPropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KActivityPlanPreDetailGrid */
export default class KActivityPlanPreDetailGridViewModel extends SimpleViewModel<
  KActivityPlanPreDetailGridEmitsType,
  KActivityPlanPreDetailGridPropType
> {

  refDataGrid = ref<KDataGridViewModel>()
  
  constructor(options: ViewModelOptions<KActivityPlanPreDetailGridPropType>) {
    super(options)
  }

  viewDidMount() {
  }

  /** 网格准备完毕事件 */
  onReady() {
    if(this.props.preChangeActivities){
      this.props.preChangeActivities.forEach(number=>{
        this.refDataGrid.value?.setSelectedRow(number, true)
      })
    }
  }

  getSelectedRows() {
    return this.refDataGrid.value?.getSelectedRows() || []
  }
}
