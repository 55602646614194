
import { defineComponent, ref } from 'vue'
import { theme } from 'ant-design-vue'
import { VuePropTypes, AppContext, NavigationMenuGroup, utils } from '@kmsoft/upf-core'
import { FolderClientSrv } from '../../../client-srv'
import KNavigationBar from './KNavigationBar'

export default defineComponent({
  name: 'KExtendLayoutSidebar',
  components: {
    KNavigationBar
  },
  props: {
    theme: VuePropTypes.string().def('dark'),
    menus: VuePropTypes.array().def(),
    menuActiveKey: VuePropTypes.string().def(),
    isMenusVisible: VuePropTypes.bool().def(true)
  },
  setup(props) {
    let { token } = theme.useToken()
    const environment = AppContext.current.getEnvironment()
    let url = location.href
    const ulrList = utils.getQueryObjList(url) as Array<any>
    const objParam = ulrList.find(obj => Object.prototype.hasOwnProperty.call(obj, 'objParam'))
    const objParamCache = sessionStorage.getItem('KMPDM-openDetail')

    if (objParam || objParamCache) {
      const baseParam = objParam ? objParam.objParam : objParamCache
      const data = atob(baseParam)
      const params = new URLSearchParams(data)
      const obj: Record<string, any> = {}
      params.forEach((value, key) => {
        obj[key] = value
      })
      if (objParam) {
        sessionStorage.setItem('KMPDM-openDetail', baseParam)
      }
      FolderClientSrv.openObjTab(obj)
    }

    return {
      showSiderLayout: objParam || objParamCache ? false : true,
      appName: environment.getAppName(),
      collapseBtnColor: '#ffffff',
      menusRef: ref<NavigationMenuGroup[]>(props.menus as NavigationMenuGroup[]),
      globalStyleObj: ref<any>(token)
    }
  },
  data() {
    return {
      collapsed: false
    }
  },
  methods: {
    onCollapse() {
      this.collapsed = true
    },
    onExpand() {
      this.collapsed = false
    }
  }
})
