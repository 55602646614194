import {
  SimpleViewPropOptions,
  SimpleViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { KBasePropertyEditorPropOptions } from '@kmsoft/ebf-common'

/** 参数 **/
export const KBaselineMemberGridPropOptions = {
  ...SimpleViewPropOptions,
  ...KBasePropertyEditorPropOptions,
  /** 是否设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KBaselineMemberGridPropType = ViewPropsTypeExtract<typeof KBaselineMemberGridPropOptions>

/** 事件 */
export const KBaselineMemberGridEventEmits = {
  ...SimpleViewEventEmits,
  setPrimaryObj: (row: Record<string, any>) => true
}

/** 事件类型 **/
export type KBaselineMemberGridEmitsType = ViewEmitsTypeExtract<typeof KBaselineMemberGridEventEmits>
