import { connect, withInstall } from '@kmsoft/upf-core'
import KPersonalVerificationPlanView from './KPersonalVerificationPlan.vue'
import KPersonalVerificationPlanViewModel from './KPersonalVerificationPlanViewModel'

const KPersonalVerificationPlan = connect(KPersonalVerificationPlanView, KPersonalVerificationPlanViewModel)

export default withInstall(KPersonalVerificationPlan)
export { KPersonalVerificationPlan, KPersonalVerificationPlanView, KPersonalVerificationPlanViewModel }

// 模板生成文件
// export * from './{{folderName}}'
