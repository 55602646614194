import { connect, withInstall } from '@kmsoft/upf-core'
import KRequirementObjManageView from './KRequirementObjManage.vue'
import KRequirementObjManageViewModel from './KRequirementObjManageViewModel'

const KRequirementObjManage = connect(KRequirementObjManageView, KRequirementObjManageViewModel)

export default withInstall(KRequirementObjManage)
export { KRequirementObjManage, KRequirementObjManageView, KRequirementObjManageViewModel }

// 模板生成文件
// export * from './{{folderName}}'
