import { AppContext, IRequestInterceptor, KDialog, KNotification, RequestConfig, Response } from '@kmsoft/upf-core'

export default class RequestInterceptor implements IRequestInterceptor {
  // 读取全局变量，判断当前是否使用mock
  isMock = (window as any).kmsoft.config.VUE_APP_IS_MOCK && (window as any).kmsoft.config.VUE_APP_IS_MOCK == 'true'
  baseApiUrl = this.isMock ? '' : (window as any).kmsoft.config.baseApiUrl

  requestHandler(config: RequestConfig): RequestConfig | Promise<RequestConfig> {
    //全局请求拦截
    const token = AppContext.current.getIdentity()?.token
    if (token !== undefined && config.headers) {
      config.headers['token'] = token
      // config.headers['token'] = '568039397618880512/kmpdm a15ac1c83d7940fab43ef41ae58c093a/-1'
      if (process.env.NODE_ENV !== 'production') {
        //config.headers['x-debug-host'] = '192.168.20.189'
        config.headers['x-debug-host'] = '192.168.80.52'
      }
    } else {
      // KNotification.warn({
      //   message: '用户已过期，需重新登陆'
      // })
      AppContext.current.getApplication().router.redirectLoginPage()
    }
    return config
  }

  requestErrorHandler(err: any): any {
    return Promise.reject(err)
  }

  responseHandler(response: Response): Response<any> | Promise<Response<any>> {
    if (response.headers['content-type'] === 'application/octet-stream') {
      return response
    }

    // 如果请求失败 且 错误码包含认证信息
    if (response.data && response.data.state == 'fail' && response.data.errors[0].code.indexOf('AUTH') >= 0) {
      /** 错误消息 */
      const errorResult = response.data

      // 如果是认证错误
      if (errorResult.errors && errorResult.errors.length > 0 && errorResult.errors[0].code.indexOf('AUTH') >= 0) {
        const currentApplication = AppContext.current.getApplication()
        const currentPath = currentApplication.router.vueRouter.currentRoute.value.path
        // 如果当前不是登录页
        if (currentPath != '/login') {
          currentApplication.router.redirectLoginPage()
        }
      }

      return response.data
    }

    return response.data
  }

  responseErrorHandler(err: any): any {
    const logger = AppContext.current.getLogger()
    logger.error(err.message)
    const responseErrors = err.response.data.errors
    if (responseErrors && responseErrors.length == 1) {
      const errorCode = err.response.data.errors[0].code
      if (errorCode === 'AUTH0003') {
        AppContext.current.getApplication().router.redirectLoginPage()
      }
    }
    //异常处理
    if (err.message === 'Network Error') {
      KNotification.error({
        title: '请求错误',
        content: '网络连接异常,请检查网络设置'
      })
      return Promise.reject(err)
    }

    // 如果是请求超时
    if (err.code === 'ECONNABORTED') {
      KNotification.error({
        title: '请求错误',
        content: '请求超时,请稍后重试'
      })
      return Promise.reject(err)
    }
    if (err.response) {
      switch (err.response.status) {
        case 400:
          KNotification.error({
            title: '请求错误',
            content: '请求参数错误'
          })
          break
        case 401:
          KNotification.error({
            title: '未授权',
            content: '请先登录'
          })
          AppContext.current.getApplication().router.redirectLoginPage()
          break
        case 403:
          KNotification.error({
            title: '拒绝访问',
            content: '没有权限访问该资源'
          })
          break
        case 404:
          KNotification.error({
            title: '请求错误',
            content: '请求的资源不存在'
          })
          break
        case 500:
          // 检查是否包含文件占用的错误信息
          if (err.response.data && err.response.data.includes('used by another process')) {
            KNotification.error({
              title: '文件访问错误',
              content: '文件正被其他程序使用，请关闭相关程序后重试'
            })
          } else {
            KNotification.error({
              title: '请求错误',
              content: '服务器内部错误'
            })
          }
          break
        default:
          KNotification.error({
            title: '请求错误',
            content: `HTTP错误 ${err.response.status}`
          })
      }
      return Promise.reject(err)
    }
  }
}
