// import { commonClientSrv, KDialog, objectClientSrv } from '@kmplm/ecol-base'
// import { ObjBusinessParam, objBusinessSrv } from '@kmplm/ecol-data-srv'
import lodash from 'lodash'
import { resolveComponent } from 'vue'
import { KProjectPageWrapper } from '../components'
import { ClassMetaClientSrv, CommonClientSrv, ObjBusinessParam, ObjectClientSrv } from '@kmsoft/ebf-common'
import { KDialog, KDataGridRowData } from '@kmsoft/upf-core'
import { DocClientSrv } from '../../../ebf-doc/src/client-srv'
import { PartClientSrv } from '../../../ebf-part/src'

/**
 * IFrame框架消息客户端服务类
 */
class IFrameMsgClientSrv {
  private _windowid: string

  setWindowId(windowid: string) {
    this._windowid = windowid
  }

  get windowid() {
    return this._windowid
  }

  convertParamsToString = (params: any): any => {
    if (lodash.isArray(params)) {
      const newObj: Array<Record<string, any>> = []
      for (const iterator of params) {
        newObj.push(this.convertParamsToString(iterator))
      }
      return newObj
    }

    if (lodash.isObject(params)) {
      const newObj: Record<string, any> = {}
      for (const key in params) {
        const obj = (params as Record<string, any>)[key]
        newObj[key] = this.convertParamsToString(obj)
      }
      return newObj
    }

    if (lodash.isNumber(params)) {
      return params.toString()
    }

    return params
  }

  ValidateParam = async (objParams: Array<ObjBusinessParam>) => {
    const filterParams = objParams.filter(a => a.id != null)
    let msg
    if (!filterParams || filterParams.length == 0) {
      KDialog.warning({ title: '提示', content: '未选中实际交付物！' })
      return
    }

    if (filterParams.length !== objParams.length) {
      msg = '已选中' + filterParams.length + '个对象'
      const result = await KDialog.confirmAsync({ content: '已选中' + filterParams.length + '个交付物对象' })
      if (!result) return
    }
    return filterParams
  }

  openTab(title: string, tabUrl: string) {
    CommonClientSrv.openPage(
      title,
      KProjectPageWrapper,
      {
        name: tabUrl,
        isAbsUrl: true,
        windowId: new Date().getTime()
      },
      tabUrl
    )
  }

  async openObjTab(objParam: ObjBusinessParam) {
    const param = { ...objParam } as ObjBusinessParam
    const group = await ObjectClientSrv.getModelGroupByCode(objParam.modelCode)!
    param.modelGroup = group
    const obj = await ObjectClientSrv.getObjBusiness(param)

    // 获取title
    const newTitle = ObjectClientSrv.getObjBusinessDesc(obj!)

    ObjectClientSrv.openObj(param, newTitle)
    // CommonClientSrv.openPage(
    //   newTitle,
    //   () => resolveComponent('KObjectPanel'),
    //   {
    //     objParam: param
    //   },
    //   newTitle
    // )
  }

  async structManage(rows: Array<KDataGridRowData>) {
    switch (rows[0].targetClass) {
      case 'Part':
        PartClientSrv.structManage(rows[0].targetId)
        break
      case 'Document':
        DocClientSrv.structManage(rows[0].targetId)
        break
      default:
        break
    }
  }
}

export const iframeMsgClientSrv = new IFrameMsgClientSrv()
