
import { defineView, IKTreeNode, TreeViewSelectEventArgs } from '@kmsoft/upf-core'
import { KRequireManageEventEmits, KRequireManagePropOptions } from './interface'
import KRequireManageViewModel from './KRequireManageViewModel'
import { isFunction } from 'lodash'
import { KRequireTree } from './require-tree'
import KProductNeedsEditPanel from '../../controls/product-needs-edit-panel'
import { KCustomerNeedsEditPanel } from '../../controls/customer-needs-edit-panel'

export default defineView({
  name: 'KRequireManage',
  props: KRequireManagePropOptions,
  emits: KRequireManageEventEmits,
  viewModel: KRequireManageViewModel,
  components: { KRequireTree, KProductNeedsEditPanel, KCustomerNeedsEditPanel },
  setup(props, { emit, slots, attrs, vm }) {
    const loadTreeData = (node: null | IKTreeNode) => {
      const loadData = props.loadData
      if (loadData && isFunction(loadData)) {
        return loadData(node)
      }
      return Promise.resolve([])
    }
    return {
      loadTreeData
    }
  }
})
