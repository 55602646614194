import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_data_grid, {
      dataSource: _ctx.dataSource,
      isMultipleSelection: false
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "编码",
          dataPropertyName: "number",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "名称",
          dataPropertyName: "name",
          align: "center",
          width: "150"
        }),
        _createVNode(_component_k_data_grid_template_column, {
          headerText: "实体类型",
          dataPropertyName: "className",
          align: "center",
          width: "150"
        }, {
          default: _withCtx(({ cellValue}) => [
            _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_k_data_grid_template_column, {
          headerText: "版本",
          dataPropertyName: "versionInfo",
          align: "center",
          width: "80"
        }, {
          default: _withCtx(({ row }) => [
            (row.version || row.iteration)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(row.version) + "." + _toDisplayString(row.iteration), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "--"))
          ]),
          _: 1
        }),
        (!_ctx.readonly)
          ? (_openBlock(), _createBlock(_component_k_data_grid_template_column, {
              key: 0,
              id: "action",
              name: "action",
              fixed: "right",
              align: "center",
              headerText: "操作",
              width: "200",
              dataPropertyName: "activityType"
            }, {
              default: _withCtx(({row}) => [
                _createElementVNode("a", {
                  class: "row-operation-item",
                  style: {"color":"#1966c1"},
                  onClick: ($event: any) => (_ctx.$vm.removeAffectedObject(row))
                }, "移除", 8, _hoisted_4)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["dataSource"])
  ]))
}