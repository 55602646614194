import { Api, ObjBusinessParam } from '@kmsoft/ebf-common'
import { KTreeNode } from '@kmsoft/upf-core'
import { EnumPartNodeChildExpandMode } from '../../../client-srv'

/** 目录节点 */
export class PartStructureTreeNode extends KTreeNode {
  //#region 节点信息
  /** 零部件对象参数 */
  partObjParam: ObjBusinessParam
  /** 父零部件对象参数 */
  parentPartObjParam: ObjBusinessParam
  /** bomLink 对象参数 */
  bomLinkParam: ObjBusinessParam
  /** 是否是替换件 */
  isSwap: boolean
  /** 顺序号 */
  orderId: number
  findNumber: string
  //#endregion

  /** 展开方式 */
  currentExtendMode?: EnumPartNodeChildExpandMode
  /** 节点深度 */
  nodeDepth: number = 0
  /** 查询条件 */
  // queryRule: PartStructureQueryRule
  /** 是否是基线 */
  isBaseLine: boolean
  /** 基线id */
  baselineId: string

  /** 是否时间有效性 */
  isDated: boolean
  /** 时间 */
  datedValue: string

  /** 视图 */
  view: string
  constructor() {
    super()
  }

  /** 深度 */
  get depth(): number {
    this.nodeDepth = 0
    return this.getDepth(this)
  }

  /** 节点路径 */
  get path(): string {
    return this.getPath(this)
  }

  /** masterId路径 */
  get masterIdPath(): string {
    return this.getMasterIdPath(this)
  }

  /**
   * 获取深度
   * @param node
   * @returns
   */
  private getDepth(node: KTreeNode | undefined): number {
    this.nodeDepth = this.nodeDepth + 1
    if (node?.parent && node?.parent.checked) {
      this.getDepth(node.parent)
    }
    return this.nodeDepth
  }

  /**
   * 获取节点masterId路径
   * @param node
   * @returns
   */
  private getMasterIdPath(node: KTreeNode): string {
    let parentPath = ''
    if (node.parent && node.parent) {
      parentPath = this.getMasterIdPath(node.parent) + ','
    }

    return parentPath + (node as any).partObjParam.masterId
  }

  /**
   * 获取节点路径
   * @param node
   * @returns
   */
  private getPath(node: KTreeNode): string {
    let parentPath = ''
    if (node.parent && node.parent) {
      parentPath = this.getPath(node.parent) + ','
    }
    return parentPath + node.id
  }

  async populateChildren(populateType: number): Promise<Array<KTreeNode>> {
    switch (this.currentExtendMode) {
      case 0:
        return await this.populateChildPartTreeNode()
      case 1:
        break
      case 2:
        return await this.populateChildReleasedPartTreeNode(true)
      case 3:
        return await this.populateChildPartTreeNode(true)
      case 5:
      case 6:
        return await this.populateChildBaselinePartTreeNode(true)
      case 7:
        return await this.populateChildDatedPartTreeNode(true)
    }
    return []
  }

  /**
   * 树子节点展开
   * @param argIsShowSwap 是否显示替换件
   * @returns
   */
  async populateChildBaselinePartTreeNode(argIsShowSwap: boolean = false) {
    /** 获取子节点 */
    // const currentExtendMode = PartClientSrv.getPartNodeChildExpandMode(this.viewName, this.isBaseLine)
    // const currentExtendMode = PartClientSrv.getPartNodeChildExpandMode(this.viewName)
    const result = (await Api.post('part', 'Part', 'listPartLiteChildren', {
      data: [
        {
          parentRef: { id: this.partObjParam.id, clazz: 'Part' },
          queryRule: {
            type: 'BASELINE',
            baseline: {
              id: this.baselineId,
              clazz: 'ManagedBaseline'
            }
          },
          view: this.view
        }
      ]
    })) as any
    const masterIdPath = this.masterIdPath
    const parentPath = this.path
    const nodes = result?.data.map((nodeData: any) => {
      const childNode = new PartStructureTreeNode()

      childNode.leaf = !nodeData.hasChildren
      childNode.parentPartObjParam = this.partObjParam
      childNode.partObjParam = {
        modelCode: nodeData.userObject.part.clazz,
        id: nodeData.userObject.part.id,
        masterId: nodeData.userObject.partMaster.id,
        modelGroup: 'part',
        lifecycleStateCode: nodeData.userObject?.lifecycleState
      }
      childNode.bomLinkParam = {
        modelCode: nodeData.userObject.bomLink.clazz,
        id: nodeData.userObject.bomLink.id
      }
      childNode.view = this.view
      childNode.id = nodeData.id
      childNode.name = nodeData.name
      childNode.currentExtendMode = this.currentExtendMode
      childNode.orderId = nodeData.userObject.orderId!
      childNode.findNumber = nodeData.userObject.findNumber!
      childNode.baselineId = this.baselineId
      childNode.key = parentPath + ',' + nodeData.id
      childNode.class = this.getIconClass(nodeData.userObject.workingState)
      childNode.iconType = this.getIconType(nodeData.userObject.workingState)
      // childNode.queryRule = this.queryRule
      //出现了循环结构
      if (masterIdPath.includes(nodeData.userObject.partMaster.id)) {
        childNode.disabled = true
        childNode.leaf = true
      }
      return childNode
    })

    return nodes || []
  }

  /**
   * 树子节点展开
   * @param argIsShowSwap 是否显示替换件
   * @returns
   */
  async populateChildReleasedPartTreeNode(argIsShowSwap: boolean = false) {
    /** 获取子节点 */
    const result = (await Api.post('part', 'Part', 'listPartLiteChildren', {
      data: [
        {
          parentRef: { id: this.partObjParam.id, clazz: 'Part' },
          queryRule: {
            type: 'LATEST_RELEASED'
          },
          view: this.view
        }
      ]
    })) as any
    const masterIdPath = this.masterIdPath
    const parentPath = this.path
    const nodes = result?.data.map((nodeData: any) => {
      const childNode = new PartStructureTreeNode()

      childNode.leaf = !nodeData.hasChildren
      childNode.parentPartObjParam = this.partObjParam
      childNode.partObjParam = {
        modelCode: nodeData.userObject.part.clazz,
        id: nodeData.userObject.part.id,
        masterId: nodeData.userObject.partMaster.id,
        modelGroup: 'part',
        lifecycleStateCode: nodeData.userObject?.lifecycleState
      }
      childNode.bomLinkParam = {
        modelCode: nodeData.userObject.bomLink.clazz,
        id: nodeData.userObject.bomLink.id
      }
      childNode.view = this.view
      childNode.id = nodeData.id
      childNode.name = nodeData.name
      childNode.currentExtendMode = this.currentExtendMode
      childNode.orderId = nodeData.userObject.orderId!
      childNode.findNumber = nodeData.userObject.findNumber!
      childNode.baselineId = this.baselineId
      childNode.key = parentPath + ',' + nodeData.id
      childNode.class = this.getIconClass(nodeData.userObject.workingState)
      childNode.iconType = this.getIconType(nodeData.userObject.workingState)
      // childNode.queryRule = this.queryRule
      //出现了循环结构
      if (masterIdPath.includes(nodeData.userObject.partMaster.id)) {
        childNode.disabled = true
        childNode.leaf = true
      }
      return childNode
    })

    return nodes || []
  }

  /**
   * 树子节点展开
   * @param argIsShowSwap 是否显示替换件
   * @returns
   */
  async populateChildDatedPartTreeNode(argIsShowSwap: boolean = false) {
    /** 获取子节点 */
    // const currentExtendMode = PartClientSrv.getPartNodeChildExpandMode(this.viewName, this.isBaseLine)
    // const currentExtendMode = PartClientSrv.getPartNodeChildExpandMode(this.viewName)

    const result = (await Api.post('part', 'Part', 'listPartLiteChildren', {
      data: [
        {
          parentRef: { id: this.partObjParam.id, clazz: 'Part' },
          queryRule: {
            type: 'DATED_EFFECTIVITY',
            datedValue: this.datedValue
          },
          view: this.view
        }
      ]
    })) as any
    const masterIdPath = this.masterIdPath
    const parentPath = this.path
    const nodes = result?.data.map((nodeData: any) => {
      const childNode = new PartStructureTreeNode()

      childNode.leaf = !nodeData.hasChildren
      childNode.parentPartObjParam = this.partObjParam
      childNode.partObjParam = {
        modelCode: nodeData.userObject.part.clazz,
        id: nodeData.userObject.part.id,
        masterId: nodeData.userObject.partMaster.id,
        modelGroup: 'part',
        lifecycleStateCode: nodeData.userObject?.lifecycleState
      }
      childNode.bomLinkParam = {
        modelCode: nodeData.userObject.bomLink.clazz,
        id: nodeData.userObject.bomLink.id
      }
      childNode.view = this.view
      childNode.id = nodeData.id
      childNode.name = nodeData.name
      childNode.currentExtendMode = this.currentExtendMode
      childNode.orderId = nodeData.userObject.orderId!
      childNode.findNumber = nodeData.userObject.findNumber!
      childNode.baselineId = this.baselineId
      childNode.datedValue = this.datedValue
      childNode.key = parentPath + ',' + nodeData.id
      childNode.class = this.getIconClass(nodeData.userObject.workingState)
      childNode.iconType = this.getIconType(nodeData.userObject.workingState)
      // childNode.queryRule = this.queryRule
      //出现了循环结构
      if (masterIdPath.includes(nodeData.userObject.partMaster.id)) {
        childNode.disabled = true
        childNode.leaf = true
      }
      return childNode
    })

    return nodes || []
  }

  /**
   * 树子节点展开
   * @param argIsShowSwap 是否显示替换件
   * @returns
   */
  async populateChildPartTreeNode(argIsShowSwap: boolean = false) {
    /** 获取子节点 */
    // const currentExtendMode = PartClientSrv.getPartNodeChildExpandMode(this.viewName, this.isBaseLine)
    // const currentExtendMode = PartClientSrv.getPartNodeChildExpandModeByRuleType(this.queryRule.type)
    const result = (await Api.post('part', 'Part', 'listPartLiteChildren', {
      data: [
        {
          parentRef: { id: this.partObjParam.id, clazz: 'Part' },
          queryRule: {
            type: 'LATEST_WITH_WORKING_COPY'
          },
          view: this.view
        }
      ]
    })) as any
    const masterIdPath = this.masterIdPath
    const parentPath = this.path
    const nodes = result?.data.map((nodeData: any) => {
      const childNode = new PartStructureTreeNode()

      childNode.leaf = !nodeData.hasChildren
      childNode.parentPartObjParam = this.partObjParam
      childNode.partObjParam = {
        modelCode: nodeData.userObject.part.clazz,
        id: nodeData.userObject.part.id,
        masterId: nodeData.userObject.partMaster.id,
        modelGroup: '',
        lifecycleStateCode: nodeData.userObject?.lifecycleState
      }
      childNode.bomLinkParam = {
        modelCode: nodeData.userObject.bomLink.clazz,
        id: nodeData.userObject.bomLink.id
      }
      childNode.view = this.view
      childNode.id = nodeData.id
      childNode.name = nodeData.name
      childNode.currentExtendMode = this.currentExtendMode
      childNode.orderId = nodeData.userObject.orderId!
      childNode.findNumber = nodeData.userObject.findNumber!
      childNode.key = parentPath + ',' + nodeData.id
      childNode.class = this.getIconClass(nodeData.userObject.workingState)
      childNode.iconType = this.getIconType(nodeData.userObject.workingState)
      // childNode.queryRule = this.queryRule
      //出现了循环结构
      if (masterIdPath.includes(nodeData.userObject.partMaster.id)) {
        childNode.disabled = true
        childNode.leaf = true
      }
      return childNode
    })

    return nodes || []
  }

  // 获取图标样式
  getIconClass(workingState: string) {
    if (workingState === 'INWORK') {
      return 'unlock-self'
    } else if (workingState === 'CHECKED_IN') {
      return 'lock-in'
    }
    return 'unlock-out'
  }

  // 获取根节点图标样式
  getRootIconClass(workingState: string) {
    if (workingState === 'INWORK') {
      return 'root-unlock-self'
    } else if (workingState === 'CHECKED_IN') {
      return 'root-lock-in'
    }
    return 'root-unlock-out'
  }

  // 获取图标
  getIconType(workingState: string) {
    if (workingState === 'CHECKED_IN') {
      return 'lock-in'
    }
    return 'lock-out'
  }

  // /** 应用组件样式 */
  // applyNodeStyle(node: Partial<PartStructureTreeNode>, config: PartNodeAttr) {
  //   /** 节点样式 */
  //   let style = ''
  //   // 颜色
  //   if (config.color) {
  //     style += `color:${config.color};`
  //   }

  //   // 字体
  //   if (config.font) {
  //     const font = config.font
  //     switch (font) {
  //       case EnumFontStyle.Bold:
  //         style += 'font-weight: bold;'
  //         break
  //       case EnumFontStyle.Italic:
  //         style += 'font-style: italic;'
  //         break
  //       case EnumFontStyle.Underline:
  //         style += 'text-decoration: underline;'
  //         break
  //       case EnumFontStyle.Strikeout:
  //         style += 'text-decoration: line-through;'
  //         break
  //     }
  //   }

  //   node.style = style
  // }

  /** 刷新当前节点 */
  async refresh() {
    const result = await Api.post('part', 'Part', 'getPartLiteTreeNodeByBomLinkId', {
      data: [this.bomLinkParam.id, { type: 'LATEST_WITH_WORKING_COPY' }, this.view]
    })

    if (!(result && result.success)) {
      return
    }

    const nodeData = result.data!

    /** 获取子节点 */
    // const currentExtendMode = PartClientSrv.getPartNodeChildExpandMode(this.viewName, this.isBaseLine)

    const modifyNodeData: Partial<PartStructureTreeNode> = {
      key: this.key,
      leaf: !nodeData.hasChildren,
      partObjParam: {
        modelCode: nodeData.userObject.part?.clazz!,
        id: nodeData.userObject.part?.id!,
        masterId: nodeData.userObject.partMaster?.id,
        modelGroup: 'part',
        lifecycleStateCode: nodeData.userObject?.lifecycleState
      },
      bomLinkParam: {
        modelCode: nodeData.userObject.bomLink.clazz,
        id: nodeData.userObject.bomLink.id
      },
      view: this.view,
      id: nodeData.id,
      name: nodeData.name,
      currentExtendMode: this.currentExtendMode,
      iconType: this.getIconType(nodeData.userObject.workingState),
      class: this.getIconClass(nodeData.userObject.workingState)
    }
    // this.applyNodeStyle(modifyNodeData, nodeData)
    this.host?.modifyNode(modifyNodeData)
  }
}
