
import { defineView } from '@kmsoft/upf-core'
import { KObjectFileUploadPropOptions, KObjectFileUploadEventEmits } from './interface'
import KObjectFileUploadViewModel from './KObjectFileUploadViewModel'

export default defineView({
  name: 'KObjectFileUpload',
  props: KObjectFileUploadPropOptions,
  emits: KObjectFileUploadEventEmits,
  viewModel: KObjectFileUploadViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
