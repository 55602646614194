import { localStorageCache, sysSrv } from '../../index'
import { Directive, DirectiveBinding } from 'vue'

/** 根据 purviewId 判断用户是否有权限 */
const permission: Directive = {
  mounted: (el: HTMLElement, bind: DirectiveBinding) => {
    if (sysSrv.isSSOLogin()) {
      const userPermission: any = JSON.parse(localStorageCache.getCache('KMPDM-system_limits') as string)
      const { value } = bind

      const hasPermission = userPermission.some((item: any) => item.purviewId === value)
      return hasPermission || (el.style.display = 'none')
    }
    return true
  }
}

export default permission
