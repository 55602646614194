import { BaseViewModel, KNotification, request, ViewModelOptions } from '@kmsoft/upf-core'
import { KProductNeedsSectionCreateEmitsType, KProductNeedsSectionCreatePropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api, EnumWorkingState } from '@kmsoft/ebf-common'
import lodash from 'lodash'

/** KProductNeedsSectionCreate */
export default class KProductNeedsSectionCreateViewModel extends BaseViewModel<
  KProductNeedsSectionCreateEmitsType,
  KProductNeedsSectionCreatePropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<any>({
    number: '',
    name: '',
    description: '',
    category: '',
    additionalComments: '',
    secondaryIds: null,
  })

  constructor(options: ViewModelOptions<KProductNeedsSectionCreatePropType>) {
    super(options)
  }

  viewDidMount() {
    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
    }
  }

  getValue() {
    const createObject = lodash.cloneDeep(this.refObjectCreatePanel.value?.getValue())
    return createObject
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  async save() {
    const createObject = this.getValue()
    if (this.props.formValue.id) {
      createObject!.parent = 
        {
          id: this.props.formValue.id,
          clazz: this.props.formValue.rdmExtensionType
        }
    }

    if(createObject?.content){
      const fileName = createObject?.title + '.txt'
      const htmlText = createObject?.content
      const req = new FormData()
      req.append('modelName', 'ProductNeedsSection')
      req.append('attributeName', 'secondary')
      req.append('files', new File([htmlText], fileName))
      const result = (await request.post('/kmsaasFileApi/upload', req, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })) as any
      if (!result || result.result != 'SUCCESS') {
        KNotification.error({
          title: '系统错误',
          content: result.message
        })
        return
      }
      const fileId = result.data[0]
      createObject.secondary = [{ id: fileId}]
      createObject.content = null
    }

    const params = {
      data: [
        {
          ...createObject
        }
      ]
    }
    return await Api.post('requirement', 'ProductNeedsSection', 'create', params)
  }
}
