
import { defineView, KTreeSelectNode } from '@kmsoft/upf-core'
import { KCreateCadWorkspacePropOptions, KCreateCadWorkspaceEventEmits } from './interface'
import KCreateCadWorkspaceViewModel from './KCreateCadWorkspaceViewModel'

export default defineView({
  name: 'KCreateCadWorkspace',
  props: KCreateCadWorkspacePropOptions,
  emits: KCreateCadWorkspaceEventEmits,
  viewModel: KCreateCadWorkspaceViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
