import { getCandidates } from '../interface'
import { EnumDataType, EnumAttributeType } from '@kmsoft/upf-core'
/**控件属性集合 */
const PROPS = [
  {
    name: 'mode',
    title: '多选,单选(可自定义)',
    control: 'KSelect',
    options: getCandidates(['multiple', 'single']),
    defaultValue: 'single',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
] as Array<any>

const EVENT = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'change', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { PROPS, EVENT }
