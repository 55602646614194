
import { defineView, request } from '@kmsoft/upf-core'
import { KAgentDownloadPropOptions, KAgentDownloadEventEmits } from './interface'
import KAgentDownloadViewModel from './KAgentDownloadViewModel'
import { computed } from 'vue'
import { sysSrv } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KAgentDownload',
  props: KAgentDownloadPropOptions,
  emits: KAgentDownloadEventEmits,
  viewModel: KAgentDownloadViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const showIcon = computed(() => {
      return sysSrv.isSSOLogin()
    })
    const downloadFile = async () => {
      // 创建一个隐藏的<a>元素，用于触发下载
      const link = document.createElement('a')
      link.href = './system/KmUpgradeClient.sfx.exe' // 静态文件路径，基于 public 文件夹
      link.setAttribute('download', 'KmUpgradeClient.sfx.exe') // 设置下载文件名
      document.body.appendChild(link)
      link.click() // 模拟点击
      document.body.removeChild(link) // 下载完成后移除元素
    }
    return {
      showIcon,
      downloadFile
    }
  }
})
