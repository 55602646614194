import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KImageViewEmitsType, KImageViewPropType } from './interface'
import { ref, watch } from 'vue'
import { FileClientSrv } from '../../../../client-srv'

/** KImageView */
export default class KImageViewViewModel extends BaseViewModel<KImageViewEmitsType, KImageViewPropType> {
  /** 图片 */
  imageSrc = ref<string>()
  /**当前值 */
  stateValue = ref<string>('')
  constructor(options: ViewModelOptions<KImageViewPropType>) {
    super(options)
    watch(
      () => this.stateValue.value,
      newVal => {
        if (newVal) {
          this.onClickPreview()
        }
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  setValue(value: any, setChanged?: boolean) {
    this.stateValue.value = value
  }

  /**
   * 获取图片地址
   * @description
   */
  async onClickPreview() {
    if (!this.stateValue.value) {
      return
    }
    const fileResult = await FileClientSrv.getFileObjectUrl(this.stateValue.value)
    this.imageSrc.value = fileResult
  }
}
