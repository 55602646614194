import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowKey: "id",
        name: "docAnnotaionGrid",
        ref: "refDataGrid",
        dataSource: _ctx.dataSource,
        "is-multiple-selection": true
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "workflowName",
            headerText: "流程名称",
            align: "left"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "version",
            headerText: "文件小版本",
            align: "center",
            width: "85"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "taskName",
            headerText: "流程活动",
            align: "center",
            width: "85"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "creator",
            headerText: "创建人",
            align: "left"
          }),
          _createVNode(_component_k_data_grid_text_box_column, {
            dataPropertyName: "createTime",
            headerText: "创建时间",
            align: "left",
            width: "140"
          })
        ]),
        _: 1
      }, 8, ["dataSource"])
    ]),
    _: 1
  }))
}