
import { EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KCollectRelatedObjectPropOptions, KCollectRelatedObjectEventEmits } from './interface'
import KCollectRelatedObjectViewModel from './KCollectRelatedObjectViewModel'
import { clsCodeMap, EnumLifecycleState, EnumLifecycleStateColor } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KCollectRelatedObject',
  props: KCollectRelatedObjectPropOptions,
  emits: KCollectRelatedObjectEventEmits,
  viewModel: KCollectRelatedObjectViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      clsCodeMap,
      EnumLifecycleState,
      EnumLifecycleStateColor,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      workingStateOptions: [
        {
          label: '工作中',
          value: 'INWORK'
        },
        {
          label: '已检入',
          value: 'CHECKED_IN'
        },
        {
          label: '已检出',
          value: 'CHECKED_OUT'
        }
      ]
    }
  }
})
