import {
  BaseViewModel,
  EnumToolStripCompType,
  eventEmitter,
  IKDataGridCellParams,
  IKTreeNode,
  IQuery,
  KDataGridColumn,
  KDataGridTextBoxColumn,
  KDataGridViewModel,
  KNotification,
  PageManager,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { CommonTreeNode, KCommonFolderManageEmitsType, KCommonFolderManagePropType } from './interface'
import {
  Api,
  clsCodeMap,
  EBF_IOC_KEY,
  EnumPurviewCode,
  EnumRequestCode,
  EnumSupportObjectNumMode,
  EnumToolStripItemKeys,
  KDataGridHyperLinkColumn,
  ObjectClientSrv,
  ObjectToolStripItem
} from '@kmsoft/ebf-common'
import { IFolder } from '../folder-manage/folder-tree/interface'
import { createVNode, ref, watch } from 'vue'
import _ from 'lodash'
import { KFolderTreeViewModel } from '../folder-manage/folder-tree'
import { FolderClientSrv } from '../../client-srv'
import { KMetricsEditPanel } from '../../../../ebf-requirement/src/pages/common-validation-metrics/metrics-edit-panel'

/** KCommonFolderManage */
export default class KCommonFolderManageViewModel extends BaseViewModel<
  KCommonFolderManageEmitsType,
  KCommonFolderManagePropType
> {
  /** 选中的树节点 */
  selectNode = ref<IKTreeNode>()
  /** 文件夹树 */
  refFolderTree = ref<KFolderTreeViewModel>()
  /** 对象网格 */
  refFolderObjGrid = ref<KDataGridViewModel>()
  /** 工具栏数据 */
  refToolStripItems = ref<Array<ObjectToolStripItem>>()

  constructor(options: ViewModelOptions<KCommonFolderManagePropType>) {
    super(options)
    watch(
      () => this.selectNode.value,
      () => {
        this.refreshList()
      }
    )
  }

  viewDidMount() {
    this.initToolStripItem()
  }

  /**
   * 加载树节点
   */
  async loadTreeData() {
    const param = {
      data: [this.props.folderType]
    }
    const res = await Api.post('folder', 'Folder', 'getFolderByType', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: res.data?.id,
        name: res.data?.name,
        leafFlag: res.data.leafFlag,
        nameEn: res.data.nameEn,
        type: res.data.type
      } as IFolder
      return [new CommonTreeNode(node)]
    } else {
      KNotification.error({
        title: '文件夹目录加载失败',
        content: res.message!
      })
      return Promise.resolve([])
    }
  }

  /**
   * 树节点选中事件
   * @param event
   */
  afterSelect(event: TreeViewSelectEventArgs<any>) {
    this.selectNode.value = event.node
    this.refFolderObjGrid.value?.clearSelectedRows()
  }

  // 树节点会首次加载会选中两次，增加防抖
  refreshList = _.debounce(() => {
    this.refFolderObjGrid.value?.refresh()
  }, 300)

  /**
   * 初始化工具栏
   */
  initToolStripItem() {
    this.refToolStripItems.value = [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OBJ,
        title: '对象',
        icon: 'DropObjLife',
        visible: true,
        compType: EnumToolStripCompType.CONTEXT_MENU,
        supportedObjNumMode: EnumSupportObjectNumMode.Any,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW,
            title: '创建',
            icon: 'NewObj',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            shortcutKey: 'alt+n',
            supportedObjNumMode: EnumSupportObjectNumMode.Any
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
            title: '删除',
            icon: 'DeleteObj',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
            shortcutKey: 'alt+r'
          }
        ]
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_GRID_OPERATION,
        title: '列表',
        icon: 'DropGridOp',
        visible: true,
        compType: EnumToolStripCompType.CONTEXT_MENU,
        supportedObjNumMode: EnumSupportObjectNumMode.Any,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
            title: '刷新',
            icon: 'sync',
            visible: true,
            purviewId: EnumPurviewCode.RefreshList,
            compType: EnumToolStripCompType.BUTTON,
            shortcutKey: 'f5',
            supportedObjNumMode: EnumSupportObjectNumMode.Any
          }
        ]
      }
    ]
  }

  /**
   * 工具栏点击事件
   */
  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const rows = this.refFolderObjGrid.value?.getSelectedRows()
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        this.delete(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refFolderObjGrid.value?.refresh()
        break
      default:
        break
    }
  }
  /**
   * 返回工具栏数据
   * @returns
   */
  getToolStripItem() {
    return this.refToolStripItems.value
  }

  /**
   * 加载网格数据
   */
  async loadGridData(query: IQuery) {
    if (this.selectNode.value == null) {
      return {
        rows: [],
        total: 0
      }
    }
    const param = {
      data: [
        {
          folderId: this.selectNode.value?.id,
          pageVO: {
            maxPageSize: 1000,
            curPage: query.page?.pageIndex,
            pageSize: query.page?.pageSize,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          }
        }
      ]
    }
    const result = await Api.post('folder', 'Folder', 'listFolderLink', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data && result.data.data) {
      const map = result.data.data.map((row: any) => {
        return {
          ...row,
          rdmExtensionType: row.targetExtensionType,
          type: row.targetType,
          isGeneral: row.targetIsGeneral,
          title: row.name
        }
      })
      return {
        rows: map,
        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }

  /**
   * 网格刷新
   */
  refresh() {
    this.refFolderObjGrid.value?.refresh()
  }

  /**
   * 获取选中的树节点
   */
  getSelectTreeNode() {
    return this.selectNode.value
  }

  /**
   * 获取网格选中行
   */
  getSelectedRows() {
    return this.refFolderObjGrid.value?.getSelectedRows()
  }

  /**
   * 获取网格选中行
   */
  removeSelectedRows() {
    return this.refFolderObjGrid.value?.removeSelectedRows()
  }

  /**
   * 获取文件夹树
   * @returns
   */
  getContainer() {
    return this.refFolderTree.value?.getContainer()
  }

  /**
   * 删除对象
   * @param id 对象id
   */
  async delete(rows: any) {
    FolderClientSrv.batchDelete(this.selectNode.value, rows).then(res => {
      if (res) {
        this.refFolderObjGrid.value?.removeSelectedRows()
      }
    })
  }

  columnResolver(column: any) {
    if (column.control == 'KDataGridHyperLinkColumn') {
      const overrideColumn = new KDataGridTextBoxColumn()
      overrideColumn.headerText = '编码xx'
      overrideColumn.dataPropertyName = 'number'
      overrideColumn.align = 'left'
      overrideColumn.getCellTemplate = (params: IKDataGridCellParams) => {
        return createVNode(
          'a',
          {
            title: params.row['number'],
            onClick: async () => {
              //跳转方法
              const param = {
                id: params.row.targetId,
                modelCode: params.row.targetExtensionType,
                modelGroup: await ObjectClientSrv.getModelGroupByCode(params.row.targetExtensionType)
              }
              if (param.modelCode == 'ValidationMetrics') {
                const tabKey = `${param.id}#${param.modelCode}`
                const objResult = (await ObjectClientSrv.getObjBusiness(param)) as Record<string, any>
                const title =
                  clsCodeMap.get(objResult.rdmExtensionType ? objResult.rdmExtensionType : objResult.targetExtensionType) +
                  ':' +
                  ObjectClientSrv.getObjBusinessDesc(objResult)
                PageManager.openPage(tabKey, title, KMetricsEditPanel, { objParam: param })
              } else {
                const tabKey = `${param.id}#${param.modelCode}#${new Date().getTime()}`
                eventEmitter.emit(EBF_IOC_KEY.CLOSE_DIALOG, 'closeDialog', {} as any)
                ObjectClientSrv.openObj(param, tabKey)
              }
            },
            style: 'padding:0 5px'
          },
          { default: () => params.row['number'] }
        )
      }
      return overrideColumn
    }
  }
}
