import { Agent, EnumDocumentType, SelectItem } from '@kmsoft/ebf-common'
import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { acceptTypeList, FileDataInter, KDocSelectFilesEmitsType, KDocSelectFilesPropType, Progress } from './interface'

import { fileSuffixIconMapping } from '@kmsoft/ebf-common/src/controls/object-file-upload/interface'
import { computed, ref, watch } from 'vue'

/** KDocSelectFiles */
export default class KDocSelectFilesViewModel extends BaseViewModel<KDocSelectFilesEmitsType, KDocSelectFilesPropType> {
  /** 文档类型 **/
  documentType = ref()
  /** 文档类型选项值 **/
  documentTypeOptions = ref<SelectItem[]>()
  /** 能接受的上传类型 **/
  accept = computed(() => {
    return acceptTypeList[this.documentType.value as keyof typeof acceptTypeList] || ''
  })

  getIcon = computed(() => {
    return (item: FileDataInter) => {
      const findType = Object.keys(fileSuffixIconMapping).find(key =>
        fileSuffixIconMapping[key].fileSuffixList.includes(item?.fileSuffix as string)
      )

      if (findType) {
        return fileSuffixIconMapping[findType].icon
      } else {
        return fileSuffixIconMapping.other.icon
      }
    }
  })

  constructor(options: ViewModelOptions<KDocSelectFilesPropType>) {
    super(options)
    this.documentTypeOptions.value = EnumDocumentType._list.filter(
      (item: SelectItem) => item.value !== EnumDocumentType._enum.UG_SOLIDEDGE
    )

    watch(this.documentType, newValue => {
      this.emit('update:documentType', newValue)
    })
  }

  viewDidMount() {
    this.documentType.value = EnumDocumentType._enum.SOLIDWORKS
  }

  /** 获取文件后缀 **/
  getFileSuffix(fileName: string) {
    let fileSplit = fileName.split('.')
    //文件后缀
    let fileSuffix = ''
    if (fileSplit && fileSplit.length > 1) {
      fileSuffix = fileSplit[fileSplit.length - 1]
    }
    return fileSuffix.trim().toLowerCase()
  }

  //校验本地上传文件类型
  validateLocalUpload(file: File) {
    const suffix = '.' + this.getFileSuffix(file.name)
    const acceptSuffix = this.accept.value.local.split(',').map(item => item.toLowerCase())

    if (acceptSuffix.indexOf('.*') == -1 && !acceptSuffix.includes(suffix)) {
      KNotification.error({
        title: '添加文件失败',
        content: `只能添加${this.accept.value.local}格式文件`
      })
      return false
    }

    return true
  }

  async openSelectFileDialog() {
    if (!this.accept.value) {
      KNotification.info({
        message: `请选择文档类型`
      })
      return
    }
    if (this.props.agentOpened) {
      const filePaths = await this.agentDialog()
      if (!filePaths || filePaths.length === 0) return

      filePaths.forEach(filePath => {
        const parts = filePath.split(/[/\\]/) // 分割字符串，匹配'/'或'\'
        const fileName = parts[parts.length - 1]

        this.emit('addFile', {
          tempId: String(Math.random()),
          name: '',
          number: '',
          fileName: fileName,
          fileSuffix: this.getFileSuffix(fileName),
          filePath,
          proxyUpload: true,
          extractProgress: Progress.waiting,
          docList: [],
          docStructList: [],
          selected: false,
          importStateProgress: Progress.waiting,
          importDesc: ''
        })
      })
    } else {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.multiple = true
      fileInput.accept = this.accept.value.local
      // 监听文件选择事件
      fileInput.addEventListener('change', () => {
        const fileObj = (fileInput.files || {}) as Record<number, File>
        const files = Object.values(fileObj)
        if (files.every(file => this.validateLocalUpload(file))) {
          files.forEach(file => {
            this.emit('addFile', {
              tempId: String(Math.random()),
              name: '',
              number: '',
              fileName: file.name,
              fileSuffix: this.getFileSuffix(file.name),
              file,
              proxyUpload: false,
              extractProgress: Progress.waiting,
              docList: [],
              docStructList: [],
              selected: false,
              importStateProgress: Progress.waiting,
              importDesc: ''
            })
          })
        }
      })

      // 触发文件选择对话框
      fileInput.click()
    }
  }

  async agentDialog(): Promise<string[] | undefined> {
    const dialog = new Agent.OpenFileDialog()
    dialog.Title = '选择文件'
    dialog.Filter = this.accept.value.proxy
    dialog.Multiselect = true
    if ((await dialog.ShowDialog()) === Agent.DialogResult.OK) {
      return dialog.FileNames
    }
    return undefined
  }

  removeFile(file: FileDataInter) {
    this.emit('removeFile', file)
  }
  extractFiles() {
    this.emit('nextStep')
  }
}
