import { connect, withInstall } from '@kmsoft/upf-core'
import KProductNeedsSectionCreateView from './KProductNeedsSectionCreate.vue'
import KProductNeedsSectionCreateViewModel from './KProductNeedsSectionCreateViewModel'

const KProductNeedsSectionCreate = connect(KProductNeedsSectionCreateView, KProductNeedsSectionCreateViewModel)

export default withInstall(KProductNeedsSectionCreate)
export { KProductNeedsSectionCreate, KProductNeedsSectionCreateView, KProductNeedsSectionCreateViewModel }

// 模板生成文件
// export * from './{{folderName}}'
