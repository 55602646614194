
import {
  EnumDataGridRowModelType,
  IKTreeNode,
  TreeViewSelectEventArgs,
  defineView,
  EnumDataGridAutoSizeColumnsMode
} from '@kmsoft/upf-core'
import { KPersonalVerificationPlanPropOptions, KPersonalVerificationPlanEventEmits } from './interface'
import KPersonalVerificationPlanViewModel from './KPersonalVerificationPlanViewModel'
import { EnumLifecycleStateColor, EnumLifecycleStateSimple } from '@kmsoft/ebf-common'

export default defineView({
  name: 'KPersonalVerificationPlan',
  props: KPersonalVerificationPlanPropOptions,
  emits: KPersonalVerificationPlanEventEmits,
  viewModel: KPersonalVerificationPlanViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleStateSimple,
      EnumLifecycleStateColor,
      pagination: { pageIndex: 1, pageSize: 100 },
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autosizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL
    }
  }
})
