import { connect, withInstall } from '@kmsoft/upf-core'
import KImageViewView from './KImageView.vue'
import KImageViewViewModel from './KImageViewViewModel'

const KImageView = connect(KImageViewView, KImageViewViewModel)

export default withInstall(KImageView)
export { KImageView, KImageViewView, KImageViewViewModel }

// 模板生成文件
// export * from './{{folderName}}'
