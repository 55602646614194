import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KRequirementObjManagePropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  container: VuePropTypes.createType<HTMLElement>().def()
}

/** 参数类型 **/
export type KRequirementObjManagePropType = ViewPropsTypeExtract<typeof KRequirementObjManagePropOptions>

/** 事件 */
export const KRequirementObjManageEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KRequirementObjManageEmitsType = ViewEmitsTypeExtract<typeof KRequirementObjManageEventEmits>

// 左侧列表定义
export interface IReqDataItemDefinition {
  id: string
  title: string
  key: string
}
