import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf03399c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "template-select" }
const _hoisted_2 = { style: {"width":"100%","height":"100%","padding-bottom":"8px"} }
const _hoisted_3 = { class: "folder-tree" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input_search = _resolveComponent("k-input-search")!
  const _component_k_spin = _resolveComponent("k-spin")!
  const _component_k_tree_view = _resolveComponent("k-tree-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_input_search, {
        value: _ctx.$vm.templateName,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.templateName) = $event)),
        placeholder: "请输入流程模板名称",
        onSearch: _cache[1] || (_cache[1] = () => _ctx.$vm.searchTemplate())
      }, null, 8, ["value"])
    ]),
    (_ctx.$vm.isLoading)
      ? (_openBlock(), _createBlock(_component_k_spin, {
          key: 0,
          style: {"height":"253px","z-index":"10000","position":"absolute"}
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_k_tree_view, {
        ref: _ctx.$vm.refWorkflowTemplateTree,
        nodeKey: "id",
        onAfterSelect: _cache[2] || (_cache[2] = (event) => _ctx.$vm.refreshWorkflow(event)),
        defaultExpandedLevel: 1,
        autoSelectFirstNode: true,
        autoSelectLastNode: false,
        loadData: _ctx.$vm.loadWorkflowTemplateData
      }, null, 8, ["loadData"])
    ])
  ]))
}