/** 零部件工具栏Id */
export enum EnumPartToolStripItemKeys {
  /** 主文档操作 */
  TOOL_STRIP_MAIN_DOC_OPERATION = 'MainDocOperation',

  /** 添加子零部件 */
  TOOL_STRIP_GROUP_ADD_CHILD_PART = 'AddChildPart_Root',
  /** 新建子零部件 */
  TOOL_STRIP_ITEM_ADD_CHILD_PART_NEW = 'AddChildPart_New',
  /** 搜索零部件 */
  TOOL_STRIP_ITEM_SEARCH_PART = 'Part_Search',
  /** 查找关联对象 */
  TOOL_STRIP_ITEM_SEARCH_RELATED_OBJECTS = 'SearchRelatedObjects',
  /** 创建视图版本 */
  TOOL_STRIP_ITEM_CREATE_PART_VERSION_VIEW = 'CreatePartVersionView',
  TOOL_STRIP_ITEM_ADD_CHILD_PART_SELECT_TEMPLATE = 'AddChildPart_Select_Template',
  /** 查找新增子零部件 */
  TOOL_STRIP_ITEM_ADD_CHILD_PART_FIND = 'AddChildPart_Find',

  /** 添加兄零部件 */
  TOOL_STRIP_GROUP_ADD_OLD_BRO_PART = 'AddOldBroPart_Root',
  /** 新建兄零部件 */
  TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_NEW = 'AddOldBroPart_New',
  TOOL_STRIP_ITEM_INSERT_OLD_BRO_PART_NEW = 'InsertOldBroPart_New',
  TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_SELECT_TEMPLATE = 'AddOldBroPart_Select_Template',
  TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_FIND = 'AddOldBroPart_Find',

  /** 添加弟零部件 */
  TOOL_STRIP_GROUP_ADD_YOUNG_BRO_PART = 'AddYoungBroPart_Root',
  /** 新建弟零部件 */
  TOOL_STRIP_ITEM_ADD_YOUNG_BRO_PART_NEW = 'AddYoungBroPart_New',
  TOOL_STRIP_ITEM_ADD_YOUNG_BRO_PART_SELECT_TEMPLATE = 'AddYoungBroPart_Select_Template',
  TOOL_STRIP_ITEM_ADD_YOUNG_BRO_PART_FIND = 'AddYoungBroPart_Find',

  /** 更换零部件 */
  TOOL_STRIP_GROUP_REPLACE_PART = 'ReplacePartRoot',
  /** 新建 更换零部件 */
  TOOL_STRIP_ITEM_REPLACE_PART_NEW = 'ReplacePart_New',
  /** 查找 更换零部件 */
  TOOL_STRIP_ITEM_REPLACE_PART_FIND = 'ReplacePart_Find',

  /** 删除零部件 */
  TOOL_STRIP_ITEM_DELETE_PART = 'DeletePart',
  /** 移除零部件 */
  TOOL_STRIP_ITEM_REMOVE_PART = 'RemovePart',
  /** 移除全局替换件 */
  TOOL_STRIP_ITEM_REMOVE_ALTERNATE_PART = 'RemoveAlternateSwapPart',
  /** 移除局部替换件 */
  TOOL_STRIP_ITEM_REMOVE_SUBSTITUTE_PART = 'RemoveSubstituteSwapPart',
  /** 添加替换件 */
  TOOL_STRIP_ITEM_ADD_SWAP_BORROW = 'AddSwapBorrow',
  /** 添加替换件 创建 */
  TOOL_STRIP_ITEM_ADD_SWAP_BORROW_NEW = 'AddSwapBorrow_New',
  /** 添加替换件 查找 */
  TOOL_STRIP_ITEM_ADD_SWAP_BORROW_FIND = 'AddSwapBorrow_Find',

  /** 添加全局替换件 创建 */
  TOOL_STRIP_ITEM_ADD_ALTERNATE_SWAP_BORROW_NEW = 'AddAlternateSwapBorrow_New',
  /** 添加全局替换件 查找 */
  TOOL_STRIP_ITEM_ADD_ALTERNATE_SWAP_BORROW_FIND = 'AddAlternateSwapBorrow_Find',
  /** 添加局部替换件 创建 */
  TOOL_STRIP_ITEM_ADD_SUBSTITUTE_SWAP_BORROW_NEW = 'AddSubstituteSwapBorrow_New',
  /** 添加局部替换件 查找 */
  TOOL_STRIP_ITEM_ADD_SUBSTITUTE_SWAP_BORROW_FIND = 'AddSubstituteSwapBorrow_Find',

  /** 更换替换件 */
  TOOL_STRIP_ITEM_CHANGE_SWAP_BORROW = 'ChangeSwapBorrow',
  /** 更换替换件 创建 */
  TOOL_STRIP_ITEM_CHANGE_SWAP_BORROW_NEW = 'ChangeSwapBorrow_New',
  /** 更换替换件 查找 */
  TOOL_STRIP_ITEM_CHANGE_SWAP_BORROW_FIND = 'ChangeSwapBorrow_Find',
  /** 打开对象 */
  TOOL_STRIP_OPEN_PART_OBJ = 'OpenPartObj',
  /** 显示替换件 */
  TOOL_STRIP_ITEM_SHOW_SWAP = 'ShowSwap',

  /** 版本替换 */
  TOOL_STRIP_ITEM_MENU_CHANGE_VERSION = 'MenuChangeVersion',
  /** 替换最新版本 */
  TOOL_STRIP_ITEM_MENU_CHANGE_LAST_VERSION = 'MenuChangeLastVersion',
  /** 替换最新发布版本 */
  TOOL_STRIP_ITEM_MENU_CHANGE_LAST_PUBLISH_VERSION = 'MenuChangeLastPublishVersion',
  /** 替换版本列表 */
  TOOL_STRIP_ITEM_MENU_CHANGE_VERSION_LIST = 'MenuChangeVersionList',

  /** 转为 */
  TOOL_STRIP_ITEM_MENU_CHANGE_STATE = 'MenuChangeState',
  /** 可选默认选用 */
  TOOL_STRIP_ITEM_CHOOSE_AND_CHECK = 'ChooseAndCheck',
  /** 可选默认不选用 */
  TOOL_STRIP_ITEM_CHOOSE_NOT_CHECK = 'ChooseNotCheck',
  /** 必选 */
  TOOL_STRIP_ITEM_MUST_CHOOSE = 'MustChoose',

  /** 转为子零部件 */
  TOOL_STRIP_ITEM_CHANGE_TO_CHILD = 'ChangeToChild',

  TOOL_STRIP_ITEM_CUT_PART = 'CutPart',

  TOOL_STRIP_ITEM_COPY_PART = 'CopyPart',

  TOOL_STRIP_ITEM_PASTE_PART = 'PastePart',

  /** 零部件排序 */
  TOOL_STRIP_ITEM_SORT_PART = 'Sort',
  /** 更改零部件位置 */
  TOOL_STRIP_ITEM_CHANGE_PART_POSITION = 'ChangePartPosition',
  /** 查找相关对象 */
  TOOL_STRIP_ITEM_FIND_REL_OBJ = 'FindRelObj',
  /**汇集 */
  TOOL_STRIP_REMARK_NODE_ADD_TO_GRID = 'RemarkNodeAddToGrid',
  /** 零部件视图 */
  TOOL_STRIP_ITEM_PART_STRUCTURE_VIEW = 'ToolStripItemPartStructureView',
  /** 过滤条件 */
  TOOL_STRIP_ITEM_PART_STRUCTURE_FILTER = 'ToolStripItemPartStructureFilter',
  /** 高亮 */
  TOOL_STRIP_HIGHT_LIGHT = 'HightLight',
  /** 隐藏模型 */
  TOOL_STRIP_MODEL_VISIBLE = 'ModelVisibel',
  /** 批量更新物料属性 */
  TOOL_BATCH_UPDATE_MATERIAL = 'toolBatchUpdateMaterial',
  /** 批量更新BOM */
  TOOL_BATCH_UPDATE_BOM = 'toolBatchUpdateBom',
  /** bom比较 */
  TOOL_STRIP_BOM_COMPARISON_GROUP = 'BOMComparisonGroup',
  /** bom比较 */
  TOOL_STRIP_BOM_COMPARISON = 'BOMComparison',
  /** 文档bom比较 */
  TOOL_STRIP_BOM_DOC_COMPARISON = 'BOMDocComparison',
  /** 创建基线 **/
  TOOL_STRIP_CREATE_BASELINE = 'CreateBaseline',
  /** 创建转阶段 */
  TOOL_STRIP_CREATE_PROMOTE = 'CreatePromote',
  TOOL_STRIP_EXCEL_EXPORT = 'Excelexport'
}
