import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52945e87"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"8px 0px","width":"100%","height":"100%"} }
const _hoisted_2 = { class: "layout-content-wrapper" }
const _hoisted_3 = { style: {"width":"100%","height":"100%","padding-bottom":"8px","display":"flex","min-height":"40px"} }
const _hoisted_4 = { class: "grid-name" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { style: {"margin-left":"5px"} }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_split_pane, {
            size: 15,
            minSize: 15,
            maxSize: 50
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_panel, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_k_tree_view, {
                      ref: _ctx.$vm.refTree,
                      "default-expanded-level": 0,
                      unselectablePredicate: (node)=>node!.id=='root',
                      autoSelectFirstNode: true,
                      loadData: _ctx.$vm.loadTreeData,
                      onAfterSelect: _cache[0] || (_cache[0] = (event) => _ctx.$vm.setSelectNode(event))
                    }, null, 8, ["unselectablePredicate", "loadData"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_k_split_pane, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_split_container, { horizontal: true }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_k_panel, null, {
                      header: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_k_tool_strip_button_item, {
                            icon: "sync",
                            name: "refersh",
                            title: "刷新",
                            onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.refersh())
                          }),
                          (_ctx.$vm.showButton)
                            ? (_openBlock(), _createBlock(_component_k_button, {
                                key: 0,
                                type: "primary",
                                name: "complete",
                                onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.complete())
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("完成")
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_k_data_grid, {
                          rowModelType: _ctx.rowModelType,
                          class: "verification-plan-grid",
                          rowKey: "id",
                          name: "verificationPlanGrid",
                          pagination: _ctx.pagination,
                          ref: _ctx.$vm.refVerificationPlanGrid,
                          loadData: _ctx.$vm.loadData,
                          autosizeColumnsMode: _ctx.autosizeColumnsMode
                        }, {
                          columns: _withCtx(() => [
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "title",
                              name: "title",
                              dataPropertyName: "title",
                              headerText: "标题",
                              align: "left"
                            }, {
                              default: _withCtx(({ row }) => [
                                _createVNode(_component_k_icon, {
                                  type: _ctx.$vm.getProjectStatus(row),
                                  class: _normalizeClass(_ctx.$vm.getIconClass(row)),
                                  title: _ctx.$vm.getStatusOption(row)
                                }, null, 8, ["type", "class", "title"]),
                                _createElementVNode("span", _hoisted_4, _toDisplayString(row.title), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "number",
                              name: "number",
                              dataPropertyName: "number",
                              headerText: "编码",
                              align: "left"
                            }, {
                              default: _withCtx(({ cellValue,row }) => [
                                _createElementVNode("a", {
                                  href: "javascript:void(0)",
                                  onClick: () => _ctx.$vm.openPlanTab(row)
                                }, [
                                  _createElementVNode("span", _hoisted_6, _toDisplayString(cellValue), 1)
                                ], 8, _hoisted_5)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "lifecycleStateCode",
                              name: "lifecycleStateCode",
                              dataPropertyName: "lifecycleStateCode",
                              headerText: "生命周期状态",
                              width: "140",
                              align: "left"
                            }, {
                              default: _withCtx(({ cellValue }) => [
                                cellValue
                                  ? (_openBlock(), _createBlock(_component_k_tag, {
                                      key: 0,
                                      color: _ctx.EnumLifecycleStateColor[cellValue]
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.EnumLifecycleStateSimple[cellValue]), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["color"]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_7, "--"))
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_k_data_grid_combo_box_column, {
                              id: "type",
                              name: "type",
                              dataPropertyName: "type",
                              headerText: "验证类型",
                              readonly: false,
                              options: _ctx.$vm.verificationPlanTypeOptions,
                              align: "left"
                            }, null, 8, ["options"]),
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "planStartDate",
                              name: "planStartDate",
                              dataPropertyName: "planStartDate",
                              headerText: "计划开始时间",
                              align: "left"
                            }),
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "planEndDate",
                              name: "planEndDate",
                              dataPropertyName: "planEndDate",
                              headerText: "计划完成时间",
                              align: "left"
                            }),
                            _createVNode(_component_k_data_grid_combo_box_column, {
                              id: "executor",
                              name: "executor",
                              dataPropertyName: "executor",
                              headerText: "执行者",
                              options: _ctx.$vm.userList,
                              align: "left"
                            }, null, 8, ["options"]),
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "description",
                              name: "description",
                              dataPropertyName: "description",
                              headerText: "描述",
                              readonly: false,
                              align: "left"
                            }),
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "creator",
                              name: "creator",
                              dataPropertyName: "creator",
                              headerText: "创建者",
                              align: "left"
                            }, {
                              default: _withCtx(({ cellValue}) => [
                                _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "createTime",
                              name: "createTime",
                              dataPropertyName: "createTime",
                              headerText: "创建时间",
                              align: "left"
                            }),
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "action",
                              name: "action",
                              fixed: "right",
                              align: "left",
                              headerText: "操作",
                              width: "auto"
                            }, {
                              default: _withCtx(({row}) => [
                                _createElementVNode("a", {
                                  class: "row-operation-item",
                                  onClick: (e) => _ctx.$vm.openPlanTab(row)
                                }, "查看", 8, _hoisted_8)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["rowModelType", "pagination", "loadData", "autosizeColumnsMode"])
                      ]),
                      _: 1
                    })
                  ])), [
                    [_directive_focus]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}