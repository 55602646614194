import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KBaselineCompareDialogEmitsType, KBaselineCompareDialogPropType } from './interface'

/** KBaselineCompareDialog */
export default class KBaselineCompareDialogViewModel extends BaseViewModel<
  KBaselineCompareDialogEmitsType,
  KBaselineCompareDialogPropType
> {
  constructor(options: ViewModelOptions<KBaselineCompareDialogPropType>) {
    super(options)
  }

  viewDidMount() {}

  onCancel() {
    this.emit('cancel')
  }
}
