import { BaseViewModel, KDialog, KFormViewModel, KNotification, request } from '@kmsoft/upf-core'
import {
  IConfigValidDefinition,
  IConfigValueDefinition,
  KConfigValueEventEmitsType,
  KConfigValueViewModelPropType,
  UpdateConfigValidStatusEventArgs
} from './interface'
import { EnumEditType, EnumTabType, IValueDesc } from '../../interface'
import { equals, isReqSuccess, showError } from '../../common/common'
import { ref } from 'vue'
import { Api } from '@kmsoft/ebf-common'
import { reject } from 'lodash'

export default class KConfigValueViewModel extends BaseViewModel<KConfigValueEventEmitsType, KConfigValueViewModelPropType> {
  /** 下拉菜单可选项 */
  selectEditTypeOptions = ref<Array<IValueDesc>>([])
  /** 可编辑的下拉菜单可选项 */
  selectEditEditTypeOptions = ref<Array<IValueDesc>>([])
  /** 带显示值的下拉菜单可选项 */
  selectDisplayEditTypeOptions = ref<Array<IValueDesc>>([])
  /** 单选框可选项 */
  radioEditTypeOptions = ref<Array<IValueDesc>>([])
  /** 是否显示上传按钮 */
  showUpload = ref<boolean>(false)

  formState = ref<IConfigValueDefinition>({
    code: '',
    name: '',
    value: '',
    values: [] as Array<string>,
    value_switch: false,
    dataType: '',
    editType: '',
    editParam: '',
    remark: ''
  } as IConfigValueDefinition)

  refConfigValueFrom = ref<KFormViewModel<IConfigValueDefinition>>()

  private defId: string
  private treeId: string
  /** 初始值, 用于对比是否发生变更 */
  private sourceValue: string
  private sourceValue_switch: boolean
  /** 初始对象 */
  private row: IConfigValueDefinition
  private mode: string

  /**
   * 加载完成函数
   */
  viewDidMount() {
    this.mode = EnumTabType.SYS
    this.formState.value = {
      code: '',
      name: '',
      value: '',
      values: [] as Array<string>,
      value_switch: false,
      dataType: '',
      editType: '',
      editParam: '',
      remark: ''
    } as IConfigValueDefinition
  }

  /**
   * 初始化表单内容
   * @param defId 配置项编码
   * @param treeId 树节点ID
   */
  public init(defId: string, treeId: string) {
    this.defId = defId
    this.treeId = treeId
    this.loadData()
  }

  /**
   * 加载数据
   */
  private loadData(): void {
    const param = {
      data: [
        {
          defId: this.defId as unknown,
          treeId: this.treeId as unknown
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'loadConfigSysValue', param).then(response => {
      this.loadDataInner(response)
    })
  }

  private loadDataInner(response: any): void {
    if (!isReqSuccess(response)) {
      showError(response)
      return
    }
    const value = response.data
    const formState = {
      defId: value?.defId as unknown,
      id: value?.id as unknown,
      code: value?.code,
      name: value?.name,
      value: value?.value,
      values: [] as Array<string>,
      remark: value?.remark,
      dataType: value?.dataType,
      editType: value?.editType,
      editParam: value?.editParam
    } as IConfigValueDefinition
    if (formState.value) {
      formState.values = [formState.value]
    }
    this.showUpload.value = value?.code == 'SYS_PART_IMPORT_TEMPLATE'
    this.row = formState
    this.initSelect()
    this.initSelectEdit()
    this.initSelectDisplay()
    this.initSwitch()
    this.initRadio()

    this.sourceValue = formState.value
    if (formState.editType === EnumEditType.SWITCH) {
      this.sourceValue_switch = formState.value_switch
    }

    this.formState.value = { ...this.row }
  }

  /**
   * 校验
   */
  public check(): void {
    const param = {
      data: [
        {
          treeId: this.treeId as unknown,
          defId: this.defId as unknown,
          code: this.row.code
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'checkConfigSysValid', param).then(response => {
      this.checkInner(response)
    })
  }

  private checkInner(response: any): void {
    if (!isReqSuccess(response)) {
      showError(response)
      return
    }
    if (response.data?.success) {
      KNotification.success({ title: '提示', content: '校验成功' })
    } else {
      KNotification.error({
        title: '提示',
        content: response.data?.message || ''
      })
    }
  }

  /**
   * 保存
   */
  public save(): void {
    let formState = this.formState.value
    formState = this.convertValue(formState)!
    const param = {
      data: [
        {
          treeId: this.treeId as unknown,
          defId: this.defId as unknown,
          // id: '' as unknown,
          code: formState?.code,
          value: formState?.value
        }
      ]
    }
    Api.post('sys', 'ConfigSysService', 'updateConfigSysValue', param).then(response => {
      this.saveInner(response, formState)
    })
  }

  /**
   * 上传
   */
  public upload() {
    // 动态创建文件输入框
    const fileInput = document.createElement('input')
    fileInput.type = 'file'
    fileInput.multiple = false
    // 监听文件选择事件
    fileInput.addEventListener('change', () => {
      const files = fileInput.files
      if (files && files.length > 0) {
        this.uploadPrimaryFile(files[0]).then(fileId => {
          if (!fileId) {
            KDialog.error('上传文件失败')
            return
          }
          this.formState.value = {
            ...this.formState.value,
            value: fileId
          }
          KDialog.confirm({
            title: '文件上传成功，是否保存配置值？',
            okText: '保存',
            cancelText: '取消',
            onCancel: async () => {
              this.loadData()
            },
            onOk: async () => {
              this.save()
            }
          })
        })
      } else {
        reject('未选择文件')
      }
    })
    // 触发文件选择对话框
    fileInput.click()
  }

  private async uploadPrimaryFile(file: File) {
    const req = new FormData()
    req.append('modelName', 'Part')
    req.append('attributeName', 'attachment')
    req.append('files', file)
    const result = (await request.post('/kmsaasFileApi/upload', req, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })) as any
    if (result && result.result == 'SUCCESS') {
      const data = result.data
      return data[0]
    }
  }

  private saveInner(response: any, formState: IConfigValueDefinition | undefined): void {
    if (!isReqSuccess(response)) {
      showError(response)
      return
    }
    // 保存成功后, 刷新初始值
    this.sourceValue = formState?.value || ''
    this.sourceValue_switch = formState?.value_switch || false
    KNotification.success({ title: '提示', content: '保存成功' })

    // 发布更新所属模块有效性的事件通知
    const validStatus = response.data as IConfigValidDefinition
    validStatus.defId = this.defId
    this.emit('updateConfigValidStatus', new UpdateConfigValidStatusEventArgs(validStatus))
  }

  /**
   * 刷新
   */
  public refresh(): void {
    const formState = this.formState.value
    let actualValue = formState?.value as any
    let expectedValue = this.sourceValue as any
    if (formState?.editType === EnumEditType.SWITCH) {
      actualValue = formState.value_switch
      expectedValue = this.sourceValue_switch
    }
    if (!equals(actualValue, expectedValue)) {
      KDialog.confirm({
        title: '存在数据修改，是继续保存，还是放弃修改？',
        okText: '继续保存',
        cancelText: '放弃修改',
        onCancel: async () => {
          this.loadData()
        },
        onOk: async () => {
          this.save()
        }
      })
    }
  }

  /**
   * 表单数据保存前转换
   * @param formState 表单数据
   * @returns 表单数据
   */
  private convertValue(formState: IConfigValueDefinition | undefined): IConfigValueDefinition | undefined {
    if (formState === undefined) {
      return formState
    }
    if (this.row.editType === EnumEditType.SWITCH) {
      formState.value = formState.value_switch.toString()
    }
    return formState
  }

  /**
   * 初始化[下拉菜单]选项
   */
  private initSelect(): void {
    if (this.row.editType === EnumEditType.SELECT) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<string>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            label: value,
            value: value
          })
        }
      }
      this.selectEditTypeOptions.value = options
    }
  }

  /**
   * 初始化[可编辑的下拉菜单]选项
   */
  private initSelectEdit(): void {
    if (this.row.editType === EnumEditType.SELECT_EDITABLE) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<string>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            value: value,
            label: value
          })
        }
      }
      this.selectEditEditTypeOptions.value = options
    }
  }

  /**
   * 初始化[带显示值的下拉菜单]选项
   */
  private initSelectDisplay(): void {
    if (this.row.editType === EnumEditType.SELECT_DISPLAY) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<Record<string, any>>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            label: value.displayedValue,
            value: value.actualValue
          })
        }
      }
      this.selectDisplayEditTypeOptions.value = options
    }
  }

  /**
   * 初始化[开关]
   */
  private initSwitch(): void {
    this.row.value_switch = false
    if (this.row.value === 'true') {
      this.row.value_switch = true
    }
  }

  /**
   * 初始化[单选框]
   */
  private initRadio(): void {
    if (this.row.editType === EnumEditType.RADIO) {
      const json = JSON.parse(this.row.editParam)
      const values = json?.candidate ?? ([] as Array<string>)

      const options = [] as Array<IValueDesc>

      if (values != null && values.length > 0) {
        for (const value of values) {
          options.push({
            label: value,
            value: value
          })
        }
      }
      this.radioEditTypeOptions.value = options
    }
  }

  /**
   * 可编辑下拉菜单变更事件
   * @param selectedArr 选择事件
   */
  public selectEditableChange(selectedArr: any): void {
    const formState = this.formState.value
    if (formState !== undefined) {
      formState.value = selectedArr.newValue[selectedArr.newValue.length - 1]
      if (formState.value) {
        formState.values = [formState.value]
      } else {
        formState.values = []
      }
      this.formState.value = formState
    }
  }
}
