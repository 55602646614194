import { Api, FileClientSrv } from '@kmsoft/ebf-common'
import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { computed, ref } from 'vue'
import { DocProperty } from '../../../client-agent'
import { FileDataInter, Progress } from '../doc-select-files/interface'
import { KDocBatchImportEmitsType, KDocBatchImportPropType } from './interface'

/** KDocBatchImport */
export default class KDocBatchImportViewModel extends BaseViewModel<KDocBatchImportEmitsType, KDocBatchImportPropType> {
  /** 是否已入库过 **/
  haveImport = ref<boolean>(false)
  /** 正在上传 **/
  saveLoading = ref<boolean>(false)
  /** 导入文件列表 **/
  importFileList = computed(() => {
    return this.props.fileList.filter(fileData => fileData.selected)
  })
  /** 所有都保存成功 **/
  allSaved = computed(() => {
    return this.importFileList.value.every(fileData => fileData.importStateProgress.id === 'complete')
  })

  constructor(options: ViewModelOptions<KDocBatchImportPropType>) {
    super(options)
  }

  viewDidMount() {}

  async save() {
    this.haveImport.value = true
    this.saveLoading.value = true
    //只有待入库和入库失败的可以保存
    const importFileList = this.importFileList.value.filter(fileData =>
      ['waiting', 'fail'].includes(fileData.importStateProgress.id)
    )
    const codesToRemove = new Set<string>()
    importFileList.forEach(fileData => {
      fileData.docList.forEach(doc => {
        if (doc.code && doc.code !== fileData.number) {
          codesToRemove.add(doc.code)
        }
      })
    })
    const uniqueFileList = importFileList.filter(fileData => {
      if (codesToRemove.has(fileData.number!)) {
        fileData.importStateProgress = Progress.dupicate
      }
      return !codesToRemove.has(fileData.number!)
    })

    for (let i = 0; i < uniqueFileList.length; i++) {
      const fileData = uniqueFileList[i]
      await this.importFile(fileData)
    }
    this.saveLoading.value = false
  }

  async importFile(fileData: FileDataInter) {
    fileData.importStateProgress = Progress.processing
    const params: Record<string, any> = {
      modelCode: 'Document',
      modelGroup: 'doc',
      documentType: this.props.documentType,
      number: fileData.number,
      name: fileData.name,
      version: 'A',
      lifecycleState: {},
      description: '',
      folder: {
        id: this.props.folderId
      },
      primary: [],
      secondary: [],
      docList: fileData.docList,
      docStructList: fileData.docStructList,
      fileName: fileData.filePath
    }

    if (this.props.agentOpened) {
      if (fileData.primary && fileData.primary.length > 0) {
        params.primary = fileData.primary
      } else {
        const docList = fileData.docList as DocProperty[]
        for (const docItem of docList) {
          if (docItem.checkoutPath) {
            const result = await FileClientSrv.uploadFileByAgent({
              filename: docItem.checkoutPath!,
              modelName: 'Document',
              attributeName: 'primary'
            })
            docItem.primary = [{ id: result.data[0] }]
          }
        }
        const rootNode = docList.find(item => item.isRoot)
        if (!rootNode) {
          KNotification.error({
            title: '系统提示',
            content: '主文件不能为空'
          })
        }
        params.primary = rootNode?.primary
      }
      params.createPart = this.props.filePropertyOption?.isStruct
    } else {
      params.primary = fileData.primary
    }

    if (params.createPart) {
      for (const docItem of params.docList) {
        docItem.part = {
          number: docItem.code,
          name: docItem.name,
          folder: {
            id: this.props.folderId
          }
        }
      }
    }

    return await Api.post('doc', 'Document', 'batchCreate', { data: [{ ...params }] }).then(res => {
      if (res.success) {
        fileData.importStateProgress = Progress.complete
        fileData.importDesc = ''
      } else {
        fileData.importStateProgress = Progress.fail
        fileData.importDesc = res.message
      }
      return res
    })
  }
}
