import { EnumAttributeType, EnumControlElementType } from '@kmsoft/upf-core'
/**控件属性集合 */
const PROPS = [
  {
    name: 'preview',
    title: '能否预览',
    control: EnumControlElementType.CHECKBOX,
    attributeType: EnumAttributeType.PROPS,
    defaultValue: false,
    disabled: false
  }
] as Array<any>

const EVENT = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'change', title: '值改变后', describe: '值改变后 的说明信息...' }
]

export default { PROPS, EVENT }
