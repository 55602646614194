import { connect, withInstall } from '@kmsoft/upf-core'
import KMaterialFormView from './KMaterialForm.vue'
import KMaterialFormViewModel from './KMaterialFormViewModel'

const KMaterialForm = connect(KMaterialFormView, KMaterialFormViewModel)

export default withInstall(KMaterialForm)
export { KMaterialForm, KMaterialFormView, KMaterialFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'
