import { BaseViewModel, KDataGridViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KMaterialFormEmitsType, KMaterialFormPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'

/** KMaterialForm */
export default class KMaterialFormViewModel extends BaseViewModel<KMaterialFormEmitsType, KMaterialFormPropType> {
  /**网格 */
  refMaterialGrid = ref<KDataGridViewModel>()

  constructor(options: ViewModelOptions<KMaterialFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 网格数据加载
   */
  loadData() {
    return new Promise(async (resolve, reject) => {
      resolve({
        rows: [
          {
            id: 1,
            factory: '总数',
            quantity: 1000,
            unit: '个'
          },
          {
            id: 2,
            factory: '工厂1',
            quantity: 500,
            unit: '个'
          },
          {
            id: 3,
            factory: '工厂2',
            quantity: 500,
            unit: '个'
          }
        ],
        total: 3
      })
      
      // const params = {
      //   id: this.props.objParam.id,
      //   clazz: this.props.objParam.modelCode
      // }
      // const result = (await Api.post('common', 'MaterialService', 'list', { data: [params] })) as any
      // if (result && result.code == EnumRequestCode.SUCCESS) {
      //   const data = result.data
      //   if (data && data.length != 0) {
      //     resolve({
      //       rows: data,
      //       total: data.length
      //     })
      //   } else {
      //     reject([])
      //   }
      // } else {
      //   KNotification.error({
      //     title: '查询失败',
      //     content: '查询物料库存失败：' + result.message,
      //     details: result.detail
      //   })
      // }
    })
  }

  /**
   * 刷新网格
   */
  refresh() {
    this.refMaterialGrid.value?.refresh()
  }

  /**
   * 导出excel
   */
  exportAsExcel() {
    const params = {
      columnKeys: this.refMaterialGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id)
    }
    this.refMaterialGrid.value?.exportDataAsExcel(params)
  }

  /**
   * 导出选中
   */
  exportSelectAsExcel() {
    const data = this.refMaterialGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    const params = {
      onlySelected: true,
      columnKeys: this.refMaterialGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id)
    }
    this.refMaterialGrid.value?.exportDataAsExcel(params)
  }
}
