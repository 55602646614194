
import { defineView } from '@kmsoft/upf-core'
import { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor } from '@kmsoft/ebf-common'
import { KBaselineMemberGridPropOptions, KBaselineMemberGridEventEmits } from './interface'
import KBaselineMemberGridViewModel from './KBaselineMemberGridViewModel'

export default defineView({
  name: 'KBaselineMemberGrid',
  props: KBaselineMemberGridPropOptions,
  emits: KBaselineMemberGridEventEmits,
  viewModel: KBaselineMemberGridViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return { EnumLifecycleState, clsCodeMap, EnumLifecycleStateColor }
  }
})
