import { BaseViewEventEmits, BaseViewPropOptions, ViewEmitsTypeExtract, ViewPropsTypeExtract } from '@kmsoft/upf-core'
import { KDocBatchCreateBaseEmitsOptions, KDocBatchCreateBasePropOptions } from '../interface'

export interface FileDataInter {
  id?: string
  tempId: string // 临时id，用于组件给key赋值
  name: string | undefined //文档名称
  number: string | undefined //编码
  fileName: string //文件名称
  fileSuffix: string | undefined //文件后缀
  filePath?: string //文件路径
  file?: File // 原文件
  proxyUpload: boolean // 是否是代理上传，代理上传有语言件路径，普通上传有file
  extractProgress: ProgressType //提取进度
  docList: any[] // 文档关联对象
  docStructList: any[] // 文档结构
  selected: boolean //是否选中
  importStateProgress: ProgressType //导入状态
  importDesc: string //入库说明
  primary: { id: string }[] //主文件
}

class ProgressType {
  constructor(id: string, uploadName: string, extractName: string, stateName: string, icon: string) {
    this.id = id
    this.uploadName = uploadName
    this.extractName = extractName
    this.stateName = stateName
    this.icon = icon
  }
  id: string
  uploadName: string //上传名称
  extractName: string //提取名称
  stateName: string //状态名称
  icon: string
}

export const Progress = {
  waiting: new ProgressType('waiting', '待上传', '待提取', '待入库', 'waiting'),
  processing: new ProgressType('processing', '上传中', '提取中', '入库中', 'loading'),
  uploadComplete: new ProgressType('uploadComplete', '上传完成', '上传完成', '上传完成', 'success-circle-green'),
  complete: new ProgressType('complete', '上传成功', '提取完成', '入库完成', 'success-circle-green'),
  fail: new ProgressType('fail', '上传失败', '提取失败', '入库失败', 'error-circle-red'),
  dupicate: new ProgressType('dupicate', '重复', '重复', '重复', 'success-circle-blue')
}

class AcceptType {
  constructor(local: string, proxy: string) {
    this.local = local
    this.proxy = proxy
  }
  local: string
  proxy: string
}

export const acceptTypeList = {
  Solidworks: new AcceptType('.sldasm,.sldprt', 'SOLIDWORKS文件 (*.sldasm;*.sldprt)|*.sldasm;*.sldprt'),
  Autocad2d: new AcceptType('.dwg', 'DWG文件 (*.dwg)|*.dwg'),
  Excel: new AcceptType('.xlsx,.xls', 'Excel文件 (*.xlsx;*.xls)|*.xlsx;*.xls'),
  Word: new AcceptType('.docx,.doc', 'Word文件 (*.docx;*.doc)|*.docx;*.doc'),
  Structure: new AcceptType('.*', '所有文件 (*.*)|*.*'),
  Cadence: new AcceptType('.dsn', '所有文件 (*.dsn)|*.*'),
  CadencePcb: new AcceptType('.brd', '所有文件 (*.brd)|*.*'),
  Gerber: new AcceptType('.*', '所有文件 (*.*)|*.*'),
  OdbPlus: new AcceptType('.*', '所有文件 (*.brd)|*.*'),
  Coordinate: new AcceptType('.*', '所有文件 (*.*)|*.*'),
  NetList: new AcceptType('.*', '所有文件 (*.brd)|*.*'),
  SymbolLib: new AcceptType('.*', '所有文件 (*.brd)|*.*'),
  WrapperLib: new AcceptType('.*', '所有文件 (*.brd)|*.*'),
  Solidworks2d: new AcceptType('.slddrw', '所有文件 (*.slddrw)|*.*'),
  Other: new AcceptType('.*', '所有文件 (*.*)|*.*')
}

/** 参数 **/
export const KDocSelectFilesPropOptions = {
  ...BaseViewPropOptions,
  ...KDocBatchCreateBasePropOptions
}

/** 参数类型 **/
export type KDocSelectFilesPropType = ViewPropsTypeExtract<typeof KDocSelectFilesPropOptions>

/** 事件 */
export const KDocSelectFilesEventEmits = {
  ...BaseViewEventEmits,
  ...KDocBatchCreateBaseEmitsOptions
}

/** 事件类型 **/
export type KDocSelectFilesEmitsType = ViewEmitsTypeExtract<typeof KDocSelectFilesEventEmits>
