import { connect, withInstall } from '@kmsoft/upf-core'
import KFileComparisonView from './KFileComparison.vue'
import KFileComparisonViewModel from './KFileComparisonViewModel'

const KFileComparison = connect(KFileComparisonView, KFileComparisonViewModel)

export default withInstall(KFileComparison)
export { KFileComparison, KFileComparisonView, KFileComparisonViewModel }

// 模板生成文件
// export * from './{{folderName}}'
