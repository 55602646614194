import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fed3f94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w100p" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "file-list-style"
}
const _hoisted_4 = {
  key: 1,
  style: {"margin-left":"10px"}
}
const _hoisted_5 = { class: "file-container" }
const _hoisted_6 = {
  key: 0,
  class: "count plr3"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "text-nowrap plr2 w100p" }
const _hoisted_10 = {
  key: 1,
  class: "flex1 flex-center fs18"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_tooltip = _resolveComponent("k-tooltip")!
  const _component_k_spin = _resolveComponent("k-spin")!
  const _component_k_upload_dragger = _resolveComponent("k-upload-dragger")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.readonly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.$vm.fileList.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$vm.fileList, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.id,
                    class: "file-style"
                  }, [
                    _createVNode(_component_k_icon, {
                      type: _ctx.$vm.getIcon(item),
                      width: "60",
                      height: "60"
                    }, null, 8, ["type"]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", null, _toDisplayString(item?.name), 1)
                    ])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, "无文件"))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.readonly)
      ? (_openBlock(), _createBlock(_component_k_upload_dragger, {
          key: 1,
          name: "file",
          "before-upload": _ctx.$vm.beforeUpload,
          disabled: _ctx.$vm.uploadDisabled || _ctx.isDesigner
        }, {
          default: _withCtx(() => [
            _createVNode(_component_k_spin, {
              spinning: _ctx.$vm.uploadLoading
            }, {
              default: _withCtx(() => [
                _createElementVNode("section", _hoisted_5, [
                  (_ctx.$vm.fileList.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$vm.fileList.length), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("section", {
                    class: "flex-start-center flex-wrap overflowYAuto",
                    style: _normalizeStyle({ height: `${_ctx.height}px` })
                  }, [
                    (_ctx.$vm.fileList.length > 0)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.$vm.fileList, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "card flex-center flex-direction-column mlr5 mb5",
                            key: item.id
                          }, [
                            _withDirectives(_createElementVNode("div", {
                              class: "close flex-center",
                              onClick: _withModifiers(($event: any) => (_ctx.$vm.removeData(index)), ["stop"])
                            }, "×", 8, _hoisted_7), [
                              [_vShow, !_ctx.$vm.uploadDisabled]
                            ]),
                            _withDirectives(_createElementVNode("div", {
                              class: "download flex-center",
                              onClick: _withModifiers(($event: any) => (_ctx.$vm.download(item)), ["stop"])
                            }, [
                              _createVNode(_component_k_icon, {
                                type: "download",
                                width: "60",
                                height: "60",
                                color: "#409eff"
                              })
                            ], 8, _hoisted_8), [
                              [_vShow, !_ctx.$vm.uploadDisabled]
                            ]),
                            _createElementVNode("div", null, [
                              _createVNode(_component_k_icon, {
                                type: _ctx.$vm.getIcon(item),
                                width: "60",
                                height: "60"
                              }, null, 8, ["type"])
                            ]),
                            _createVNode(_component_k_tooltip, { placement: "bottom" }, {
                              title: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(item?.name), 1)
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("footer", _hoisted_9, _toDisplayString(item?.name), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]))
                        }), 128))
                      : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$vm.uploadDisabled ? '无文件' : '点击或拖拽文件到此区域上传'), 1))
                  ], 4)
                ])
              ]),
              _: 1
            }, 8, ["spinning"])
          ]),
          _: 1
        }, 8, ["before-upload", "disabled"]))
      : _createCommentVNode("", true)
  ]))
}