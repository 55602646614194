
import { defineView } from '@kmsoft/upf-core'
import { KChangePromoteActivityPropOptions, KChangePromoteActivityEventEmits } from './interface'
import KChangePromoteActivityViewModel from './KChangePromoteActivityViewModel'

export default defineView({
  name: 'KChangePromoteActivity',
  props: KChangePromoteActivityPropOptions,
  emits: KChangePromoteActivityEventEmits,
  viewModel: KChangePromoteActivityViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
