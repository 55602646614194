import {
  EnumControlElementType,
  EnumGridElementType,
  EnumType,
  KContainer,
  KControl,
  KDataGridColumn,
  KFilterGrid,
  KGridColumn,
  KGridColumnCompute,
  KGridColumnGroup,
  KSchema,
  LayoutRenderElement,
  VNodeProps,
  defineView,
  utils,
  RenderLayoutConfig
} from '@kmsoft/upf-core'
import _ from 'lodash'
import { VNode, createVNode, resolveComponent } from 'vue'
import KPreviewViewModel from './KPreviewViewModel'
import {
  KPreviewEventEmits,
  KPreviewEventEmitsType,
  KPreviewPropOptions,
  KPreviewPropType,
  KDefaultColumnsType
} from './interface'
import { applyToConstructor } from './untils'
import {
  DragElement,
  EnumRenderLayoutType,
  KRenderLayout,
  KRenderLayoutEmitsType,
  KRenderLayoutPropType
} from '@kmsoft/upf-view-engine'
import { KViewRenderElementWrap } from '../../../render'
import { KToolStripContainer } from '../../../controls/tool-strip'
import { KSegmentContainer } from '../../../controls/segment'

export default defineView({
  name: 'KPreview',
  props: KPreviewPropOptions,
  emits: KPreviewEventEmits,
  viewModel: KPreviewViewModel,
  setup(props, { attrs, emit, expose, slots, vm }) {
    /**
     * 布局元素删除事件
     * @param code 元素code
     */
    const onElementDelete = (deleteElement: LayoutRenderElement) => {
      vm.emit('elementDelete', deleteElement)
      vm.clearElementSelected()
    }

    /**
     * 容器中拖入新元素时触发
     * @param dragElement 拖动元素
     */
    const onElementDragIn = (dragElement: DragElement<LayoutRenderElement>) => {
      const element = dragElement._underlying_vm_

      vm.emit('elementDragIn', element.name)

      vm.onElementSelected(element)
    }

    /**
     * 根据类型渲染指定元素
     * @param element 元素
     */
    const renderElement = (element: LayoutRenderElement) => {
      const control = element.control.split('_')[0]

      // 布局元素为页段类型时,直接渲染页段组件
      if (control == EnumControlElementType.SEGMENT) {
        return __renderSegmentContainer((element as any) as KContainer)
      }

      //布局元素为组合时，渲染组合容器
      if (control == EnumControlElementType.GROUP) {
        return __renderOwnComponent(element as any, 'group')
      }

      //布局元素为表单时，渲染表单容器
      if (control == EnumControlElementType.FORM) {
        return __renderOwnComponent(element as any, 'form')
      }

      //布局元素为工具栏时，渲染按钮栏容器
      if (control == EnumControlElementType.TOOL_STRIP) {
        return (
          <KToolStripContainer
            elements={(element as KContainer).controls}
            selectedElement={props.selectedElement}
            designerMetaControl={props.designerMetaControl}
            onElementSelected={vm.onElementSelected}
          />
        )
      }

      //布局元素为按钮时，渲染按钮
      if (control == EnumControlElementType.BUTTON) {
        return <k-button {...element.props}>{element.title}</k-button>
      }

      //布局元素为下拉按钮时,渲染渲染下拉按钮
      if (control == EnumControlElementType.DROPDOWN_BUTTON) {
        const slots = {
          overlay: () => {
            return (
              <k-menu>
                {element.props.menuList!.map((x: any) => {
                  return (
                    <k-menu-item key={x.value} disabled={x.disabled}>
                      {x.label}
                    </k-menu-item>
                  )
                })}
              </k-menu>
            )
          }
        }
        return (
          <k-dropdown trigger={element.props.trigger} placement={element.props.placement} v-slots={slots}>
            <k-button
              style={'position:relative;top:7px;height:36px;width:120px'}
              size={element.props.size}
              type={element.props.type}
              loading={element.props.loading}
              disabled={!element.props.disabled}
            >
              {element.title}
              <k-icon type={element.props.icon} />
            </k-button>
          </k-dropdown>
        )
      }

      //布局元素为表格时，渲染表格
      if (control == EnumControlElementType.GRID) {
        const layout = vm.getParentElementLayoutConfig(element.name, [props.rootSchema])
        const columns = __toKDataGridColumns(element as KControl)
        const metaDataGridProps = {
          columns: columns,
          isMultipleSelection: false
        }

        return (
          <KViewRenderElementWrap meta={element} parentLayout={layout}>
            <k-data-grid ref="refDataGrid" {...metaDataGridProps} />
          </KViewRenderElementWrap>
        )
      }

      //布局元素为筛选表格时，渲染筛选表格
      if (control == EnumControlElementType.FILTER_GRID) {
        return __renderFilterGirdControl(element as KControl)
      }

      //布局元素为列表头部栏
      if (control == EnumControlElementType.GRID_HEAD_TOOL) {
        const style = 'overflow:visible;z-index:2 '
        return __renderOwnComponent(element as any, 'grid-head-tool', style)
      }

      if (control == EnumControlElementType.PAGE) {
        return __renderOwnComponent(element as any, 'page')
      }

      //布局元素为文本框时，渲染输入框
      if (control == EnumControlElementType.INPUT) {
        return __renderBaseControl(element as KControl)
      }

      //布局元素为多文本时，渲染多文本框
      if (control == EnumControlElementType.TEXT_AREA) {
        return __renderBaseControl(element as KControl)
      }

      //布局元素为日期时，渲染日期框
      if (control == EnumControlElementType.DATE_TIME) {
        return __renderBaseControl(element as KControl)
      }

      //布局元素为数字框，渲染数字框
      if (control == EnumControlElementType.NUMBER) {
        return __renderBaseControl(element as KControl)
      }

      //布局元素为复选框，渲染复选框
      if (control == EnumControlElementType.CHECKBOX) {
        return __renderBaseControl(element as KControl)
      }

      //布局元素为下拉框，渲染下拉框
      if (control == EnumControlElementType.COMBOBOX) {
        return __renderBaseControl(element as KControl)
      }

      // 其他类型返回占位符
      return __renderOtherControl(element as KControl)
    }

    /**
     * 渲染基础控件
     * @param element 拖入元素
     */
    const __renderBaseControl = (element: KControl) => {
      const layout = vm.getParentElementLayoutConfig(element.name, [props.rootSchema])
      let comp: VNode | null
      const control = element.control.split('_')[0]

      switch (control) {
        case EnumControlElementType.INPUT:
          comp = <k-input {...element.props} />
          break
        case EnumControlElementType.TEXT_AREA:
          comp = <k-textarea {...element.props} />
          break
        case EnumControlElementType.DATE_TIME:
          comp = <k-date-time {...element.props} />
          break
        case EnumControlElementType.NUMBER:
          comp = <k-input-number {...element.props} />
          break
        case EnumControlElementType.CHECKBOX:
          comp = <k-checkbox {...element.props} />
          break
        case EnumControlElementType.COMBOBOX:
          comp = <k-select {...element.props} />
          break
        case EnumControlElementType.GRID:
        default:
          comp = createVNode(resolveComponent(control), { ...element.props })
          break
      }

      return (
        <KViewRenderElementWrap meta={element} parentLayout={layout}>
          {comp}
        </KViewRenderElementWrap>
      )
    }

    /**
     * 渲染其他控件
     * @param element 拖入元素
     **/
    const __renderOtherControl = (element: KControl) => {
      const layout = vm.getParentElementLayoutConfig(element.name, [props.rootSchema])
      const control = element.control.split('_')[0]
      /** 组件 */
      const component = resolveComponent(control)

      let vNode: VNode

      // 如果返回的是String 则没有找到组件
      if (typeof component == 'string') {
        vNode = <div>`??${control}??`</div>
      } else {
        // isDesigner 设计模式，防止自定义控件中的按钮可触发
        vNode = createVNode(component, { isDesigner: true, ...element.props })
      }

      return (
        <KViewRenderElementWrap meta={element} parentLayout={layout}>
          {vNode}
        </KViewRenderElementWrap>
      )
    }

    /**
     * 网格列转换
     * @param element 拖入元素
     **/
    const __toKDataGridColumns = (elements: KControl) => {
      return (
        utils.isArray(elements.props.columns) &&
        elements.props.columns!.map((column: KGridColumnGroup | KGridColumn | KGridColumnCompute) => {
          switch (column.type) {
            case EnumGridElementType.COLUMN:
              return __toSingleKDataGridColumn(column)
            case EnumGridElementType.GROUP:
              return __toSingleKDataGridColumnGroup(column)
            case EnumGridElementType.COMPUTE:
              return __toSingleKDataGridColumnComputed(column)
            default:
              return ({} as any) as KDataGridColumn
          }
        })
      )
    }

    /**
     * 渲染列
     * @param element 拖入元素
     */
    const __toSingleKDataGridColumn = (element: KGridColumn) => {
      const { name, title, props } = element as KGridColumn
      const { visible, disabled } = element
      const commonColumnInstanceValues = {
        id: element.name,
        headerText: element.title,
        readonly: disabled,
        dataPropertyName: element.name,
        fixed: element.props?.frozen ? 'left' : undefined,
        rowGroup: element.props?.rowGroup,
        enableRowGroup: element.props?.enableRowGroup,
        hide: element.props?.hide,
        ...props
      }

      /** 通用列实例值, 当列为自定义时，默认用KDataGridTextBoxColumn来显示 */
      let control = element.control.split('_')[0]
      if (!KDefaultColumnsType.includes(element.control.split('_')[0])) {
        control = 'KDataGridTextBoxColumn'
      }
      const columnInstance = applyToConstructor(control, [])

      // copy common values to column instance
      Object.assign(columnInstance, commonColumnInstanceValues)

      // copy editArgument values to column instance
      if (props) Object.assign(columnInstance, props)
      return columnInstance
    }

    /**
     * 渲染分组列
     * @param element 拖入元素
     */
    const __toSingleKDataGridColumnGroup = (element: KGridColumnGroup) => {
      const control = element.control.split('_')[0]
      const columnGroupInstance = applyToConstructor(control, []) //new KDataGridColumnGroup()
      columnGroupInstance.headerText = element.title
      columnGroupInstance.align = element.props.align || 'center'

      // recursive children

      columnGroupInstance.children = element.controls.map(childElement => __toSingleKDataGridColumn(childElement as KGridColumn))

      return columnGroupInstance
    }

    /**
     * 渲染计算列
     * @param element 拖入元素
     */
    const __toSingleKDataGridColumnComputed = (element: KGridColumnCompute): any => {
      const control = element.control.split('_')[0]
      const columnCompute = applyToConstructor(control, []) //new KDataGridComputeColumn()
      columnCompute.headerText = element.title
      columnCompute.align = element.props.align || 'center'
      /**计算列公式 */
      columnCompute.expression = element.props.expression
      /**计算列只做显示 */
      columnCompute.readonly = true
      return columnCompute
    }

    /**
     * 渲染筛选网格
     * @param element 拖入元素
     */
    const __renderFilterGirdControl = (element: KControl) => {
      const elementProps = _.cloneDeep(element.props)
      const data = elementProps.views
      if (data.length > 0) {
        data[0].elements.forEach((item: any) => {
          if (!KDefaultColumnsType.includes(item.control)) {
            item.control = 'KDataGridTextBoxColumn'
          }
        })
      }
      return createVNode(KFilterGrid, { ...elementProps, loadData: () => Promise.resolve([]) })
    }

    /**
     * 渲染自身组件
     * @param container 元素
     */
    const __renderOwnComponent = (element: KContainer | KSchema, className: string, style?: string) => {
      const previewProps: VNodeProps<KPreviewPropType, KPreviewEventEmitsType> = {
        class: className,
        style: style,
        schema: element,
        rootSchema: props.rootSchema,
        selectedElement: props.selectedElement,
        designerMetaControl: props.designerMetaControl,
        onElementSelected: vm.onElementSelected,
        onElementDelete: (element: LayoutRenderElement) => vm.emit('elementDelete', element),
        onElementDragIn: (elementName: string) => vm.emit('elementDragIn', elementName)
      }

      return <k-preview {...previewProps} />
    }

    /**
     * 渲染页段容器
     * @param element 元素
     */
    const __renderSegmentContainer = (element: KContainer) => {
      return <KSegmentContainer title={element.title}>{__renderOwnComponent(element, 'segment')}</KSegmentContainer>
    }

    return () => {
      if (!props.schema) {
        return <k-empty />
      }

      const draggableSlots = {
        item: ({ element }: { element: LayoutRenderElement }) => {
          return renderElement(element)
        }
      }

      const renderLayoutProps: VNodeProps<KRenderLayoutPropType, KRenderLayoutEmitsType> = {
        class: ['k-layout-designer-preview', { 'root-element': props.schema.type == EnumType.ROOT }, attrs.class],
        designMode: true,
        elements: props.schema.controls,
        containerConfig: props.schema.props as RenderLayoutConfig,
        layoutType: props.schema.type == EnumType.ROOT ? EnumRenderLayoutType.BLOCK : EnumRenderLayoutType.GRID,
        selectedElement: props.selectedElement,
        onAdd: slotProps => onElementDragIn(slotProps.item),
        onDelete: element => onElementDelete(element as LayoutRenderElement),
        'onUpdate:selectedElement': element => {
          vm.onElementSelected(element as any)
        }
      }

      return createVNode(KRenderLayout, renderLayoutProps, draggableSlots)
    }
  }
})
