import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-548c0c42"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "border1 h100p"
}
const _hoisted_2 = { style: {"height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_KUserSelector = _resolveComponent("KUserSelector")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_number_column = _resolveComponent("k-data-grid-number-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, { class: "panel-content" }, {
    header: _withCtx(() => [
      (!_ctx.readonly)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createVNode(_component_k_tool_strip, null, {
              default: _withCtx(() => [
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "添加",
                  icon: "plus",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.add()))
                }),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "移除",
                  icon: "minus",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.remove()))
                }),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "刷新",
                  icon: "sync",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.refresh()))
                }),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "排序",
                  icon: "ordered-list",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.sort()))
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_2, [
        _createVNode(_component_k_data_grid, {
          loadData: _ctx.$vm.loadData,
          rowModelType: "serverSide",
          ref: "refObjGrid"
        }, {
          columns: _withCtx(() => [
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "接收人",
              name: "receiver",
              dataPropertyName: "receiver",
              readonly: true,
              align: "center",
              width: "150"
            }, {
              default: _withCtx(({ cellValue}) => [
                _createVNode(_component_KUserSelector, {
                  value: cellValue,
                  readonly: true,
                  disabled: true
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_combo_box_column, {
              headerText: "接收状态",
              name: "status",
              dataPropertyName: "status",
              readonly: true,
              align: "center",
              width: "150",
              options: _ctx.$vm.statusOptions
            }, null, 8, ["options"]),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "接收时间",
              name: "receivingTime",
              dataPropertyName: "receivingTime",
              readonly: true,
              align: "center",
              width: "150"
            }),
            _createVNode(_component_k_data_grid_number_column, {
              headerText: "发放份数",
              name: "countOfCopies",
              dataPropertyName: "countOfCopies",
              align: "center",
              range: { min: 0, max: 2000000000 },
              width: "150"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "创建时间",
              dataPropertyName: "createTime",
              readonly: true,
              align: "center",
              width: "auto"
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "创建者",
              dataPropertyName: "creator",
              readonly: true,
              align: "center",
              width: "auto"
            }, {
              default: _withCtx(({ cellValue}) => [
                _createTextVNode(_toDisplayString(cellValue?.split(' ')[0]), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["loadData"])
      ])
    ]),
    _: 1
  }))
}