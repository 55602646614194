import { connect, withInstall } from '@kmsoft/upf-core'
import KInputNumberEditorView from './KInputNumberEditor.vue'
import KInputNumberEditorViewModel from './KInputNumberEditorViewModel'

const KInputNumberEditor = connect(KInputNumberEditorView, KInputNumberEditorViewModel)

export default withInstall(KInputNumberEditor)
export { KInputNumberEditor, KInputNumberEditorView, KInputNumberEditorViewModel }

// 模板生成文件
// export * from './{{folderName}}'
