import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KChangeOrderRelationshipEmitsType, KChangeOrderRelationshipPropType } from './interface'
import { Api, EnumRequestCode, KObjectClassGridViewModel, ObjBusinessParam } from '@kmsoft/ebf-common'
import { ref } from 'vue'

/** KChangeOrderRelationship */
export default class KChangeOrderRelationshipViewModel extends BaseViewModel<
  KChangeOrderRelationshipEmitsType,
  KChangeOrderRelationshipPropType
> {
  refChangeRequestGrid = ref<KObjectClassGridViewModel>()
  refChangeActivityGrid = ref<KObjectClassGridViewModel>()
  activeKey = ref<Array<string>>(['1', '2'])

  /**
   * 变更请求
   */
  plmLinkSources = ref<Array<Record<string, any>>>([])

  /**
   * 变更活动
   */
  plmLinkTargets = ref<Array<Record<string, any>>>([])

  constructor(options: ViewModelOptions<KChangeOrderRelationshipPropType>) {
    super(options)
  }

  viewDidMount() {
    this.getRelationship()
  }

  titleClick(key: string) {
    if (!this.activeKey.value.includes(key)) {
      this.activeKey.value.push(key)
    }
  }

  /**
   * 查询关联关系
   */
  async getRelationship() {
    const reqParam = {
      data: [this.props.objParam?.id]
    }
    const result = await Api.post('change', 'ChangeOrder', 'getLinks', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.plmLinkTargets.value = result.data?.plmLinkTargets || []
      this.plmLinkSources.value = result.data?.plmLinkSources || []

      this.plmLinkTargets.value.forEach((item: any) => {
        this.refChangeActivityGrid.value?.insertRow(item)
      })
      this.plmLinkSources.value.forEach((item: any) => {
        this.refChangeRequestGrid.value?.insertRow(item)
      })
      return
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  loadChangeRequestData() {
    return this.plmLinkSources.value
  }

  loadChangeActivityData() {
    return this.plmLinkTargets.value
  }
}
