import {
  BaseViewModel,
  ViewModelOptions,
  EnumToolStripCompType,
  KDataGridViewModel,
  IKTreeNode,
  EnumDialogResult
} from '@kmsoft/upf-core'
import { Api, EnumToolStripItemKeys, KObjectToolStripViewModel, EnumRequestCode, ObjectClientSrv } from '@kmsoft/ebf-common'
import { KBaselineCompareEmitsType, KBaselineComparePropType } from './interface'
import { KAddPlanDialog } from '../../controls'
import { ref } from 'vue'

/** KBaselineCompare */
export default class KBaselineCompareViewModel extends BaseViewModel<KBaselineCompareEmitsType, KBaselineComparePropType> {
  refToolStrip = ref<KObjectToolStripViewModel>()
  refBaselineGrid = ref<KDataGridViewModel>()
  refResultGrid = ref<KDataGridViewModel>()
  leftTreeNode = ref<IKTreeNode[]>([])
  rightTreeNode = ref<IKTreeNode[]>([])
  showCompareTree = ref<boolean>(false)
  showTreeNode = ref<boolean>(false)
  compareResult = ref<any>([])
  leftTitle = ref<string>('')
  rightTitle = ref<string>('')
  constructor(options: ViewModelOptions<KBaselineComparePropType>) {
    super(options)
  }

  /** 工具栏按钮 */
  toolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE,
      title: '基线对比',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    }
  ]

  viewDidMount() {
    this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE, true)
  }

  onToolStripItemClicked(event: any) {
    this.compareBaseline()
  }

  rowSelected() {
    const data = this.refBaselineGrid.value?.getSelectedRows()
    if (data?.length == 2) {
      this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE, false)
    } else {
      this.refToolStrip.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE, true)
    }
  }

  async loadData() {
    const result = (await Api.post('requirement', 'ProductNeeds', 'listBaseline', {
      data: [
        {
          id: this.props.objParam.id,
          clazz: this.props.objParam.modelCode
        }
      ]
    })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    }
  }

  async compareBaseline() {
    this.showCompareTree.value = true
    this.showTreeNode.value = false
    const selectRows = this.refBaselineGrid.value?.getSelectedRows()
    this.leftTitle.value = selectRows![0].baselineLabel
    this.rightTitle.value = selectRows![1].baselineLabel
    const params = {
      data: [
        [
          {
            id: selectRows![0].id,
            clazz: selectRows![0].rdmExtensionType
          },
          {
            id: selectRows![1].id,
            clazz: selectRows![1].rdmExtensionType
          }
        ]
      ]
    }
    const result = (await Api.post('requirement', 'ProductNeeds', 'baselineCompare', params)) as any
    this.showTreeNode.value = true
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.compareResult.value = result.data.differenceList.map((item: any, index: number) => {
        return {
          id: index,
          ...item
        }
      })
      const leftTreeRoot = result.data.treeDataList[0].filter((item: any) => !item.parentId)
      if (leftTreeRoot) {
        this.leftTreeNode.value = this.buildTree(result.data.treeDataList[0], leftTreeRoot.id)
        console.log(this.leftTreeNode.value)
      }
      const rightTreeRoot = result.data.treeDataList[1].filter((item: any) => !item.parentId)
      if (rightTreeRoot) {
        this.rightTreeNode.value = this.buildTree(result.data.treeDataList[1], rightTreeRoot.id)
      }
    }
  }

  /**
   * 构建树
   */
  buildTree(array: Array<IKTreeNode>, parentId: string): Array<IKTreeNode> {
    const result = [] as Array<IKTreeNode>
    array.forEach(item => {
      if (item.parentId == parentId) {
        item.children = this.buildTree(array, item.id)
        result.push(item)
      }
    })
    return result
  }

  refresh() {
    this.refResultGrid.value?.refresh()
  }

  exportAsExcel() {
    const params = {
      columnKeys: this.refResultGrid.value?.getColumnDefs().map((x: any) => x.id)
    }
    this.refResultGrid.value?.exportDataAsExcel(params)
  }

  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row.id,
      modelCode: row!.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.rdmExtensionType)
    }
    ObjectClientSrv.openObj(param)
  }
}
