import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","padding-bottom":"8px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input_search = _resolveComponent("k-input-search")!
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _directive_focus = _resolveDirective("focus")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    style: {"padding":"8px 0px","width":"100%","height":"100%"},
    ref: _ctx.$vm.refFolderTreeEl
  }, [
    _createVNode(_component_k_panel, null, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_k_input_search, {
            value: _ctx.$vm.queryCriteria,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.queryCriteria) = $event)),
            placeholder: "请输入需求名称",
            onSearch: _cache[1] || (_cache[1] = () => _ctx.$vm.searchProperty()),
            onChange: _cache[2] || (_cache[2] = () => _ctx.$vm.refreshQueryCriteria())
          }, null, 8, ["value"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_k_tree_view, {
          ref: _ctx.$vm.refRequireTree,
          nodeKey: "id",
          onAfterSelect: _cache[3] || (_cache[3] = (event) => _ctx.afterSelect(event)),
          defaultExpandedLevel: 0,
          autoSelectFirstNode: "",
          onAfterMenuClicked: _cache[4] || (_cache[4] = (event)=>_ctx.$vm.toolClick(event)),
          loadData: (node) => _ctx.loadTreeData(node)
        }, null, 8, ["loadData"])
      ]),
      _: 1
    })
  ])), [
    [_directive_focus]
  ])
}