import { connect, withInstall } from '@kmsoft/upf-core'
import KCadRelationDocView from './KCadRelationDoc.vue'
import KCadRelationDocViewModel from './KCadRelationDocViewModel'

const KCadRelationDoc = connect(KCadRelationDocView, KCadRelationDocViewModel)

export default withInstall(KCadRelationDoc)
export { KCadRelationDoc, KCadRelationDocView, KCadRelationDocViewModel }

// 模板生成文件
// export * from './{{folderName}}'
