import { connect, withInstall } from '@kmsoft/upf-core'
import KBaselineCompareDialogView from './KBaselineCompareDialog.vue'
import KBaselineCompareDialogViewModel from './KBaselineCompareDialogViewModel'

const KBaselineCompareDialog = connect(KBaselineCompareDialogView, KBaselineCompareDialogViewModel)

export default withInstall(KBaselineCompareDialog)
export { KBaselineCompareDialog, KBaselineCompareDialogView, KBaselineCompareDialogViewModel }

// 模板生成文件
// export * from './{{folderName}}'
