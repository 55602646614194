import { connect, withInstall } from '@kmsoft/upf-core'
import KOfficeDocumentEditorView from './KOfficeDocumentEditor.vue'
import KOfficeDocumentEditorViewModel from './KOfficeDocumentEditorViewModel'

const KOfficeDocumentEditor = connect(KOfficeDocumentEditorView, KOfficeDocumentEditorViewModel)

export default withInstall(KOfficeDocumentEditor)
export { KOfficeDocumentEditor, KOfficeDocumentEditorView, KOfficeDocumentEditorViewModel }

// 模板生成文件
// export * from './{{folderName}}'
