import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%","padding":"5px","display":"flex","flex-direction":"column"} }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = { style: {"flex":"1"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_switch = _resolveComponent("k-switch")!
  const _component_k_radio_group = _resolveComponent("k-radio-group")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_tool_strip, { ref: "refObjClsToolStrip" }, {
        default: _withCtx(() => [
          (_ctx.mode === _ctx.EnumTabType.SYS)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 0,
                name: "btnCheck",
                title: "校验",
                onClick: _cache[0] || (_cache[0] = () => _ctx.$vm.check())
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_k_tool_strip_button_item, {
            name: "btnSave",
            title: "保存",
            onClick: _cache[1] || (_cache[1] = () => _ctx.$vm.save())
          }),
          _createVNode(_component_k_tool_strip_button_item, {
            name: "btnRefresh",
            title: "刷新",
            onClick: _cache[2] || (_cache[2] = () => _ctx.$vm.refresh())
          }),
          (_ctx.$vm.showUpload)
            ? (_openBlock(), _createBlock(_component_k_tool_strip_button_item, {
                key: 1,
                name: "btnUpload",
                title: "上传",
                onClick: _cache[3] || (_cache[3] = () => _ctx.$vm.upload())
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_k_form, {
        ref: _ctx.$vm.refConfigValueFrom,
        "label-col": { span: 5 },
        "wrapper-col": { span: 16 },
        model: _ctx.$vm.formState
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_form_item, {
            label: "编码",
            name: "code",
            required: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_input, {
                value: _ctx.$vm.formState!.code,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.formState!.code) = $event)),
                maxlength: 40,
                disabled: true
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_k_form_item, {
            label: "名称",
            name: "name",
            required: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_input, {
                value: _ctx.$vm.formState!.name,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$vm.formState!.name) = $event)),
                maxlength: 40,
                disabled: true
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_k_form_item, {
            label: "配置值",
            name: "value",
            required: false
          }, {
            default: _withCtx(() => [
              (_ctx.$vm.formState!.editType === _ctx.EnumEditType.TEXT)
                ? (_openBlock(), _createBlock(_component_k_input, {
                    key: 0,
                    value: _ctx.$vm.formState!.value,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.$vm.formState!.value) = $event)),
                    maxlength: 64
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true),
              (_ctx.$vm.formState!.editType === _ctx.EnumEditType.TEXTAREA)
                ? (_openBlock(), _createBlock(_component_k_textarea, {
                    key: 1,
                    value: _ctx.$vm.formState!.value,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.$vm.formState!.value) = $event)),
                    maxlength: 64,
                    rows: 5
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true),
              (_ctx.$vm.formState!.editType === _ctx.EnumEditType.SELECT)
                ? (_openBlock(), _createBlock(_component_k_select, {
                    key: 2,
                    value: _ctx.$vm.formState!.value,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.$vm.formState!.value) = $event)),
                    options: _ctx.$vm.selectEditTypeOptions
                  }, null, 8, ["value", "options"]))
                : _createCommentVNode("", true),
              (_ctx.$vm.formState!.editType === _ctx.EnumEditType.SELECT_EDITABLE)
                ? (_openBlock(), _createBlock(_component_k_select, {
                    key: 3,
                    value: _ctx.$vm.formState!.values,
                    "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.$vm.formState!.values) = $event)),
                    options: _ctx.$vm.selectEditEditTypeOptions,
                    onChange: _cache[10] || (_cache[10] = (selectedArr) => _ctx.$vm.selectEditableChange(selectedArr)),
                    mode: "tags",
                    maxlength: 64
                  }, null, 8, ["value", "options"]))
                : _createCommentVNode("", true),
              (_ctx.$vm.formState!.editType === _ctx.EnumEditType.SELECT_DISPLAY)
                ? (_openBlock(), _createBlock(_component_k_select, {
                    key: 4,
                    value: _ctx.$vm.formState!.value,
                    "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.$vm.formState!.value) = $event)),
                    options: _ctx.$vm.selectDisplayEditTypeOptions
                  }, null, 8, ["value", "options"]))
                : _createCommentVNode("", true),
              (_ctx.$vm.formState!.editType === _ctx.EnumEditType.SWITCH)
                ? (_openBlock(), _createBlock(_component_k_switch, {
                    key: 5,
                    checked: _ctx.$vm.formState!.value_switch,
                    "onUpdate:checked": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.$vm.formState!.value_switch) = $event))
                  }, null, 8, ["checked"]))
                : _createCommentVNode("", true),
              (_ctx.$vm.formState!.editType === _ctx.EnumEditType.RADIO)
                ? (_openBlock(), _createBlock(_component_k_radio_group, {
                    key: 6,
                    value: _ctx.$vm.formState!.value,
                    "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.$vm.formState!.value) = $event)),
                    options: _ctx.$vm.radioEditTypeOptions
                  }, null, 8, ["value", "options"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_k_form_item, {
            label: "备注",
            name: "remark",
            required: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_textarea, {
                value: _ctx.$vm.formState!.remark,
                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.$vm.formState!.remark) = $event)),
                maxlength: 170,
                rows: 10,
                disabled: true
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ]))
}