
import { defineView, KDialog } from '@kmsoft/upf-core'
import { KQuickSearchBarPropOptions, KQuickSearchBarEventEmits } from './interface'
import KQuickSearchBarViewModel from './KQuickSearchBarViewModel'
import { AdvancedSearchFilterHelper } from '..'

/** 快速搜索 */
export default defineView({
  name: 'KQuickSearchBar',
  inheritAttrs: false,
  props: KQuickSearchBarPropOptions,
  emits: KQuickSearchBarEventEmits,
  viewModel: KQuickSearchBarViewModel,
  components: {},
  setup(props, { attrs, emit, slots, vm }) {
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.key == 'Enter') {
        onSearch()
      }
    }
    const onSearch = () => {
      const filter = vm.getAdvancedSearchFilter()
      if (!filter.conditions || filter.conditions.length <= 0) {
        KDialog.error({ title: '提示',
          content: '错误的搜索条件'
        })
        return
      }
      emit('search', filter)
    }

    return {
      onKeyPress,
      onSearch
    }
  }
})
