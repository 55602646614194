import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_data_grid, {
      ref: "refDataGrid",
      rowKey: "id",
      rowModelType: _ctx.EnumDataGridRowModelType.SERVER_SIDE,
      loadData: _ctx.$vm.loadData,
      pagination: { pageIndex: 1, pageSize: 50 },
      isMultipleSelection: false
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "模板编码",
          dataPropertyName: "number",
          align: "center",
          width: "250"
        }),
        _createVNode(_component_k_data_grid_text_box_column, {
          headerText: "模板名称",
          dataPropertyName: "name",
          align: "center",
          width: "250"
        })
      ]),
      _: 1
    }, 8, ["rowModelType", "loadData"])
  ]))
}