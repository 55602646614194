import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KObjectDisplayEmitsType, KObjectDisplayPropType } from './interface'
import { ref, watch } from 'vue'
import { clsCodeMap } from '../../../../client-srv'

/** KObjectDisplay */
export default class KObjectDisplayViewModel extends BaseViewModel<KObjectDisplayEmitsType, KObjectDisplayPropType> {
  /**当前值 */
  stateValue = ref<string>('')
  /**原始值 */
  originalValue = ref<string>()
  stateReadonly = ref<boolean>(false)
  inputValue = ref<string>('')
  constructor(options: ViewModelOptions<KObjectDisplayPropType>) {
    super(options)
    watch(
      () => options.props.readonly,
      newValue => {
        this.stateReadonly.value = newValue
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}
  displayTest() {
    const viewDescription = this.props.cellParams?.row?.partView?.description
    if (this.props.value) this.stateValue.value = this.props.value
    this.inputValue.value = clsCodeMap.get(this.stateValue.value) || '--'
    if (viewDescription) {
      return `${this.inputValue.value}(${viewDescription})`
    }
    return this.inputValue.value
  }
  getValue() {
    return this.stateValue.value
  }
  setValue(value: any, setChanged?: boolean) {
    this.stateValue.value = value
    if (setChanged) {
      this.originalValue.value = value
    }
  }
  getModifiedValue() {
    return this.stateValue.value
  }
  isModified() {
    return this.stateValue.value != this.originalValue.value
  }
}
