import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_folder_manage = _resolveComponent("k-folder-manage")!

  return (_openBlock(), _createBlock(_component_k_folder_manage, {
    loadData: _ctx.$vm.loadTreeData,
    showCADOperation: true,
    cadFolderId: _ctx.folderId
  }, null, 8, ["loadData", "cadFolderId"]))
}